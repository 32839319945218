import * as S from "./styles";
import { useClient, useDiscountFlex, useGlobals } from "@/hooks";
import { useState, useMemo } from "react";
import OrderTotals from "./orderTotals/orderTotals";
import ProductCard from "./productCard/productCard";
import { Accordion } from "@/components/accordion/Accordion";
import { PriceProductProps } from "@/types";

interface OrderResumeProps {
  cartItems: any;
  cartTotals?: any;
  productList?: any;
}

const OrderResume: React.FC<OrderResumeProps> = ({ productList, cartTotals }) => {
  const { paymentCode } = useGlobals();
  const { selectedClient } = useClient();
  const { getDiscountProduct } = useDiscountFlex();
  const [isExpanded, setIsExpanded] = useState(false);

  const quantity = useMemo(() => {
    if (productList) return productList.length;
    return null;
  }, [productList]);

  const quantityDetails = useMemo(() => {
    return (
      <S.QuantityWrapper>
        <S.QuantityLabel>Quantidade de itens</S.QuantityLabel>
        <S.Quantity>{quantity}</S.Quantity>
      </S.QuantityWrapper>
    );
  }, [quantity]);

  const productCard = useMemo(() => {
    if (!productList) return null;
    return productList.map((item: any) => {
      const product = item.product;
      const image = product.IMAGEM;
      const packing = item?.item?.option_uid;
      const [packingType, qtdEmb] = packing?.split("_");
      const quantity = item.item.quantity;
      const productName = product.DESC_PRODUTO;

      const discountPrice = getDiscountProduct({
        quantity: qtdEmb,
        package: packingType,
        productId: product.COD_PRODUTO,
      })?.discountPrice;

      const price = product?.PRECO?.find(
        (p: PriceProductProps) => {
          const qtd = Number(qtdEmb);
          if (packingType === "PK") {
            return p.SG_EMBALAGEM === packingType && p.NROCONDICAOPAGTO === paymentCode && p.QTD_MULTIPLO_VENDA === qtd;
          }
          return p.SG_EMBALAGEM === packingType && p.NROCONDICAOPAGTO === paymentCode && p.QTD_EMBALAGEM === qtd;
        }
      ) as PriceProductProps;

      // console.log('produto e preco: ', price);
      const productPrice =
        discountPrice?.[`${paymentCode}`] ||
        price?.VLR_FINAL ||
        price?.VLR_PRECO;

      const stPrice = price?.ST_VLR_FINAL;
      // console.log('st price: ', stPrice);

      //alterar logica quando for implementar
      const unityPrice = Number((productPrice / qtdEmb));

      const calcPrice = price?.VLR_PRECO_CALCULADO;

      return (
        <ProductCard
          name={productName}
          uid={item?.item?.uid}
          productPrice={productPrice}
          productTotalPrice={productPrice * quantity}
          unityPrice={unityPrice}
          packing={packing}
          img={image}
          stPrice={stPrice}
          quantity={quantity}
          client={selectedClient}
          productCalculatedPrice={calcPrice}
          productTaxCode={price?.COD_TRIBUTACAO_PRODUTO}
        />
      );
    });
  }, [productList, paymentCode, getDiscountProduct, selectedClient]);

  return (
    <S.ResumeWrapper>
      <Accordion
        id="resumo-do-pedido"
        accordionExpanded={isExpanded}
        headerDetails={quantityDetails}
        accordionTitle="Resumo do pedido"
        setAccordionExpanded={setIsExpanded}
        noBodyPadding
        maxheight="unset"
      >
        <S.ProductCardList>{productCard}</S.ProductCardList>
      </Accordion>
      <OrderTotals successTotals={cartTotals} />
    </S.ResumeWrapper>
  );
};

export default OrderResume;
