import "./styles.css";
import { colors } from "@/constants";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const ContentBanner = styled.div`
  padding: 0px 15px;
`;

export const LinkHref = styled.a``;

export const RouteLink = styled(Link)``;

export const Banner = styled.img`
  width: 100%;
  border-radius: 6px;
`;

export const Indicator = styled.img<{ isactive?: number }>`
  height: 10px;
  margin: 0px 4px;
  border: none;
  transition: all 0.25;
  border-radius: 10px;
  width: ${(props) => (props.isactive ? 20 : 10)}px;
  background-color: ${(props) =>
    props.isactive ? colors.brand.red : colors.gray.gray40};
`;

export const GalleryItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.brand.white};
  img {
    object-fit: contain;
    aspect-ratio: 1/1;
    width: 100%;
  }
`;
