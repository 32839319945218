import styled from "styled-components";
import { colors } from "@/constants";

export const FooterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    bottom: 0px;
    z-index: 10;

    position: fixed;
    bottom: 0; /* Garante que o footer fique sempre no bottom */
    width: 100%;
    max-width: 500px;
    height: 105px;
    padding: 20px;

    background-color: ${colors.brand.white};
    border-radius: 8px 8px 0px 0px;
    border-top: solid 1px ${colors.gray.gray10};
    box-shadow: 0px -6px 20px 0px ${colors.shadown6};
`;

export const BackButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 55px;
    height: 55px;
    border-radius: 10px;
    border: solid 1px ${colors.gray.gray10};
    background-color: ${colors.brand.white};

    svg{
        color: ${colors.gray.gray40};
    }
`;

export const NextButton = styled.div<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 175px;
    height: 55px;
    display: flex;
    border-radius: 10px;
    background-color: ${props => props.disabled ? colors.gray.gray10 : colors.alert.green110};

    font-size: 16px;
    line-height: 18.75px;
    font-weight: 400;
    color: ${({ disabled }) => disabled ? colors.gray.gray40 : colors.brand.white};
`;