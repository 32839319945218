import * as S from "./styles";
import React, { useMemo } from "react";
import { StPrice } from "@/components";
import { PackingTypes, formatCurrency } from "@/shared";

import { ItemsProps } from "@/talons/orderStatusPage/useOrderStatusPage";
import { PackagesTypesProps, PackagingProductProps } from "@/types";
import { useDiscountFlex } from "@/hooks";
import OrderStatusCounter from './order-status-counter';
import { RiExchangeDollarLine } from "react-icons/ri";
import colors from "@/constants/systemColors";

interface CardProps {
  item: ItemsProps;
  isFirst?: boolean;
  addProductToCart?: any;
  updateProductAmount?: any;
  removeProductFromCart?: any;
}

const CartProductCard = ({ item, isFirst }: CardProps) => {

  const { getDiscountProduct } = useDiscountFlex();

  const packing = useMemo(() => {
    return item?.EMBALAGEM?.find((i: PackagingProductProps) => {
      const [pack, qtd] = item?.packaging?.split("_");
      const qty = Number(qtd);

      if (pack === "PK") {
        return i?.SG_EMBALAGEM === pack &&
          i?.QTD_MULTIPLO_VENDA === qty;
      }
      return i?.SG_EMBALAGEM === pack && i?.QTD_EMBALAGEM === qty;
    })
  }, [item?.EMBALAGEM, item?.packaging]) as PackagingProductProps;

  const packaging = useMemo(() => {
    const [pack, qtd] = item?.packaging?.split("_");

    return {
      pack,
      qtd,
    }
  }, [item?.packaging]) as { pack: PackagesTypesProps, qtd: string };

  // const price = useMemo(() => {
  //   if (item?.integrationPriceFlex && item?.stPricePackaging) {
  //     return item?.integrationPriceFlex + item?.stPricePackaging;
  //   } else if (item?.integrationPriceFlex && !item?.stPricePackaging) {
  //     return item?.integrationPriceFlex;
  //   } else {
  //     return item?.price;
  //   }
  // }, [item?.price, item?.stPricePackaging, item?.integrationPriceFlex]);

  const amountProduct = useMemo(
    () => {
      const [pack, qtd] = item?.packaging?.split("_");

      const qty = Number(qtd);

      return getDiscountProduct({
        package: pack,
        quantity: qty,
        productId: item.COD_PRODUTO,
      })
    },
    [item, getDiscountProduct]
  );

  if (item?.quantity === 0) {
    return null;
  }

  return (
    <S.Container $isfirst={isFirst}>
      <S.Subcontainer>
        <S.ProductImage
          alt={item?.product}
          src={`data:image/jpg;base64,${item?.IMAGEM}`}
        />
        <S.ProductDescriptionRow>
          <S.ProductDescription>{item?.product}</S.ProductDescription>
          {Number(item?.percentualFlex) !== 0 && item.price && (
            <S.FlexPercentage $positive={Number(item?.percentualFlex) > 0}>
              <RiExchangeDollarLine size={20} color={colors.brand.grafite} />
              <S.FlexPercentageLabel>{Number(item?.percentualFlex).toFixed(2)}%</S.FlexPercentageLabel>
            </S.FlexPercentage>
          )}
        </S.ProductDescriptionRow>
        {item?.price ? (
          <S.PriceBlock>
            <S.PriceRow>{formatCurrency({ value: item?.price })}</S.PriceRow>
            <S.Price>{formatCurrency({ value: item?.unitPrice })}</S.Price>
            {Number(item?.stPrice) > 0 && <StPrice precoSt={Number(item?.stPrice)} />}
            <S.UnityPrice>
              {formatCurrency({ value: item?.unitPricePerItem })}/un.
            </S.UnityPrice>
          </S.PriceBlock>
        ) : null}
      </S.Subcontainer>
      <S.Subcontainer alignItems="center">
        {<S.ButtonContainer
        // data-test={`remove-product-button-${item?.sku}`}
        >
          <S.RemoveButton
            onClick={() => { }}
          >
            <S.RemoveButtonLabel>
              {/* Remover */}
            </S.RemoveButtonLabel>
          </S.RemoveButton>
        </S.ButtonContainer>}
        <S.PackingBlock>
          <S.IconContainer $radius={4}>
            <S.Packing src={PackingTypes?.[packaging?.pack]?.icon} />
          </S.IconContainer>
          <S.PackingDescription>
            <S.PackingLabel>
              {PackingTypes?.[packaging?.pack]?.packingTitle}
            </S.PackingLabel>
            <S.PackingUnities>
              {packaging?.qtd} unidades
            </S.PackingUnities>
          </S.PackingDescription>
        </S.PackingBlock>
        <S.ButtonContainer>
          {!item?.price ? (
            <S.Unavailable>Indisponível</S.Unavailable>
          ) : (
            <S.CounterWrapper>
              <OrderStatusCounter
                id={item?.sku}
                quantity={item?.quantity}
                background="light"
                showBorder={true}
              />
            </S.CounterWrapper>

          )}
        </S.ButtonContainer>
      </S.Subcontainer>
    </S.Container>
  );
};

export default CartProductCard;