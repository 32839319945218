import * as S from './styles';

import { RiArrowLeftSLine } from "react-icons/ri";

interface FooterProps {
    handleNextStep?: () => void;
    handleBackStep?: () => void;
    disabled?: boolean;
    activeStep?: number;
}

export const Footer: React.FC<FooterProps> = ({ handleNextStep, handleBackStep, disabled, activeStep }) => {
    return (
        <S.FooterWrapper>
            <S.BackButton onClick={handleBackStep}>
                <RiArrowLeftSLine size={34} />
            </S.BackButton>
            <S.NextButton onClick={handleNextStep} disabled={disabled}>
                {activeStep === 7 ? 'Criar conta' : 'Avançar'}
            </S.NextButton>
        </S.FooterWrapper>
    )
}