import * as S from "./styles";
import { Menu } from "@/components";
import { colors } from "@/constants";
import { RiSearchLine } from "react-icons/ri";

const RenderBuilding = () => {
  return (
    <S.Container>
      <S.Content>
        <RiSearchLine size={75} color={colors.gray.gray40} />
        <S.Label>Página não encontrada</S.Label>
      </S.Content>
      <Menu />
    </S.Container>
  );
};

export default RenderBuilding;
