import { useGlobals } from "@/hooks";
import * as Sentry from "@sentry/react";
import { useUserContext } from "@/hooks";
import { useMutation } from "@apollo/client";
import DEFAULT_OPERATIONS from "./signIn.gql";
import { useNetworkStatusContext } from "@/hooks";
import { tableKeys } from "@/shared/storageFunctions";
import { validateCNPJ } from "@/shared/formValidator";
import { useDbFunctions } from "../dbFunctions/useDBFunctions";
import { FormEvent, useCallback, useEffect, useState } from "react";
import { getOnStorage, removeFromStorage, setOnStorage } from "@/shared";

interface ErrorControl {
  error: boolean;
  message?: string;
}

export const useSignIn = () => {
  const { setIsOpenResetPasswordModal } = useGlobals();

  const { signInMutation } = DEFAULT_OPERATIONS;
  const { deleteDB } = useDbFunctions();
  const { connectivityStatus } = useNetworkStatusContext();
  const { setIsSignedIn, getCustomer, setEmail } = useUserContext();

  
  const [inputValue, setInputValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [inputMaxValue, setInputMaxValue] = useState<number>(100);
  
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [taxVatValid, setTaxVatValid] = useState<boolean>(false);
  const [taxVatExist, setTaxVatExist] = useState<boolean>(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);

  const [passwordValid, setPasswordValid] = useState<boolean>(false);

  const [inputError, setInputError] = useState<ErrorControl>({ error: false });
  const [loginError, setLoginError] = useState<ErrorControl | null>(null);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const cnpjRegex = /^\d{14}$/;

  const [signIn, { loading: loadingSignIn }] = useMutation(signInMutation, {
    fetchPolicy: "no-cache",
  });

  const handleLoginInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.trim();
      setInputValue(value);

      const cleanValue = value.replace(/\D/g, "");
      if (cleanValue.length === 14) {
        if (cnpjRegex.test(cleanValue)) {
          setInputMaxValue(14);
          const formatted = handleFormatCNPJ(cleanValue);

          setInputValue(formatted);
          setTaxVatValid(true);
          setEmailValid(false);
          return;
        }
      } else {
        setTaxVatValid(false);
        setInputMaxValue(100);
      }

      if (emailRegex.test(value)) {
        setInputMaxValue(100);
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    },
    [emailRegex, cnpjRegex]
  );

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const password = event.target.value;
    setPasswordValue(password);

    if (password) {
      setPasswordValid(true);
    } else {
      setPasswordValid(false);
    }
  };

  const handleFormatCNPJ = (cnpj: string) => {
    const digits = cnpj.replace(/\D/g, "");
    if (digits.length === 14) {
      return `${digits.slice(0, 2)}.${digits.slice(2, 5)}.${digits.slice(
        5,
        8
      )}/${digits.slice(8, 12)}-${digits.slice(12, 14)}`;
    }
    return cnpj;
  };

  const getUserNode = (response: any) => {
    const tipoUsuario = response.tipo_usuario;
    return response[tipoUsuario.toLowerCase()] || null;
  };

  const saveAwsData = useCallback(
    async (awsData: any) => {
      awsData = JSON.parse(awsData);
      const res = await getCustomer();

      if (res?.data?.customer) {
        const { data } = res;
        const { customer } = data;
        const rcaData = {
          tipoUsuario: awsData?.tipo_usuario,
          firstname: customer?.firstname,
          lastname: customer?.lastname,
          nomeUsuario: awsData?.nomeUsuario,
          codPessoa: awsData?.cod_pessoa,
          fechaPedido: awsData?.fecha_pedido,
          viewDashboard: awsData?.view_dashboard,
          email: customer?.email,
          phone: customer?.personal_phone,
          taxvat: customer?.taxvat,
        };
        const dataSets = getUserNode(awsData)[0].link_Dataset;

        if (rcaData && dataSets) {
          setOnStorage("rca_usuario", rcaData);
          setOnStorage("new_data_sets", dataSets)
        }
      }
    },
    [getCustomer]
  );

  const loadDatasets = useCallback((count = 0) => {
    if (count === 5) return;

    const listDataBase = getOnStorage("new_data_sets");
    if (listDataBase?.length) {
      setIsLoadingFiles(true);
      return;
    }

    setTimeout(() => {
      loadDatasets(count + 1);
    }, 400);
  }, []);

  const handleSignIn = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      const loginDate = new Date();
      const lastLogin = getOnStorage('login')
      
      if (lastLogin && inputValue !== lastLogin) {
        if (!window.confirm('Ao realizar um novo login com o usuário diferente do último acesso, isto irá apagar os carrinhos e dados já carregados caso exista. Deseja prosseguir?')) {
          return
        }
        tableKeys.forEach(key => removeFromStorage(key));
        await deleteDB()
      }
      setOnStorage("login_date", loginDate);
      setOnStorage("login", inputValue);
      setIsContinueDisabled(true);
      if (connectivityStatus === "offline") {
        const token_offline = getOnStorage("token_offline");
        if (token_offline) {
          Sentry.captureException('Tem token offline');
          setOnStorage("token_magento", token_offline);
          setOnStorage("token_offline", null);
          setIsSignedIn(true);
        }
      } else {
        try {
          const variables = emailValid
            ? { email: inputValue, password: passwordValue }
            : taxVatValid
              ? { taxvat: inputValue, password: passwordValue }
              : null;

          if (!variables) {
            setLoginError({
              error: true,
              message: "Dados inválidos. Verifique e tente novamente.",
            });
            return;
          }

          const res = await signIn({ variables });

          if (res && !res?.errors) {
            const RCALogin = res?.data?.RCALogin;
            if (RCALogin?.magento_token && RCALogin?.aws_data) {
              setOnStorage("token_magento", RCALogin?.magento_token);
              await saveAwsData(RCALogin?.aws_data);
            }

            if (RCALogin?.aws_token) {
              setOnStorage("token_aws", RCALogin.aws_token);
            }

            if (RCALogin?.aws_data) {
              loadDatasets();
            }
          }
        } catch (e) {
          setLoginError({
            error: true,
            message:
              "Não foi possível fazer login. Verifique os dados e tente novamente.",
          });
          setIsContinueDisabled(false);
        }
      }
    },
    [
      inputValue,
      emailValid,
      taxVatValid,
      passwordValue,
      signIn,
      saveAwsData,
      loadDatasets,
      setIsSignedIn,
      deleteDB,
      connectivityStatus
    ]
  );

  useEffect(() => {
    if (taxVatValid && inputValue) {
      const isCnpjValid = validateCNPJ(inputValue);
      setTaxVatExist(isCnpjValid);
    } else {
      setTaxVatExist(false);
    }
  }, [emailValid, taxVatValid, inputValue]);

  useEffect(() => {
    if (!taxVatExist && inputValue && taxVatValid) {
      setInputError({
        error: true,
        message: "Este CNPJ não existe. Tente novamente!",
      });
    } else {
      setInputError({
        error: false,
      });
    }
  }, [taxVatValid, inputValue, taxVatExist]);

  const handleRedirect = useCallback(() => {
    if (inputValue && emailValid) {
      setEmail(inputValue);
      setIsOpenResetPasswordModal(true);
    } else {
      setLoginError({
        error: true,
        message:
          "Verifique seu endereço de e-mail para prosseguir com o reset de senha.",
      });
    }
  }, [setLoginError, setEmail, setIsOpenResetPasswordModal, emailValid, inputValue]);

  return {
    isLoadingFiles,
    setIsLoadingFiles,
    handleLoginInput,
    handlePasswordChange,
    emailValid,
    passwordValue,
    passwordValid,
    inputValue,
    taxVatExist,
    inputError,
    inputMaxValue,
    setIsSignedIn,
    handleSignIn,
    loginError,
    loading: loadingSignIn,
    isContinueDisabled,
    handleRedirect
  };
};
