import { colors } from "@/constants";
import styled from "styled-components";

export const ShippingInfoWrapper = styled.div`
  background-color: ${colors.brand.white};

  border: solid 1px ${colors.background1};
  border-radius: 6px;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  height: fit-content;
  padding: 20px 16px;

  &:not(:last-of-type) {
    border-bottom: solid 1px ${colors.background1};
  }
`;

export const ShippingIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 30px;
  width: 30px;
  height: 30px;

  border-radius: 50%;

  background-color: ${colors.brand.grafite};

  svg {
    color: ${colors.brand.white};
  }
`;

export const ShippingInformation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Tag = styled.span`
  padding: 4px 8px;
  background-color: ${colors.grey4};
  border-radius: 3px;
  width: fit-content;

  color: ${colors.grey3};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${colors.brand.grafite};
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.grey3};

  > div {
    display: flex;
    flex-direction: row;
    gap: 3px;

    padding-bottom: 16px;
  }
`;

export const FreeTag = styled.span`
  color: ${colors.green};
  font-size: 16px;
  font-weight: 900;
  margin-top: 8px;
`;
