import * as S from "./styles";
import { TextInput } from "@/components";
import { PasswordValidator } from "@/components";
import { useUserDataPage } from "@/talons/userDataPage/useUserDataPage";

const UserData = () => {
  const {
    userData,
    handleInputChange,
    handlePasswordChange,
    newPassword,
    handleChangeCustomerData,
    allowed,
    passwordError,
    passwordSuccess,
    userConnectivity,
    loading 
  } = useUserDataPage();


  return (
    <>
      <S.Wrapper>
        <S.UserDataWrapper>
          <S.Form className="disabled">
            <TextInput
              id="user-email"
              name="email"
              value={userData.email}
              onChange={() => null}
              label={"E-mail"}
              type="email"
              required={false}
              noFocusBorder={true}
              disabled={true}
            />
          </S.Form>
          <S.FormWrapper>
            <S.Label>Editar dados</S.Label>
            <S.Form>
              <S.FormControl>
                <TextInput
                  id="user-firstname"
                  name="firstName"
                  value={userData.firstName}
                  onChange={handleInputChange}
                  label={"Nome"}
                  type="text"
                  required={false}
                  noFocusBorder={true}
                />
              </S.FormControl>
              <S.FormControl>
                <TextInput
                  id="user-lastname"
                  name="lastName"
                  value={userData.lastName}
                  onChange={handleInputChange}
                  label={"Sobrenome"}
                  type="text"
                  required={false}
                  noFocusBorder={true}
                />
              </S.FormControl>
              <S.FormControl className="small-control">
                <TextInput
                  id="user-cpf"
                  name="cpf"
                  value={userData.cpf}
                  onChange={() => null}
                  label={"CPF/CNPJ"}
                  type="text"
                  required={false}
                  noFocusBorder={true}
                  disabled={true}
                />
              </S.FormControl>
              <S.FormControl className="small-control disabled">
                <TextInput
                  id="user-phone"
                  name="phone"
                  value={userData.phone}
                  onChange={handleInputChange}
                  label={"DDD + Celular"}
                  type="text"
                  required={false}
                  noFocusBorder={true}
                  maxLenght={11}
                  disabled={true}
                />
              </S.FormControl>
            </S.Form>
            <S.Form>
              <S.Label>Alterar senha</S.Label>
              <S.FormControl className="password-control">
                <TextInput
                  id="user-actual-password"
                  name="actualPassword"
                  value={userData.actualPassword}
                  onChange={handleInputChange}
                  label={"Senha atual"}
                  type="password"
                  required={true}
                  noFocusBorder={true}
                  errorMessage={passwordError?.message}
                  error={passwordError?.error}
                  success={passwordSuccess?.success}
                  successMessage={passwordSuccess?.message}
                />
              </S.FormControl>
              <S.FormControl className="password-control">
                <TextInput
                  id="user-new-password"
                  name="newPassword"
                  value={newPassword ? newPassword : ""}
                  onChange={handlePasswordChange}
                  label={"Crie sua nova senha"}
                  type="password"
                  required={true}
                  noFocusBorder={true}
                />
              </S.FormControl>
            </S.Form>
            <PasswordValidator password={newPassword} />
          </S.FormWrapper>
        </S.UserDataWrapper>
        <S.SaveModal>
          {!userConnectivity && <S.Connectivity>Conecte-se para alterar seus dados.</S.Connectivity>}
          <S.SaveButton disabled={!allowed || loading || !userConnectivity} onClick={() => handleChangeCustomerData()}>Salvar Alterações</S.SaveButton>
        </S.SaveModal>
      </S.Wrapper>
    </>
  );
};

export default UserData;
