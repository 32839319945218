import React, { Dispatch, InputHTMLAttributes, SetStateAction } from 'react';
import * as S from './styles';
import { SearchField } from "@/components";
import { useCart } from '@/hooks';

interface SearchInputProps extends InputHTMLAttributes<any> {
  onBlur?: () => void;
  onFocus?: () => void;
  setInput: Dispatch<SetStateAction<string>>;
  id: string;
  value: string;
}

const SearchInput = ({
  onBlur,
  onFocus,
  setInput,
  value,
  ...rest
}: SearchInputProps) => {

  const { setIsSearching, setFilterTerm } = useCart();
  return (
    <S.InputContainer>
       <SearchField
          onFocus={onFocus}
          value={value}
          showMicrophone={false}
          showCamera={false}
          setInput={setInput}
          {...rest}
        />
        <S.CancelButton $show={rest?.id === 'carrinho' ? true : !!value} onClick={() => {
          setFilterTerm("");
          setIsSearching(prev => !prev);
          }}>
          Cancelar
        </S.CancelButton>
    </S.InputContainer>
  )
}

export default SearchInput;