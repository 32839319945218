import * as S from './styles';
import { useState, useEffect } from 'react';
import { TextInput, Select } from '@/components';
interface CreateAccountProps {
    cnpjDataController: any,
    ufData: any,
    loading: boolean,
    handleSubmit: () => void
}

export const CreateAccount: React.FC<CreateAccountProps> = ({ cnpjDataController, ufData, handleSubmit, loading }) => {
    const { cnpjInput, isCnpjValid, handleCnpjInput } = cnpjDataController;
    const { ufValue, handleChangeUfInput, handleClickSelect, activeUf, ufList } = ufData;

    const buttonDisabled = (!isCnpjValid || !ufValue) || loading;

    return (
        <S.Container>
            <S.Label>Comece pelo número do seu CNPJ, para que possamos consulta-lo.</S.Label>
            <S.Form>
                <TextInput
                    value={cnpjInput}
                    onChange={handleCnpjInput}
                    type="text"
                    fillwidth
                    id={'inputCNPJ'}
                    name={'inputCNPJ'}
                    label={cnpjInput ? 'CNPJ' : 'Digite o seu CNPJ'}
                    data-test="cadastro-input-cnpj"
                    required={true}
                    maxLenght={18}
                    success={isCnpjValid && cnpjInput}
                />
                <Select
                    value={ufValue}
                    handleChangeInput={handleChangeUfInput}
                    handleClickSelect={handleClickSelect}
                    active={activeUf}
                    options={ufList}
                    defaultLabel='Selecione o Estado'
                    activeLabel='Estado'

                />
                <S.ContinueButton disabled={buttonDisabled} onClick={handleSubmit}>
                    Continuar
                </S.ContinueButton>
            </S.Form>
        </S.Container>
    )
}