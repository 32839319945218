import { colors } from "@/constants";
import styled from "styled-components";

import {
  RiCurrencyLine,
  RiArrowLeftLine,
  RiAccountBoxLine,
  RiCheckboxCircleFill,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";

interface PropsIcon {
  margintop?: number;
  marginleft?: number;
}

export const AccountBoxLine = styled(RiAccountBoxLine)`
  margin-left: 10px;
  color: ${colors.brand.white};
`;

export const ArrowLeftLine = styled(RiArrowLeftLine)`
  margin-right: 10px;
  color: ${colors.brand.white};
`;

export const Div = styled.div``;

export const ListBg = styled.div`
  gap: 1px;
  display: flex;
  padding: 1px 0px;
  margin-bottom: 10px;
  flex-direction: column;
  background-color: ${colors.grey4};
`;

export const CheckContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding-right: 16px;
  margin-right: 16px;
  border-right: 1px solid ${colors.grey11};
`;

export const Check = styled.div``;

export const PaymentIcon = styled(RiCurrencyLine)`
  margin-right: 10px;
`;

export const CheckFill = styled(RiCheckboxCircleFill)``;

export const CheckBlank = styled(RiCheckboxBlankCircleLine)``;

export const TitlePaymentForm = styled.p`
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  color: ${colors.brand.grafite};
`;

export const FinalPrice = styled.span`
  font-weight: 700;
  margin-left: 5px;
`;

export const PaymendCard = styled.button<{ $isactive: boolean }>`
  border: none;
  padding: 12px 16px;
  align-items: center;
  display: inline-flex;
  background-color: ${(i) =>
    i.$isactive ? colors.alert.green50 : colors.brand.white};
`;

export const ButtonContent = styled.div`
  bottom: 0px;
  bottom: 0px;
  position: sticky;
  padding: 26px 16px;
  background-color: ${colors.brand.white};
  box-shadow: 0px -4px 10px 0px ${colors.shadown6};
`;

export const ButtonModal = styled.button`
  border: none;
  width: 100%;
  padding: 18px 0px;
  border-radius: 8px;
  color: ${colors.brand.white};
  background-color: ${colors.green};
`;

export const ModalContet = styled.div`
  position: relative;
`;

export const ModalTitle = styled.h5`
  top: 0px;
  font-size: 16px;
  font-weight: 500;
  padding-top: 30px;
  position: sticky;
  text-align: center;
  padding-bottom: 18px;
  background-color: ${colors.brand.white};
`;

export const PaymentMode = styled.p`
  color: ${colors.brand.white};
  margin: 0px;
`;

export const Input = styled.input`
  &::placeholder {
    font-size: 14px;
  }
  width: 100%;
  border: none;
  outline: none;
`;

export const Icon = styled.img<PropsIcon>`
  margin-top: ${(props) => props.margintop || 0}px;
  margin-left: ${(props) => props.marginleft || 0}px;
`;

export const Button = styled.button`
  padding: 0px;
  border: none;
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  background-color: ${colors.transparent};
`;

export const Row = styled.div`
  /* gap: 10px; */
  width: 100%;
  align-items: center;
  display: inline-flex;
`;

export const RowButton = styled.button`
  width: 100%;
  border: none;
  padding: 0px;
  cursor: pointer;
  margin-top: 10px;
  align-items: center;
  display: inline-flex;
  background-color: ${colors.transparent};
  justify-content: space-between;
`;

export const InputContent = styled.div`
  gap: 10px;
  width: 100%;
  height: 44px;
  display: flex;
  background: ${colors.brand.white};
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const SearchContent = styled.div`
  top: 0px;
  bottom: 0px;
  z-index: 9999;
  position: sticky;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 14px;
  padding: 12px 15px 12px 15px;
  width: -webkit-fill-available;
  box-shadow: 0px 10px 15px 0px ${colors.shadown2};
  background-color: ${colors.brand.grafite};
`;

export const CancelButton = styled.button<{ $show: boolean }>`
  flex: none;
  border: none;
  padding: 0px;
  display: flex;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.25s;
  color: ${colors.brand.white};
  opacity: ${(i) => (i.$show ? 1 : 0)};
  background-color: ${colors.transparent};
  margin-left: ${(i) => (i.$show ? 10 : 0)}px;
  max-width: ${(i) => (i.$show ? "100%" : "0px")};
`;
