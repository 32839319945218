import { colors } from "@/constants";
import styled from "styled-components";

export const ProductCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 2fr;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 24px 16px 16px 16px;

  &:not(:last-of-type){
    border-bottom: solid 1px ${colors.background1};
  }
`;

export const ProductImage = styled.img`
  width: 58px;
  height: 58px;
`;

export const ProductImageWrapper = styled.div`
  width: 58px;
  height: 58px;
  position: relative;
`;

export const QuantityLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 22px;
  height: 22px;
  background-color: ${colors.red};
  border-radius: 6px;

  color: ${colors.brand.white};
  font-weight: 600;
  font-size: 14px;
  line-height: 16.41px;

  position: absolute;
  top: -11px;
  right: -11px;
`;

export const ProductName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 16px;
`;

export const Name = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  color: ${colors.gray.gray60};
  padding-top: 8px;
`;

export const Prices = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-end;
`;

export const TotalPrice = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  color: ${colors.brand.grafite};
`;

export const Price = styled.span`
  font-size: 11px;
  font-weight: 400;
  line-height: 12.89px;
  color: ${colors.grey3};
`;

export const UnityPrice = styled.div`
  display: flex;

  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;

  background-color: ${colors.grey4};
  color: ${colors.grey3};
  padding: 3px 5px;
`;

export const PackingBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 12.89px;
  color: ${colors.grey3};
`;

export const Packing = styled.img`
  width: 18px;
  height: 18px;
`;

export const PackingLabel = styled.span`
  text-align: left;
`;

export const PackingUnities = styled.span`
  text-align: left;
`;

export const PackingDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;