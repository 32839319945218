import * as S from './styles'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Step } from './step/Step';

const steps = [
    {
        name: 'carrinho'
    },
    {
        name: 'pagamento'
    },
    {
        name: 'obrigado'
    }
];

const StepperContainer: React.FC = () => {
    const [activeStepIndex, setActiveStepIndex] = useState(0);

    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname === '/carrinho') {
            setActiveStepIndex(0);
        } else if (pathname === '/checkout') {
            setActiveStepIndex(1);
        } else if (pathname.includes('sucesso')) {
            setActiveStepIndex(2);
        }
    }, [pathname]);


    return (
        <S.Container>
            {steps.map((step, index: number) => {
                const isStepComplete = index < activeStepIndex;
                            
                return (
                    <>
                        <Step key={step.name} step={step} isComplete={isStepComplete} isActive={index === activeStepIndex} totalLength={steps.length} index={index}/>
                    </>
                )
            }
            )}
        </S.Container>
    )
}

export default StepperContainer;