import styled from "styled-components";
import { colors } from "@/constants";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
     
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;

    background-color: ${colors.brand.white};

`;

export const Header = styled.div`
    width: 100%;
    padding: 12px 4px;
`;

export const CloseButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    width: 50px;
    height: 50px;

    border: none;

    svg{
        width: 22px;
        height: 22px;
        color: ${colors.gray.gray50};
    }
`;

export const FullGalleryWrapper = styled.div`
    padding-top: 40%;
    flex-grow: 1;
`;