import React from "react";
import { LinkProps } from "@/types";
import { Container } from "@/components";
import * as R from "@/constants/systemRoutes";
import ProtectedRoute from "./ProtectedRoute";
import { HashRouter, Route, Routes } from "react-router-dom";

interface RouteProps {
  id: string;
  path: string;
  element: JSX.Element;
  isProtected: boolean;
}

const allViews = require("@/views");
const allRoutes = Object.values(R);

const routes = allRoutes
  .filter((r) => r.view)
  .map(({ id, link: path, view, isProtected }: LinkProps): RouteProps => {
    const Element = allViews?.[view || "NoMatch"];
    return {
      id,
      path,
      element: <Element />,
      isProtected: isProtected || false,
    };
  });

const CustomRoutes: React.FC = () => (
  <HashRouter>
    <Routes>
      {routes.map((route) =>
        route.isProtected ? (
          <Route
            key={route.id}
            path={route.path}
            element={
              <ProtectedRoute
                requiredAccess={
                  route.id === "CHECKOUT"
                    ? "checkout"
                    : route.id === "SUCCESS"
                    ? "success"
                    : route.id
                }
                element={<Container>{route.element}</Container>}
              />
            }
          />
        ) : (
          <Route
            key={route.id}
            path={route.path}
            element={<Container>{route.element}</Container>}
          />
        )
      )}
    </Routes>
  </HashRouter>
);

export default CustomRoutes;
