import styled from "styled-components";
import { colors } from "@/constants";
import { RiArrowDownSLine, RiArrowDownSFill, RiShoppingCartLine, RiShoppingBag3Line } from "react-icons/ri";

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
`

export const Header = styled.div`
  padding: 0px 35px 58px 35px;
  background-color: ${colors.brand.grafite};
`;

export const TopRow = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Activity = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray.gray30};
`

export const AverageSale = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.brand.white};
`

export const SelectPeriod = styled.div`
  padding: 10px 12px;
  border-radius: 6px;
  background-color: ${colors.grey7};
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.gray.gray20};
`
export const ArrowDownIcon = styled(RiArrowDownSLine)``

export const BarGraph = styled.div`
  transform: translateY(30px);
`

export const PerformanceDisplay = styled.div`
  background-color: ${colors.grey7};
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 12px 20px;
  max-width: 350px;
  position: absolute;
  top: 243px;
  left: 20px;
`

export const Percentage = styled.p`
  font-size: 32px;
  font-weight: 900;
  color: ${colors.brand.white};
`

export const Comparison = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.brand.white};
`

export const Sales = styled.div`
  display: flex;
  padding: 43px 20px 20px 20px;
  background-color: ${colors.brand.white};
  justify-content: space-between;
`

export const VerticalCard = styled.div`
  border: 1px solid ${colors.grey11};
  border-radius: 8px;
  overflow: hidden;
  box-sizing: border-box;
`

export const UpperVerticalCard = styled.div`
  padding: 16px;
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ShoppingCartIcon = styled(RiShoppingCartLine)<{$color: string}>`
  ${({ $color }) => {
    if($color === 'green') {
      return `color: ${colors.alert.green300};`
    }
    if($color === 'orange') {
      return `color: ${colors.alert.orange300};`
    }
    if($color === 'red') {
      return `color: ${colors.alert.red200};`
    }
  }}
`

export const ShoppingBagIcon = styled(RiShoppingBag3Line)<{$color: string}>`
  ${({ $color }) => {
    if($color === 'green') {
      return `color: ${colors.alert.green300};`
    }
    if($color === 'orange') {
      return `color: ${colors.alert.orange300};`
    }
    if($color === 'red') {
      return `color: ${colors.alert.red200};`
    }
  }}
`

export const LineChart = styled.div`
  max-width: 61px;
`

export const Sector = styled.div`
  margin-top: 12px;
  font-size: 16px;
  font-weight: 700;
  color: ${colors.brand.grafite};
  border-bottom: 1px solid ${colors.grey11};
  padding-bottom: 10px;
  box-sizing: border-box;
`

export const PercentageTag = styled.div`
  padding: 0 8px;
  background-color: ${colors.alert.green50};
  color: ${colors.alert.green300};
  width: fit-content;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 4px;
`
export const ArrowDownFillIcon = styled(RiArrowDownSFill)``

export const PercentageValues = styled.div`
  margin-top: 16px;
`

export const Type = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.brand.grafite};
`
export const Value = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.gray.gray40};
  margin-top: 8px;
  &:nth-child(2) {
    margin-bottom: 16px;
  }
`

export const BottomVerticalCard = styled.div`
  background-color: ${colors.gray.gray10};
  padding: 16px;
  font-size: 12px;
  font-weight: 500;
  color: ${colors.brand.grafite};
`
export const Networth = styled.p<{$color: string}>`
  font-size: 16px;
  font-weight: 800;
  color: ${colors.alert.green300};
  ${({ $color }) => {
    if($color === 'green') {
      return `color: ${colors.alert.green300};`
    }
    if($color === 'orange') {
      return `color: ${colors.alert.orange300};`
    }
    if($color === 'red') {
      return `color: ${colors.alert.red200};`
    }
  }}
`

export const Categories = styled.div`
  padding: 20px;
`
export const Title = styled.p`
  color: ${colors.brand.grafite};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
`

export const HorizontalCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.grey11};
  padding: 20px 16px;
  background-color: ${colors.brand.white};
  &:not(:last-child) {
    margin-bottom: 16px;

  }
`

export const UpperHorizontalCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.grey11};
  padding-bottom: 8px;
`

export const Name = styled.p`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.brand.grafite};
`

export const Chart = styled.div`
  width: 70px;
  height: 42px;
`

export const NetworthContent = styled.div`
  text-align: right;
  font-size: 12px;
  color: ${colors.brand.grafite};
  font-weight: 500;
`

export const BottomHorizontalCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

export const Column = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const Percentages = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.brand.grafite};
`

export const ValueBottom = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.gray.gray40};
`

export const Summarize = styled.div`
  margin-top: 12px;
  padding: 0px 20px 160px 20px;
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.brand.grafite};
  &:nth-child(1) {
    border-bottom: 1px solid ${colors.grey11};
    padding-bottom: 13px;
    margin-bottom: 10px;
  }
`

export const Quantity = styled.span<{$color?: boolean}>`
  font-size: 14px;
  font-weight: 700;
  color: ${(i) => (i.$color ? colors.alert.red200 : colors.brand.grafite)};
`

export const FloatMessage = styled.div`
  z-index: 9;
  bottom: 0px;
  width: 100%;
  position: fixed;
  max-width: var(--mobile);
  padding: 20px 20px 40px 72px;
  border-radius: 10px 10px 0px 0px;
  background-color: ${colors.brand.white};
  box-shadow: 0px 0px 20px 0px ${colors.shadown1};
  color: ${colors.brand.grafite};
  font-size: 14px;
  font-weight: 500;
  text-align: right;
`

export const ValueToReceive = styled.span`
  font-size: 18px;
  font-weight: 800;
  margin-left: 8px;
`

export const Disclaimer = styled.p`
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  margin-top: 14px;
`




