import {
    RiFileTextLine,
    RiNewspaperLine,
    RiBarcodeLine,
    RiCheckboxCircleLine,
    RiMoneyDollarCircleLine,
    RiTruckLine,
    RiStore2Line
} from "react-icons/ri";

import * as S from "./styles";

export const OrderInfo = ({ order }: any) => {

    const totalItems = order?.items.reduce((total: number, item: any) => {
        return total + item.quantity_ordered;
    }, 0);

    return (
        <S.OrderInfos>
            <S.InfoCard>
                <S.IconCard>
                    <RiFileTextLine />
                </S.IconCard>
                <S.CardContent>
                    <S.Label>Nº do pedido:</S.Label>
                    <S.BoldLabel>{order.number}</S.BoldLabel>
                </S.CardContent>
            </S.InfoCard>
            <S.InfoCard>
                <S.IconCard>
                    <RiFileTextLine />
                </S.IconCard>
                <S.CardContent>
                    <S.Label>Nº do pedido ERP:</S.Label>
                    <S.BoldLabel>{order.number}</S.BoldLabel>
                </S.CardContent>
            </S.InfoCard>
            <S.InfoCard>
                <S.IconCard>
                    <RiBarcodeLine />
                </S.IconCard>
                <S.CardContent>
                    <S.Label>Pagamento via:</S.Label>
                    <S.BoldLabel>Boleto</S.BoldLabel>
                </S.CardContent>
            </S.InfoCard>
            <S.InfoCard>
                <S.IconCard>
                    <RiNewspaperLine />
                </S.IconCard>
                <S.CardContent>
                    <S.Label>Quantidade de itens:</S.Label>
                    <S.BoldLabel>{totalItems} {totalItems > 1 ? `itens` : `item`}</S.BoldLabel>
                </S.CardContent>
            </S.InfoCard>
        </S.OrderInfos>
    )
};