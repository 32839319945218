import { useState } from "react";
import * as S from "./styles";
import * as R from "@/constants/systemRoutes";
import { useUserContext } from "@/hooks";
import { LOGIN, SYNCHRONIZATION, NEW_CLIENT } from "@/constants/systemRoutes";

import { RiLineChartLine } from "react-icons/ri";

const listMenu = [SYNCHRONIZATION, NEW_CLIENT];

const indicadores = [
  { id: 'extrato-produtividade', label: 'Extrato de Produtividade' },
  { id: 'ciclo-vendas', label: 'Ciclo de Vendas' },
  { id: 'relatorio-vendas', label: 'Relatório de Vendas' }
];

const List = () => {
  const { signOut } = useUserContext();
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const Item = (item: any, color: string, onClick?: () => void) => (
    <S.MenuItem
      key={item.id}
      $isDisabled={item.disabled}
      to={(!item.disabled && item.link) || "#"}
      data-test={`dashboards-menu-link-${item.id}`}
      onClick={onClick}
    >
      <S.ContentLink isred={color}>
        {item.icon}
        <S.ItemLabel isred={color}>{item.title}</S.ItemLabel>
      </S.ContentLink>

      <S.Arrow size={24} />
    </S.MenuItem>
  );

  return (
    <S.MenuList>
      {listMenu.map(({ ...item }: any) => Item(item, ""))}

      {/* Item que abre o submenu */}
      <S.IndicadoresMenuItems key={90} onClick={toggleSubmenu} data-test={'dashboard-menu-indicadores'}>
        <S.ContentLink>
          <RiLineChartLine size={21} />
          <S.ItemLabel>Indicadores</S.ItemLabel>
        </S.ContentLink>
        <S.Arrow size={24} />
      </S.IndicadoresMenuItems>

      {isSubmenuOpen && (
        <S.Submenu isOpen={isSubmenuOpen}>
          {/* Mapear os indicadores para criar itens do submenu */}
          {indicadores.map((indicador) => (
            <S.MenuItem
              key={indicador.id}
              to={`${R.INDICATORS.link}?tipo=${indicador.id}`}
              data-test={`dashboard-menu-indicadores-${indicador.id}`}
            >
              <S.ContentLink>
                <S.ItemLabel>{indicador.label}</S.ItemLabel>
              </S.ContentLink>
            </S.MenuItem>
          ))}
        </S.Submenu>
      )}

      {Item(
        { ...LOGIN, title: "Sair", icon: <S.Logout size={21} /> },
        "isred",
        signOut
      )}

    </S.MenuList>
  );
};

export default List;
