import * as S from "./styles";
import OrderResume from "../checkout/orderResume";
import Shipping from "../checkout/shippingInformation";
import SuccessMessage from "./successMessage/successMessage";
import { useSuccessPage } from "@/talons/successPage/useSuccessPage";
import CheckoutHeader from "@/components/checkoutHeader/CheckoutHeader";

const Success = () => {
  const { orderDate, cartTotals, orderNumber, shippingData, productList, connectivityStatus, setCanAccessSuccess } = useSuccessPage();

  return (
    <>
      <CheckoutHeader pageName="Pedido Confirmado" key="sucesso" />
      <S.SuccessPageWrapper>
        <S.OrderHeader>
          <S.OrderNumber>Pedido confirmado #{orderNumber} </S.OrderNumber>
          <S.OrderDate> {orderDate} </S.OrderDate>
        </S.OrderHeader>
        <S.OrderContent>
          <SuccessMessage connectivityStatus={connectivityStatus} setCanAccessSuccess={setCanAccessSuccess} />
          <Shipping shippingData={shippingData} label={false} />
          <OrderResume productList={productList} cartItems={productList} cartTotals={cartTotals} />
        </S.OrderContent>
      </S.SuccessPageWrapper>
    </>
  );
};

export default Success;