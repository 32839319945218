import { colors } from "@/constants";
import styled from "styled-components";

export const ModalContent = styled.div`
  padding: 20px 20px 48px 20px;
`;

export const ModalTitle = styled.h6`
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 32px;
  color: ${colors.gray.gray60};
  background-color: ${colors.brand.white};
`;

export const ContentProduct = styled.div`
  gap: 16px;
  align-items: start;
  margin-bottom: 24px;
  padding-bottom: 14px;
  display: inline-flex;
  border-bottom: 1px solid ${colors.gray.gray10};
`;

export const ImageProductContent = styled.div`
  padding: 5px;
  display: grid;
  border-radius: 6px;
  place-items: center;
  border: 1px solid ${colors.background1};
`;

export const ImageProduct = styled.img`
  width: 80px;
  height: 80px;
  object-fit: contain;
`;

export const ProductInfos = styled.div``;

export const ProductDesc = styled.p`
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 24px;
  color: ${colors.brand.grafite};
`;

export const InstructionLAbel = styled.p`
  font-size: 16px;
  font-weight: 700;
  padding: 0px 10px;
  text-align: center;
  margin-bottom: 28px;
  color: ${colors.brand.grafite};
`;

export const ProductPriceLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 6px;
  color: ${colors.gray.gray60};
`;

export const RelativeValue = styled.div`
  position: relative;
  width: auto;
`;
export const Cifrao = styled.h6`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.brand.grafite};
`;

export const Value = styled.h6`
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  width: -webkit-fill-available;
  color: ${colors.brand.grafite};
`;

export const InputProductPrice = styled.input`
  /* width: 30%; */
  border: none;
  width: max-content;
  padding: 0px !important;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  min-width: 50px;
  max-width: 80px;
  position: relative;
  z-index: 1;
  color: ${colors.transparent};
  caret-color: #676767;
  background-color: ${colors.transparent};
  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove spinners in Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const ProductPrice = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  border: 1px solid ${colors.grey8};
  box-shadow: 0px 4px 10px 0px ${colors.shadown1};
`;

export const ContentFlex = styled.div`
  margin-top: 20px;
`;

export const BalanceContent = styled.div`
  margin-top: 18px;
  padding: 17px 15px;
  border-radius: 6px;
  background-color: ${colors.gray.gray10};
`;

export const Balance = styled.p`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 10px;
  color: ${colors.brand.grafite};
`;

export const BalanceValue = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin-left: 10px;
`;

export const BalanceValueEmphasis = styled.span<{ $negative?: boolean }>`
  font-size: 18px;
  margin-left: 5px;
  color: ${(i) => (i.$negative ? colors.alert.red200 : colors.brand.grafite)};
`;

const themes = {
  green: colors.green,
  dark: colors.brand.grafite,
  default: colors.gray.gray20,
};

export const ApplyButton = styled.button<{
  $inactive?: boolean;
  theme?: "green" | "dark";
}>`
  border: none;
  padding: 13px;
  width: 100%;
  margin-top: 8px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  color: ${(i) => (i.$inactive ? colors.gray.gray40 : colors.brand.white)};
  background-color: ${(i) =>
    i.$inactive
      ? themes.default
      : i.theme === "green"
      ? themes.green
      : themes.dark};
`;
