import process from "process";
import { Buffer } from "buffer";
import { getOnStorage, removeFromStorage } from "./shared";
import RouterProvider from "@/routes/index";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";

import ModalTimeChange from "./components/modal-time-change/ModalTimeChange";

// import * as Sentry from "@sentry/react";

import {
  from,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";

import {
  CartProvider,
  UserProvider,
  FetchProvider,
  GlobalProvider,
  ClientProvider,
  CartTotalsProvider,
  TimeMonitorProvider,
  DiscountFlexProvider,
  useNetworkStatusContext,
  DiscountFlexAmountProvider,
  StockProvider
} from "@/hooks";

import { useEffect } from "react";
import { setWasmUrl } from "@lottiefiles/dotlottie-react";
import { GraphQLError } from "graphql";
import { useDbFunctions } from "./talons";

window.Buffer = Buffer;
window.process = process;

const App = () => {
  const { updateConnectivityStatus, connectivityStatus } = useNetworkStatusContext();

  const { deleteDB } = useDbFunctions();

  const httpLink = new HttpLink({
    uri: window.agp_config.MAGENTO_URL,
  });

  useEffect(() => {
    setWasmUrl(`${process.env.PUBLIC_URL}/dotlottie-player.wasm`);
  }, []);

  console.log('Teste');

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration?.active?.postMessage({
        type: 'CONFIG',
        config: window.agp_config
      })
    })
  };

  const authLink = setContext(async (operation, { headers }) => {
    const token = getOnStorage("token_magento");

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "Content-Type": "application/json; charset=utf-8",
      },
    };
  });

  const errorHandlerLink = onError(({ networkError, graphQLErrors }) => {
    if (networkError) {
      updateConnectivityStatus("offline");
    } else if (graphQLErrors?.length) {
      if (connectivityStatus === "online") {
        const err = graphQLErrors?.find(({ extensions }) => {
          return extensions?.category ?? null;
        }) as GraphQLError;

        const errorCategory = err?.extensions?.category as string;

        if (errorCategory?.toLowerCase()?.includes('authorization')) {
          removeFromStorage('login_date');
          removeFromStorage('token_magento');
          removeFromStorage('token_aws');
          removeFromStorage('rca');
          removeFromStorage('selected_client');
          removeFromStorage('datasets');
          removeFromStorage('discounts');
          removeFromStorage('rca_usuario');
          removeFromStorage('cart_id');
          deleteDB();
          window.alert('Sessão expirada. Por favor, entre com sua conta novamente.');
          window.location.reload();
        }
      }
    }
  });


  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authLink, errorHandlerLink, httpLink]),
  });

  return (
    <ApolloProvider client={client}>
      <FetchProvider>
        <ClientProvider>
          <GlobalProvider>
            <UserProvider>
              <TimeMonitorProvider>
                <StockProvider>
                  <DiscountFlexProvider>
                    <CartProvider>
                      <DiscountFlexAmountProvider>
                        <CartTotalsProvider>
                          <ModalTimeChange />
                          <RouterProvider />
                        </CartTotalsProvider>
                      </DiscountFlexAmountProvider>
                    </CartProvider>
                  </DiscountFlexProvider>
                </StockProvider>
              </TimeMonitorProvider>
            </UserProvider>
          </GlobalProvider>
        </ClientProvider>
      </FetchProvider>
    </ApolloProvider>
  );
};

export default App;
