import * as S from "./styles";
import Modal from "../modal/Modal";
import { Dispatch, SetStateAction } from "react";
import { ProductProps, Attributes } from "@/types";

interface ModalCustomAttributesProps {
  isOpen: boolean;
  product: ProductProps;
  openModal: Dispatch<SetStateAction<boolean>>;
}

const ModalCustomAttributes = ({
  product,
  openModal,
  isOpen = true,
}: ModalCustomAttributesProps) => {
  const attributesLabels = {
    ean_grp: "EAN",
    peso_grp: "Peso",
    grp_marca: "Marca",
    familia_grp: "Família",
    dimensao_grp: "Dimensão",
    fabricante_401: "Fabricante",
    volume_104: "Volume",
    palavra_chave_grp: "Palavra-chave",
    description: "Descrição",
    formato_101: "Formato",
    fragrância_201: "Fragrância",
    tipo_81: "Tipo",
    embalagem_105: "Embalagem",
  };

  return (
    <Modal
      id={`modal-custom-attributes-${product.COD_PRODUTO}`}
      direction="right"
      isOpen={isOpen}
      closeModal={openModal}
    >
      <S.Title>Detalhes do produto</S.Title>
      <S.Divider />
      <S.ModalContent>
        <S.ProductIdGrid>
          <S.ProductImageWrapper>
            <S.ProductImage
              alt={product.DESC_PRODUTO}
              src={`data:image/jpg;base64,${product.IMAGEM}`}
            />
          </S.ProductImageWrapper>
          <S.ProductName>{product.DESC_PRODUTO}</S.ProductName>
        </S.ProductIdGrid>
        <S.ProductTable>
          <S.TableBody>
            {product?.ATRIBUTOS?.filter((i) => {
              const key = Object.keys(i)?.[0] as Attributes;
              return i?.[key] !== null;
            })?.map((item, index) => {
              const key = Object.keys(item)?.[0] as Attributes;
              const value = item[key];
              if (key && value) {
                if (!!attributesLabels[key as keyof typeof attributesLabels]) {
                  return (
                    <S.TableRow
                      key={`${key}-${index}`}
                      $applyBackground={(index + 1) % 2 !== 0}
                    >
                      <S.TableCell $bold={"true"}>
                        {attributesLabels[key as keyof typeof attributesLabels]}
                      </S.TableCell>
                      <S.TableCell>{value}</S.TableCell>
                    </S.TableRow>
                  );
                } else {
                  return (
                    <S.TableRow
                      key={`${key}-${index}`}
                      $applyBackground={(index + 1) % 2 !== 0}
                    >
                      <S.TableCell $bold={"true"} $capitalize={true}>
                        {key?.split("_")?.[0]}
                      </S.TableCell>
                      <S.TableCell>{value}</S.TableCell>
                    </S.TableRow>
                  );
                }
              }
              return null;
            })}
          </S.TableBody>
        </S.ProductTable>
      </S.ModalContent>
    </Modal>
  );
};

export default ModalCustomAttributes;
