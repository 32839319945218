import { useCallback, useEffect, useRef, useState } from "react";

export const useScrollPageEvent = () => {
  const isLoaded = useRef(false);
  const [page, setPage] = useState(1);

  const handleScroll = useCallback(() => {
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    if (scrollTop + windowHeight + 150 >= documentHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [setPage]);

  useEffect(() => {
    if (isLoaded.current) return;
    isLoaded.current = true;

    window.addEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return { page, setPage };
};
