import styled from "styled-components";
import { colors } from "@/constants";

export const ContentPage = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${colors.brand.white};
    padding: 24px 20px;
`;

export const OrderInfos = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
`;

export const InfoCard = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
`;

export const IconCard = styled.div`
    svg{
        width: 24px;
        height: 24px;
    }
`;

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const Label = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: ${colors.grey3};
`;

export const BoldLabel = styled.span`
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: ${colors.brand.grafite};
`;
