import styled from "styled-components";
import { colors } from "@/constants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 100%;
    padding: 20px 20px;
    padding-bottom: 105px;
`;

export const PageHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  
`;

export const PageTitle = styled.span`
    font-size: 18px;
    font-weight: 500;
    line-height: 21.6px;
    color: ${colors.brand.grafite};
`;

export const Description = styled.p`
    font-size: 12px;
    font-weight: 400;
    line-height: 16.8px;
    color: ${colors.gray.gray50};
`;

export const PageContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const UploadBox = styled.div<{ success: boolean, error: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;

    width: 100%;
    min-height: 200px;
    padding: 34px 0;
    border-radius: 6px;
    border: 2px dashed ${({ success, error }) => success ? colors.alert.green110 : error ? colors.alert.red200 : colors.gray.gray10};

    input{
        background-color: red;
    }
`;

export const UploadIcon = styled.div<{ success: boolean, error: boolean }>`
    width: 96px;
    height: 96px;
    background-color: ${({ success, error }) => success ? colors.alert.green100 : error ? colors.alert.red50 : colors.gray.gray10};
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-bottom: 12px;

    svg{
        color: ${({ success, error }) => success ? colors.alert.green110 : error ? colors.alert.red200 : colors.brand.grafite};
    }
`;

export const UploadMessage = styled.span<{ error?: boolean }>`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1%;
    color: ${({ error }) => error ? colors.alert.red200 : colors.gray.gray60};

    b{
        color: ${colors.alert.blue100};
    }
`;

export const UploadSmallMessage = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.gray.gray40};
`;

export const FileList = styled.div`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const FileItem = styled.div<{ topBorder?: boolean }>`
    width: 100%;
    display: grid;
    grid-template-columns: 56px 1fr auto;
    align-items: center;
    gap: 12px;

    ${({ topBorder }) => topBorder ? `border-top: solid 1px ${colors.gray.gray10}; padding-top: 20px;` : `border-bottom: solid 1px ${colors.gray.gray10};`}
    padding-bottom: 20px;
`;

export const FileIcon = styled.div<{ error?: boolean, gray?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 56px;
    height: 56px;
    border-radius: 50%;
    background-color: ${({ error, gray }) => error ? colors.alert.red50 : gray ? colors.gray.gray10 : colors.alert.green100};

    svg{
        color: ${({ error, gray }) => error ? colors.alert.red200 : gray ? colors.gray.gray60 : colors.alert.green110};
    }
`;

export const FileDetails = styled.div`
    display: flex;
    
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

export const FileName = styled.p<{ error?: boolean }>`
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1%;
    color: ${({ error }) => error ? colors.alert.red200 : colors.brand.grafite};

    margin-bottom: 4px;
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  background-color: #e0e0df;
  border-radius: 5px;
  margin-bottom: 2px;
`;

export const ProgressFill = styled.div`
  height: 100%;
  background-color: ${colors.alert.green110};
  border-radius: 5px;
  transition: width 0.4s ease;
`;

export const ProgressText = styled.div`
    display: flex;
    justify-content: space-between;
    
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: ${colors.gray.gray40};
`;

export const RemoveButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: transparent;
    border: none;
    padding: none;
    margin-bottom: 2px;

    svg{
        color: ${colors.gray.gray40};
    }
`;

export const ErrorRefetch = styled.button`
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: ${colors.brand.grafite};

    width: 140px;
    height: 36px;
    padding: 0 20px;
    margin-bottom: 2px;
    background-color: transparent;

    border-radius: 6px;
    border: solid 1px ${colors.gray.gray10};
`;

export const ErrorRefetchAll = styled.button`
    margin-top: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;

    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${colors.alert.blue100};

    border: none;
    background-color: transparent;

    svg{
        color: ${colors.alert.blue100};
    }
`;

export const SearchButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100px;
    height: 36px;
    border: solid 1px ${colors.gray.gray10};
    box-shadow: 0px 1px 2px 0px #1E293B1F;
    border-radius: 6px;

    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 2%;
    color: ${colors.brand.grafite};
`;