import { useEffect } from "react";
import { useGlobals } from "@/hooks/index";
import { Navigate } from "react-router-dom";
import { useUserContext } from "@/hooks/index";

const ProtectedRoute = ({ element, requiredAccess }: any) => {
  const { isSignedIn } = useUserContext();
  const { canAccessCheckout, canAccessSuccess, setCanAccessCheckout } =
    useGlobals();

  useEffect(() => {
    if (requiredAccess !== "checkout" && canAccessCheckout) {
      setCanAccessCheckout(false);
    }
  }, [requiredAccess, canAccessCheckout, setCanAccessCheckout]);

  if (!isSignedIn) {
    return <Navigate to="/login" />;
  }

  if (requiredAccess === "checkout" && !canAccessCheckout) {
    return <Navigate to="/carrinho" />;
  }

  if (requiredAccess === "success" && !canAccessSuccess) {
    return <Navigate to="/checkout" />;
  }

  return element;
};

export default ProtectedRoute;
