import * as S from "./styles";
import React, { useCallback, useMemo, useState } from "react";
import { Counter, ToolTip, StPrice, Stock } from "@/components";
import { useAddToCart } from "@/talons";
import { RiInformation2Line, RiExchangeDollarLine } from "react-icons/ri";
import { PackingTypes, formatCurrency } from "@/shared";

import {
  useCart,
  useGlobals,
  useCartTotals,
  useUserContext,
  useDiscountFlex,
  useDiscountAmountFlex,
  useClient,
  useStock,
} from "@/hooks";

import {
  ProductProps,
  PriceProductProps,
  PackagingProductProps,
} from "@/types";
import { colors } from "@/constants";
import { CartItems } from "../../hooks/CartContext";

const PackingImg = `${process.env.PUBLIC_URL}/icon-caixa.png`;

const getOriginalValue = (value: number, percent: number) => {
  const porcentagemDecimal = percent / 100;
  const fatorMultiplicador = 1 + porcentagemDecimal;
  return value / fatorMultiplicador;
};

interface Product extends ProductProps {
  quantity: number;
  packing: string;
  currentPrice?: PriceProductProps;
  sku: number;
  uid: string;
  option_uid: string;
  category_id: number;
}
interface CardProps {
  item: Product | any;
  isFirst?: boolean;
  addProductToCart?: any;
  updateProductAmount?: any;
  removeProductFromCart?: any;
}

const CartProductCard = ({ item, isFirst }: CardProps) => {
  const [tooltip, setTooltip] = useState(false);
  const { paymentCode } = useGlobals();
  const { selectedClient } = useClient();
  const { rcaSaldoFlex } = useUserContext();
  const { removeProductFromCart, getLimit } = useCart();
  const { stockLeft: stock } = useStock();
  const { fetchCorrectPrice } = useCartTotals();
  const { getDiscountProduct, deleteDiscount, discounts } = useDiscountFlex();
  const { discountAmountClient: discountAmount } = useDiscountAmountFlex();

  const [packType, qtd] = useMemo(() => {
    return item?.packing?.split("_");
  }, [item?.packing]);

  const stockLeft = useMemo(() => {
    return getLimit(item?.COD_PRODUTO);
  }, [item?.COD_PRODUTO, getLimit]);

  const packing = useMemo(() => {
    return item.EMBALAGEM.find((e: PackagingProductProps) => {
      if (e?.QTD_MULTIPLO_VENDA > 1 && packType === "PK") {
        return e.SG_EMBALAGEM === packType && e.QTD_MULTIPLO_VENDA?.toString() === qtd;
      }
      return e.SG_EMBALAGEM === packType && e.QTD_EMBALAGEM?.toString() === qtd;
    });
  }, [item, packType, qtd]) as PackagingProductProps;

  const { currentCart } = useAddToCart({
    sku: item?.COD_PRODUTO,
    packingOption: `${packing?.SG_EMBALAGEM}_${packing?.QTD_MULTIPLO_VENDA > 1 ? packing?.QTD_MULTIPLO_VENDA : packing?.QTD_EMBALAGEM}`,
  });

  const currentQuantity = useMemo(
    () =>
      currentCart?.find((i) => {
        const [prodPack, qty] = i?.option_uid?.split("_");
        if (packing?.QTD_MULTIPLO_VENDA > 1) {
          return (
            i?.sku === item?.COD_PRODUTO &&
            packing?.SG_EMBALAGEM === prodPack &&
            qty === packing?.QTD_MULTIPLO_VENDA?.toString()
          )
        }
        return (
          i?.sku === item?.COD_PRODUTO && prodPack === packing?.SG_EMBALAGEM && qty === packing?.QTD_EMBALAGEM?.toString()
        );
      })?.quantity || 0,
    [currentCart, item?.COD_PRODUTO, packing?.QTD_EMBALAGEM, packing?.QTD_MULTIPLO_VENDA, packing?.SG_EMBALAGEM]
  );

  const hasMultiplePacking = useMemo(() => (item?.EMBALAGEM?.length > 1 && currentCart?.find((i: CartItems) => i?.sku === item?.COD_PRODUTO) ? currentCart?.find((i: CartItems) => i?.sku === item?.COD_PRODUTO) : null), [item, currentCart]);

  // Qtd total unificada do produto adicionadas ao carrinho, quando o produto possui múltiplas embalagens
  const totalQuantity = useMemo(() => {
    if (hasMultiplePacking) {
      let count = 0;
      currentCart?.reduce((acc, currentValue) => {
        if (currentValue?.sku === item?.COD_PRODUTO) {
          const [, qtd] = currentValue?.option_uid?.split("_");

          const qty = Number(qtd);
          const val = currentValue?.quantity * qty;
          count = acc += val;

        }
        return acc;
      }, 0);
      return count;
    }
    return 0;
  }, [currentCart, hasMultiplePacking, item?.COD_PRODUTO]);

  // Qtd total adicionada ao carrinho, calculada com base em produtos com ou sem múltiplas embalagens
  const rawCurrentAddedQty = useMemo(() => {
    const maximum = packing?.QTD_MULTIPLO_VENDA > 1;

    if (hasMultiplePacking && hasMultiplePacking?.sku) {
      return totalQuantity;
    } else if (!hasMultiplePacking?.sku && currentQuantity) {
      return maximum ? packing?.QTD_MULTIPLO_VENDA * currentQuantity : packing?.QTD_EMBALAGEM * currentQuantity;
    }
    return 0;
  }, [currentQuantity, hasMultiplePacking, packing?.QTD_EMBALAGEM, packing?.QTD_MULTIPLO_VENDA, totalQuantity]);

  // Qtd de produto em estoque calculada com base na quantidade adicionada ao carrinho
  const rawQuantity = useMemo(() => {
    const limit = getLimit(item?.COD_PRODUTO);
    const stockRemaining = stock?.[item?.COD_PRODUTO]?.stock_left;

    // Valida se existe controle de estoque local - para o caso deste produto já ter sido comprado antes

    if (stock?.[item?.COD_PRODUTO]) {
      if (stockRemaining && stockRemaining > 0) {

        if (rawCurrentAddedQty) {
          return stockRemaining <= rawCurrentAddedQty ? 0 : stockRemaining - rawCurrentAddedQty;
        }
        return stockRemaining;
      }
      return 0;
    }

    // Se não tiver controle de estoque local para este produto, vamos tomar como base o estoque que veio do dataset

    if (rawCurrentAddedQty) {
      return limit <= rawCurrentAddedQty ? 0 : limit - rawCurrentAddedQty;
    }
    return limit;

  }, [getLimit, item?.COD_PRODUTO, stock, rawCurrentAddedQty]);

  // Controle de estoque geral
  const hasStock = useMemo(() => {
    const limit = getLimit(item?.COD_PRODUTO);
    const multiple = packing?.QTD_MULTIPLO_VENDA > 1;
    const stockRemaining = stock?.[item?.COD_PRODUTO]?.stock_left;

    if (stock?.[item?.COD_PRODUTO]) {
      if (stockRemaining && stockRemaining > 0) {
        if (rawCurrentAddedQty) {
          return stockRemaining > 0 && stockRemaining >= rawCurrentAddedQty;
        }
        return multiple ? stockRemaining > 0 && stockRemaining >= packing?.QTD_MULTIPLO_VENDA : stockRemaining > 0 && stockRemaining >= packing?.QTD_EMBALAGEM;
      }
      return null;
    }
    if (rawCurrentAddedQty) {
      return limit > 0 && limit >= rawCurrentAddedQty;
    }
    return multiple ? limit > 0 && limit >= packing?.QTD_MULTIPLO_VENDA : limit > 0 && limit >= packing?.QTD_EMBALAGEM;

  }, [getLimit, packing?.QTD_EMBALAGEM, packing?.QTD_MULTIPLO_VENDA, item?.COD_PRODUTO, rawCurrentAddedQty, stock]);

  const discountPrice = useMemo(() => {
    let packQtd = packing?.QTD_EMBALAGEM;
    if (packing?.QTD_MULTIPLO_VENDA > 1) {
      packQtd = packing?.QTD_MULTIPLO_VENDA;
    }
    return getDiscountProduct({
      package: packType,
      productId: item.COD_PRODUTO,
      quantity: packQtd,
    })?.discountPrice?.[paymentCode];
  }, [packing, getDiscountProduct, packType, item.COD_PRODUTO, paymentCode]);

  const currentPrice = useMemo(() => {
    return fetchCorrectPrice(item?.PRECO, item?.option_uid, paymentCode);
  }, [fetchCorrectPrice, item?.PRECO, item?.option_uid, paymentCode]);

  const valuePrice = useMemo(() => {
    // console.log('value: ', { discountPrice, currentPrice });
    return discountPrice || currentPrice?.VLR_FINAL || currentPrice?.VLR_PRECO;
  }, [discountPrice, currentPrice]);

  const price = useMemo(
    () => formatCurrency({ value: valuePrice }),
    [valuePrice]
  );

  const unityPrice = useMemo(() => {
    const qtd = packing?.QTD_MULTIPLO_VENDA > 1 ? packing?.QTD_MULTIPLO_VENDA : packing?.QTD_EMBALAGEM;

    return formatCurrency({
      value: valuePrice / qtd,
    });
  }, [packing, valuePrice]);

  const rowPrice = useMemo(
    () =>
      formatCurrency({
        value: valuePrice * currentQuantity,
      }),
    [valuePrice, currentQuantity]
  );

  const amountProduct = useMemo(
    () => {
      let packOption = packing?.SG_EMBALAGEM;
      let packQtd = packing?.QTD_EMBALAGEM;

      if (packing?.QTD_MULTIPLO_VENDA > 1) {
        packQtd = packing?.QTD_MULTIPLO_VENDA;
      }
      return getDiscountProduct({
        package: packOption,
        quantity: packQtd,
        productId: item.COD_PRODUTO,
      })
    },
    [packing, item, getDiscountProduct]
  );

  const limitFlex = useMemo(() => {
    const discountPrice = amountProduct?.discountPrice?.[`${paymentCode}`] || 0;
    const originalValue =
      getOriginalValue(discountPrice, amountProduct?.percentual || 0) || 0;
    const diffPrice = originalValue - discountPrice;

    return (
      Math.trunc(((rcaSaldoFlex || 0) + discountAmount) / diffPrice) +
      currentQuantity
    );
  }, [
    paymentCode,
    rcaSaldoFlex,
    discountAmount,
    currentQuantity,
    amountProduct?.percentual,
    amountProduct?.discountPrice,
  ]);

  const handleRemoveOnCart = useCallback(() => {
    const calcOriginalValue = (value: number, percent: number) => {
      if (!value) return 0;
      const porcentagemDecimal = percent / 100;
      const fatorMultiplicador = 1 + porcentagemDecimal;
      return value / fatorMultiplicador;
    };

    if (discountPrice && rcaSaldoFlex) {
      const discountPercentual = amountProduct?.percentual || 0;
      const unitDiscount =
        discountPrice - calcOriginalValue(discountPrice, discountPercentual);
      const productDiscount = unitDiscount * currentQuantity;

      // Cálculo para validar se a remocao do produto ira deixar o saldo flex negativo.
      if (rcaSaldoFlex - productDiscount + discountAmount < 0) {
        setTooltip(true);
        setTimeout(() => setTooltip(false), 5000);
        return;
      }
    }

    let packOption = packing?.SG_EMBALAGEM;
    let packQtd = packing?.QTD_EMBALAGEM;

    if (packing?.QTD_MULTIPLO_VENDA > 1) {
      packQtd = packing?.QTD_MULTIPLO_VENDA;
    }

    deleteDiscount({
      package: packOption,
      quantity: packQtd,
      productId: item.COD_PRODUTO,
    });
    removeProductFromCart({ uid: item.uid });
  }, [
    amountProduct?.percentual,
    currentQuantity,
    discountPrice,
    item.uid,
    packing,
    rcaSaldoFlex,
    item?.COD_PRODUTO,
    deleteDiscount,
    removeProductFromCart,
    discountAmount,
  ]);

  const tooltipMessage = useMemo(() => {
    return `A remoção deste item fará com que o saldo flex fique negativo: ${formatCurrency(
      {
        value: (rcaSaldoFlex || 0) + discountAmount,
      }
    )}`;
  }, [rcaSaldoFlex, discountAmount]);

  const packingUnities = useMemo(() => {
    const qtdEmb = packing?.QTD_MULTIPLO_VENDA > 1 ? packing?.QTD_MULTIPLO_VENDA : packing?.QTD_EMBALAGEM;

    return qtdEmb > 1 ? `${qtdEmb} unidades` : `${qtdEmb} unidade`;

  }, [packing]);

  const packingLabel = useMemo(() => {
    const emb = packing?.SG_EMBALAGEM;
    return emb;
  }, [packing]);

  if (item?.quantity === 0) {
    return null;
  }

  return (
    <S.OuterGrid>
      <S.Container $alignItems="center">
        <S.ProductImage
          alt={item?.DESC_PRODUTO}
          src={`data:image/jpg;base64,${item?.IMAGEM}`}
        />
        <ToolTip
          direction="top"
          show={tooltip}
          displayMode="default"
          text={tooltipMessage}
        >
          <S.ButtonContainer
            data-test={`remove-product-button-${item?.COD_PRODUTO}`}
          >
            <S.RemoveButton onClick={() => handleRemoveOnCart()}>
              <S.RemoveButtonLabel>Remover</S.RemoveButtonLabel>
            </S.RemoveButton>
          </S.ButtonContainer>
        </ToolTip>
      </S.Container>
      <S.Container>
        <S.InnerBlock $extraPadding={true}>
          <S.DescriptionBlock>
            <S.DescriptionRow>
              <S.ProductDescription>{item?.DESC_PRODUTO}</S.ProductDescription>
              {amountProduct?.percentual && (
                <S.FlexPercentage $positive={amountProduct.percentual > 0}>
                  <RiExchangeDollarLine size={20} color={colors.brand.grafite} />
                  <S.FlexPercentageLabel>{(amountProduct?.percentual).toFixed(2)}%</S.FlexPercentageLabel>
                </S.FlexPercentage>
              )}
            </S.DescriptionRow>
            {hasStock && (
              <S.StockContainer>
                <S.IconContainer>
                  <RiInformation2Line size={24} color={colors.gray.gray40} />
                </S.IconContainer>
                <Stock stockLeft={rawQuantity} height={36} />
              </S.StockContainer>
            )}
          </S.DescriptionBlock>
          {valuePrice ? (
            <S.PriceBlock>
              <S.PriceRow>{rowPrice}</S.PriceRow>
              <StPrice
                precoSt={currentPrice?.ST_VLR_FINAL}
                precoCalculado={currentPrice?.VLR_PRECO_CALCULADO}
                precoPauta={currentPrice?.VLR_PAUTA}
                codTributacaoProduto={currentPrice?.COD_TRIBUTACAO_PRODUTO}
                quantidade={currentQuantity}
                cliente={selectedClient}
                descontoFlex={discounts?.[selectedClient?.COD_CLIENTE]?.[item?.uid]?.percentual ? discounts?.[selectedClient?.COD_CLIENTE]?.[item?.uid]?.percentual : 0}
              />
              <S.Price>{price}</S.Price>
              <S.UnityPrice>{unityPrice}/un.</S.UnityPrice>
            </S.PriceBlock>
          ) : null}
        </S.InnerBlock>
        <S.InnerBlock>
          <S.PackingBlock>
            <S.IconContainer $radius={4}>
              <S.Packing src={PackingTypes?.[packingLabel].icon} />
            </S.IconContainer>
            <S.PackingDescription>
              <S.PackingLabel>
                {PackingTypes?.[packingLabel].packingTitle}
              </S.PackingLabel>
              <S.PackingUnities>
                {packingUnities}
              </S.PackingUnities>
            </S.PackingDescription>
          </S.PackingBlock>
          <S.ButtonContainer>
            <S.CounterButton />
            {!valuePrice ? (
              <S.Unavailable>Indisponível</S.Unavailable>
            ) : (
              <Counter
                showBorder
                pack={packing}
                product={item}
                background="white"
                limitFlex={limitFlex}
                id={`${item.COD_PRODUTO}`}
                discountProduct={amountProduct}
              />
            )}

          </S.ButtonContainer>
        </S.InnerBlock>
      </S.Container>
    </S.OuterGrid>
  )
};

export default CartProductCard;
