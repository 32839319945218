interface CurrencyProps {
    value: number | string; // value pode ser número ou string
    isFormat?: boolean;
}

export const formatCurrency = ({ value, isFormat = true }: CurrencyProps): string | number => {
    if (isFormat) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(typeof value === 'number' ? value : parseFloat(value));
    } else {
        // Garantir que value é uma string antes de aplicar replace
        const stringValue = typeof value === 'number' ? value.toString() : value;
        return parseFloat(stringValue.replace('R$ ', '').replace(',', '.'));
    }
};

/**
 * ## USO
 *
 * - isFormat true --> Transforma value (10) em string formatada (R$ 10,00)
 * - isFormat false --> Transforma value formatado (tipo string) (R$ 10,00) em número (10)
 *
 */
