import styled, { css } from 'styled-components';
import { colors, device } from '@/constants';

const wrapStyle = (checked?: boolean) =>
  checked &&
  css`
    flex-wrap: wrap;
  `;

export const Content = styled.div`
  padding: 0px 20px;
`;

export const RowContent = styled.div`
  gap: 16px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  @media (max-width: ${device.mobile320}) {
    flex-wrap: wrap;
    gap: 0px;
  }
`;

export const Card = styled.div<{ $bgcolor?: string }>`
  position: relative;
  @media (max-width: ${device.mobile320}) {
    display: flex;
    flex-direction: column;
  }
`;

export const BoxCard = styled.div<{ $width?: number }>`
  width: 50%;
  @media (max-width: ${device.mobile320}) {
    width: 100%;
  }
`;

export const Separator = styled.div<{ $vertical?: boolean }>`
  margin: 20px 0px;
  background-color: ${colors.gray.gray10};
  width: ${(i) => (i.$vertical ? '1px' : '100%')};
  height: ${(i) => (i.$vertical ? '100%' : '1px')};
`;

export const Line = styled.div<{
  $gap?: number;
  $iswrap?: boolean;
  $endline?: boolean;
  $bgwhite?: boolean;
  $horizontalstart?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: ${(i) => i.$gap || 1}px;
  align-items: ${(i) => (i.$endline ? 'end' : 'center')};
  background-color: ${(i) => (i.$bgwhite ? colors.brand.white : 'transparent')};
  justify-content: ${(i) => (i.$horizontalstart ? 'start' : 'space-between')};

  ${(props) => wrapStyle(props?.$iswrap)}
`;

export const Percentual = styled.h4`
  margin: 0px;
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  color: ${colors.brand.grafite};
`;

export const VerticalPercentual = styled.h4`
  font-size: 28px;
  font-weight: 700;
  text-align: left;
  margin: 6px 0px 10px 0px;
  color: ${colors.brand.grafite};
`;

export const FloatMetric = styled.div<{ $bgcolor?: string }>`
  position: absolute;
  right: 0px;
  bottom: 0px;
`;

export const MetricPercentual = styled.div<{ $bgcolor?: string }>`
  display: flex;
  padding: 4px 8px;
  width: min-content;
  height: min-content;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  background-color: ${(i) => i.$bgcolor || colors.brand.white};
`;

export const MetricPercentualLabel = styled.p<{ $color?: string }>`
  margin: 0px;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.brand.red};
`;

export const LabelMetric = styled.p`
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.brand.red};
`;

export const ContentGraphic = styled.div`
  position: relative;
`;

export const BgLine = styled.div`
  gap: 1px;
  width: 100%;
  align-items: stretch;
  display: inline-flex;
  justify-content: space-evenly;
  background-color: ${colors.grey11};
  @media (max-width: ${device.mobile320}) {
    flex-wrap: wrap;
  }
`;

export const VerticalGraphic = styled.div`
  padding: 0px 20px;
  width: -webkit-fill-available;
  background-color: ${colors.brand.white};
  @media (max-width: ${device.mobile320}) {
    &:nth-child(2) {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    &:first-child {
      padding-bottom: 8px;
    }
    &:last-child {
      padding-top: 8px;
    }
  }
`;

export const TitleCard = styled.h3`
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 28px;
  color: ${colors.brand.grafite};
`;

export const IconGraphic = styled.div<{ bgcolor?: string }>`
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 40px;
  background-color: ${(i) => i.bgcolor || colors.brand.white};
`;

export const Column = styled.div<{ $flex?: boolean; margin?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${(i) => i.margin};
  flex: ${(i) => (i.$flex ? 'auto' : 'unset')};
`;

export const LabelCategory = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-right: 5px;
  color: ${colors.brand.grafite};
`;

export const Value = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px 0px 0px;
  color: ${colors.gray.gray40};
`;

export const ValueSpanBold = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray.gray40};
`;

export const Label = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.brand.grafite};
`;

export const LabelMeta = styled.p`
  margin: 5px 0px 0px 0px;
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  color: ${colors.gray.gray40};
`;

export const StatisticCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  padding: 0px 8px 0px 4px;
`;
