import { colors } from "@/constants";
import styled from "styled-components";

export const CheckoutPage = styled.div`

`;

export const CheckoutPageWrapper = styled.div`
  display: grid;
  gap: 20px;

  padding: 24px;
  padding-bottom: 136px;

  max-width: 100%;
  height: 100%;
`;

export const PaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ShippingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const DefaultLabel = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  ${colors.brand.grafite};
`;

export const OrderNumberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const OrderResumeWrapper = styled.div``;

export const PlaceOrder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: sticky;
  width: 100%;
  bottom: 0;

  background-color: ${colors.brand.white};
  height: fit-content;

  border-radius: 6px 6px 0 0;
  box-shadow: 0px -10px 30px 0px ${colors.shadown1};
  box-sizing: border-box;

  padding: 24px;

  >div{
    width: 100%;
  }
`;

export const PlaceOrderButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  width: 100%;
  height: 55px;

  background-color: ${colors.brand.red};

  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  color: ${colors.brand.white};

  border: none;
  border-radius: 8px;

  cursor: pointer;


  &:disabled {
    background-color: ${colors.gray.gray10};
    border: none;

    color: ${colors.gray.gray40};

    >div{
      background-color: ${colors.gray.gray10};
    }
  }
`;

export const ButtonIcon = styled.div`
  background-color: ${colors.red2};

  display: flex;
  justify-content: center;
  align-items: center;

  width: 26px;
  height: 26px;

  border-radius: 50%;

  svg {
    width: 22px;
    height: 22px;
  }
`;
