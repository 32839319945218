import { BrandProps } from "@/types";

export const brandsMock: BrandProps[] = [
  { DESC_MARCA: "3 corações", ID_MARCA: 1748 },
  { DESC_MARCA: "3 fazendas", ID_MARCA: 2459 },
  { DESC_MARCA: "51", ID_MARCA: 344 },
  { DESC_MARCA: "51 ice", ID_MARCA: 950 },
  { DESC_MARCA: "88", ID_MARCA: 1511 },
  { DESC_MARCA: "Aberfeldy", ID_MARCA: 2093 },
  { DESC_MARCA: "Above", ID_MARCA: 494 },
  { DESC_MARCA: "Absolut", ID_MARCA: 2045 },
  { DESC_MARCA: "Absoluto", ID_MARCA: 2692 },
  { DESC_MARCA: "Acao lar", ID_MARCA: 737 },
  { DESC_MARCA: "Ades", ID_MARCA: 2006 },
  { DESC_MARCA: "Adidas", ID_MARCA: 1352 },
  { DESC_MARCA: "Adocyl", ID_MARCA: 1580 },
  { DESC_MARCA: "Adorable", ID_MARCA: 1325 },
  { DESC_MARCA: "Adria", ID_MARCA: 467 },
  { DESC_MARCA: "Agora", ID_MARCA: 923 },
  { DESC_MARCA: "Aguia", ID_MARCA: 2456 },
  { DESC_MARCA: "Ajax", ID_MARCA: 908 },
  { DESC_MARCA: "Albany", ID_MARCA: 536 },
  { DESC_MARCA: "Alcafoods", ID_MARCA: 2624 },
  { DESC_MARCA: "Alfaluminio", ID_MARCA: 2087 },
  { DESC_MARCA: "Aliança", ID_MARCA: 1889 },
  { DESC_MARCA: "Alma flores", ID_MARCA: 863 },
  { DESC_MARCA: "Almirante", ID_MARCA: 1118 },
  { DESC_MARCA: "Always", ID_MARCA: 1415 },
  { DESC_MARCA: "Ama vita", ID_MARCA: 995 },
  { DESC_MARCA: "Amaball", ID_MARCA: 779 },
  { DESC_MARCA: "Amacitel", ID_MARCA: 1784 },
  { DESC_MARCA: "Amarula", ID_MARCA: 2183 },
  { DESC_MARCA: "Amavita", ID_MARCA: 581 },
  { DESC_MARCA: "Amelia", ID_MARCA: 1052 },
  { DESC_MARCA: "Amstel", ID_MARCA: 1343 },
  { DESC_MARCA: "Andorinha", ID_MARCA: 422 },
  { DESC_MARCA: "Anepel", ID_MARCA: 431 },
  { DESC_MARCA: "Anglo", ID_MARCA: 1532 },
  { DESC_MARCA: "Apti", ID_MARCA: 518 },
  { DESC_MARCA: "Ariel", ID_MARCA: 1421 },
  { DESC_MARCA: "Arisco", ID_MARCA: 416 },
  { DESC_MARCA: "Arrifana", ID_MARCA: 656 },
  { DESC_MARCA: "Askov", ID_MARCA: 899 },
  { DESC_MARCA: "Assessorlar", ID_MARCA: 1643 },
  { DESC_MARCA: "Assim", ID_MARCA: 101 },
  { DESC_MARCA: "Assolan", ID_MARCA: 692 },
  { DESC_MARCA: "Assugrin", ID_MARCA: 1172 },
  { DESC_MARCA: "Asteca", ID_MARCA: 1130 },
  { DESC_MARCA: "Atol", ID_MARCA: 1739 },
  { DESC_MARCA: "Aurora", ID_MARCA: 575 },
  { DESC_MARCA: "Avanco", ID_MARCA: 2204 },
  { DESC_MARCA: "Avant", ID_MARCA: 2516 },
  { DESC_MARCA: "Aviação", ID_MARCA: 1856 },
  { DESC_MARCA: "Axe", ID_MARCA: 338 },
  { DESC_MARCA: "Azulejo", ID_MARCA: 2267 },
  { DESC_MARCA: "Baby soft", ID_MARCA: 722 },
  { DESC_MARCA: "Baby wipes", ID_MARCA: 2210 },
  { DESC_MARCA: "Babysec", ID_MARCA: 1439 },
  { DESC_MARCA: "Bacardi", ID_MARCA: 1064 },
  { DESC_MARCA: "Baccio", ID_MARCA: 2234 },
  { DESC_MARCA: "Baianinha", ID_MARCA: 2201 },
  { DESC_MARCA: "Bala blue", ID_MARCA: 2030 },
  { DESC_MARCA: "Balalaika", ID_MARCA: 584 },
  { DESC_MARCA: "Ballantines", ID_MARCA: 2048 },
  { DESC_MARCA: "Baly", ID_MARCA: 941 },
  { DESC_MARCA: "Ban-sept", ID_MARCA: 1391 },
  { DESC_MARCA: "Band-aid", ID_MARCA: 1004 },
  { DESC_MARCA: "Bao de minas", ID_MARCA: 1418 },
  { DESC_MARCA: "Barkam", ID_MARCA: 2024 },
  { DESC_MARCA: "Barkan", ID_MARCA: 2021 },
  { DESC_MARCA: "Barla", ID_MARCA: 560 },
  { DESC_MARCA: "Baruel", ID_MARCA: 989 },
  { DESC_MARCA: "Basilar", ID_MARCA: 1883 },
  { DESC_MARCA: "Baton", ID_MARCA: 2147 },
  { DESC_MARCA: "Bauducco", ID_MARCA: 701 },
  { DESC_MARCA: "Baygon", ID_MARCA: 1559 },
  { DESC_MARCA: "Bb cream", ID_MARCA: 362 },
  { DESC_MARCA: "Beautycolor", ID_MARCA: 851 },
  { DESC_MARCA: "Becel", ID_MARCA: 764 },
  { DESC_MARCA: "Beira alta", ID_MARCA: 545 },
  { DESC_MARCA: "Beira rio", ID_MARCA: 1946 },
  { DESC_MARCA: "Bejame", ID_MARCA: 2429 },
  { DESC_MARCA: "Bela ischia", ID_MARCA: 1331 },
  { DESC_MARCA: "Bella roma", ID_MARCA: 1307 },
  { DESC_MARCA: "Belliz", ID_MARCA: 1448 },
  { DESC_MARCA: "Belma", ID_MARCA: 1988 },
  { DESC_MARCA: "Bem estar", ID_MARCA: 2695 },
  { DESC_MARCA: "Benedictine", ID_MARCA: 2078 },
  { DESC_MARCA: "Benjamin nieto", ID_MARCA: 2213 },
  { DESC_MARCA: "Best", ID_MARCA: 1157 },
  { DESC_MARCA: "Best lite", ID_MARCA: 482 },
  { DESC_MARCA: "Bestia", ID_MARCA: 2384 },
  { DESC_MARCA: "Bettanin", ID_MARCA: 827 },
  { DESC_MARCA: "Bi-o", ID_MARCA: 833 },
  { DESC_MARCA: "Bic", ID_MARCA: 377 },
  { DESC_MARCA: "Bigfral", ID_MARCA: 266 },
  { DESC_MARCA: "Biocolor", ID_MARCA: 1013 },
  { DESC_MARCA: "Biocrema", ID_MARCA: 845 },
  { DESC_MARCA: "Bis", ID_MARCA: 617 },
  { DESC_MARCA: "Blendy", ID_MARCA: 470 },
  { DESC_MARCA: "Boa noite", ID_MARCA: 2111 },
  { DESC_MARCA: "Bob", ID_MARCA: 1238 },
  { DESC_MARCA: "Bodega real", ID_MARCA: 2408 },
  { DESC_MARCA: "Bom ar", ID_MARCA: 602 },
  { DESC_MARCA: "Bom ar air wick", ID_MARCA: 2060 },
  { DESC_MARCA: "Bom coco", ID_MARCA: 2414 },
  { DESC_MARCA: "Bom lixo", ID_MARCA: 311 },
  { DESC_MARCA: "Bom roll", ID_MARCA: 1385 },
  { DESC_MARCA: "Bombril", ID_MARCA: 662 },
  { DESC_MARCA: "Bonare", ID_MARCA: 2156 },
  { DESC_MARCA: "Bonduelle", ID_MARCA: 1928 },
  { DESC_MARCA: "Bono", ID_MARCA: 527 },
  { DESC_MARCA: "Bordon", ID_MARCA: 1403 },
  { DESC_MARCA: "Borges", ID_MARCA: 179 },
  { DESC_MARCA: "Bosford", ID_MARCA: 2171 },
  { DESC_MARCA: "Bozzano", ID_MARCA: 755 },
  { DESC_MARCA: "Brasso", ID_MARCA: 1598 },
  { DESC_MARCA: "Bravo", ID_MARCA: 824 },
  { DESC_MARCA: "Bretzke", ID_MARCA: 1490 },
  { DESC_MARCA: "Brigitta", ID_MARCA: 1874 },
  { DESC_MARCA: "Brilhante", ID_MARCA: 296 },
  { DESC_MARCA: "Brilho fácil", ID_MARCA: 1700 },
  { DESC_MARCA: "Brilho&ton", ID_MARCA: 2057 },
  { DESC_MARCA: "Brilhol", ID_MARCA: 2477 },
  { DESC_MARCA: "Brilhus", ID_MARCA: 1295 },
  { DESC_MARCA: "Brillo", ID_MARCA: 578 },
  { DESC_MARCA: "Britvic", ID_MARCA: 161 },
  { DESC_MARCA: "Bubaloo", ID_MARCA: 404 },
  { DESC_MARCA: "Bubbaloo", ID_MARCA: 1214 },
  { DESC_MARCA: "Bufalo", ID_MARCA: 356 },
  { DESC_MARCA: "Cabaré", ID_MARCA: 2636 },
  { DESC_MARCA: "Cabare", ID_MARCA: 2639 },
  { DESC_MARCA: "Caboclo", ID_MARCA: 935 },
  { DESC_MARCA: "Cachaca 21", ID_MARCA: 1679 },
  { DESC_MARCA: "Café brasileiro", ID_MARCA: 1778 },
  { DESC_MARCA: "Cafe brasileiro", ID_MARCA: 791 },
  { DESC_MARCA: "Cajamar", ID_MARCA: 1556 },
  { DESC_MARCA: "Camelinho", ID_MARCA: 272 },
  { DESC_MARCA: "Camil", ID_MARCA: 2513 },
  { DESC_MARCA: "Camp", ID_MARCA: 290 },
  { DESC_MARCA: "Campo belo", ID_MARCA: 881 },
  { DESC_MARCA: "Campo largo", ID_MARCA: 74 },
  { DESC_MARCA: "Campos jordao", ID_MARCA: 1808 },
  { DESC_MARCA: "Cancao", ID_MARCA: 1970 },
  { DESC_MARCA: "Candida", ID_MARCA: 905 },
  { DESC_MARCA: "Candura", ID_MARCA: 1355 },
  { DESC_MARCA: "Canonico", ID_MARCA: 2552 },
  { DESC_MARCA: "Cantinho do vale", ID_MARCA: 449 },
  { DESC_MARCA: "Caravelas", ID_MARCA: 524 },
  { DESC_MARCA: "Carefree", ID_MARCA: 89 },
  { DESC_MARCA: "Carmelita", ID_MARCA: 1775 },
  { DESC_MARCA: "Cartago", ID_MARCA: 509 },
  { DESC_MARCA: "Casa bento", ID_MARCA: 1031 },
  { DESC_MARCA: "Casa blanca", ID_MARCA: 2099 },
  { DESC_MARCA: "Casa perini", ID_MARCA: 2015 },
  { DESC_MARCA: "Casa&perfume", ID_MARCA: 665 },
  { DESC_MARCA: "Casakm", ID_MARCA: 2417 },
  { DESC_MARCA: "Casaredo", ID_MARCA: 2615 },
  { DESC_MARCA: "Caseiro", ID_MARCA: 2480 },
  { DESC_MARCA: "Casillero del diablo", ID_MARCA: 1499 },
  { DESC_MARCA: "Castelo", ID_MARCA: 812 },
  { DESC_MARCA: "Casting gloss", ID_MARCA: 1349 },
  { DESC_MARCA: "Catupinga", ID_MARCA: 2270 },
  { DESC_MARCA: "Cayman", ID_MARCA: 1175 },
  { DESC_MARCA: "Ccgl", ID_MARCA: 2651 },
  { DESC_MARCA: "Celebrate", ID_MARCA: 2600 },
  { DESC_MARCA: "Cereser", ID_MARCA: 1985 },
  { DESC_MARCA: "Chalise", ID_MARCA: 251 },
  { DESC_MARCA: "Chapinha", ID_MARCA: 932 },
  { DESC_MARCA: "Charming", ID_MARCA: 2276 },
  { DESC_MARCA: "Chic lar", ID_MARCA: 2621 },
  { DESC_MARCA: "Chiclets", ID_MARCA: 1232 },
  { DESC_MARCA: "Chivas regal", ID_MARCA: 2315 },
  { DESC_MARCA: "Chocolatto", ID_MARCA: 980 },
  { DESC_MARCA: "Chokito", ID_MARCA: 1547 },
  { DESC_MARCA: "Chuva de prata", ID_MARCA: 2303 },
  { DESC_MARCA: "Cica", ID_MARCA: 2677 },
  { DESC_MARCA: "Cif", ID_MARCA: 98 },
  { DESC_MARCA: "Civ", ID_MARCA: 2195 },
  { DESC_MARCA: "Claybom", ID_MARCA: 215 },
  { DESC_MARCA: "Clean plus", ID_MARCA: 1658 },
  { DESC_MARCA: "Clear", ID_MARCA: 1328 },
  { DESC_MARCA: "Clik", ID_MARCA: 1964 },
  { DESC_MARCA: "Clinihair", ID_MARCA: 2168 },
  { DESC_MARCA: "Clorets", ID_MARCA: 1229 },
  { DESC_MARCA: "Close up", ID_MARCA: 1805 },
  { DESC_MARCA: "Closeup", ID_MARCA: 746 },
  { DESC_MARCA: "Club social", ID_MARCA: 1925 },
  { DESC_MARCA: "Coala", ID_MARCA: 419 },
  { DESC_MARCA: "Cocinero", ID_MARCA: 1670 },
  { DESC_MARCA: "Coco quadrado", ID_MARCA: 497 },
  { DESC_MARCA: "Coco refresh", ID_MARCA: 1334 },
  { DESC_MARCA: "Coco verde+", ID_MARCA: 1121 },
  { DESC_MARCA: "Colgate", ID_MARCA: 239 },
  { DESC_MARCA: "Colgate plax", ID_MARCA: 1790 },
  { DESC_MARCA: "Colman", ID_MARCA: 1583 },
  { DESC_MARCA: "Colombo", ID_MARCA: 2594 },
  { DESC_MARCA: "Colorama", ID_MARCA: 80 },
  { DESC_MARCA: "Comfort", ID_MARCA: 308 },
  { DESC_MARCA: "Concha y toro", ID_MARCA: 1613 },
  { DESC_MARCA: "Concho y toro", ID_MARCA: 2471 },
  { DESC_MARCA: "Conde foucald", ID_MARCA: 1451 },
  { DESC_MARCA: "Condor", ID_MARCA: 1277 },
  { DESC_MARCA: "Confort", ID_MARCA: 1847 },
  { DESC_MARCA: "Confraria", ID_MARCA: 689 },
  { DESC_MARCA: "Contini", ID_MARCA: 1715 },
  { DESC_MARCA: "Copag", ID_MARCA: 1481 },
  { DESC_MARCA: "Coperalcool", ID_MARCA: 1010 },
  { DESC_MARCA: "Copo mais", ID_MARCA: 1835 },
  { DESC_MARCA: "Copobras", ID_MARCA: 1160 },
  { DESC_MARCA: "Copomais", ID_MARCA: 1931 },
  { DESC_MARCA: "Coqueiro", ID_MARCA: 758 },
  { DESC_MARCA: "Coquel", ID_MARCA: 1685 },
  { DESC_MARCA: "Coquetel", ID_MARCA: 1313 },
  { DESC_MARCA: "Cor&ton", ID_MARCA: 1502 },
  { DESC_MARCA: "Corona", ID_MARCA: 1496 },
  { DESC_MARCA: "Corpo a corpo", ID_MARCA: 1460 },
  { DESC_MARCA: "Cotidian", ID_MARCA: 1412 },
  { DESC_MARCA: "Cotton", ID_MARCA: 371 },
  { DESC_MARCA: "Cottonbaby", ID_MARCA: 2237 },
  { DESC_MARCA: "Country wine", ID_MARCA: 269 },
  { DESC_MARCA: "Cova do frade", ID_MARCA: 2423 },
  { DESC_MARCA: "Cremer", ID_MARCA: 446 },
  { DESC_MARCA: "Cremer magic", ID_MARCA: 1751 },
  { DESC_MARCA: "Cricket", ID_MARCA: 1463 },
  { DESC_MARCA: "Cristal", ID_MARCA: 407 },
  { DESC_MARCA: "Cristalina", ID_MARCA: 1079 },
  { DESC_MARCA: "Crunch", ID_MARCA: 1346 },
  { DESC_MARCA: "Crystal", ID_MARCA: 2669 },
  { DESC_MARCA: "D-tone", ID_MARCA: 2054 },
  { DESC_MARCA: "Da barra", ID_MARCA: 254 },
  { DESC_MARCA: "Da fruta", ID_MARCA: 857 },
  { DESC_MARCA: "Da terrinha", ID_MARCA: 2585 },
  { DESC_MARCA: "Da terrinha", ID_MARCA: 2588 },
  { DESC_MARCA: "Dadinho", ID_MARCA: 632 },
  { DESC_MARCA: "Dafruta", ID_MARCA: 1046 },
  { DESC_MARCA: "Dalla", ID_MARCA: 2618 },
  { DESC_MARCA: "Daneva", ID_MARCA: 2441 },
  { DESC_MARCA: "Danny", ID_MARCA: 1859 },
  { DESC_MARCA: "Danubio", ID_MARCA: 1475 },
  { DESC_MARCA: "Darling", ID_MARCA: 1274 },
  { DESC_MARCA: "Davene", ID_MARCA: 395 },
  { DESC_MARCA: "Days", ID_MARCA: 1919 },
  { DESC_MARCA: "Delicia", ID_MARCA: 821 },
  { DESC_MARCA: "Deluxe", ID_MARCA: 1793 },
  { DESC_MARCA: "Destac", ID_MARCA: 1433 },
  { DESC_MARCA: "Detefon", ID_MARCA: 773 },
  { DESC_MARCA: "Dewars", ID_MARCA: 2072 },
  { DESC_MARCA: "Diabo verde", ID_MARCA: 1469 },
  { DESC_MARCA: "Diana", ID_MARCA: 911 },
  { DESC_MARCA: "Dilluca", ID_MARCA: 2165 },
  { DESC_MARCA: "Dimension", ID_MARCA: 1697 },
  { DESC_MARCA: "Dimple", ID_MARCA: 1733 },
  { DESC_MARCA: "Dinalle", ID_MARCA: 92 },
  { DESC_MARCA: "Dipol", ID_MARCA: 452 },
  { DESC_MARCA: "Do avo", ID_MARCA: 2327 },
  { DESC_MARCA: "Do barril", ID_MARCA: 1007 },
  { DESC_MARCA: "Do chefe", ID_MARCA: 1844 },
  { DESC_MARCA: "Doce menor", ID_MARCA: 2564 },
  { DESC_MARCA: "Docile", ID_MARCA: 1142 },
  { DESC_MARCA: "Dog chow", ID_MARCA: 878 },
  { DESC_MARCA: "Dolce gusto", ID_MARCA: 1073 },
  { DESC_MARCA: "Dom bosco", ID_MARCA: 1973 },
  { DESC_MARCA: "Domecq", ID_MARCA: 2549 },
  { DESC_MARCA: "Domus", ID_MARCA: 947 },
  { DESC_MARCA: "Don bastian", ID_MARCA: 2027 },
  { DESC_MARCA: "Don genaro", ID_MARCA: 2396 },
  { DESC_MARCA: "Dona benta", ID_MARCA: 1523 },
  { DESC_MARCA: "Dona clara", ID_MARCA: 698 },
  { DESC_MARCA: "Doriana", ID_MARCA: 1667 },
  { DESC_MARCA: "Dove", ID_MARCA: 116 },
  { DESC_MARCA: "Dove baby", ID_MARCA: 959 },
  { DESC_MARCA: "Downy", ID_MARCA: 1199 },
  { DESC_MARCA: "Drebys", ID_MARCA: 2159 },
  { DESC_MARCA: "Dualette", ID_MARCA: 1538 },
  { DESC_MARCA: "Duchen", ID_MARCA: 2324 },
  { DESC_MARCA: "Ducoco", ID_MARCA: 1409 },
  { DESC_MARCA: "Duelo", ID_MARCA: 2360 },
  { DESC_MARCA: "Duetto", ID_MARCA: 1832 },
  { DESC_MARCA: "Duracell", ID_MARCA: 305 },
  { DESC_MARCA: "Durepoxi", ID_MARCA: 263 },
  { DESC_MARCA: "Easy-off", ID_MARCA: 1589 },
  { DESC_MARCA: "Eisenbahn", ID_MARCA: 1895 },
  { DESC_MARCA: "Elefante", ID_MARCA: 242 },
  { DESC_MARCA: "Elege", ID_MARCA: 2465 },
  { DESC_MARCA: "Elseve", ID_MARCA: 167 },
  { DESC_MARCA: "Embalixo", ID_MARCA: 740 },
  { DESC_MARCA: "Emege", ID_MARCA: 2483 },
  { DESC_MARCA: "Encosta ribeira", ID_MARCA: 2375 },
  { DESC_MARCA: "Epopéia", ID_MARCA: 2387 },
  { DESC_MARCA: "Epoxi", ID_MARCA: 1061 },
  { DESC_MARCA: "Esfrebom", ID_MARCA: 854 },
  { DESC_MARCA: "Etchart", ID_MARCA: 2306 },
  { DESC_MARCA: "Eternity", ID_MARCA: 2120 },
  { DESC_MARCA: "Etti", ID_MARCA: 986 },
  { DESC_MARCA: "Eve", ID_MARCA: 2252 },
  { DESC_MARCA: "Expert", ID_MARCA: 2090 },
  { DESC_MARCA: "Expertise", ID_MARCA: 1781 },
  { DESC_MARCA: "Familiar", ID_MARCA: 2689 },
  { DESC_MARCA: "Farmax", ID_MARCA: 1070 },
  { DESC_MARCA: "Farnese", ID_MARCA: 1454 },
  { DESC_MARCA: "Fazendinha", ID_MARCA: 1982 },
  { DESC_MARCA: "Feelclean", ID_MARCA: 2686 },
  { DESC_MARCA: "Fermix", ID_MARCA: 1655 },
  { DESC_MARCA: "Fiat lux", ID_MARCA: 644 },
  { DESC_MARCA: "Fino grao", ID_MARCA: 1358 },
  { DESC_MARCA: "Fiorissimo", ID_MARCA: 2282 },
  { DESC_MARCA: "Fixed", ID_MARCA: 149 },
  { DESC_MARCA: "Flc", ID_MARCA: 2102 },
  { DESC_MARCA: "Fleischmann", ID_MARCA: 1871 },
  { DESC_MARCA: "Flor de ype", ID_MARCA: 707 },
  { DESC_MARCA: "Focauld", ID_MARCA: 2228 },
  { DESC_MARCA: "Fofinho", ID_MARCA: 782 },
  { DESC_MARCA: "Fofo", ID_MARCA: 815 },
  { DESC_MARCA: "Fofura", ID_MARCA: 1184 },
  { DESC_MARCA: "Forsan", ID_MARCA: 1442 },
  { DESC_MARCA: "Fort", ID_MARCA: 1862 },
  { DESC_MARCA: "Fort limp", ID_MARCA: 914 },
  { DESC_MARCA: "Foxlux", ID_MARCA: 2042 },
  { DESC_MARCA: "Francis", ID_MARCA: 539 },
  { DESC_MARCA: "Fresh", ID_MARCA: 1247 },
  { DESC_MARCA: "Frisco", ID_MARCA: 572 },
  { DESC_MARCA: "Friskies", ID_MARCA: 1688 },
  { DESC_MARCA: "Fronteira", ID_MARCA: 2150 },
  { DESC_MARCA: "Fructis", ID_MARCA: 2132 },
  { DESC_MARCA: "Fructus", ID_MARCA: 1826 },
  { DESC_MARCA: "Fugini", ID_MARCA: 890 },
  { DESC_MARCA: "Furioso", ID_MARCA: 968 },
  { DESC_MARCA: "G'nutre", ID_MARCA: 1727 },
  { DESC_MARCA: "Galak", ID_MARCA: 1208 },
  { DESC_MARCA: "Gallo", ID_MARCA: 926 },
  { DESC_MARCA: "Gallo", ID_MARCA: 761 },
  { DESC_MARCA: "Galo", ID_MARCA: 1364 },
  { DESC_MARCA: "Gang", ID_MARCA: 1955 },
  { DESC_MARCA: "Garnier", ID_MARCA: 203 },
  { DESC_MARCA: "Garoto", ID_MARCA: 548 },
  { DESC_MARCA: "Gb", ID_MARCA: 2546 },
  { DESC_MARCA: "Gillette", ID_MARCA: 917 },
  { DESC_MARCA: "Gina", ID_MARCA: 1763 },
  { DESC_MARCA: "Giovanna baby", ID_MARCA: 2114 },
  { DESC_MARCA: "Girando sol", ID_MARCA: 1211 },
  { DESC_MARCA: "Glade", ID_MARCA: 1577 },
  { DESC_MARCA: "Goialli", ID_MARCA: 1022 },
  { DESC_MARCA: "Gold", ID_MARCA: 1181 },
  { DESC_MARCA: "Golf", ID_MARCA: 2198 },
  { DESC_MARCA: "Goma pox", ID_MARCA: 1601 },
  { DESC_MARCA: "Gomes da costa", ID_MARCA: 749 },
  { DESC_MARCA: "Gordons", ID_MARCA: 2369 },
  { DESC_MARCA: "Gota", ID_MARCA: 1652 },
  { DESC_MARCA: "Gourmet", ID_MARCA: 2126 },
  { DESC_MARCA: "Gran par", ID_MARCA: 2606 },
  { DESC_MARCA: "Granado", ID_MARCA: 323 },
  { DESC_MARCA: "Grey goose", ID_MARCA: 2225 },
  { DESC_MARCA: "Guarany", ID_MARCA: 461 },
  { DESC_MARCA: "Halls", ID_MARCA: 1226 },
  { DESC_MARCA: "Harpic", ID_MARCA: 590 },
  { DESC_MARCA: "Havaianas", ID_MARCA: 86 },
  { DESC_MARCA: "Heineken", ID_MARCA: 1103 },
  { DESC_MARCA: "Heinz", ID_MARCA: 998 },
  { DESC_MARCA: "Hellmanns", ID_MARCA: 392 },
  { DESC_MARCA: "Helmanns", ID_MARCA: 2381 },
  { DESC_MARCA: "Herbissimo", ID_MARCA: 1058 },
  { DESC_MARCA: "Hersheys", ID_MARCA: 659 },
  { DESC_MARCA: "Hikari", ID_MARCA: 2372 },
  { DESC_MARCA: "Hillo", ID_MARCA: 1379 },
  { DESC_MARCA: "Hinomoto", ID_MARCA: 1607 },
  { DESC_MARCA: "Hipoglos", ID_MARCA: 1820 },
  { DESC_MARCA: "Honey", ID_MARCA: 2162 },
  { DESC_MARCA: "Huggies", ID_MARCA: 212 },
  { DESC_MARCA: "Huppy baby", ID_MARCA: 1166 },
  { DESC_MARCA: "Hydratta", ID_MARCA: 1406 },
  { DESC_MARCA: "Ice drink", ID_MARCA: 2180 },
  { DESC_MARCA: "Ice fresh", ID_MARCA: 1397 },
  { DESC_MARCA: "Iguaçu", ID_MARCA: 728 },
  { DESC_MARCA: "Iki", ID_MARCA: 1169 },
  { DESC_MARCA: "Imedia excellence", ID_MARCA: 1259 },
  { DESC_MARCA: "Imédia excellence", ID_MARCA: 1271 },
  { DESC_MARCA: "Impala", ID_MARCA: 200 },
  { DESC_MARCA: "Imperador", ID_MARCA: 491 },
  { DESC_MARCA: "Imperio", ID_MARCA: 2504 },
  { DESC_MARCA: "Indaia", ID_MARCA: 1724 },
  { DESC_MARCA: "Ingleza", ID_MARCA: 1604 },
  { DESC_MARCA: "Intimus", ID_MARCA: 197 },
  { DESC_MARCA: "Invicta", ID_MARCA: 1316 },
  { DESC_MARCA: "Ioio", ID_MARCA: 752 },
  { DESC_MARCA: "Ioio mix", ID_MARCA: 2243 },
  { DESC_MARCA: "Ipanema", ID_MARCA: 83 },
  { DESC_MARCA: "Itaipava", ID_MARCA: 2666 },
  { DESC_MARCA: "Italac", ID_MARCA: 500 },
  { DESC_MARCA: "Itamaraty", ID_MARCA: 953 },
  { DESC_MARCA: "Itambé", ID_MARCA: 647 },
  { DESC_MARCA: "Jack daniels", ID_MARCA: 2081 },
  { DESC_MARCA: "Jamel", ID_MARCA: 164 },
  { DESC_MARCA: "Johnnie walker", ID_MARCA: 2363 },
  { DESC_MARCA: "Johnson", ID_MARCA: 119 },
  { DESC_MARCA: "Johnson's", ID_MARCA: 326 },
  { DESC_MARCA: "Johnsons", ID_MARCA: 176 },
  { DESC_MARCA: "Jumbinho", ID_MARCA: 1937 },
  { DESC_MARCA: "Jureia", ID_MARCA: 1400 },
  { DESC_MARCA: "Jurupinga", ID_MARCA: 227 },
  { DESC_MARCA: "K&m", ID_MARCA: 1706 },
  { DESC_MARCA: "Kalipto", ID_MARCA: 875 },
  { DESC_MARCA: "Kanechomn", ID_MARCA: 608 },
  { DESC_MARCA: "Karina", ID_MARCA: 473 },
  { DESC_MARCA: "Karpex", ID_MARCA: 1586 },
  { DESC_MARCA: "Karui", ID_MARCA: 1163 },
  { DESC_MARCA: "Keep cooler", ID_MARCA: 938 },
  { DESC_MARCA: "Keeprend", ID_MARCA: 1340 },
  { DESC_MARCA: "Kelldrin", ID_MARCA: 2672 },
  { DESC_MARCA: "Kero coco", ID_MARCA: 1634 },
  { DESC_MARCA: "Ketto", ID_MARCA: 2096 },
  { DESC_MARCA: "Ki-suco", ID_MARCA: 2051 },
  { DESC_MARCA: "Kian", ID_MARCA: 1298 },
  { DESC_MARCA: "Kimimo", ID_MARCA: 1991 },
  { DESC_MARCA: "Kinder bueno", ID_MARCA: 677 },
  { DESC_MARCA: "King", ID_MARCA: 704 },
  { DESC_MARCA: "Kislla", ID_MARCA: 1268 },
  { DESC_MARCA: "Kiss", ID_MARCA: 329 },
  { DESC_MARCA: "Kit kat", ID_MARCA: 557 },
  { DESC_MARCA: "Kitchen", ID_MARCA: 1736 },
  { DESC_MARCA: "Kitut", ID_MARCA: 1628 },
  { DESC_MARCA: "Kleenex", ID_MARCA: 554 },
  { DESC_MARCA: "Knorr", ID_MARCA: 68 },
  { DESC_MARCA: "Kolene", ID_MARCA: 623 },
  { DESC_MARCA: "Koleston", ID_MARCA: 113 },
  { DESC_MARCA: "Kraft", ID_MARCA: 1997 },
  { DESC_MARCA: "Kro", ID_MARCA: 2510 },
  { DESC_MARCA: "La bodega del sol", ID_MARCA: 2399 },
  { DESC_MARCA: "La fiore", ID_MARCA: 1760 },
  { DESC_MARCA: "La flore", ID_MARCA: 1262 },
  { DESC_MARCA: "La fruta", ID_MARCA: 1055 },
  { DESC_MARCA: "La preferida", ID_MARCA: 2330 },
  { DESC_MARCA: "La serenissima", ID_MARCA: 716 },
  { DESC_MARCA: "La violetera", ID_MARCA: 1529 },
  { DESC_MARCA: "Lacta", ID_MARCA: 521 },
  { DESC_MARCA: "Lady soft", ID_MARCA: 1865 },
  { DESC_MARCA: "Ladysoft", ID_MARCA: 1772 },
  { DESC_MARCA: "Laka", ID_MARCA: 2486 },
  { DESC_MARCA: "Leão", ID_MARCA: 1487 },
  { DESC_MARCA: "Lebre", ID_MARCA: 503 },
  { DESC_MARCA: "Leite de colonia", ID_MARCA: 983 },
  { DESC_MARCA: "Leite rosas", ID_MARCA: 800 },
  { DESC_MARCA: "Leonoff", ID_MARCA: 680 },
  { DESC_MARCA: "Lider", ID_MARCA: 1535 },
  { DESC_MARCA: "Life clean", ID_MARCA: 410 },
  { DESC_MARCA: "Lifebuoy", ID_MARCA: 2207 },
  { DESC_MARCA: "Lightner", ID_MARCA: 2135 },
  { DESC_MARCA: "Lily", ID_MARCA: 2003 },
  { DESC_MARCA: "Limpamania", ID_MARCA: 629 },
  { DESC_MARCA: "Limpol", ID_MARCA: 1028 },
  { DESC_MARCA: "Liskka", ID_MARCA: 2189 },
  { DESC_MARCA: "Listerine", ID_MARCA: 1304 },
  { DESC_MARCA: "Liza", ID_MARCA: 635 },
  { DESC_MARCA: "Loctite", ID_MARCA: 2108 },
  { DESC_MARCA: "Lokal", ID_MARCA: 2675 },
  { DESC_MARCA: "Lolita", ID_MARCA: 1301 },
  { DESC_MARCA: "Lolly", ID_MARCA: 2144 },
  { DESC_MARCA: "Long chips", ID_MARCA: 47 },
  { DESC_MARCA: "Look", ID_MARCA: 1823 },
  { DESC_MARCA: "Looney tunes", ID_MARCA: 1196 },
  { DESC_MARCA: "Looping", ID_MARCA: 1430 },
  { DESC_MARCA: "Lor", ID_MARCA: 1241 },
  { DESC_MARCA: "Loréal", ID_MARCA: 230 },
  { DESC_MARCA: "Loreal", ID_MARCA: 365 },
  { DESC_MARCA: "Lovetex", ID_MARCA: 2297 },
  { DESC_MARCA: "Lunar", ID_MARCA: 2285 },
  { DESC_MARCA: "Lustral ype", ID_MARCA: 2300 },
  { DESC_MARCA: "Lustros", ID_MARCA: 1742 },
  { DESC_MARCA: "Lux", ID_MARCA: 785 },
  { DESC_MARCA: "Lysoclin", ID_MARCA: 1256 },
  { DESC_MARCA: "Lysoform", ID_MARCA: 866 },
  { DESC_MARCA: "Lysol", ID_MARCA: 1943 },
  { DESC_MARCA: "M&m", ID_MARCA: 2540 },
  { DESC_MARCA: "Mabel", ID_MARCA: 1814 },
  { DESC_MARCA: "Mãe terra", ID_MARCA: 1958 },
  { DESC_MARCA: "Maggi", ID_MARCA: 848 },
  { DESC_MARCA: "Magic brill", ID_MARCA: 566 },
  { DESC_MARCA: "Maguary", ID_MARCA: 143 },
  { DESC_MARCA: "Mais coco", ID_MARCA: 1571 },
  { DESC_MARCA: "Maizena", ID_MARCA: 464 },
  { DESC_MARCA: "Marata", ID_MARCA: 650 },
  { DESC_MARCA: "Marcus james", ID_MARCA: 1034 },
  { DESC_MARCA: "Maria", ID_MARCA: 1067 },
  { DESC_MARCA: "Martini", ID_MARCA: 347 },
  { DESC_MARCA: "Martzano", ID_MARCA: 2186 },
  { DESC_MARCA: "Masterfoods", ID_MARCA: 2543 },
  { DESC_MARCA: "Mat inset", ID_MARCA: 674 },
  { DESC_MARCA: "Mate leao", ID_MARCA: 1838 },
  { DESC_MARCA: "Matte leão", ID_MARCA: 2339 },
  { DESC_MARCA: "Max", ID_MARCA: 1289 },
  { DESC_MARCA: "Maxton", ID_MARCA: 1541 },
  { DESC_MARCA: "Maybelline", ID_MARCA: 2066 },
  { DESC_MARCA: "Mazola", ID_MARCA: 1520 },
  { DESC_MARCA: "Medfio", ID_MARCA: 533 },
  { DESC_MARCA: "Melitta", ID_MARCA: 1478 },
  { DESC_MARCA: "Memphis", ID_MARCA: 1049 },
  { DESC_MARCA: "Menina", ID_MARCA: 887 },
  { DESC_MARCA: "Merilu", ID_MARCA: 1472 },
  { DESC_MARCA: "Mi pueblo", ID_MARCA: 2555 },
  { DESC_MARCA: "Mi pueblo", ID_MARCA: 2558 },
  { DESC_MARCA: "Milka", ID_MARCA: 641 },
  { DESC_MARCA: "Minuano", ID_MARCA: 794 },
  { DESC_MARCA: "Miracolo", ID_MARCA: 2069 },
  { DESC_MARCA: "Miramar", ID_MARCA: 2528 },
  { DESC_MARCA: "Mirulla", ID_MARCA: 2018 },
  { DESC_MARCA: "Moca", ID_MARCA: 776 },
  { DESC_MARCA: "Mococa", ID_MARCA: 1091 },
  { DESC_MARCA: "Mocoquinha", ID_MARCA: 1106 },
  { DESC_MARCA: "Moema", ID_MARCA: 734 },
  { DESC_MARCA: "Molico", ID_MARCA: 1337 },
  { DESC_MARCA: "Mon bijou", ID_MARCA: 131 },
  { DESC_MARCA: "Monange", ID_MARCA: 107 },
  { DESC_MARCA: "Montanero", ID_MARCA: 2390 },
  { DESC_MARCA: "Montilla", ID_MARCA: 284 },
  { DESC_MARCA: "Moroaba", ID_MARCA: 1505 },
  { DESC_MARCA: "Mortein", ID_MARCA: 830 },
  { DESC_MARCA: "Mosaico", ID_MARCA: 2420 },
  { DESC_MARCA: "Moscowita", ID_MARCA: 1811 },
  { DESC_MARCA: "Mosteiro", ID_MARCA: 2609 },
  { DESC_MARCA: "Mucilon", ID_MARCA: 1244 },
  { DESC_MARCA: "Muky", ID_MARCA: 1508 },
  { DESC_MARCA: "Multialcool", ID_MARCA: 2249 },
  { DESC_MARCA: "Mumu", ID_MARCA: 599 },
  { DESC_MARCA: "Mundial", ID_MARCA: 386 },
  { DESC_MARCA: "Mupy", ID_MARCA: 2630 },
  { DESC_MARCA: "Muriel", ID_MARCA: 2138 },
  { DESC_MARCA: "Mybit", ID_MARCA: 2612 },
  { DESC_MARCA: "Nadir", ID_MARCA: 2680 },
  { DESC_MARCA: "Natal", ID_MARCA: 2294 },
  { DESC_MARCA: "Natu nobilis", ID_MARCA: 341 },
  { DESC_MARCA: "Naturalmente", ID_MARCA: 1283 },
  { DESC_MARCA: "Natureza", ID_MARCA: 2444 },
  { DESC_MARCA: "Negresco", ID_MARCA: 530 },
  { DESC_MARCA: "Nene", ID_MARCA: 2105 },
  { DESC_MARCA: "Nescafé", ID_MARCA: 944 },
  { DESC_MARCA: "Nescau", ID_MARCA: 59 },
  { DESC_MARCA: "Nesfit", ID_MARCA: 2492 },
  { DESC_MARCA: "Nesquik", ID_MARCA: 1934 },
  { DESC_MARCA: "Nestle", ID_MARCA: 2603 },
  { DESC_MARCA: "Nestlé", ID_MARCA: 224 },
  { DESC_MARCA: "Nestogeno", ID_MARCA: 1388 },
  { DESC_MARCA: "Neston", ID_MARCA: 2438 },
  { DESC_MARCA: "Neugebauer", ID_MARCA: 233 },
  { DESC_MARCA: "Neutrogena", ID_MARCA: 260 },
  { DESC_MARCA: "Neutrox", ID_MARCA: 206 },
  { DESC_MARCA: "Neval", ID_MARCA: 1151 },
  { DESC_MARCA: "Neve", ID_MARCA: 596 },
  { DESC_MARCA: "New suco", ID_MARCA: 2597 },
  { DESC_MARCA: "Nick\u00b4s", ID_MARCA: 1205 },
  { DESC_MARCA: "Niely", ID_MARCA: 1691 },
  { DESC_MARCA: "Niely gold", ID_MARCA: 110 },
  { DESC_MARCA: "Nikito", ID_MARCA: 1802 },
  { DESC_MARCA: "Ninho", ID_MARCA: 56 },
  { DESC_MARCA: "Nino dog", ID_MARCA: 2627 },
  { DESC_MARCA: "Nita", ID_MARCA: 134 },
  { DESC_MARCA: "Nivea", ID_MARCA: 191 },
  { DESC_MARCA: "Noble", ID_MARCA: 2519 },
  { DESC_MARCA: "Noch", ID_MARCA: 1082 },
  { DESC_MARCA: "Noch ice", ID_MARCA: 1115 },
  { DESC_MARCA: "Noilly prat", ID_MARCA: 2075 },
  { DESC_MARCA: "Norsal", ID_MARCA: 806 },
  { DESC_MARCA: "Novex", ID_MARCA: 836 },
  { DESC_MARCA: "Nugget", ID_MARCA: 611 },
  { DESC_MARCA: "Nutella", ID_MARCA: 1817 },
  { DESC_MARCA: "Nutrinectar", ID_MARCA: 2258 },
  { DESC_MARCA: "Nutrisse", ID_MARCA: 125 },
  { DESC_MARCA: "Nuts", ID_MARCA: 869 },
  { DESC_MARCA: "Nylooks", ID_MARCA: 1994 },
  { DESC_MARCA: "Ob", ID_MARCA: 332 },
  { DESC_MARCA: "Ober", ID_MARCA: 1940 },
  { DESC_MARCA: "Oeste", ID_MARCA: 2495 },
  { DESC_MARCA: "Off", ID_MARCA: 1562 },
  { DESC_MARCA: "Oils", ID_MARCA: 1019 },
  { DESC_MARCA: "Ola", ID_MARCA: 1565 },
  { DESC_MARCA: "Old spice", ID_MARCA: 1979 },
  { DESC_MARCA: "Old star", ID_MARCA: 1319 },
  { DESC_MARCA: "Old ville", ID_MARCA: 2432 },
  { DESC_MARCA: "Olden blend", ID_MARCA: 1892 },
  { DESC_MARCA: "Ole", ID_MARCA: 302 },
  { DESC_MARCA: "Oliveira", ID_MARCA: 1202 },
  { DESC_MARCA: "Oliveiras do conde", ID_MARCA: 2177 },
  { DESC_MARCA: "Olivia", ID_MARCA: 1916 },
  { DESC_MARCA: "Omo", ID_MARCA: 65 },
  { DESC_MARCA: "Optimum", ID_MARCA: 1619 },
  { DESC_MARCA: "Opus", ID_MARCA: 2129 },
  { DESC_MARCA: "Oral b", ID_MARCA: 1217 },
  { DESC_MARCA: "Oral-b", ID_MARCA: 515 },
  { DESC_MARCA: "Oreo", ID_MARCA: 686 },
  { DESC_MARCA: "Orloff", ID_MARCA: 314 },
  { DESC_MARCA: "Ouro branco", ID_MARCA: 1721 },
  { DESC_MARCA: "Ourolux", ID_MARCA: 1193 },
  { DESC_MARCA: "Ovomaltine", ID_MARCA: 1709 },
  { DESC_MARCA: "Ox", ID_MARCA: 155 },
  { DESC_MARCA: "Ox fibers", ID_MARCA: 1904 },
  { DESC_MARCA: "Ox oils", ID_MARCA: 1907 },
  { DESC_MARCA: "Ox proteins", ID_MARCA: 1910 },
  { DESC_MARCA: "Paixao", ID_MARCA: 2039 },
  { DESC_MARCA: "Paizano", ID_MARCA: 1457 },
  { DESC_MARCA: "Palhinha", ID_MARCA: 1427 },
  { DESC_MARCA: "Palmolive", ID_MARCA: 902 },
  { DESC_MARCA: "Palmolive naturals", ID_MARCA: 1286 },
  { DESC_MARCA: "Paloma", ID_MARCA: 218 },
  { DESC_MARCA: "Pampers", ID_MARCA: 1085 },
  { DESC_MARCA: "Panasonic", ID_MARCA: 1484 },
  { DESC_MARCA: "Panco", ID_MARCA: 2633 },
  { DESC_MARCA: "Pantene", ID_MARCA: 455 },
  { DESC_MARCA: "Parana", ID_MARCA: 977 },
  { DESC_MARCA: "Paratudo", ID_MARCA: 1574 },
  { DESC_MARCA: "Parmalat", ID_MARCA: 2393 },
  { DESC_MARCA: "Paso grande", ID_MARCA: 2435 },
  { DESC_MARCA: "Passatempo", ID_MARCA: 1841 },
  { DESC_MARCA: "Passe bem", ID_MARCA: 593 },
  { DESC_MARCA: "Passport", ID_MARCA: 974 },
  { DESC_MARCA: "Pata negra", ID_MARCA: 2231 },
  { DESC_MARCA: "Pato", ID_MARCA: 485 },
  { DESC_MARCA: "Pavao", ID_MARCA: 884 },
  { DESC_MARCA: "Pedigree", ID_MARCA: 440 },
  { DESC_MARCA: "Pedra 90", ID_MARCA: 683 },
  { DESC_MARCA: "Pele", ID_MARCA: 1631 },
  { DESC_MARCA: "Perdigao", ID_MARCA: 2525 },
  { DESC_MARCA: "Perfex", ID_MARCA: 1922 },
  { DESC_MARCA: "Pergola", ID_MARCA: 209 },
  { DESC_MARCA: "Perini", ID_MARCA: 2219 },
  { DESC_MARCA: "Periquita", ID_MARCA: 2312 },
  { DESC_MARCA: "Perrier jouet", ID_MARCA: 1292 },
  { DESC_MARCA: "Personal", ID_MARCA: 95 },
  { DESC_MARCA: "Pescador", ID_MARCA: 1424 },
  { DESC_MARCA: "Petra", ID_MARCA: 2645 },
  { DESC_MARCA: "Petybon", ID_MARCA: 872 },
  { DESC_MARCA: "Phebo", ID_MARCA: 860 },
  { DESC_MARCA: "Phisalia", ID_MARCA: 956 },
  { DESC_MARCA: "Pilao", ID_MARCA: 1913 },
  { DESC_MARCA: "Pilão", ID_MARCA: 299 },
  { DESC_MARCA: "Pinduca", ID_MARCA: 1730 },
  { DESC_MARCA: "Pinheirense", ID_MARCA: 2123 },
  { DESC_MARCA: "Pinho bril", ID_MARCA: 1025 },
  { DESC_MARCA: "Pinho sol", ID_MARCA: 389 },
  { DESC_MARCA: "Piquitucho", ID_MARCA: 2683 },
  { DESC_MARCA: "Piracanjuba", ID_MARCA: 185 },
  { DESC_MARCA: "Pirakids", ID_MARCA: 1310 },
  { DESC_MARCA: "Piraque", ID_MARCA: 512 },
  { DESC_MARCA: "Pirassununga 1921", ID_MARCA: 2474 },
  { DESC_MARCA: "Pirisa", ID_MARCA: 1673 },
  { DESC_MARCA: "Pitu", ID_MARCA: 818 },
  { DESC_MARCA: "Plenitud", ID_MARCA: 929 },
  { DESC_MARCA: "Plenitud active", ID_MARCA: 1853 },
  { DESC_MARCA: "Plugados", ID_MARCA: 1799 },
  { DESC_MARCA: "Polibril", ID_MARCA: 2261 },
  { DESC_MARCA: "Poliflor", ID_MARCA: 1382 },
  { DESC_MARCA: "Pom pom", ID_MARCA: 1766 },
  { DESC_MARCA: "Pomarola", ID_MARCA: 398 },
  { DESC_MARCA: "Pomodoro", ID_MARCA: 1466 },
  { DESC_MARCA: "Pompom", ID_MARCA: 170 },
  { DESC_MARCA: "Ponzan", ID_MARCA: 653 },
  { DESC_MARCA: "Porto alegre", ID_MARCA: 1544 },
  { DESC_MARCA: "Prata", ID_MARCA: 1076 },
  { DESC_MARCA: "Pratsy", ID_MARCA: 2084 },
  { DESC_MARCA: "Predilecta", ID_MARCA: 743 },
  { DESC_MARCA: "Preferita", ID_MARCA: 44 },
  { DESC_MARCA: "President", ID_MARCA: 2468 },
  { DESC_MARCA: "Presidente", ID_MARCA: 1037 },
  { DESC_MARCA: "Prestige", ID_MARCA: 2411 },
  { DESC_MARCA: "Prestigio", ID_MARCA: 1550 },
  { DESC_MARCA: "Prestobarba", ID_MARCA: 1250 },
  { DESC_MARCA: "Prevent", ID_MARCA: 2354 },
  { DESC_MARCA: "Princesa", ID_MARCA: 2660 },
  { DESC_MARCA: "Pringles", ID_MARCA: 725 },
  { DESC_MARCA: "Pritt", ID_MARCA: 2288 },
  { DESC_MARCA: "Probak ii", ID_MARCA: 2333 },
  { DESC_MARCA: "Procomfort", ID_MARCA: 335 },
  { DESC_MARCA: "Prodasa", ID_MARCA: 893 },
  { DESC_MARCA: "Protex", ID_MARCA: 1190 },
  { DESC_MARCA: "Prudence", ID_MARCA: 368 },
  { DESC_MARCA: "Purilev", ID_MARCA: 401 },
  { DESC_MARCA: "Purina", ID_MARCA: 1043 },
  { DESC_MARCA: "Puro coco", ID_MARCA: 1901 },
  { DESC_MARCA: "Q lustro", ID_MARCA: 2654 },
  { DESC_MARCA: "Qboa", ID_MARCA: 359 },
  { DESC_MARCA: "Quaker", ID_MARCA: 137 },
  { DESC_MARCA: "Qualidy", ID_MARCA: 437 },
  { DESC_MARCA: "Qualite", ID_MARCA: 2264 },
  { DESC_MARCA: "Qualy", ID_MARCA: 458 },
  { DESC_MARCA: "Quero", ID_MARCA: 434 },
  { DESC_MARCA: "Radium", ID_MARCA: 128 },
  { DESC_MARCA: "Raid", ID_MARCA: 1616 },
  { DESC_MARCA: "Rainha", ID_MARCA: 2489 },
  { DESC_MARCA: "Raiola", ID_MARCA: 1112 },
  { DESC_MARCA: "Raiolita", ID_MARCA: 1139 },
  { DESC_MARCA: "Rancheiro", ID_MARCA: 770 },
  { DESC_MARCA: "Randon", ID_MARCA: 1898 },
  { DESC_MARCA: "Rastro", ID_MARCA: 2321 },
  { DESC_MARCA: "Rayovac", ID_MARCA: 614 },
  { DESC_MARCA: "Razzo", ID_MARCA: 1154 },
  { DESC_MARCA: "Red bull", ID_MARCA: 1265 },
  { DESC_MARCA: "Removex", ID_MARCA: 1436 },
  { DESC_MARCA: "Renata", ID_MARCA: 2063 },
  { DESC_MARCA: "Repelex", ID_MARCA: 1640 },
  { DESC_MARCA: "Report", ID_MARCA: 1868 },
  { DESC_MARCA: "Rexona", ID_MARCA: 104 },
  { DESC_MARCA: "Ricca", ID_MARCA: 293 },
  { DESC_MARCA: "Rico", ID_MARCA: 1886 },
  { DESC_MARCA: "Rider", ID_MARCA: 374 },
  { DESC_MARCA: "Risque", ID_MARCA: 122 },
  { DESC_MARCA: "Rivoli", ID_MARCA: 245 },
  { DESC_MARCA: "Robinson crusoe", ID_MARCA: 2117 },
  { DESC_MARCA: "Rocks", ID_MARCA: 1694 },
  { DESC_MARCA: "Romanato", ID_MARCA: 428 },
  { DESC_MARCA: "Royal", ID_MARCA: 569 },
  { DESC_MARCA: "Royalpack", ID_MARCA: 2345 },
  { DESC_MARCA: "Safra", ID_MARCA: 1373 },
  { DESC_MARCA: "Saint germain", ID_MARCA: 992 },
  { DESC_MARCA: "Salada", ID_MARCA: 1649 },
  { DESC_MARCA: "Salon line", ID_MARCA: 2000 },
  { DESC_MARCA: "Salsaretti", ID_MARCA: 839 },
  { DESC_MARCA: "Salton", ID_MARCA: 140 },
  { DESC_MARCA: "Salvelox", ID_MARCA: 1637 },
  { DESC_MARCA: "San tome", ID_MARCA: 413 },
  { DESC_MARCA: "Sangue boi", ID_MARCA: 257 },
  { DESC_MARCA: "Sani-sol", ID_MARCA: 731 },
  { DESC_MARCA: "Sanol", ID_MARCA: 53 },
  { DESC_MARCA: "Sanro", ID_MARCA: 1493 },
  { DESC_MARCA: "Santa carolina", ID_MARCA: 2216 },
  { DESC_MARCA: "Santa clara", ID_MARCA: 2498 },
  { DESC_MARCA: "Santa helena", ID_MARCA: 2291 },
  { DESC_MARCA: "Santa tavola", ID_MARCA: 2657 },
  { DESC_MARCA: "Santepel", ID_MARCA: 1757 },
  { DESC_MARCA: "Santo antonio", ID_MARCA: 380 },
  { DESC_MARCA: "Sao francisco", ID_MARCA: 2309 },
  { DESC_MARCA: "Sao lourenco", ID_MARCA: 965 },
  { DESC_MARCA: "Sao roque", ID_MARCA: 1769 },
  { DESC_MARCA: "Sapeka", ID_MARCA: 1610 },
  { DESC_MARCA: "Sbp", ID_MARCA: 587 },
  { DESC_MARCA: "Scotch-brite", ID_MARCA: 1133 },
  { DESC_MARCA: "Scott", ID_MARCA: 1040 },
  { DESC_MARCA: "Seda", ID_MARCA: 41 },
  { DESC_MARCA: "Selvagem", ID_MARCA: 2240 },
  { DESC_MARCA: "Semalo", ID_MARCA: 2507 },
  { DESC_MARCA: "Semorin", ID_MARCA: 488 },
  { DESC_MARCA: "Sempre livre", ID_MARCA: 320 },
  { DESC_MARCA: "Senador", ID_MARCA: 668 },
  { DESC_MARCA: "Sensodyne", ID_MARCA: 77 },
  { DESC_MARCA: "Ser voce", ID_MARCA: 1187 },
  { DESC_MARCA: "Serigy", ID_MARCA: 2033 },
  { DESC_MARCA: "Siene", ID_MARCA: 2534 },
  { DESC_MARCA: "Siene", ID_MARCA: 2531 },
  { DESC_MARCA: "Silvo", ID_MARCA: 1595 },
  { DESC_MARCA: "Simple", ID_MARCA: 2036 },
  { DESC_MARCA: "Sinha", ID_MARCA: 2698 },
  { DESC_MARCA: "Skala", ID_MARCA: 476 },
  { DESC_MARCA: "Skyy", ID_MARCA: 2318 },
  { DESC_MARCA: "Smart", ID_MARCA: 1220 },
  { DESC_MARCA: "Smirnoff", ID_MARCA: 248 },
  { DESC_MARCA: "Snickers", ID_MARCA: 2537 },
  { DESC_MARCA: "Snob", ID_MARCA: 1754 },
  { DESC_MARCA: "Snow flakes", ID_MARCA: 1787 },
  { DESC_MARCA: "Social", ID_MARCA: 1568 },
  { DESC_MARCA: "Social clean", ID_MARCA: 1796 },
  { DESC_MARCA: "Sococo", ID_MARCA: 710 },
  { DESC_MARCA: "Soft color", ID_MARCA: 2273 },
  { DESC_MARCA: "Softy's", ID_MARCA: 2402 },
  { DESC_MARCA: "Sol", ID_MARCA: 563 },
  { DESC_MARCA: "Solar expertise", ID_MARCA: 1001 },
  { DESC_MARCA: "Sonho valsa", ID_MARCA: 1718 },
  { DESC_MARCA: "Sorriso", ID_MARCA: 1136 },
  { DESC_MARCA: "Soya", ID_MARCA: 1661 },
  { DESC_MARCA: "St ives", ID_MARCA: 1253 },
  { DESC_MARCA: "St.ives", ID_MARCA: 1235 },
  { DESC_MARCA: "Stella doro", ID_MARCA: 1967 },
  { DESC_MARCA: "Stock", ID_MARCA: 2222 },
  { DESC_MARCA: "Stylus", ID_MARCA: 542 },
  { DESC_MARCA: "Suave", ID_MARCA: 1124 },
  { DESC_MARCA: "Suavit", ID_MARCA: 1976 },
  { DESC_MARCA: "Suavity", ID_MARCA: 1361 },
  { DESC_MARCA: "Sublime", ID_MARCA: 1109 },
  { DESC_MARCA: "Sul", ID_MARCA: 620 },
  { DESC_MARCA: "Sundown", ID_MARCA: 236 },
  { DESC_MARCA: "Sunless", ID_MARCA: 1829 },
  { DESC_MARCA: "Super bonder", ID_MARCA: 281 },
  { DESC_MARCA: "Super candida", ID_MARCA: 719 },
  { DESC_MARCA: "Super clean", ID_MARCA: 2255 },
  { DESC_MARCA: "Super gold", ID_MARCA: 188 },
  { DESC_MARCA: "Super lub", ID_MARCA: 2336 },
  { DESC_MARCA: "Superbom", ID_MARCA: 626 },
  { DESC_MARCA: "Surf", ID_MARCA: 62 },
  { DESC_MARCA: "Sym", ID_MARCA: 506 },
  { DESC_MARCA: "Tabasco", ID_MARCA: 2174 },
  { DESC_MARCA: "Tabu", ID_MARCA: 803 },
  { DESC_MARCA: "Tacolac", ID_MARCA: 1703 },
  { DESC_MARCA: "Tal qual", ID_MARCA: 2561 },
  { DESC_MARCA: "Tal qual", ID_MARCA: 2567 },
  { DESC_MARCA: "Tandy", ID_MARCA: 1712 },
  { DESC_MARCA: "Tang", ID_MARCA: 1016 },
  { DESC_MARCA: "Tanqueray", ID_MARCA: 2366 },
  { DESC_MARCA: "Tarantella", ID_MARCA: 1094 },
  { DESC_MARCA: "Taschibra", ID_MARCA: 2453 },
  { DESC_MARCA: "Tay", ID_MARCA: 1625 },
  { DESC_MARCA: "Teisseire", ID_MARCA: 1127 },
  { DESC_MARCA: "Teixeira", ID_MARCA: 1850 },
  { DESC_MARCA: "Tek", ID_MARCA: 317 },
  { DESC_MARCA: "Tena", ID_MARCA: 2009 },
  { DESC_MARCA: "Tena pants", ID_MARCA: 2012 },
  { DESC_MARCA: "Tenaz", ID_MARCA: 287 },
  { DESC_MARCA: "Tenys pe", ID_MARCA: 443 },
  { DESC_MARCA: "Tequiloka", ID_MARCA: 1322 },
  { DESC_MARCA: "Theoto", ID_MARCA: 1526 },
  { DESC_MARCA: "Three bond", ID_MARCA: 71 },
  { DESC_MARCA: "Threebond", ID_MARCA: 2582 },
  { DESC_MARCA: "Tic tac", ID_MARCA: 1949 },
  { DESC_MARCA: "Tinindo", ID_MARCA: 2357 },
  { DESC_MARCA: "Tixan", ID_MARCA: 1961 },
  { DESC_MARCA: "Tixan ype", ID_MARCA: 767 },
  { DESC_MARCA: "Tnt", ID_MARCA: 2642 },
  { DESC_MARCA: "Toblerone", ID_MARCA: 638 },
  { DESC_MARCA: "Toddy", ID_MARCA: 695 },
  { DESC_MARCA: "Toddynho", ID_MARCA: 1514 },
  { DESC_MARCA: "Tonys", ID_MARCA: 2522 },
  { DESC_MARCA: "Topz", ID_MARCA: 425 },
  { DESC_MARCA: "Torcida", ID_MARCA: 962 },
  { DESC_MARCA: "Toro", ID_MARCA: 1145 },
  { DESC_MARCA: "Toro centenario", ID_MARCA: 1148 },
  { DESC_MARCA: "Tostines", ID_MARCA: 1553 },
  { DESC_MARCA: "Total 12", ID_MARCA: 2279 },
  { DESC_MARCA: "Tra la la", ID_MARCA: 920 },
  { DESC_MARCA: "Tradicao", ID_MARCA: 713 },
  { DESC_MARCA: "Trakinas", ID_MARCA: 1088 },
  { DESC_MARCA: "Tramontina", ID_MARCA: 2576 },
  { DESC_MARCA: "Tramontina", ID_MARCA: 2579 },
  { DESC_MARCA: "Três corações", ID_MARCA: 152 },
  { DESC_MARCA: "Tres marchand", ID_MARCA: 2246 },
  { DESC_MARCA: "Tresemme", ID_MARCA: 350 },
  { DESC_MARCA: "Triangulo", ID_MARCA: 1370 },
  { DESC_MARCA: "Trident", ID_MARCA: 896 },
  { DESC_MARCA: "Trim", ID_MARCA: 278 },
  { DESC_MARCA: "Trisanti", ID_MARCA: 1646 },
  { DESC_MARCA: "Trisena", ID_MARCA: 788 },
  { DESC_MARCA: "Triutil", ID_MARCA: 2351 },
  { DESC_MARCA: "Tubul", ID_MARCA: 2378 },
  { DESC_MARCA: "Tuc's", ID_MARCA: 671 },
  { DESC_MARCA: "Tucs", ID_MARCA: 2591 },
  { DESC_MARCA: "Turma da monica", ID_MARCA: 182 },
  { DESC_MARCA: "Turma xuxinha", ID_MARCA: 1367 },
  { DESC_MARCA: "Uau", ID_MARCA: 842 },
  { DESC_MARCA: "Ufe", ID_MARCA: 353 },
  { DESC_MARCA: "Underberg", ID_MARCA: 2348 },
  { DESC_MARCA: "Uniao", ID_MARCA: 1223 },
  { DESC_MARCA: "União", ID_MARCA: 275 },
  { DESC_MARCA: "Urbano", ID_MARCA: 551 },
  { DESC_MARCA: "Urca", ID_MARCA: 1097 },
  { DESC_MARCA: "Vacalim", ID_MARCA: 2663 },
  { DESC_MARCA: "Vanish", ID_MARCA: 605 },
  { DESC_MARCA: "Vantage", ID_MARCA: 2426 },
  { DESC_MARCA: "Vasenol", ID_MARCA: 1877 },
  { DESC_MARCA: "Veja", ID_MARCA: 146 },
  { DESC_MARCA: "Veja x-14", ID_MARCA: 1664 },
  { DESC_MARCA: "Velho barreiro", ID_MARCA: 1517 },
  { DESC_MARCA: "Vida", ID_MARCA: 383 },
  { DESC_MARCA: "Vida macia", ID_MARCA: 1682 },
  { DESC_MARCA: "Vidrex", ID_MARCA: 1592 },
  { DESC_MARCA: "Vieira", ID_MARCA: 2648 },
  { DESC_MARCA: "Vieira de castro", ID_MARCA: 50 },
  { DESC_MARCA: "Vigor", ID_MARCA: 797 },
  { DESC_MARCA: "Vila frol", ID_MARCA: 2153 },
  { DESC_MARCA: "Villa balestra", ID_MARCA: 2405 },
  { DESC_MARCA: "Villa velha", ID_MARCA: 1676 },
  { DESC_MARCA: "Vim", ID_MARCA: 1178 },
  { DESC_MARCA: "Vinigas", ID_MARCA: 1622 },
  { DESC_MARCA: "Virtude", ID_MARCA: 1952 },
  { DESC_MARCA: "Visconti", ID_MARCA: 2447 },
  { DESC_MARCA: "Vitalia", ID_MARCA: 809 },
  { DESC_MARCA: "Vitarella", ID_MARCA: 158 },
  { DESC_MARCA: "Vody r", ID_MARCA: 2462 },
  { DESC_MARCA: "Whiskas", ID_MARCA: 479 },
  { DESC_MARCA: "White horse", ID_MARCA: 971 },
  { DESC_MARCA: "Wilkinson", ID_MARCA: 1376 },
  { DESC_MARCA: "William lawsons", ID_MARCA: 2141 },
  { DESC_MARCA: "Wyborowa", ID_MARCA: 1880 },
  { DESC_MARCA: "Wyda", ID_MARCA: 1100 },
  { DESC_MARCA: "Xavante", ID_MARCA: 194 },
  { DESC_MARCA: "Xodo", ID_MARCA: 1394 },
  { DESC_MARCA: "Xtapa", ID_MARCA: 2570 },
  { DESC_MARCA: "Xtapa", ID_MARCA: 2573 },
  { DESC_MARCA: "Yamasterol", ID_MARCA: 2342 },
  { DESC_MARCA: "York", ID_MARCA: 2450 },
  { DESC_MARCA: "Ypê", ID_MARCA: 173 },
  { DESC_MARCA: "Ype", ID_MARCA: 1280 },
  { DESC_MARCA: "Ypioca", ID_MARCA: 2192 },
  { DESC_MARCA: "Zabet", ID_MARCA: 221 },
  { DESC_MARCA: "Zero cal", ID_MARCA: 1445 },
  { DESC_MARCA: "Zulu", ID_MARCA: 1745 },
  { DESC_MARCA: "Zumbi", ID_MARCA: 2501 },
];

export default brandsMock;
