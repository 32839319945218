import * as S from "./styles";
import { useClient } from "@/hooks";
import { formatCode } from "@/shared";
import { RiCustomerService2Line } from "react-icons/ri";

const MyCompany = () => {
  const { selectedClient } = useClient();
  return (
    <S.CompanyWrapper>
      <S.PageDescription>
        <S.Description>
          Os dados abaixo não são editáveis, só podem ser alterados pela{" "}
          <RiCustomerService2Line /> <b>Central de Atendimento</b>.
        </S.Description>
      </S.PageDescription>
      <S.Content>
        <S.Form>
          <S.Title>Dados da Empresa</S.Title>
          <S.Field>
            <S.Label>CNPJ</S.Label>
            <S.CustomInput>
              {formatCode(selectedClient.NR_DOCUMENTO)}
            </S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Razão Social</S.Label>
            <S.CustomInput>{selectedClient.NM_CLIENTE}</S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Nome Fantasia</S.Label>
            <S.CustomInput>{selectedClient.NM_FANTASIA}</S.CustomInput>
          </S.Field>
          {selectedClient?.COD_SEGMENTO?.[0]?.cod_segmento && <S.Field>
            <S.Label>Segmento</S.Label>
            <S.CustomInput>{selectedClient.COD_SEGMENTO.map(i => i.cod_segmento).join(', ')}</S.CustomInput>
          </S.Field>}
          <S.Field>
            <S.Label>Inscrição Estadual</S.Label>
            <S.CustomInput>
              {selectedClient.ST_COMPRA === "I" ? "Isento" : "Não Isento"}
            </S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Telefone</S.Label>
            <S.CustomInput $isdanger>(67) 3030-1234</S.CustomInput>
          </S.Field>
        </S.Form>
        <S.Form>
          <S.Title>Endereço de Entrega</S.Title>
          <S.Field>
            <S.Label>CEP</S.Label>
            <S.CustomInput $isdanger>79000-000</S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Endereço</S.Label>
            <S.CustomInput>
              {selectedClient.ENDERECO}, {selectedClient.NUMERO}
            </S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Complemento</S.Label>
            <S.CustomInput $isdanger>Apto. 3025</S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Bairro</S.Label>
            <S.CustomInput>{selectedClient.BAIRRO}</S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Estado </S.Label>
            <S.CustomInput>{selectedClient.ESTADO}</S.CustomInput>
          </S.Field>
          <S.Field>
            <S.Label>Cidade </S.Label>
            <S.CustomInput>{selectedClient.CIDADE}</S.CustomInput>
          </S.Field>
        </S.Form>
      </S.Content>
    </S.CompanyWrapper>
  );
};

export default MyCompany;
