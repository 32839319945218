import { useSearchParams } from "react-router-dom";
import { useUserContext } from "@/hooks";

import * as S from './styles';

const Indicators = () => {
    const { customer } = useUserContext();
    const { codPessoa } = customer;
    const [searchParams] = useSearchParams();
    const tipoIndicador = searchParams.get('tipo');

    const formatDateForUrl = () => {
        const date = new Date();
        const year = date.getFullYear().toString().slice(-2);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        return `1Ano${year}Mes${month}`;
    };

    const indicatorLinks: { [key: string]: string } = {
        'relatorio-vendas': `https://parceiros.grpereira.com.br/AppMobileAtacado/RelatorioVendasBFDigital/Mobile#/{${codPessoa}}`,
        'extrato-produtividade': `https://parceiros.grpereira.com.br/AppMobileAtacado/ProdAtacVendasResultado2016/Mv2#/${codPessoa}`,
        'ciclo-vendas': `https://parceiros.grpereira.com.br/AppMobileAtacado/ProdutividadeCicloVendas?znv=${codPessoa}&mesId=${formatDateForUrl()} ` // Exemplo para ciclo de vendas
    };

    // Verifica se o tipo de indicador é válido e mapeado
    const iframeSrc = tipoIndicador ? indicatorLinks[tipoIndicador] : null;

    return (
        <S.Container>
            {/* Renderiza o iframe apenas se existir um tipo de indicador válido */}
            {iframeSrc ? (
                <iframe
                    src={iframeSrc}
                    title="Indicadores"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                />
            ) : (
                <p>Tipo de indicador não encontrado.</p>
            )}

        </S.Container>

    );
}

export default Indicators;