import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { NetworkStatusProvider } from "@/hooks/index";
import App from "./app";
import { registerServiceWorker } from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <NetworkStatusProvider>
      <App />
    </NetworkStatusProvider>
  </React.StrictMode>
);

registerServiceWorker();
reportWebVitals();

