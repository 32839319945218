import { useMemo } from 'react';
import * as S from './styles';

import { RiSignalWifiOffLine, RiLoopRightLine } from "react-icons/ri";

interface Success {
    connectivityStatus: string,
    setCanAccessSuccess: any
}

const SuccessMessage: React.FC<Success> = ({ connectivityStatus, setCanAccessSuccess }) => {

    const online = useMemo((): boolean => {
        if (connectivityStatus === 'online') return true;
        return false;
    }, [connectivityStatus]);

    const onClick = () => {
        setCanAccessSuccess(false);
    }

    const buttons = useMemo((): any => {
        return (
            <S.NavigateButtons>
                <S.Button to="/order-status" onClick={onClick} className='dark' data-test='success-link-acompanhamento'>
                    Ir para acompanhamento de pedidos
                </S.Button>
                <S.Button to="/order-status" onClick={onClick} className='green' data-test='success-link-meus-pedidos'>
                    Ir para meus pedidos
                </S.Button>
            </S.NavigateButtons>
        )
    }, [])

    const Message = useMemo(() => {
        return (
            <S.MessageContent>
                <S.Icon>
                    {online ? (
                        <RiLoopRightLine />
                    ) : (
                        <RiSignalWifiOffLine />
                    )}

                </S.Icon>
                <S.MessageTitle>
                    {online ? (
                        <>
                            <S.TitleText>Pedido realizado!</S.TitleText>
                            <S.TitleText>Em processamento</S.TitleText>
                        </>
                    ) : (
                        <>
                            <S.TitleText>Pedido realizado sem</S.TitleText>
                            <S.TitleText>acesso à internet</S.TitleText>
                        </>
                    )}
                </S.MessageTitle>
                <S.MessageText>
                    {online ? (
                        `Seu pedido foi realizado com sucesso e está em processamento. Acompanhe o status na tela de acompanhamento de pedidos`
                    ) : (
                        `Para que esse pedido seja integrado, você deverá sincroniza-lo assim que tiver acesso à internet.`
                    )}
                </S.MessageText>
                {buttons}
            </S.MessageContent>
        )
    }, [buttons, online]);

    return (
        <S.MessageWrapper>
            {Message}
        </S.MessageWrapper>
    )
}

export default SuccessMessage;