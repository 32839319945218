import { colors } from '@/constants';
import styled, { css } from 'styled-components';

interface ContentProps {
  $bgColor?: string;
  margintop?: number;
  maxheight?: string;
  $noRadius?: boolean;
  $isExpanded: boolean;
  $nobodypadding?: boolean;
}

export const AccordionWrapper = styled.div<{
  $noRadius?: boolean;
  $bgColor?: string;
}>`
  max-width: 100%;
  color: ${colors.brand.grafite};
  border-radius: ${(i) => (i.$noRadius ? 0 : 6)}px;
  background-color: ${(i) => i.$bgColor || colors.brand.white};
`;

export const AccordionHeader = styled.div<{ $noheaderpadding?: boolean; $showborder?: boolean }>`
  margin: 0;
  padding: ${(i) => (i.$noheaderpadding ? 0 : 18)}px;
  ${(props) =>
    props.$showborder &&
    css`
      padding: 6px 18px;
    `};
`;

export const HeaderController = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
`;

export const HeaderDetails = styled.div``;

export const Label = styled.span`
  display: flex;
  align-items: center;
  gap: 15px;

  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;

  svg {
    width: 25px;
    height: 25px;
    color: ${colors.gray.gray60};
  }
`;

export const AccordionBody = styled.div<ContentProps>`
  max-height: 0px;
  margin-top: 0px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  border-radius: ${(i) => (i.$noRadius ? 0 : 6)}px;
  padding: ${(i) => !i.$nobodypadding && '0 23px'};
  background-color: ${(i) => i.$bgColor || colors.brand.white};
  ${(props) =>
    props.$isExpanded &&
    css`
      padding-top: 0px;
      /* max-height: unset; */
      margin-top: ${(i) => props.margintop || 0}px;
      max-height: ${props.maxheight || '300vh'};
    `}
`;

export const AccordionController = styled.div<ContentProps>`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background-color: ${(props) => (props.$isExpanded ? colors.grey4 : `transparent`)};

  svg {
    transition: all 0.3s;
    color: ${colors.gray.gray40};
    transform: rotate(${(i) => (i.$isExpanded ? 180 : 0)}deg);
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${colors.background1};
  width: 100%;
`;
