import * as S from "./styles";
import { FilterItemProps } from "@/types";

interface FilterProps {
  item: FilterItemProps;
  handleList: (item: FilterItemProps) => void;
}

const Item = ({ item, handleList }: FilterProps) => {
  const { title, checked } = item;

  return (
    <S.Item
      $isactive={checked}
      onClick={() => handleList(item)}
      id={`filterbar-content-item-${item.id}`}
    >
      <S.Label>
        {title} <S.LabelSpan>({item.count})</S.LabelSpan>
      </S.Label>
    </S.Item>
  );
};

export default Item;
