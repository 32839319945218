import * as S from './styles';
import { useFileUpload } from '@/shared/uploadFIles';
import Loading from '@/components/spinner-loader/Loading';
import { RiUploadCloud2Line, RiCheckLine, RiDeleteBin2Line, RiAlertLine } from 'react-icons/ri';
import { useEffect } from 'react';

const UploadInvoices = ({ setNextStepDisabled }: any) => {

    const {
        files,
        uploadProgress,
        uploadError,
        getRootProps,
        retryUpload,
        removeFile,
    } = useFileUpload({
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': []
        },
        maxSize: 5000000,
        minFiles: 3,
        maxFiles: 3
    });

    const preFiles = [{ id: 1, name: 'nota 1' }, { id: 2, name: 'nota 2' }, { id: 3, name: 'nota 3' }];

    useEffect(() => {
        if (files.length === 3 && !Object.keys(uploadError).length) {
            setNextStepDisabled(false);
        } else {
            setNextStepDisabled(true);
        }
    }, [files, uploadError]);

    return (
        <S.Container>
            <S.PageHeader>
                <S.PageTitle> Agora, envie 3 notas de compras com outros fornecedores. </S.PageTitle>
                <S.Description> As notas devem ser de pelo menos dos últimos 3 meses. </S.Description>
            </S.PageHeader>
            <S.PageContent>
                {preFiles.map((item, id) => {
                    const file = files[id];
                    const progress = file ? (uploadProgress[file.id] || 0) : 0;
                    const hasError = file ? uploadError[file.id] : false;

                    return (
                        <S.FileList>
                            <S.FileItem topBorder={true}>
                                <S.FileIcon gray={!file} error={hasError}>
                                    {file && progress === 100 && !hasError ? (
                                        <RiCheckLine size={24} />
                                    ) : !file && !hasError ? (
                                        <RiUploadCloud2Line size={24} />
                                    ) : file && progress < 100 ? (
                                        <Loading size={20} borderSize={2} circularTopColor='#23A047' circularBorderColor='transparent' fullBorder={true} />
                                    ) : file && hasError && (
                                        <RiAlertLine size={24} />
                                    )}
                                </S.FileIcon>
                                <S.FileDetails>
                                    <S.FileName error={hasError}>
                                        {file ? (
                                            file.file.name
                                        ) : (
                                            `Carregar ${item.name}`
                                        )}
                                    </S.FileName>
                                    <S.ProgressText>
                                        {file && !hasError ? (
                                            <span>{file.size}</span>
                                        ) : file && hasError ? (
                                            <span>Tente novamente</span>
                                        ) : !file && (
                                            <span>Max. 5MB | JPG, PNG, PDF</span>
                                        )}

                                        {!hasError && file && (
                                            <span>{progress}%</span>
                                        )}
                                    </S.ProgressText>
                                    {!hasError && file && (
                                        <S.ProgressBar>
                                            <S.ProgressFill style={{ width: `${progress}%` }} />
                                        </S.ProgressBar>
                                    )}

                                </S.FileDetails>

                                {file && !hasError ? (
                                    <S.RemoveButton onClick={() => removeFile(file.id)}>
                                        <RiDeleteBin2Line size={20} />
                                    </S.RemoveButton>
                                ) : file && hasError ? (
                                    <S.ErrorRefetch onClick={() => retryUpload(file)}>Enviar novamente</S.ErrorRefetch>
                                ) : !file && (
                                    <S.SearchButton {...getRootProps()}>Buscar</S.SearchButton>
                                )}

                            </S.FileItem>
                        </S.FileList>
                    )
                })}
            </S.PageContent>
        </S.Container>
    )
}

export default UploadInvoices;