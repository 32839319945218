import Infos from './infos';
import Header from './header';
import * as S from './styles';
import MenuList from './list';
// import Metricas from "./metricas";
// import FastLinks from "./fast-links";

const DashboardPaage = () => {
  return (
    <S.Container className="dashboards">
      <Header />
      {/* <FastLinks /> */}
      {/* <Metricas /> */}
      <S.Title>Menus</S.Title>
      <MenuList />
      <Infos />
    </S.Container>
  );
};

export default DashboardPaage;
