import styled from "styled-components";
import { colors } from "@/constants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
`;

export const Form = styled.div`
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    gap: 20px;
`;

export const Label = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: ${colors.gray.gray50};
`;

export const Field = styled.div`
    display: flex;
    width: 65%;
`;