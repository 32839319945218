import { colors } from '@/constants';
import * as S from './styles';
import Chart, { Props as ChartProps } from 'react-apexcharts';
import { useMemo } from 'react';

interface Props {
  progress: number;
}

const unshow = { show: false };

const RadialBarChart = ({ progress }: Props) => {
  const mountParams = (color: string, hollow: number, stroke: number) => ({
    plotOptions: {
      radialBar: {
        endAngle: 120,
        startAngle: -120,
        track: { background: '#00000000' },
        hollow: { size: `${hollow}%` }, // Tamanho do círculo interno
        dataLabels: { name: unshow, value: unshow, total: unshow },
      },
    },
    fill: { colors: [color] },
    stroke: { lineCap: 'round', width: stroke },
  } as ChartProps);

  const optionsBorder = useMemo(() => mountParams(colors.shadown8, 90, 35), []);
  const optionsLeft = useMemo(() => mountParams(colors.alert.red200, 81, 10), []);
  const optionsRight = useMemo(() => mountParams(colors.alert.red100, 81, 10), []);
  const bgLeft = useMemo(() => mountParams('#FFE4EA18', 75, 2), []);
  const bgRight = useMemo(() => mountParams('#F4433618', 75, 2), []);

  return (
    <S.Box id="chart">
      <Chart options={optionsLeft} series={[progress]} type="radialBar" />

      <S.Left>
        <S.Text bottom="5px" size={12} bold={400}>
          Faltam
        </S.Text>

        <S.Text
          size={12}
          bold={700}
          radius={16}
          bg={colors.brand.red}
          padding="5px 11px 5px 8px"
          shadown="0px 4px 4px 0px #00000040"
        >
          R$ 342.226
        </S.Text>
      </S.Left>

      <S.Lables>
        <S.Text top="13%" size={13} color="grafite" bold={500}>
          Você atingiu
        </S.Text>

        <S.Text $inline size={24} bold={700} bottom="10px">
          R$ 360.
          <S.Span bold={700} size={22}>
            899
          </S.Span>
        </S.Text>

        <S.Text size={14} color="grafite" bold={500}>
          Sua meta é: R$ 703.125
        </S.Text>

        <S.Text top={'11%'} bold={800} size={14}>
          Você atingiu 70% da <br />
          sua meta
        </S.Text>
      </S.Lables>

      <S.Bar>
        <Chart options={optionsRight} series={[100 - 3 - progress]} type="radialBar" />
      </S.Bar>

      <S.Bar $norotate>
        <Chart options={bgRight} series={[progress]} type="radialBar" />
      </S.Bar>

      <S.Bar>
        <Chart options={bgLeft} series={[100 - 3 - progress]} type="radialBar" />
      </S.Bar>

      <S.Bar>
        <Chart options={optionsBorder} series={[100]} type="radialBar" />
      </S.Bar>
    </S.Box>
  );
};

export default RadialBarChart;
