import * as S from './styles';
import { UseEffect } from '@sentry/react/build/types/types';
import { RiUploadCloud2Line, RiCheckLine, RiDeleteBin2Line } from 'react-icons/ri';

import { useFileUpload } from '@/shared/uploadFIles';
import { useEffect } from 'react';

const UploadContratoSocial = ({ setNextStepDisabled }: any) => {

    const {
        files,
        uploadProgress,
        uploadError,
        getRootProps,
        retryUpload,
        removeFile,
    } = useFileUpload({
        accept: {
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': [],
            'application/pdf': []
        },
        maxSize: 5000000,
        minFiles: 1,
        maxFiles: 1
    });

    const progress = uploadProgress[files[0]?.id] || 0;
    const hasError = uploadError[files[0]?.id];

    useEffect(() => {
        if (!(files.length > 0) && !hasError) {
            setNextStepDisabled(true);
        } else if (files.length > 0 && !hasError) {
            setNextStepDisabled(false);
        } else {
            setNextStepDisabled(true);
        }

    }, [files, uploadError])

    console.log(files, 'Files')

    return (
        <S.Container>
            <S.PageHeader>
                <S.PageTitle> Anexe o seu contrato social </S.PageTitle>
                <S.Description> Precisamos do contrato social </S.Description>
            </S.PageHeader>
            <S.PageContent>
                <S.FileList>
                    <S.FileItem topBorder={true}>
                        <S.FileIcon gray={files.length <= 0} error={hasError}>
                            {files.length > 0 ? (
                                <RiCheckLine size={24} />
                            ) : (
                                <RiUploadCloud2Line size={24} />
                            )}

                        </S.FileIcon>
                        <S.FileDetails>
                            <S.FileName error={hasError}>
                                {files.length > 0 ? files[0].file.name : hasError ? 'Erro de upload' : 'Carregar Contrato Social'}
                            </S.FileName>
                            <S.ProgressText>
                                {files.length == 0 && !hasError ? (
                                    <span>Max. 5MB | JPG, PNG, PDF</span>
                                ) : files.length > 0 && !hasError ? (
                                    <span>{files[0].size}</span>
                                ) : (
                                    <span>Tente novamente</span>
                                )}
                                {!hasError && files.length > 0 && (
                                    <span>{progress}%</span>
                                )}
                            </S.ProgressText>
                            {!hasError && files.length > 0 && (
                                <S.ProgressBar>
                                    <S.ProgressFill style={{ width: `${progress}%` }} />
                                </S.ProgressBar>
                            )}

                        </S.FileDetails>
                        {files.length === 0 && !hasError ? (
                            <S.SearchButton {...getRootProps()}>Buscar</S.SearchButton>
                        ) : !hasError && files.length > 0 ? (
                            <S.RemoveButton onClick={() => removeFile(files[0].id)}>
                                <RiDeleteBin2Line size={20} />
                            </S.RemoveButton>
                        ) : hasError && (
                            <S.ErrorRefetch onClick={() => retryUpload(files[0])}>Enviar novamente</S.ErrorRefetch>
                        )}
                    </S.FileItem>
                </S.FileList>
            </S.PageContent>
        </S.Container>
    )
}

export default UploadContratoSocial;