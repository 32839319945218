import styled from "styled-components";
import { colors } from "@/constants";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Label = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: ${colors.gray.gray50};
`;

export const BoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 12px;
    /* justify-content: space-between; */
`;

export const Box = styled.div<{ valid: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;

    border: solid 1px ${colors.background1};
    border-radius: 6px;

    width: 80px;
    height: 60px;
    color: ${({valid}) => valid ? `${colors.green}` : `${colors.gray.gray50}`};
`;

export const StrongText = styled.span`
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;

    &.underlined{
        text-decoration: underline;
    }
`;

export const DetailText = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
`;