import { colors } from "@/constants";
import styled from "styled-components";

export const TotalsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PriceLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.grey3};
`;

export const PriceValue = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.brand.grafite};
`;

export const ShippingValue = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${colors.green};
`;

export const TotalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-top: solid 1px ${colors.background1};
  border-radius: 0px 0px 6px 6px;

  padding: 20px 16px;
  background-color: ${colors.brand.white};
`;

export const CartTotalRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;

  border-top: solid 1px ${colors.background1};
`;

export const CartTotalValue = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;

  color: ${colors.brand.grafite};
`;
