import { useState } from "react";
import { Slider } from "..";
import { FullScreenGallery } from "./fullscreen-gallery/FullScreenGallery";

const mountImages = (IMAGE: string, LINK?: string): any => ({
  IMAGE,
  LINK,
});

const ProductGallery = ({ image, id }: any) => {
  const [fullScreenImage, setFullScreenImage] = useState("");

  const handleImageClick = (image: any) => {
    setFullScreenImage(image);
  };

  const handleCloseFulScreen = () => {
    setFullScreenImage("");
  };

  return (
    <>
      {image && (
        <Slider
          dots
          canSwipe
          isGallery
          autoplay={false}
          slidesToShow={1}
          id="main-gallery"
          slides={[
            mountImages(`data:image/jpg;base64,${image}`),
            // mountImages(`data:image/jpg;base64,${image}`),
          ]}
          openFullScreen={handleImageClick}
          closeFullScreen={handleCloseFulScreen}
        />
      )}

      {fullScreenImage && (
        <FullScreenGallery
          images={[
            mountImages(`data:image/jpg;base64,${image}`),
            // mountImages(`data:image/jpg;base64,${image}`),
          ]}
          firstImage={fullScreenImage}
          handleCloseFulScreen={handleCloseFulScreen}
        />
      )}
    </>
  );
};

export default ProductGallery;
