import styled from "styled-components";
import { colors } from "@/constants";

export const ErrorWrapper = styled.div<{ type?: string }>`
  width: 100%;
  background-color: ${({ type }) => type === 'success' ? colors.green : colors.red3};
  border-radius: 0 0 10px 10px;
  padding: 4px 8px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SuccessWrapper = styled.div`

`;

export const Message = styled.span`
  color: ${colors.brand.white};
  font-size: 14px;
  padding-bottom: 1px;
`;
