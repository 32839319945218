import styled from "styled-components";
import colors from "../../../constants/systemColors";

export const AccountPageWrapper = styled.div``;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  width: 100%;
  box-sizing: border-box;
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.brand.grafite};

  border-radius: 6px;

  width: 100%;
  box-sizing: border-box;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: -45px;
  padding: 20px;
  background-color: ${colors.brand.red};

  border-radius: 6px;

  width: 100%;
  box-sizing: border-box;
`;

export const PhotoWrapper = styled.div`
  display: flex;
  position: relative;

  width: 50px;
  height: 50px;
`;

export const Image = styled.div`
  width: 44px;
  height: 44px;
  display: grid;
  border-radius: 50%;
  place-items: center;
  background-color: ${colors.grey7};
`;

export const Sigla = styled.p`
  margin: 0px;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.gray.gray40};
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  color: ${colors.brand.white};

  white-space: nowrap; /* Impede que o texto quebre em várias linhas */
  overflow: hidden; /* Esconde qualquer conteúdo que exceda a largura da div */
`;

export const Message = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const Name = styled.span`
  width: 100%;
  box-sizing: border-box;
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Balance = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 8px 20px;

  border: solid 1px ${colors.gray.gray50};
  border-top: none;
  border-radius: 0px 0px 6px 6px;

  color: ${colors.brand.white};
  font-size: 14px;
  line-height: 21px;
`;

export const BalancePrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const Label = styled.span`
  font-weight: 400;
`;

export const Price = styled.div`
  font-weight: 700;
`;

export const BalanceHidden = styled.div`
  height: 15px;
  width: 90px;
  background-color: ${colors.gray.gray40};
`;

export const ToggleVisibility = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  svg {
    width: 18px;
    height: 18px;
    color: ${colors.gray.gray40};
  }
`;

export const UserMenu = styled.div<{ collapsed?: any }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;

  padding: 16px;

  height: ${({ collapsed }) => (collapsed ? "0" : "auto")};
  padding: ${({ collapsed }) => (collapsed ? "0" : "20px")};
  overflow: hidden;
  opacity: ${({ collapsed }) => (collapsed ? "0" : "1")};

  transition: height 0.5s ease, padding 0.5s ease, opacity 0.5s ease;
`;

export const MenuItem = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 0;
  opacity: ${(i) => (i.isDisabled ? 0.3 : 1)};

  &:nth-of-type(2) {
    border-color: ${colors.gray.gray50};
    border-style: solid;
    border-width: 0 1px;
  }

  svg {
    width: 24px;
    height: 24px;
    color: ${colors.gray.gray40};
  }
`;

export const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const MenuLabel = styled.span`
  font-size: 11px;
  font-weight: 400;
  line-height: 14.3px;
  color: ${colors.brand.white};
`;
