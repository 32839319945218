import "./styles.css";
import { colors } from "@/constants";
import styled from "styled-components";
import Menu from "@/components/menu/Menu";

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: ${colors.brand.red};
  margin-top: 50px;
`;

const Home = () => {
  return (
    <div className="App">
      <Menu />

      <Title>Bem vindo ao RCA Bate Forte</Title>
    </div>
  );
};

export default Home;
