import { useMemo } from 'react';
import * as S from './styles';
import { usePackingIcon } from '@/shared/packing';
import { Price } from '@/components';

import { useDbFunctions } from '@/talons';

export const ProductCard = ({ product }: any) => {

    const enteredOption = product?.entered_options?.[0]?.value || '';
    const [code, quantity] = enteredOption.split('_');
    const icon = usePackingIcon(code);
    const totalPrice = product.product_sale_price.value * product.quantity_ordered;

    console.log(product);


    return (
        <S.ProductCard>
            <S.ProductImage>
                <img src={`data:image/jpg;base64,${product.IMAGEM}`} alt={product?.product_image} />
            </S.ProductImage>
            <S.ProductInfo>
                <S.ProductName>
                    {product?.product_name}
                </S.ProductName>
                <S.PackageInfo>
                    <S.PackingIcon src={icon} alt={`${code} icon`} />
                    <PackingName code={code} />
                    <S.PackingQuantity>
                        {quantity} {quantity > 1 ? 'unidades' : 'unidade'}
                    </S.PackingQuantity>
                </S.PackageInfo>
                <ProductDetails product={product} quantity={quantity} />
                <S.ProductPrice>
                    <S.Label>Preço total</S.Label>
                    <Price value={totalPrice} />
                </S.ProductPrice>
            </S.ProductInfo>
        </S.ProductCard>
    )
}

export const PackingName = ({ code }: any) => {
    const packingName = useMemo(() => {
        switch (code) {
            case 'Pack':
                return <S.PackingName>Pack com</S.PackingName>;
            case 'PK':
                return <S.PackingName>Pack com</S.PackingName>;
            case 'PT':
                return <S.PackingName>Pacote com</S.PackingName>;
            case 'CX':
                return <S.PackingName>Caixa com</S.PackingName>;
            case 'FD':
                return <S.PackingName>Fardo com</S.PackingName>;
            default:
                return <S.PackingName />;
        }
    }, [code]);

    return packingName;
};

export const ProductDetails = ({ product, quantity }: any) => {
    const unityValue = product.product_sale_price.value / quantity;

    return (
        <S.ProductDetails>
            <S.ProductDetailsItem>
                <S.Label>Quantidade</S.Label>
                <S.Value>
                    <S.ProductQuantity>{product.quantity_ordered}</S.ProductQuantity>
                    {quantity}/un
                </S.Value>
            </S.ProductDetailsItem>
            <S.ProductDetailsItem>
                <S.Label>Preço unitário</S.Label>
                <S.Value>
                    A partir de <Price value={unityValue} />/un
                </S.Value>
            </S.ProductDetailsItem>
            <S.ProductDetailsItem>
                <S.Label>ST</S.Label>
                <S.Value>
                    <Price value={product.st_price_packaging ? product.st_price_packaging : 0} />
                </S.Value>
            </S.ProductDetailsItem>
            <S.ProductDetailsItem>
                <S.Label>Preço embalagem</S.Label>
                <S.Value>
                    <Price value={product.product_sale_price.value} />
                </S.Value>
            </S.ProductDetailsItem>
        </S.ProductDetails>
    )
}
