import { colors } from '@/constants';
import { styled } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  color: ${colors.brand.grafite};
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  padding-bottom: 20px;
  text-align: center;
  gap: 10px;
`;

export const Icon = styled.div`
  svg {
    height: 96px;
    width: 96px;
  }
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21.09px;
  max-width: 230px;
`;

export const SubText = styled.div`
  max-width: 280px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.06px;
`;

export const SyncButton = styled.button`
  align-items: center;
  background-color: ${colors.brand.grafite};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 18px;
  width: 100%;
  margin-top: 12px;
`;

export const ButtonLabel = styled.div`
  color: ${colors.brand.white};
  font-weight: 700;
  font-size: 16px;
  line-height: 18.75px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
