import Company from "./card";
import { ClientProps } from "@/types";

type Props = {
  showProgress?: boolean;
  clients: ClientProps[];
  selectedClient?: ClientProps;
  setLocalClient: (value: ClientProps | undefined) => void;
};

const ClientCard = ({ clients, showProgress, selectedClient, setLocalClient }: Props) => {
  if (!clients || !clients.length) return null
  
  return (
    <>
      {clients?.map((client, ind) => {
        const isActive = selectedClient?.COD_CLIENTE === client.COD_CLIENTE;
        return (
          <Company
            key={ind}
            client={client}
            isChecked={isActive}
            setClient={setLocalClient}
            showProgress={showProgress}
          />
        );
      })}
    </>
  );
};

export default ClientCard;
