import { useCart, useClient } from "@/hooks";
import { useMemo } from "react";

interface AddToCartHookProps {
  sku: number;
  packingOption: string;
}

export const useAddToCart = ({ sku, packingOption }: AddToCartHookProps) => {
  const { selectedClient } = useClient();

  const { cartDetails } = useCart();

  const currentQuantity = useMemo(() => {
    return (
      cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find(
        (i) => i?.sku === sku && i?.option_uid === packingOption
      )?.quantity ?? 0
    );
  }, [cartDetails, selectedClient?.COD_CLIENTE, sku, packingOption]);

  const currentCart = useMemo(() => {
    return cartDetails?.[selectedClient?.COD_CLIENTE]?.items;
  }, [cartDetails, selectedClient?.COD_CLIENTE]);

  return {
    currentCart,
    quantity: currentQuantity,
  };
};
