import { colors } from "@/constants";
import styled from "styled-components";

export const CartCounterContainer = styled.div`
  padding: 2px 4px;
  width: fit-content;
  height: 16px;
  border-radius: 6px;
  position: absolute;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.brand.red};
  top: -10%;
  right: 3.5%;
`;

export const Counter = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 11.72px;
  text-align: left;
  color: ${colors.brand.white};
`;