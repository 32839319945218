import * as S from './Styled';
import { colors } from '@/constants';
import { getOnStorage } from '@/shared';
import { useDbFunctions } from '@/talons';
import { useNavigate } from 'react-router-dom';
import { ECOMMERCE } from '@/constants/systemRoutes';
import { useCart, useClient, useGlobals } from '@/hooks';
import { TabSlider, ListCompany, ToolTip } from '@/components';
import { ClientProgress, ClientProps, TabCodes, TabParams } from '@/types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const ProductivityExtract = () => {
  const navigation = useNavigate();
  const { getOnDB } = useDbFunctions();
  const { setSelectedClient } = useClient();
  const { createEmptyCart } = useCart();
  const { setPaymentCode } = useGlobals();

  const isLoading = useRef(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showAnimate, setShowAnimate] = useState(true);
  const [clients, setClients] = useState([] as ClientProps[]);
  const [selectedTab, setSelectedTab] = useState('all' as TabCodes);
  const [selectedClient, setLocalClient] = useState({} as ClientProps | undefined);

  const listTabs = useMemo((): TabParams[] => {
    return [
      { code: 'all', title: 'Todos', notification: clients.length },
      {
        code: 'noPurchases',
        title: 'Sem comprar',
        notification: clients.filter((c) => c.daysWithoutPurchase).length,
      },
      {
        code: 'titles',
        title: 'Títulos',
        notification: clients.filter((c) => c.notifications).length,
      },
      {
        code: 'suspended',
        title: 'Suspensos',
        notification: clients.filter((c) => c.suspended).length,
      },
    ] as TabParams[];
  }, [clients]);

  const listClients = useMemo((): ClientProps[] => {
    const progressTypes = ['checked', 'danger', 'empty', 'semi-checked', 'warning'];

    const randomic = (val: number) => Math.floor(Math.random() * val) + 1;
    const checkBoolean = (val: number) => (Math.floor(Math.random() * 1 + 1) ? randomic(val) : 0);

    const list = [...clients].map((client) => {
      client.daysWithoutPurchase = checkBoolean(100);
      client.progress1 = progressTypes[randomic(5)] as ClientProgress;
      client.progress2 = progressTypes[randomic(5)] as ClientProgress;
      client.progress3 = progressTypes[randomic(5)] as ClientProgress;
      client.progress4 = progressTypes[randomic(5)] as ClientProgress;

      return client;
    });

    if (selectedTab === 'all') return list;
    if (selectedTab === 'suspended') list.filter((c) => c.suspended);
    if (selectedTab === 'titles') list.filter((c) => c.notifications);
    if (selectedTab === 'noPurchases') list.filter((c) => c.daysWithoutPurchase);

    return list;
  }, [clients, selectedTab]);

  const loadClients = useCallback(async () => {
    const list = await getOnDB('clientes');

    const currClient = getOnStorage('selected_client');
    if (currClient) setLocalClient(currClient);
    setClients(list || []);
    if (!list?.length) return loadClients();

    isLoading.current = false;
  }, [getOnDB]);

  useEffect(() => {
    if (clients.length || isLoading.current) return;

    isLoading.current = true;
    loadClients();
  }, [loadClients, clients, isLoading]);

  const isEnabled = useMemo(() => {
    return !!selectedClient?.COD_CLIENTE;
  }, [selectedClient?.COD_CLIENTE]);

  const goTo = async () => {
    if (!selectedClient) return;

    setSelectedClient(selectedClient);
    createEmptyCart({ client: selectedClient });
    setPaymentCode(selectedClient?.COD_CONDICAO_PADRAO);
    navigation(ECOMMERCE.link);
  };

  const handleDisabled = useCallback(async () => {
    if (!selectedClient) {
      setShowTooltip(false);
      return;
    }

    const dependencies = await Promise.all([
      getOnDB('produtos'),
      getOnDB('categorias'),
      getOnDB('estoque'),
      getOnDB('marcas'),
      getOnDB('percentualFornecedor'),
      getOnDB('precos'),
      getOnDB('substituicaotributaria'),
    ]);

    const isFilled = dependencies.reduce((a, i) => (a ? !!i.length : a), true);
    setShowTooltip(!isFilled);
  }, [getOnDB, selectedClient]);

  useEffect(() => {
    handleDisabled();
  }, [selectedClient, handleDisabled]);

  const handleSelectedTab = useCallback((val: TabCodes) => {
    setShowAnimate(true);
    setSelectedTab(val);
    setTimeout(() => setShowAnimate(false), 900);
  }, [])

  return (
    <S.Container className="productivity-extract">
      <S.BgDark>
        <S.Row>
          <S.Col>
            <S.Text color={colors.gray.gray30}>ZNV 607 01 MS 06</S.Text>
          </S.Col>

          <S.Col>
            <S.Text radius={100} bg={colors.grey7} padding="4px 12px" color={colors.gray.gray30}>
              4650
            </S.Text>
          </S.Col>
        </S.Row>

        <S.Row mb={16}>
          <S.Col>
            <S.Text color={colors.gray.gray30}>Fátima do Sul / Centro</S.Text>
          </S.Col>

          <S.Col>
            <S.Text color={colors.gray.gray30}>PJ. Carlos BB</S.Text>
          </S.Col>
        </S.Row>

        <S.CycleCard>
          <S.Row>
            <S.Col width='100%'>
              <S.Row gap={14} justify='start'>
                <S.CalendarTimer size={32} />

                <S.Col>
                  <S.Text size={14} color={colors.brand.white} bold={700}>
                    Ciclo <S.Span size={12} bg={colors.grey7} radius={100} padding='4px 6px'>2</S.Span>
                  </S.Text>

                  <S.Text size={12} margintop={3} color={colors.brand.white}>27/jan - 23/fev</S.Text>
                </S.Col>
              </S.Row>

              <S.Divisor direction='line' />

              <S.Row gap={14} justify='start'>
                <S.LineChartFill size={32} />

                <S.Col>
                  <S.Text size={14} color={colors.brand.white} bold={700}>
                    Meta <S.Span size={12} bg={colors.grey7} radius={100} padding='4px 6px'>21</S.Span>
                  </S.Text>

                  <S.Text size={12} margintop={3} color={colors.brand.white}>Carteira: 42 (+31 31)</S.Text>
                </S.Col>
              </S.Row>

              <S.Divisor direction='line' />

              <S.Row gap={14} justify='start'>
                <S.SpeedUpLine size={32} />

                <S.Col>
                  <S.Text size={14} color={colors.brand.white} bold={700}>
                    Possível <S.Span size={12} bg={colors.grey7} radius={100} padding='4px 6px'>35</S.Span>
                  </S.Text>

                  <S.Text size={12} margintop={3} color={colors.brand.white}>Para meta</S.Text>
                </S.Col>
              </S.Row>
            </S.Col>

            <S.Divisor direction='column' />

            <S.Col align='center'>
              <S.ContentTrophy>
                <S.TrophyLine size={32} />
              </S.ContentTrophy>

              <S.Text marginbottom={5} display='inline-flex' color={colors.brand.white} bold={700} size={14}>
                Atingido <S.Span margin='0px 0px 0px 4px' radius={100} bg={colors.green5} padding='4px 6px' size={12}>000</S.Span>
              </S.Text>

              <S.Text marginbottom={15} display='inline-flex' color={colors.brand.white} bold={700} size={14}>
                Valor <S.Span margin='0px 0px 0px 4px' radius={100} bg={colors.green5} padding='4px 6px' size={12}>0</S.Span>
              </S.Text>

              <S.Text display='inline-flex' color={colors.brand.white}>Dentro da meta</S.Text>
            </S.Col>
          </S.Row>
        </S.CycleCard>

        <S.Text textalign="center" size={12} bold={400} color={colors.gray.gray30}>
          Clientes demonstrada refere-se ao início do ciclo
        </S.Text>
      </S.BgDark>

      <S.ContentTabSlider>
        <TabSlider lightMode listTabs={listTabs} selectedTab={selectedTab} setSelectedTab={handleSelectedTab} />
      </S.ContentTabSlider>

      <S.ContentListCompany className={showAnimate ? 'enter-efect' : ''}>
        <ListCompany showProgress clients={listClients} selectedClient={selectedClient} setLocalClient={setLocalClient} />
      </S.ContentListCompany>

      <S.FloatMessage>
        {isEnabled ? (
          <ToolTip
            direction="top"
            show={showTooltip}
            displayMode="default"
            text="Por favor, sincronize a aplicação para liberar o acesso."
          >
            <S.ButtonAccess onClick={goTo} disabled={showTooltip} data-test="modal-companies-button-go-in">
              Entrar com esse cliente
            </S.ButtonAccess>
          </ToolTip>
        ) : (
          <>
            <S.Row gap={10} justify="start">
              <S.CalendarIcon size={20} />

              <S.Text color="grafite" bold={500} size={14} textalign="right">
                Seu ciclo atual
              </S.Text>
            </S.Row>

            <S.DownContentMessage>
              <S.LeftDays>15 dias</S.LeftDays>
              <S.RightDays>7 dias</S.RightDays>
            </S.DownContentMessage>

            <S.Row>
              <S.Text color="gray" bold={400} size={12} textalign="left">
                Início
              </S.Text>

              <S.Text color="gray" bold={400} size={12} textalign="right">
                22 dias
              </S.Text>
            </S.Row>
          </>
        )}
      </S.FloatMessage>
    </S.Container>
  );
};

export default ProductivityExtract;
