import * as S from "./styles";
import "@/shared/defaultStyle.css";
import { useCallback } from "react";
import Profile from "./profile/Profile";
import * as R from "@/constants/systemRoutes";
import { useNavigate } from "react-router-dom";
// import multiUser from "@/assets/svgs/multiUser.svg";
import { useAccountPage } from "@/talons/accountPage/useAccountPage";

import {
  RiLogoutBoxRLine,
  RiArrowRightSLine,
  RiExchangeDollarFill,
} from "react-icons/ri";
import { useClient } from "@/hooks";

const multiUser = `${process.env.PUBLIC_URL}/multiUser.svg`;

const MyAccount = () => {
  const navigation = useNavigate();
  const { logoutClient } = useClient();
  const { handleTouchStart, handleTouchMove, handleTouchEnd, collapsed } =
    useAccountPage();

  const localLogoutClient = useCallback(() => {
    logoutClient();
    navigation(R.DASHBOARD.link);
  }, [navigation, logoutClient]);

  return (
    <S.AccountPageWrapper
      className="full-wrapper"
      data-test="account-page-wrapper"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <S.HeaderSpace />

      <Profile collapsed={collapsed} />

      <S.PageContent>
        <S.Menu data-test="accont-link-menu">
          <S.MenuTitle>Conta do Cliente</S.MenuTitle>
          <S.MenuItem to={R.COMPANY.link} data-test="link-my-company">
            <S.Group>
              <S.RiUsersLine src={multiUser} />
              <S.Label>Dados da empresa</S.Label>
            </S.Group>

            <S.NavActionIcon>
              <RiArrowRightSLine />
            </S.NavActionIcon>
          </S.MenuItem>
          {/* 
          <S.MenuItem to="" data-test="link-financial">
            <S.Group>
              <RiExchangeDollarFill />
              <S.Label>Financeiro</S.Label>
            </S.Group>

            <S.NavActionIcon>
              <RiArrowRightSLine />
            </S.NavActionIcon>
          </S.MenuItem> */}
        </S.Menu>

        <S.Menu>
          <S.MenuTitle>Ações</S.MenuTitle>

          <S.MenuButton data-test={'client-local-logout-link'} onClick={localLogoutClient}>
            <S.Group className="logout">
              <RiLogoutBoxRLine />
              <S.Label>Sair desse cliente</S.Label>
            </S.Group>

            <S.NavActionIcon>
              <RiArrowRightSLine />
            </S.NavActionIcon>
          </S.MenuButton>
        </S.Menu>
      </S.PageContent>
    </S.AccountPageWrapper>
  );
};

export default MyAccount;
