import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Price } from '@/components';
import * as S from './styles';
import { StPrice } from '@/components';

import {
    RiUserStarLine,
    RiUserLine,
    RiFileTextLine,
    RiArrowRightSLine,
    RiNewspaperLine,
    RiBarcodeLine,
    RiCheckboxCircleLine,
    RiMoneyDollarCircleLine,
    RiTruckLine,
    RiStore2Line
} from "react-icons/ri";

const StatusProgressItems = [
    { status: "Pedido Recebido", icon: <RiCheckboxCircleLine /> },
    { status: "Pedido Faturado", icon: <RiMoneyDollarCircleLine /> },
    { status: "Nota Fiscal Emitida", icon: <RiNewspaperLine /> },
    { status: "Pedido Enviado", icon: <RiTruckLine /> },
    { status: "Pedido Entregue", icon: <RiStore2Line /> },
];

export const OrderCard = ({ order }: any) => {
    const orderOrigin = order.gp_order_ext.origin;
    const navigate = useNavigate();

    const totalItems = order.items.reduce((total: number, item: any) => {
        return total + item.quantity_ordered;
    }, 0);

    const orderDate = new Date(order?.order_date);

    const daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    const dayOfWeekIndex = orderDate.getDay();
    const dayOfWeek = daysOfWeek[dayOfWeekIndex];

    const handleCardClick = () => {
        navigate('/pedido', { state: { order } });
    };

    return (
        <>
            <S.OrderDate>
                <S.LabelDate>Pedido feito em:</S.LabelDate>
                <S.Date>{dayOfWeek}, {orderDate.toLocaleDateString()}</S.Date>
            </S.OrderDate>
            <S.OrderCard>
                {orderOrigin === 'RCA' && (
                    <S.RCAOrderTag>
                        <S.TagIcon>
                            <RiUserStarLine />
                        </S.TagIcon>
                        <S.TagLabel>Pedido realizado pelo Representante Comercial: <br /><b>{order?.gp_order_ext?.rcaCustomer?.name}</b></S.TagLabel>
                    </S.RCAOrderTag>
                )}
                <S.Content>
                    <S.OrderData>
                        <S.InformativeIcon>
                            <RiFileTextLine />
                        </S.InformativeIcon>
                        <S.Details>
                            <S.OrderNumbersContainer>
                                <S.OrderNumbers>
                                    <S.Number>
                                        <S.NormalLabel>Nº do pedido:</S.NormalLabel>
                                        <S.BoldLabel>{order.number}</S.BoldLabel>
                                    </S.Number>
                                    <S.Number>
                                        <S.NormalLabel>Nº do pedido ERP:</S.NormalLabel>
                                        <S.BoldLabel>{order.number}</S.BoldLabel>
                                    </S.Number>
                                </S.OrderNumbers>
                                <S.ItemsQuantity>
                                    <span>Itens: {totalItems}</span>
                                </S.ItemsQuantity>
                            </S.OrderNumbersContainer>
                            <S.OrderTotals>
                                <S.TotalRow>
                                    <S.NormalLabel>Total faturado:</S.NormalLabel>
                                    <S.TotalInvoicedPrice>
                                        <Price value={order.total?.grand_total.value} />
                                    </S.TotalInvoicedPrice>
                                </S.TotalRow>
                                <S.TotalRow>
                                    <S.NormalLabel>Total final:</S.NormalLabel>
                                    <S.FinalPrice>
                                        <Price value={order.total?.base_grand_total.value} />
                                    </S.FinalPrice>
                                </S.TotalRow>
                            </S.OrderTotals>
                        </S.Details>
                        <S.ActionArrow onClick={handleCardClick}>
                            <RiArrowRightSLine />
                        </S.ActionArrow>
                    </S.OrderData>
                    <S.InvoiceContainer>
                        <S.Button disabled={true}>
                            <RiNewspaperLine />
                            <span>Nota Fiscal</span>
                        </S.Button>
                        <S.Button disabled={true}>
                            <RiBarcodeLine />
                            <span>Imprimir Boleto</span>
                        </S.Button>
                    </S.InvoiceContainer>
                    <S.OrderStatus>
                        <OrderCardStatus order={order} />
                        <S.StatusInfo>
                            {order.status}
                        </S.StatusInfo>
                    </S.OrderStatus>
                </S.Content>
            </S.OrderCard>

        </>
    )
}

const OrderCardStatus = ({ order }: any) => {
    return (
        <S.TimeLine>
            {StatusProgressItems.map((status, index, { length }) => {
                const isActive = (status.status === order.status) || (index < length - 1 && StatusProgressItems[index + 1].status === order.status);

                return (
                    <S.TimeLineItem key={index} isActive={isActive}>
                        <S.TimeLineIcon>{status.icon}</S.TimeLineIcon>
                        <S.TimeLineDot isActive={isActive}>
                            <div className="dot"></div>
                        </S.TimeLineDot>
                    </S.TimeLineItem>
                )
            }
            )}
        </S.TimeLine>
    )

}