import { useState, useEffect } from "react";
import * as S from './styles';

interface CustomSelectProps {
    value?: string,
    handleChangeInput?: (value: string) => void,
    handleClickSelect?: () => void,
    active?: boolean,
    options: any,
    defaultLabel?: string,
    activeLabel?: string
}

const CustomSelectComponent: React.FC<CustomSelectProps> = ({
    value,
    handleChangeInput,
    handleClickSelect,
    active,
    options,
    activeLabel,
    defaultLabel
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");

    const toggleDropdown = () => setIsOpen((prev) => !prev);

    const handleOptionClick = (optionValue: string) => {
        setSelectedOption(optionValue);
        setIsOpen(false);

        if (handleChangeInput) {
            handleChangeInput(optionValue);
        }
    };

    return (
        <S.SelectWrapper>
            <S.Label $hasValue={!!selectedOption}>
                {selectedOption ? activeLabel : defaultLabel}
            </S.Label>

            <S.CustomSelect $isactive={isOpen} onClick={toggleDropdown}>
                {selectedOption}
            </S.CustomSelect>

            <S.OptionsList visible={isOpen}>
                {options.map((option: any) => {
                    return (<S.Option key={option.id} onClick={() => handleOptionClick(option.sigla)}>
                        {option.nome}
                    </S.Option>)
                })}
            </S.OptionsList>
        </S.SelectWrapper>
    );
};

export default CustomSelectComponent;
