import * as S from './Styled';
import { useState } from 'react';
import Metricas from '../metricas';
import { colors } from '@/constants';
import { Accordion } from '@/components';
import { formatCurrency } from '@/shared';
import CardBarProgress from './CardBarProgress';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

interface Ganhos {
  ganho: number;
  checked: boolean;
}

interface Props {
  title: string;
  meta: number;
  ganho: number;
  premio: number;
  apuracao: number;
  atingido: number;
  metaPrice: number;

  meta1: Ganhos;
  meta2: Ganhos;
  meta3: Ganhos;
}

const ProductivityExtractPenality = ({ title, meta, ganho, atingido, premio, meta1, meta2, meta3 }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <S.Card $isOpen={isOpen} className="productivity-extract-card-penality">
        <S.Row>
          <S.Text marginbottom={15} textalign="left" size={18} bold={700} color="grafite">
            {title}
          </S.Text>
        </S.Row>

        <S.Row>
          <Metricas value={meta} />
        </S.Row>

        <S.Separete />

        <S.Row>
          <S.Column>
            <S.Text marginbottom={8} textalign="left" size={14} bold={500} color="grafite">
              %Atingido
            </S.Text>

            <S.Text textalign="left" size={14} bold={500} color="gray">
              {atingido}%
            </S.Text>
          </S.Column>

          <S.Column>
            <S.Text marginbottom={8} textalign="left" size={14} bold={500} color="grafite">
              %Ganho
            </S.Text>

            <S.Text textalign="left" size={14} bold={500}>
              {`${ganho}`}%
            </S.Text>
          </S.Column>

          <S.Column>
            <S.Text marginbottom={8} textalign="right" size={14} bold={500} color="grafite">
              Valor do Prêmio
            </S.Text>

            <S.Text textalign="right" size={16} bold={800} color="grafite">
              {formatCurrency({ value: premio })}
            </S.Text>
          </S.Column>
        </S.Row>
      </S.Card>

      <Accordion
        marginTop={-6}
        noBodyPadding
        noRadius={true}
        id="card-extrato"
        accordionTitle=""
        showHeader={false}
        expandedIcon="arrow"
        accordionExpanded={isOpen}
        setAccordionExpanded={setIsOpen}
      >
        <S.AccordionContent>
          <S.Row>
            <S.Column>
              <S.Text color="grafite" bold={700} size={14}>
                Meta
              </S.Text>

              <S.Text color="grafite" bold={700} size={14}>
                Ganhos
              </S.Text>
            </S.Column>

            <S.ProgressContent>
              <CardBarProgress hasProgreess checked={meta1.checked} ganho={meta1.ganho} percentual={95} />
              <CardBarProgress hasProgreess checked={meta2.checked} ganho={meta2.ganho} percentual={100} />
              <CardBarProgress checked={meta3.checked} ganho={meta3.ganho} percentual={105} />
            </S.ProgressContent>
          </S.Row>
        </S.AccordionContent>
      </Accordion>

      <S.ButtonAccordion onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? 'Esconder' : 'Visualizar'} faixas{' '}
        <S.ChevronIcon>
          {isOpen ? <FaCaretUp size={16} color={colors.gray.gray50} /> : <FaCaretDown size={16} color={colors.gray.gray50} />}
        </S.ChevronIcon>
      </S.ButtonAccordion>
    </>
  );
};

export default ProductivityExtractPenality;
