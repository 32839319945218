import styled from "styled-components";
import { colors } from "@/constants";
import { Link } from "react-router-dom";

export const SignInWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-grow: 1;
  padding: 20px;

  background-color: ${colors.brand.white};
`;

export const PageHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CallToAction = styled.span`
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  color: ${colors.brand.grafite};
`;

export const Message = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: ${colors.gray.gray50};
`;

export const Error = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: ${colors.red3};
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const FormController = styled.div`
  width: 100%;
`;

export const SmallText = styled.div`
  text-align: right;

  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: ${colors.gray.gray50};
`;

export const TextAction = styled.span`
  font-weight: 500;
  color: ${colors.gray.gray60};
  cursor: pointer;
`;

export const ContinueButton = styled.button`
  width: 100%;
  height: 55px;
  padding: 18px;

  background-color: ${colors.green};
  border: solid 1px ${colors.green};
  border-radius: 10px;

  font-size: 16px;
  font-weight: 500;
  color: ${colors.brand.white};
  line-height: 18.75px;

  &:disabled {
    background-color: ${colors.gray.gray10};
    border: none;

    color: ${colors.gray.gray40};
  }
`;
