import { colors } from "@/constants";
import styled from "styled-components";

export const ResumeWrapper = styled.div`
  > .accordion-wrapper {
    border-radius: 6px 6px 0px 0px !important;
  }
`;

export const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 4px;
`;

export const QuantityLabel = styled.span`
  font-size: 14px;
  color: ${colors.grey3};
`;

export const Quantity = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;

  border-radius: 50%;
  background-color: ${colors.grey4};

  color: ${colors.grey3};
  font-size: 14px;
  font-weight: 400;
`;

export const ProductCardList = styled.div`
  display: flex;
  flex-direction: column;

  border-top: solid 1px ${colors.background1};
  margin-top: 24px;
  padding: 0px 0px 16px 0;
`;
