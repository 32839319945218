import { colors } from '@/constants';
import styled, { css } from 'styled-components';
import { RiArrowDownSFill } from 'react-icons/ri';

const { grey11 } = colors;
const { gray40 } = colors.gray;
const { red50 } = colors.alert;
const { grafite, red, white } = colors.brand;

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
`;

export const DownIcon = styled(RiArrowDownSFill)`
  color: ${red};
`;

export const Card = styled.div`
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: ${white};
  border: 1px solid ${grey11};
`;

export const Row = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: grid;
`;

export const Separete = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0px 15px 0px;
  background-color: ${grey11};
`;

export const Text = styled.p<{
  bold?: number;
  size?: number;
  padding?: string;
  bottom?: number;
  color?: 'grafite' | 'red' | 'gray';
  textalign?: 'left' | 'right' | 'center';
}>`
  ${(i) =>
    i.color === 'red' &&
    css`
      width: fit-content;
      align-items: center;
      display: inline-flex;
      background-color: ${red50};
    `}
  border-radius: 10px;
  padding: ${(i) => i.padding || '0px'};
  text-align: ${(i) => i.textalign || 'left'};
  margin-bottom: ${(i) => i.bottom || 0}px;
  font-weight: ${(i) => (!i.bold ? 400 : i.bold)};
  font-size: ${(i) => (!i.size ? 12 : i.size)}px;
  color: ${(i) => (!i.color || i.color === 'gray' ? gray40 : i.color === 'red' ? red : grafite)};
`;
