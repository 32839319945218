import * as S from "./styles";
import { useClient } from "@/hooks";
import { useMemo, useState } from "react";
import { Price } from "@/components/price/Price";
import { useNavigate } from "react-router-dom";
import * as R from "@/constants/systemRoutes";
// import RiLuggageCartItem from "@/assets/svgs/RiLuggageCartItem.svg";

import {
  RiAddFill,
  RiEyeLine,
  RiListCheck,
  RiCurrencyLine,
  RiEyeCloseLine,
} from "react-icons/ri";

const RiLuggageCartItem = `${process.env.PUBLIC_URL}/RiLuggageCartItem.svg`;
interface ProfileProps {
  collapsed?: boolean;
};

const Profile: React.FC<ProfileProps> = ({ collapsed }) => {
  const { selectedClient } = useClient();
  const [balanceIsVisible, setBalanceIsVisible] = useState(true);

  const navigate = useNavigate();

  const greeting = useMemo(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
      return "Olá, bom dia!";
    } else if (hour >= 12 && hour < 18) {
      return "Olá, boa tarde!";
    } else {
      return "Olá, boa noite!";
    }
  }, []);

  return (
    <S.PageContent>
      <S.Profile>
        <S.UserInfo>
          <S.PhotoWrapper>
            <S.Image>
              <S.Sigla>{selectedClient?.NM_FANTASIA?.charAt(0)}</S.Sigla>
            </S.Image>

          </S.PhotoWrapper>
          <S.Text data-test="greeting-user-name">
            <S.Message>{greeting}</S.Message>
            <S.Name>{selectedClient.NM_FANTASIA}</S.Name>
          </S.Text>
        </S.UserInfo>

        <S.Balance>
          <S.BalancePrice data-test="avaiable-balance">
            <S.Label>Saldo disponível:</S.Label>
            {balanceIsVisible ? (
              <S.Price data-test="visible-balance-price">
                <Price value={selectedClient.SALDO_DISPONIVEL} />
              </S.Price>
            ) : (
              <S.BalanceHidden data-test="hidden-balance-price" />
            )}
          </S.BalancePrice>
          <S.ToggleVisibility
            data-test="handle-balance-visibility"
            onClick={() => setBalanceIsVisible(!balanceIsVisible)}
          >
            {balanceIsVisible ? <RiEyeLine /> : <RiEyeCloseLine />}
          </S.ToggleVisibility>
        </S.Balance>

        {/* Menu de itens do customer */}
        <S.UserMenu data-test="user-menu-links" collapsed={collapsed}>
          <S.MenuItem onClick={() => { navigate(R.CLIENT_ORDER_PAGE.link) }}>
            <S.MenuIcon src={RiLuggageCartItem} />
            <S.MenuLabel data-test="link-orders">Meus Pedidos</S.MenuLabel>
          </S.MenuItem>
          <S.MenuItem isDisabled={true}>
            <RiListCheck />
            <S.MenuLabel data-test="link-lists">Minhas Listas</S.MenuLabel>
          </S.MenuItem>
          <S.MenuItem isDisabled={true}>
            <RiCurrencyLine />
            <S.MenuLabel data-test="link-credit-limit">
              Limite de Crédito
            </S.MenuLabel>
          </S.MenuItem>
        </S.UserMenu>
      </S.Profile>
    </S.PageContent>
  );
};

export default Profile;
