interface Amount {
    currency: string,
    value: number,
    __typename: 'Money'
};

interface ShippingMethod {
    __typename: 'Result',
    amount: Amount,
    carrier_code: string,
    carrier_title: string,
    delivery_date: string,
    delivery_time: string,
    message: string,
    method_code: string,
    method_title: string
};

const shippingMethod: ShippingMethod = {
    __typename: 'Result',
    amount: {
        currency: 'BRL',
        value: 0,
        __typename: 'Money'
    },
    carrier_code: 'freeshipping',
    carrier_title: 'Free Shipping',
    delivery_date: '15 de março de 2024',
    delivery_time: 'x',
    message: 'Comprando até 11/03 as 15:00h receba até dia 14/03',
    method_code: 'freeshipping',
    method_title: 'Free'
};

export default shippingMethod;