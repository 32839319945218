import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useCart } from '@/hooks';
import { ToolTip } from '@/components';
import * as S from './styles';
import {
    RiNewspaperLine,
    RiBarcodeLine,
    RiCheckboxCircleLine,
    RiMoneyDollarCircleLine,
    RiTruckLine,
    RiStore2Line,
    RiRepeatLine
} from "react-icons/ri";

import { OrderInfo } from './orderInfo/OrderInfo';
import { ProductCard } from './productCard/ProductCard';
import { Price } from '@/components';

import { useDbFunctions } from '@/talons';
import { useCalcPrice } from '@/talons';

const statusProgressItems = [
    { status: "Pedido Recebido", icon: <RiCheckboxCircleLine /> },
    { status: "Pedido aprovado", icon: <RiMoneyDollarCircleLine /> },
    { status: "Pedido faturado", icon: <RiNewspaperLine /> },
    { status: "Transporte em andamento", icon: <RiTruckLine /> },
    { status: "Entrega realizada", icon: <RiStore2Line /> },
];

export const OrderDetail = () => {
    const location = useLocation();
    const { order } = location.state;
    const { items, gp_order_ext } = order;
    const { items: updatedItems } = gp_order_ext;

    const { addProductToCart, isAdding } = useCart();
    const { getOnDB } = useDbFunctions();

    const [combinedItems, setCombinedItems] = useState<any[]>([]);
    const mergedItems = [...order.gp_order_ext.items, ...order.items];

    useEffect(() => {
        const fetchProductsDB = async () => {
            const productUnityDB = await getOnDB("produtos");

            // Verifica se updatedItems não é vazio
            if (updatedItems && updatedItems.length > 0) {
                const combined = [
                    ...order.items.map((item: any) => {
                        const matchedProduct = productUnityDB.find(prod => prod.COD_PRODUTO === parseInt(item.product_sku.split('-')[0]));
                        const updatedItem = updatedItems.find((gpItem: any) => gpItem.order_item_id === item.id);

                        return {
                            ...item,
                            ...matchedProduct,
                            ...(updatedItem || {}) // Faz a combinação com o updatedItem se ele existir
                        };
                    }),
                    ...updatedItems.filter((gpItem: any) => !order.items.some((item: any) => item.id === gpItem.order_item_id)) // Adiciona os itens de gp_order_ext que não estão em order.items
                ];

                setCombinedItems(combined);
            } else {
                // Se updatedItems for vazio, faz apenas o mapeamento padrão dos items
                const combined = order.items.map((item: any) => {
                    const matchedProduct = productUnityDB.find(prod => prod.COD_PRODUTO === parseInt(item.product_sku.split('-')[0]));
                    return {
                        ...item,
                        ...matchedProduct
                    };
                });

                setCombinedItems(combined);
            }
        };

        fetchProductsDB();
    }, [order.items, updatedItems]);


    const totalItems = combinedItems.reduce((total: number, item: any) => {
        return total + item.quantity_ordered;
    }, 0);

    return (
        <S.ContentPage>
            <OrderInfo order={order} />
            <S.InvoiceContainer>
                <S.Button disabled={true}>
                    <RiNewspaperLine />
                    <span>Nota Fiscal</span>
                </S.Button>
                <S.Button disabled={true}>
                    <RiBarcodeLine />
                    <span>Imprimir Boleto</span>
                </S.Button>
            </S.InvoiceContainer>
            <TimeLine order={order} />
            <Actions items={combinedItems} addProductToCart={addProductToCart} isAdding={isAdding} />
            <S.ItemsQuantity>
                {totalItems} {totalItems > 1 ? 'itens' : 'item'}
            </S.ItemsQuantity>
            <S.Products>
                {combinedItems.map((product: any) => (
                    <ProductCard key={product.COD_PRODUTO} product={product} />
                ))}
            </S.Products>
            <S.OrderTotals>
                <S.TotalRow>
                    <S.NormalLabel>Total Faturado</S.NormalLabel>
                    <S.TotalInvoicedPrice>
                        <Price value={order.total?.base_grand_total.value} />
                    </S.TotalInvoicedPrice>
                </S.TotalRow>
                <S.TotalRow>
                    <S.BoldLabel>Total Final</S.BoldLabel>
                    <S.FinalPrice>
                        <Price value={order.total?.grand_total.value} />
                    </S.FinalPrice>
                </S.TotalRow>
            </S.OrderTotals>
        </S.ContentPage>
    );
};

const TimeLine = ({ order }: any) => {
    return (
        <S.Timeline>
            {statusProgressItems.map((item, index, { length }) => {
                const isActive = (item.status === order.status) || (index < length - 1 && statusProgressItems[index + 1].status === order.status);
                const showLine = index < length - 1;
                return (
                    <S.TimelineItem key={index}>
                        <S.TimeLineIcon isActive={isActive}>
                            {item.icon}
                        </S.TimeLineIcon>
                        <S.DotSpace>
                            <S.TimeLineDot isActive={isActive}>
                                <div className="dot"></div>
                            </S.TimeLineDot>
                            {showLine && <S.TimeLine isActive={isActive} />}
                        </S.DotSpace>
                        <S.Detail>
                            <S.TimeLineLabel isActive={isActive}>
                                {item.status}
                            </S.TimeLineLabel>
                            {item.status.toLowerCase().includes('recebido') && (
                                <S.TimeLineDate>
                                    {new Date(order.order_date).toLocaleDateString()}
                                </S.TimeLineDate>
                            )}
                        </S.Detail>
                    </S.TimelineItem>
                )
            })}
        </S.Timeline>
    );
};

const Actions = ({ items, addProductToCart, isAdding }: any) => {
    const [showTip, setShowTip] = useState(false);

    const tooltipMessage = 'Produtos adicionados ao carrinho.';

    const addMultipleProductsToCart = useCallback(async (items: any) => {
        for (const product of items) {
            const [packageType, quantityOption] = product?.entered_options[0]?.value?.split('_');
            console.log('isAdding', product);

            // Garantimos que a adição de cada produto finalize antes de passar para o próximo
            await addProductToCart({
                sku: product.COD_PRODUTO,
                quantity: product.quantity_ordered,
                option_uid: product.entered_options[0]?.value,
                uid: `${product.COD_PRODUTO}-${packageType}-${quantityOption}`,
                category_id: product.COD_CATEGORIA,
            });
        }
        setShowTip(true);
        console.log('Todos os produtos foram adicionados ao carrinho.');
    }, [isAdding]);

    return (
        <S.Actions>
            <ToolTip
                direction="top"
                show={showTip}
                displayMode="default"
                text={tooltipMessage}
            >
                <S.RepeatOrderButton onClick={() => addMultipleProductsToCart(items)}>
                    <RiRepeatLine />
                    Repetir este pedido
                </S.RepeatOrderButton>
            </ToolTip>

        </S.Actions>
    );
};

export default OrderDetail;
