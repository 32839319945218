import styled from "styled-components";
import { colors } from "@/constants";

export const Container = styled.div<{ $isfirst?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.$isfirst ? 0 : 18}px;
  gap: 8px;
  padding-bottom: 18px;
  border-bottom: 1px solid ${colors.background1};
`;

export const Subcontainer = styled.div<{ alignItems?: string; gridTemplateColumns?: string }>`
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  padding: 0 18px;
  flex-direction: row;
  gap: 14px;
  justify-content: space-between;
  align-items: ${(props) => (props.alignItems || 'flex-start')};
`;

export const ProductImage = styled.img`
  width: 58px;
  height: 58px;
`;

export const ProductDescriptionRow = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding-right: 4px;
`;

export const ProductDescription = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  text-align: left;
  color: ${colors.gray.gray60};
  max-height: 45px;
  text-overflow: ellipsis;
`;

export const PriceBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const PriceRow = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: right;
  color: ${colors.brand.grafite};
`;

export const UnityPrice = styled.span`
  width: fit-content;
  height: 18px;
  padding: 3px 5px;
  border-radius: 3px;
  background-color: ${colors.gray.gray10};
  font-family: Roboto, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.72px;
  text-align: center;
  color: ${colors.grey3};
`;

export const Price = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 12.89px;
  text-align: right;
  color: ${colors.gray.gray40};
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
`;

export const RemoveButton = styled.button`
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RemoveButtonLabel = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  text-align: center;
  color: ${colors.gray.gray40};
  text-decoration: underline;
`;

export const PackingBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

export const Packing = styled.img`
  width: 21.4px;
  height: 21.4px;
`;

export const PackingLabel = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  text-align: left;
  color: ${colors.gray.gray60};
`;

export const PackingUnities = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 12.89px;
  text-align: left;
  color: ${colors.grey3};
`;

export const PackingDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const CounterButton = styled.div``;

export const Unavailable = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 10px 6px;
  border-radius: 6px;
  border: none;
  width: 100%;
  text-align: center;
  color: ${colors.grey3};
  background-color: ${colors.gray.gray10};
`;

export const IconContainer = styled.div<{ $radius?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.$radius || 6}px;
  height: 36px;
  width: 36px;
  padding: 6px;
 
`;

export const FlexPercentage = styled.div<{ $positive?: boolean }>`
  width: 44px;
  min-width: 44px;
  height: 54px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  border-radius: 6px;
  border: solid 1px ${colors.gray.gray10};
  background-color:${(props) => (props.$positive ? colors.alert.green200 : colors.alert.orange100)};

  color: ${colors.brand.grafite};

`;

export const FlexPercentageLabel = styled.span`
  font-size: 12px;
  font-weight: 700;
  line-height: 14.06px;
`;

export const CounterWrapper = styled.div`
  width: 130px;
`;