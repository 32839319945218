import * as S from './styles';

interface LoadingProps {
    skeleton?: boolean;
    size?: number;
    borderSize?: number;
    circularTopColor?: string;
    circularBorderColor?: string;
    fullBorder?: boolean;
}

const Loading: React.FC<LoadingProps> = ({ fullBorder = false, skeleton = false, size = 16, borderSize = 2, circularTopColor = '#6b6b6b', circularBorderColor = '#e0e0e0', ...props }) => {
    return (
        <>
            {skeleton ? (
                <S.SkeletonWrapper>
                    <S.Skeleton />
                </S.SkeletonWrapper>
            ) : (
                <S.Loading size={size} border={borderSize} topColor={circularTopColor} borderColor={circularBorderColor} fullBorder={fullBorder} />
            )}
        </>
    );
};

export default Loading;
