import styled from 'styled-components';
import { colors } from '@/constants';

export const OfflinePageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 200px;
`;


export const OfflinePageContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 241px;

    text-align: center;
    color: ${colors.brand.grafite};
`;

export const OfflinePageIcon = styled.div`
    svg{
        color: ${colors.brand.grafite};
        fill: ${colors.brand.grafite};
    }
`;

export const Title = styled.span`
    font-size: 18px;
    line-height: 21.09px;
    font-weight: 700;
`;

export const Description = styled.span`
    font-size: 14px;
    font-weight: 400;
`;