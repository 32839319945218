
import styled from "styled-components";
import { colors } from "@/constants";

export const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;


export const Label = styled.label<{ $hasValue: boolean }>`
  position: absolute;
  left: 20px;
  top: ${({ $hasValue }) => ($hasValue ? "0px" : "50%")};
  transform: translateY(${({ $hasValue }) => ($hasValue ? "-50%" : "-50%")});
  font-size: ${({ $hasValue }) => ($hasValue ? "12px" : "16px")};
  color: ${({ $hasValue }) => ($hasValue ? colors.gray.gray60 : colors.gray.gray40)};
  background-color: white;
  padding: 0 5px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
`;

export const CustomSelect = styled.div<{ $isactive: boolean }>`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ $isactive }) =>
        $isactive ? `solid 2px ${colors.gray.gray60}` : `solid 1px ${colors.grey8}`};
  border-radius: 10px;
  padding: 0 20px;
  cursor: pointer;
  background-color: white;
`;

export const OptionsList = styled.ul<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
  position: absolute;
  width: 100%;
  background-color: white;
  border: 1px solid ${colors.grey8};
  border-radius: 8px;
  margin-top: 5px;
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  z-index: 10;
`;

export const Option = styled.li`
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.gray.gray60};
    color: white;
  }
`;
