import * as S from "./styles";
import { ToolTip } from "@/components";
import { formatCurrency } from "@/shared";
import { cleanInput } from "@/shared/commonFunctions";
import { useCallback, useMemo, useState, useEffect } from "react";
import { useCart, useDiscountAmountFlex, useDiscountFlex, useUserContext, useClient } from "@/hooks";
import { DiscountStorageProps, PackagesTypesProps, PackagingProductProps, ProductProps } from "@/types";

const addIcon = `${process.env.PUBLIC_URL}/addIcon.svg`;
const trashIcon = `${process.env.PUBLIC_URL}/trashIcon.svg`;
const removeIcon = `${process.env.PUBLIC_URL}/removeIcon.svg`;

interface CounterProps {
  id: string;
  limitFlex: number;
  showBorder?: boolean;
  product: ProductProps;
  pack: PackagingProductProps;
  discountProduct?: DiscountStorageProps;
  background: "light" | "white";
}

const Counter = ({
  id,
  pack,
  product,
  limitFlex,
  showBorder,
  background,
  discountProduct,
}: CounterProps) => {
  const uid = useMemo(
    () => {
      if (pack?.QTD_MULTIPLO_VENDA > 1) {
        return `${product?.COD_PRODUTO}-${pack?.SG_EMBALAGEM}-${pack?.QTD_MULTIPLO_VENDA}`
      }
      return `${product?.COD_PRODUTO}-${pack?.SG_EMBALAGEM}-${pack?.QTD_EMBALAGEM}`;
    },
    [product, pack]
  );

  const { selectedClient } = useClient();
  const { rcaSaldoFlex } = useUserContext();
  const { deleteDiscount } = useDiscountFlex();
  const [timeoutId, setTimeoutId] = useState(null as any);
  const [isOpenTooltip, setOpenTooltip] = useState(false);
  const { updateProductAmount, removeProductFromCart, checkLimit, cartDetails } = useCart();
  const { discountAmountClient: discountAmount } = useDiscountAmountFlex();

  const quantity = useMemo(
    () => {
      if (cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.length > 0) {

        return cartDetails?.[selectedClient?.COD_CLIENTE]?.items?.find((i) => { 
          const [emb, qtd] = i?.option_uid?.split("_") as [ PackagesTypesProps, string];

          if (pack?.QTD_MULTIPLO_VENDA > 1 && emb === "PK") {

            return i?.sku === product?.COD_PRODUTO &&
            pack?.SG_EMBALAGEM === emb &&
            qtd === pack?.QTD_MULTIPLO_VENDA?.toString();
          }

          return i?.sku === product?.COD_PRODUTO && pack?.SG_EMBALAGEM === emb && qtd === pack?.QTD_EMBALAGEM?.toString() })?.quantity || 0;
      } else {
        return 0;
      }
    },
    [cartDetails, product?.COD_PRODUTO, selectedClient?.COD_CLIENTE, pack]
  );

  const [counter, setCounter] = useState(quantity);
  const [currentCounter, setCurrentCounter] = useState(`${quantity}`);

  useEffect(() => {
    if (quantity) setCounter(quantity);
    if (quantity) setCurrentCounter(`${quantity}`);
  }, [quantity]);


  const handleDecrement = useCallback(
    (qtd: number) => {
      setOpenTooltip(false)
      if (qtd > 0) {
        updateProductAmount({
          uid,
          sku: product?.COD_PRODUTO,
          quantity: qtd - 1,
        });
        setCounter(qtd - 1);
      } else {
        setCounter(0);
        removeProductFromCart({ uid });
      }
    },
    [uid, product?.COD_PRODUTO, updateProductAmount, removeProductFromCart]
  );

  const handleRemoveOnCart = useCallback(() => {
    let packOption = pack?.SG_EMBALAGEM;
    let packQtd = pack?.QTD_EMBALAGEM;
    if (pack?.QTD_MULTIPLO_VENDA > 1) {
      packQtd = pack?.QTD_MULTIPLO_VENDA;
    }
    deleteDiscount({
      package: packOption,
      quantity: packQtd,
      productId: product.COD_PRODUTO,
    });
    setCounter(0);
    removeProductFromCart({ uid });
  }, [uid, pack, product?.COD_PRODUTO, deleteDiscount, removeProductFromCart]);

  const handleIncrement = useCallback(
    (quantity: number) => {
      setOpenTooltip(false)
      updateProductAmount({
        uid,
        quantity: quantity + 1,
        sku: product?.COD_PRODUTO,
      });
      setCounter(quantity + 1);
    },
    [uid, product?.COD_PRODUTO, updateProductAmount]
  );

  const calcLimit = useCallback((qtd: number) => {
    return checkLimit(uid, product?.COD_PRODUTO, qtd, cartDetails?.[selectedClient?.COD_CLIENTE]?.items, false);
  }, [uid, product.COD_PRODUTO, cartDetails, selectedClient?.COD_CLIENTE, checkLimit])

  const limit = useMemo(() => {
    return calcLimit(counter + 1);
  }, [calcLimit, counter]);

  const handleTooltip = useCallback(() => {
    setOpenTooltip(true);
    setTimeout(() => setOpenTooltip(false), 5000);
  }, [setOpenTooltip]);

  const isLimit = useMemo(() => {
    return counter >= limit;
  }, [counter, limit]);

  const tooltipMessage = useMemo(() => {
    if (!isLimit) {
      return `Saldo Flex Indiponível: ${formatCurrency({
        value: (rcaSaldoFlex || 0) + discountAmount,
      })}`;
    } else {
      return `Produto com limite de estoque atingido`;
    }
  }, [isLimit, rcaSaldoFlex, discountAmount]);

  const isLimitFlex = useMemo(() => {
    return (
      !!discountProduct?.percentual &&
      discountProduct?.percentual < 0 &&
      counter >= limitFlex
    );
  }, [counter, limitFlex, discountProduct]);

  const isLimitFlexDown = useMemo(() => {
    return (
      !!discountProduct?.percentual &&
      discountProduct?.percentual > 0 &&
      counter <= limitFlex
    );
  }, [counter, limitFlex, discountProduct]);

  const isLimitUp = useMemo(() => {
    return counter >= limit || isLimitFlex;
  }, [limit, counter, isLimitFlex]);

  const handleBlur = useCallback(
    (count: number) => {
      if (!count) handleRemoveOnCart();
      
      const curLimit = calcLimit(count);
      const discount = discountProduct?.percentual;
      const isStockLimit = !discount && count > curLimit;
      const isFlexLimit = (discount && discount < 0) ? (count > limitFlex) : (count < limitFlex);
      const isCurrentLimit = isStockLimit || isFlexLimit;

      
      
      if (isCurrentLimit) {
        const hierarchy = curLimit < limitFlex ? curLimit : limitFlex || curLimit;
        setCounter(hierarchy);
        setCurrentCounter(`${hierarchy}`);
        return;
      }

      updateProductAmount({ uid, quantity: count, sku: product?.COD_PRODUTO });
    },
    [
      uid,
      product?.COD_PRODUTO,
      limitFlex,
      discountProduct?.percentual,
      updateProductAmount,
      calcLimit,
      handleRemoveOnCart,
    ]
  );

  const changeValue = useCallback(
    (qtd: string) => {
      const cleanValue = cleanInput(qtd);
      setCurrentCounter(cleanValue);
      
      if (!cleanValue) return;
      
      const qtdParsed = parseInt(cleanValue);
      if (timeoutId) clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(() => currentCounter && qtdParsed && handleBlur(qtdParsed), 300);

      setTimeoutId(newTimeoutId);
    },
    [handleBlur, timeoutId, currentCounter]
  );

  useEffect(() => {
    return () => timeoutId && clearTimeout(timeoutId);
  }, [timeoutId]);

  return (
    <ToolTip
      direction="top"
      show={isOpenTooltip}
      displayMode="default"
      text={tooltipMessage}
    >
      <S.CounterWrapper background={background}>
        <S.CounterButton
          data-test={`decrement-counter-component-${id}`}
          $islimit={isLimitFlexDown}
          onClick={() =>
            isLimitFlexDown
              ? handleTooltip()
              : counter === 1
              ? handleRemoveOnCart()
              : handleDecrement(counter)
          }
        >
          <S.Icon src={counter === 1 ? trashIcon : removeIcon} />
        </S.CounterButton>

        <S.Input
          type="text"
          value={currentCounter}
          showborder={showBorder}
          onBlur={() => handleBlur(Number(currentCounter))}
          onChange={(e) => changeValue(e.target.value?.replace(/[^0-9.]/g, ''))}
        />

        <S.CounterButton
          $islimit={isLimitUp}
          data-test={`increment-counter-component-${id}`}
          onClick={() =>
            isLimitFlex || isLimitUp
              ? handleTooltip()
              : handleIncrement(counter)
          }
        >
          <S.Icon src={addIcon} />
        </S.CounterButton>
      </S.CounterWrapper>
    </ToolTip>
  );
};

export default Counter;
