import * as S from "./styles";

import Lottie from 'react-lottie-player'

import SplashAnimation from "./animation/splash_screen.json"

const SplashScreen = () => {

  return (
    <S.SplashScreen>
      <S.SplashBackground>
        <Lottie animationData={SplashAnimation} play loop/>
      </S.SplashBackground>
    </S.SplashScreen>
  );
};

export default SplashScreen;
