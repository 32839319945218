import { colors } from '@/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
`;

export const BgDark = styled.div`
  padding: 25px 25px 50px 25px;
  background-color: ${colors.brand.grafite};
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background-color: ${colors.grey11};
`;

export const Row = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  justify-content: space-between;
`;

export const ColBgDark = styled.p<{ $hasbg?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  line-height: 19.6px;
  color: ${colors.gray.gray30};
  ${(i) =>
    i.$hasbg &&
    css`
      border-radius: 100px;
      padding: 4px 12px 4px 12px;
      background-color: ${colors.grey7};
    `}
`;

export const Content = styled.div<{ $isDanger?: boolean }>`
  margin-top: -${(i) => (i.$isDanger ? 6 : 0)}px;
  padding: ${(i) => (i.$isDanger ? '25px 20px 40% 20px' : '0px 20px')};
  border-radius: ${(i) => (i.$isDanger ? '6px 6px' : '0px 0px')} 0px 0px;
  background-color: ${(i) => (i.$isDanger ? colors.alert.red50 : colors.brand.white)};
`;

export const Message = styled.div`
  display: grid;
  transform: translateY(-40px);
  margin-bottom: -35px;
`;

export const GraphicUpImg = styled.img`
  width: 20px;
`;

export const GraphicIconContent = styled.div`
  padding: 6px;
  width: 32px;
  display: grid;
  place-items: center;
  height: 32px;
  border-radius: 6px;
  background-color: ${colors.brand.red};
`;

export const GraphicIconMessage = styled.p`
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: ${colors.brand.white};
`;

export const UpContentMessage = styled.div`
  gap: 10px;
  padding: 15px;
  display: inline-flex;
  border-radius: 6px 6px 0px 0px;
  background-color: ${colors.grey7};
`;

export const Enphasis = styled.span`
  border-radius: 3px;
  padding: 1px 3px;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.alert.red200};
  background-color: ${colors.alert.red100};
`;

export const DownContentMessage = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;

export const LeftDays = styled.div`
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${colors.brand.white};
  border-bottom-left-radius: 6px;
  background-color: ${colors.alert.red200};
`;

export const RightDays = styled.div`
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${colors.alert.red200};
  border-bottom-right-radius: 6px;
  background-color: ${colors.alert.red100};
`;

export const SubTotal = styled.p`
  font-size: 14px;
  font-weight: 500;
  padding: 20px 0px 26px 0px;
  text-align: right;
  color: ${colors.brand.grafite};
  span {
    font-size: 20px;
    margin-left: 3px;
    font-weight: 800;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  text-align: left;
  font-weight: 700;
  margin: 0px 0px 20px 0px;
  color: ${colors.brand.red};
`;

export const FloatMessage = styled.div`
  z-index: 9;
  bottom: 0px;
  width: 100%;
  position: fixed;
  text-align: right;
  max-width: var(--mobile);
  padding: 20px 20px 40px 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: ${colors.brand.white};
  box-shadow: 0px 0px 20px 0px ${colors.shadown1};
`;

export const Text = styled.p<{
  bold?: number;
  size?: number;
  paddingleft?: string;
  marginbottom?: number;
  color?: 'grafite' | 'red' | 'gray';
  textalign: 'left' | 'right' | 'center';
}>`
  text-align: ${(i) => i.textalign};
  padding-left: ${(i) => i.paddingleft || '0px'};
  margin-bottom: ${(i) => i.marginbottom || 0}px;
  font-weight: ${(i) => (!i.bold ? 400 : i.bold)};
  font-size: ${(i) => (!i.size ? 12 : i.size)}px;
  color: ${(i) => (!i.color ? colors.gray.gray40 : i.color === 'red' ? colors.brand.red : colors.brand.grafite)};
`;

export const Span = styled.span``;

export const Total = styled.span`
  font-size: 18px;
  font-weight: 800;
`;
