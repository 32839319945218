import { useUserContext } from '@/hooks';
import { useMutation } from '@apollo/client';
import { useNetworkStatusContext } from '@/hooks';
import { formatCode, formatTelephone } from '@/shared';
import DEFAULT_OPERATIONS from './useUserDataPage.gql';
import { updateSpecificFieldInStorage } from '@/shared';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { usePasswordStrength } from '../passwordValidator/usePasswordValidator';
interface ErrorControl {
  error: boolean;
  message?: string;
}

interface SuccessControl {
  success: boolean;
  message?: string;
}

export const useUserDataPage = () => {
  const { customer: rcaUserData } = useUserContext();
  const { connectivityStatus } = useNetworkStatusContext();
  const [newPassword, setNewPassword] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<ErrorControl | null>(null);
  const [passwordSuccess, setPasswordSuccess] = useState<SuccessControl | null>(null);
  const { validatePassword, validateLowerCaseChar, validPassword, validateLength, validateNumericChar, validateUpperCaseChar } = usePasswordStrength();

  const userConnectivity = connectivityStatus === 'online';
  const { updateCustomerMutation, updatePasswordMutation } = DEFAULT_OPERATIONS;

  const [updateCustomer, { loading }] = useMutation(updateCustomerMutation, {
    fetchPolicy: 'no-cache',
  });

  const [changePassword, { loading: loadingChangePassword }] = useMutation(updatePasswordMutation, {
    fetchPolicy: 'no-cache',
  });

  const [userData, setUserData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    cpf: '',
    actualPassword: '',
  });

  useEffect(() => {
    if (rcaUserData) {
      const userCode = formatCode(rcaUserData.taxvat);

      setUserData({
        email: rcaUserData.email,
        firstName: rcaUserData.firstname,
        lastName: rcaUserData.lastname,
        phone: formatTelephone(rcaUserData.phone),
        cpf: userCode || '',
        actualPassword: '',
      });
    }
  }, [rcaUserData]);

  const handleInputChange = (e: any) => {
    setPasswordError(null);

    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'phone') {
      formattedValue = formatTelephone(value);
    }
    setUserData((prevState) => ({
      ...prevState,
      [name]: formattedValue,
    }));
  };

  const handlePasswordChange = useCallback(
    ({ target: { value } }: any) => {
      setPasswordError(null);
      setNewPassword(value);
      validatePassword(value);
    },
    [validatePassword]
  );

  const isValidPassword = useMemo(() => {
    if (validPassword && validateLength && validateLowerCaseChar && validateNumericChar && validateUpperCaseChar) {
      return true;
    } else {
      return false;
    }
  }, [validPassword, validateLength, validateLowerCaseChar, validateNumericChar, validateUpperCaseChar]);

  const handleChangeCustomerData = useCallback(async () => {
    if (userData) {
      try {
        await updateCustomer({
          variables: {
            input: {
              firstname: userData.firstName,
              lastname: userData.lastName,
            },
          },
        });

        if (newPassword) {
          await changePassword({
            variables: {
              currentPassword: userData.actualPassword,
              newPassword: newPassword,
            },
          });
          setPasswordSuccess({
            success: true,
            message: 'Senha alterada com sucesso.'
          })
        }
      } catch (e) {
        const error = e?.toString().toLowerCase();
        setPasswordSuccess(null);

        if (error?.includes('especifique o valor da "senhaatual"')) {
          setPasswordError({
            error: true,
          });
        } else if (error?.includes('senha inválida')) {
          setPasswordError({
            message: 'Senha inválida.',
            error: true,
          });
        } else {
          console.log('Erro ao atualizar dados');
        }
      } finally {
        setNewPassword(null);
        updateSpecificFieldInStorage('rca_usuario', 'firstname', userData.firstName);
      }
    }
  }, [userData, newPassword, changePassword, updateCustomer]);

  return {
    handleInputChange,
    handlePasswordChange,
    handleChangeCustomerData,
    userData,
    newPassword,
    loading: loading || loadingChangePassword,
    allowed: ((newPassword && isValidPassword) || !newPassword) && userData.firstName && userData.lastName,
    passwordError,
    passwordSuccess,
    userConnectivity,
  };
};
