import * as S from './styles';
import { RiPlayMiniLine, RiPlayReverseMiniLine, RiSkipForwardMiniLine, RiSkipBackMiniLine } from "react-icons/ri";

interface PaginatorProps {
    totalPages: number | any;
    currentPage: number;
    handlePagination: (page: number) => void;
}

export const Paginator = ({ currentPage, totalPages, handlePagination }: any) => {
    console.log(currentPage, totalPages)
    return (
        <S.Pagination>
            <S.PageControl>
                <S.PageControlItem onClick={() => handlePagination(1)} disabled={currentPage === 1}>
                    <RiSkipBackMiniLine />
                </S.PageControlItem>
                <S.PageControlItem onClick={() => handlePagination(currentPage - 1)} disabled={(currentPage - 1) === 0}>
                    <RiPlayReverseMiniLine />
                </S.PageControlItem>
            </S.PageControl>
            <S.Pages>
                <S.CurrentPage type='text' value={currentPage}></S.CurrentPage>
                <S.TotalPages>de <b>{totalPages}</b></S.TotalPages>
            </S.Pages>
            <S.PageControl>
                <S.PageControlItem onClick={() => handlePagination(currentPage + 1)}>
                    <RiPlayMiniLine />
                </S.PageControlItem>
                <S.PageControlItem onClick={() => handlePagination(totalPages)}>
                    <RiSkipForwardMiniLine />
                </S.PageControlItem>
            </S.PageControl>
        </S.Pagination>
    )
}

export default Paginator