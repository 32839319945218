import styled from 'styled-components';
import { colors } from '@/constants';

export const OrderDate = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    font-size: 14px;
    line-height: 16px;
`;

export const LabelDate = styled.span`
    font-weight: 400;
    color:${colors.grey3}; 
`;

export const Date = styled.span`
    font-weight: 700;
    color:${colors.brand.grafite};
`;

export const OrderCard = styled.div`
    width: 100%;
    background-color: ${colors.brand.white};
    border-radius: 6px;

    padding-bottom: 20px;
`;

export const RCAOrderTag = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    padding: 24px 20px;
    border: 6px 6px 0 0;
    background-color: ${colors.alert.blue10};
    color: ${colors.alert.blue200};
`;

export const Content = styled.div`
    padding: 24px 20px;
`;

export const TagIcon = styled.div`
    display: flex;
    flex-grow: 0;
    justify-content: center;
    align-items: center;


    width: 30px;
    min-width: 30px;
    max-width: 30px;

    height: 32px;
    border-radius: 4px;
    background-color: ${colors.alert.blue200};
    color: ${colors.brand.white};
`;

export const TagLabel = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
`;

export const OrderData = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;   
    gap: 12px;
`;

export const OrderNumbersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    padding-bottom: 16px;
    border-bottom: solid 1px ${colors.background1};
    border-radius: 6px;
`;

export const InformativeIcon = styled.div`
    svg{
        width: 24px;
        height: 24px;
        color:${colors.brand.grafite};
    }
`;

export const ActionArrow = styled.div`
    svg{
        width: 24px;
        height: 24px;
        color:${colors.brand.grafite};
    }
`;

export const OrderNumbers = styled.div`
    display: flex;
    flex-direction: row;
    gap: 27px;
`;

export const Number = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 109px;

    font-size: 12px;
    line-height: 16px;
`;

export const NormalLabel = styled.span`
    font-weight: 400;
    color:${colors.grey3};
`;

export const BoldLabel = styled.span`
    font-weight: 700;
    color:${colors.brand.grafite};
`;

export const ItemsQuantity = styled.div`
    padding: 4px 8px;
    width: fit-content;
    background-color: ${colors.background1};
    border-radius: 3px;

    font-size: 11px;
    font-weight: 400;
    line-height: 12.89px;
    color: ${colors.grey3};
`;

export const OrderTotals = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    font-size: 12px;
`;

export const TotalRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const TotalInvoicedPrice = styled.span`
    font-weight: 700;
    color:${colors.brand.grafite};
`;

export const FinalPrice = styled.span`
    font-weight: 700;
    font-size: 14px;
    line-height: 16.41px;
    color:${colors.green};
`;

// 

export const InvoiceContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    padding: 20px 0;
    margin: 20px 0;
    border-top: solid 1px ${colors.background1};
    border-bottom: solid 1px ${colors.background1};
`;

export const Button = styled.div<{ disabled: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    height: 60px;

    background-color: ${colors.grey4};
    border-radius: 6px;

    font-size: 12px;
    font-weight: 700;
    line-height: 14.06px;
    color: ${colors.brand.grafite};

    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

    svg{
        width: 20px;
        height: 20px;
    }
`;

// Order status

export const OrderStatus = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    width: 100%;
`;

export const TimeLine = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Cria 5 colunas de tamanho igual */
    align-items: center;
    gap: 28px;
  
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 83%;
        left: 10px;
        right: 10px;
        height: 3px;
        background-color: ${colors.gray.gray40}; /* Cor da linha */
        z-index: 0;
    }
`;

export const TimeLineItem = styled.div<{ isActive: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    svg{
        color: ${({ isActive }) => (isActive ? colors.green : colors.gray.gray40)};
    }

`;

export const TimeLineIcon = styled.div`
    svg{
        width: 24px;
        height: 24px;
    }
`;

export const TimeLineDot = styled.div<{ isActive?: boolean }>`
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .dot{
        width: ${({ isActive }) => (isActive ? '15px' : '10px')};
        height: ${({ isActive }) => (isActive ? '15px' : '10px')};
        background-color: ${({ isActive }) => (isActive ? colors.green : colors.brand.white)};
        border-radius: 50%;
        border: ${({ isActive }) => (isActive ? `solid 2px ${colors.green10}` : `solid 2px ${colors.gray.gray40}`)};
    }
`;

export const StatusInfo = styled.span`
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: ${colors.alert.green50};
    border-radius: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 14.06px;
    color: ${colors.green};
`;