import * as S from "./styles";
import { useCallback, useMemo, useState } from 'react';
import Lottie from 'react-lottie-player'

import BetinhoSmall from "./animations/betinho_small.json";
import BetinhoMedium from "./animations/betinho_medium.json";

interface BetinhoProps {
  message: string,
  size: "small" | "medium"
  showMessage: boolean
}

const BetinhoTheRobot = ({ message, size, showMessage }: BetinhoProps) => {

  const [closeMessage, setCloseMessage] = useState(false)

  const handleCloseMessage = useCallback(() => {
    setCloseMessage(true)
  }, [])

  const animationData = useMemo(() => {
    return size === "small" ? BetinhoSmall : BetinhoMedium;
  }, [size]);  

  return (
    <S.BetinhoContainer className='betinho'>
      {(showMessage && !closeMessage) && 
        <S.FloatingMessage>
          {message}
          <S.CloseIcon onClick={handleCloseMessage}/>
        </S.FloatingMessage>
      }
      <Lottie animationData={animationData} play loop/>
    </S.BetinhoContainer>
  )
};

export default BetinhoTheRobot