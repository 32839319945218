import { useUserContext } from "@/hooks";
import * as S from "./styles";

import { Graphics } from "@/components";

import BarChart from "./BarChart";

const SalesReport = () => {
  const { dashboards } = useUserContext();
  return (
    <S.Container className="container">
      <S.Header className="header">
        <S.TopRow>
          <S.Activity>
            Atividade
            <S.AverageSale>Média de Vendas</S.AverageSale>
          </S.Activity>
          <S.SelectPeriod>
            Vendas
            <S.ArrowDownIcon size={18}></S.ArrowDownIcon>
          </S.SelectPeriod>
        </S.TopRow>
        <S.BarGraph className="graph">
          <BarChart />
        </S.BarGraph>
      </S.Header>
      <S.PerformanceDisplay>
        <S.Percentage>30%</S.Percentage>
        <S.Comparison>Seu desempenho de vendas é de 30%
        melhor comparado com o mês passado</S.Comparison>
      </S.PerformanceDisplay>
      <S.Sales>
        <S.VerticalCard>
          <S.UpperVerticalCard>
            <S.Icons>
              <S.ShoppingCartIcon $color="green" size={31}/>
              <S.LineChart>
                <Graphics height={31} theme="green" data={dashboards?.metaFaturamento.dashboard}/>
              </S.LineChart>
            </S.Icons>
            <S.Sector>
              Ecommerce
              <S.PercentageTag>
                <S.ArrowDownFillIcon size={16} />
                37%
              </S.PercentageTag>
            </S.Sector>
            <S.PercentageValues>
              <S.Type>%Venda Bruta</S.Type>
              <S.Value>60%</S.Value>
              <S.Type>%Devolução</S.Type>
              <S.Value>30%</S.Value>
            </S.PercentageValues>
          </S.UpperVerticalCard>
          <S.BottomVerticalCard>
              Valor Líquido
            <S.Networth $color="green">
              R$ 40.450.000,00
            </S.Networth>
          </S.BottomVerticalCard>
        </S.VerticalCard>

        <S.VerticalCard>
          <S.UpperVerticalCard>
            <S.Icons>
              <S.ShoppingBagIcon $color="green" size={31}/>
              <S.LineChart>
                <Graphics height={31} theme="green" data={dashboards?.metaFaturamento.dashboard}/>
              </S.LineChart>
            </S.Icons>
            <S.Sector>
              Força de Vendas
              <S.PercentageTag>
                <S.ArrowDownFillIcon size={16} />
                63%
              </S.PercentageTag>
            </S.Sector>
            <S.PercentageValues>
              <S.Type>%Venda Bruta</S.Type>
              <S.Value>40%</S.Value>
              <S.Type>%Devolução</S.Type>
              <S.Value>70%</S.Value>
            </S.PercentageValues>
          </S.UpperVerticalCard>
          <S.BottomVerticalCard>
              Valor Líquido
            <S.Networth $color="green">
              R$ 40.450.000,00
            </S.Networth>
          </S.BottomVerticalCard>
        </S.VerticalCard>
      </S.Sales>

      <S.Categories>
        <S.Title>Categorias</S.Title>
        <S.HorizontalCard>
          <S.UpperHorizontalCard>
            <S.Name>Mercearia</S.Name>
            <S.Chart>
              <Graphics height={31} theme="green" data={dashboards?.metaFaturamento.dashboard}/>
            </S.Chart>
            <S.NetworthContent>
              Valor Líquido
              <S.Networth $color="green">R$ 40.450.000,00</S.Networth>
            </S.NetworthContent>
          </S.UpperHorizontalCard>
          <S.BottomHorizontalCard>
            <S.Column>
              <S.ShoppingCartIcon $color="green" size={24} />
              <S.Percentages>
                Ecommerce
                <S.ValueBottom>37%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
            <S.Column>
              <S.ShoppingBagIcon $color="green" size={24} />
              <S.Percentages>
                Força de Vendas
                <S.ValueBottom>63%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
          </S.BottomHorizontalCard>
        </S.HorizontalCard>

        <S.HorizontalCard>
          <S.UpperHorizontalCard>
            <S.Name>Liquida</S.Name>
            <S.Chart>
              <Graphics height={31} theme="orange" data={dashboards?.metaFaturamento.dashboard}/>
            </S.Chart>
            <S.NetworthContent>
              Valor Líquido
              <S.Networth $color="orange">R$ 40.450.000,00</S.Networth>
            </S.NetworthContent>
          </S.UpperHorizontalCard>
          <S.BottomHorizontalCard>
            <S.Column>
              <S.ShoppingCartIcon $color="orange" size={24} />
              <S.Percentages>
                Ecommerce
                <S.ValueBottom>37%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
            <S.Column>
              <S.ShoppingBagIcon $color="orange" size={24} />
              <S.Percentages>
                Força de Vendas
                <S.ValueBottom>63%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
          </S.BottomHorizontalCard>
        </S.HorizontalCard>

        <S.HorizontalCard>
          <S.UpperHorizontalCard>
            <S.Name>Perfumaria</S.Name>
            <S.Chart>
              <Graphics height={31} theme="orange" data={dashboards?.metaFaturamento.dashboard}/>
            </S.Chart>
            <S.NetworthContent>
              Valor Líquido
              <S.Networth $color="orange">R$ 40.450.000,00</S.Networth>
            </S.NetworthContent>
          </S.UpperHorizontalCard>
          <S.BottomHorizontalCard>
            <S.Column>
              <S.ShoppingCartIcon $color="orange" size={24} />
              <S.Percentages>
                Ecommerce
                <S.ValueBottom>37%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
            <S.Column>
              <S.ShoppingBagIcon $color="orange" size={24} />
              <S.Percentages>
                Força de Vendas
                <S.ValueBottom>63%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
          </S.BottomHorizontalCard>
        </S.HorizontalCard>

        <S.HorizontalCard>
          <S.UpperHorizontalCard>
            <S.Name>Bazar</S.Name>
            <S.Chart>
              <Graphics height={31} theme="red" data={dashboards?.metaFaturamento.dashboard}/>
            </S.Chart>
            <S.NetworthContent>
              Valor Líquido
              <S.Networth $color="red">R$ 40.450.000,00</S.Networth>
            </S.NetworthContent>
          </S.UpperHorizontalCard>
          <S.BottomHorizontalCard>
            <S.Column>
              <S.ShoppingCartIcon $color="red" size={24} />
              <S.Percentages>
                Ecommerce
                <S.ValueBottom>37%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
            <S.Column>
              <S.ShoppingBagIcon $color="red" size={24} />
              <S.Percentages>
                Força de Vendas
                <S.ValueBottom>63%</S.ValueBottom>
              </S.Percentages>
            </S.Column>
          </S.BottomHorizontalCard>
        </S.HorizontalCard>
      </S.Categories>
      <S.Summarize className="summarize">
        <S.Item>
          Total Bruto
          <S.Quantity>R$ 40.450.000,00</S.Quantity>
        </S.Item>
        <S.Item>
          Devolução
          <S.Quantity $color>-R$ 40.450.000,00</S.Quantity>
        </S.Item>
      </S.Summarize>
      <S.FloatMessage>
        Total a Receber
        <S.ValueToReceive>R$ 40.450.000,00</S.ValueToReceive>
        <S.Disclaimer>*Os valores são meramente informativos, podendo variar até o fim do período de apuração.</S.Disclaimer>
      </S.FloatMessage>
    </S.Container>
  );
};

export default SalesReport