import { useClient } from "@/hooks";
import { openDB as idb, IDBPDatabase } from "idb";

import {
  BrandProps,
  StockProps,
  ClientProps,
  ProductProps,
  CategoryProps,
  AWSParamsProps,
  IndicatorsProps,
  PageBuilderProps,
  PromocaoClientProps,
  TaxSubstitutionProps,
  ParametroPrecoBaseProps,
  RecomendacaoBigDataProps,
  PercentualFornecedorProps,
  productRestrictionClientProps,
} from "@/types";

const dbName = "BATE_FORTE";

const tableKeys = [
  "marcas",
  "indicadores",
  "produtos",
  "clientes",
  "categorias",
  "pagebuilder",
  "parametrosAws",
  "substituicaotributaria",
  "dicionario_categorias",
  "dicionario_produtos",
  "precoPromocaoCliente",
  "recomendacaoBigData",
  "precos",
  "imagens",
  "estoque",
  "catalogo",
  "janelaEntrega",
  "parametroPrecoBase",
  "percentualFornecedor",
  "pedidos_offline",
  "productRestrictionClient",
  "saldoFlex",
] as const;

type Tables = (typeof tableKeys)[number];
const listTables: Tables[] = [...tableKeys];

type TableTypes = {
  precos: any;
  imagens: any;
  catalogo: any;
  saldoFlex: any;
  janelaEntrega: any;
  pedidos_offline: any;
  marcas: BrandProps[];
  estoque: StockProps[];
  clientes: ClientProps[];
  produtos: ProductProps[];
  categorias: CategoryProps[];
  indicadores: IndicatorsProps[];
  parametrosAws: AWSParamsProps[];
  pagebuilder: PageBuilderProps[];
  parametroPrecoBase: ParametroPrecoBaseProps[];
  substituicaotributaria: TaxSubstitutionProps[];
  recomendacaoBigData: RecomendacaoBigDataProps[];
  percentualFornecedor: PercentualFornecedorProps[];
  dicionario_produtos: Record<number, ProductProps>;
  dicionario_categorias: Record<number, CategoryProps>;
  productRestrictionClient: productRestrictionClientProps[];
  /**
    * @description Preço Promocao. [idCliend][idProduto]
  */
  precoPromocaoCliente: Record<number, Record<number, PromocaoClientProps>>;
};

export const openDBConnection = async () => {
  const currDB = await idb(dbName, 1, {
    upgrade(db) {
      for (const name of listTables) {
        db.createObjectStore(name, {
          keyPath: name,
          autoIncrement: true,
        });
      }
    },
  });
  return currDB;
};

const transformBlobToJSON = async (item: any) => {
  if (!item) return item;
  return new Promise((res) => {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      res(JSON.parse(event?.target?.result || {}));
    };
    reader.readAsText(item);
  });
};

const handleGet = async <T extends Tables>(
  name: T,
  currDB: IDBPDatabase<unknown>
): Promise<TableTypes[T]> => {
  const transaction = currDB?.transaction(name, "readonly");
  const store = transaction?.objectStore(name);
  const data = await store.getAll();
  const lastData = data?.pop() || []
  // return lastData?.data || [] as TableTypes[T];
  return transformBlobToJSON(lastData.data) as TableTypes[T];
};

export const useDbFunctions = () => {
  const { selectedClient } = useClient();

  const handleProductList = async <T extends Tables>(
    name: T,
    currDB: IDBPDatabase<unknown>
  ): Promise<TableTypes[T]> => {
    const list = (await handleGet("produtos", currDB)) || [];
    const restrictedList = (await handleGet("productRestrictionClient", currDB)) || [];

    const restrictions =
      restrictedList
        ?.filter((r: any) => r.COD_CLIENTE === selectedClient.COD_CLIENTE)
        ?.map((r: any) => r.COD_PRODUTO) || [];

    return list?.filter(
      (p: any) => !restrictions.includes(p.COD_PRODUTO)
    ) as TableTypes[T];
  };

  const closeDBConnection = (db: IDBPDatabase<unknown> | undefined) => {
    db?.close();
    db = undefined;
  };

  const getOnDB = async <T extends Tables>(name: T): Promise<TableTypes[T]> => {
    const currDB = await openDBConnection();

    const list = await (name === "produtos"
      ? handleProductList(name, currDB)
      : handleGet(name, currDB));

    closeDBConnection(currDB);
    return list as TableTypes[T];
  };

  const setOnDB = async (data: any[] | any, name: Tables) => {
    const currDB = await openDBConnection();
    const transaction = currDB?.transaction(name, "readwrite");
    const store = transaction?.objectStore(name);

    const bytes = new TextEncoder().encode(JSON.stringify(data));
    const blob = new Blob([bytes], { type: "application/json;charset=utf-8" });
    const allKeys = await store.getAllKeys();
    
    if (allKeys.length) {
      const key: any = allKeys[allKeys.length - 1]
      await store.delete(key);
    }
    // await store.put({ data, keyPath: name });
    await store.put({ data: blob, keyPath: name });

    transaction.commit();
    closeDBConnection(currDB);
  };

  const deleteDB = async () => {
    indexedDB.deleteDatabase(dbName);
    await new Promise((resolve) => setTimeout(() => resolve(true), 500));
  };

  return { getOnDB, setOnDB, deleteDB, closeDBConnection };
};
