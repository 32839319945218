import * as S from './Styled';
import { formatCurrency } from '@/shared';

interface Props {
  title: string;
  apuracao: number;
  atingido: number;
  desconto: number;
  valorDesconto: number;
}

const ProductivityExtractPenality = ({ title, apuracao, desconto, atingido, valorDesconto }: Props) => {
  return (
    <S.Card className="productivity-extract-card-penality">
      <S.Row>
        <S.Column>
          <S.Text textalign="left" size={18} bold={700} color="grafite">
            {title}
          </S.Text>
        </S.Column>

        <S.Column>
          <S.Text bottom={8} textalign="right" size={14} bold={500} color="grafite">
            Apuração
          </S.Text>

          <S.Text>{formatCurrency({ value: apuracao })}</S.Text>
        </S.Column>
      </S.Row>

      <S.Separete />

      <S.Row>
        <S.Column>
          <S.Text bottom={8} textalign="left" size={14} bold={500} color="grafite">
            %Atingido
          </S.Text>

          <S.Text textalign="left" size={14} bold={500} color="gray">
            {atingido}%
          </S.Text>
        </S.Column>

        <S.Column>
          <S.Text bottom={8} textalign="left" size={14} bold={500} color="grafite">
            %Desconto
          </S.Text>

          <S.Text padding="2px 8px" textalign="left" size={14} bold={500} color="red">
            <S.DownIcon /> {`${desconto}`}%
          </S.Text>
        </S.Column>

        <S.Column>
          <S.Text bottom={8} textalign="right" size={14} bold={500} color="grafite">
            Valor do Desconto
          </S.Text>

          <S.Text textalign="right" size={16} bold={800} color="grafite">
            {formatCurrency({ value: valorDesconto })}
          </S.Text>
        </S.Column>
      </S.Row>
    </S.Card>
  );
};

export default ProductivityExtractPenality;
