import * as S from "./styles";
import { colors } from "@/constants";
import { ClientProps } from "@/types";
import { useMemo, useState } from "react";
import { useDiscountAmountFlex } from "@/hooks";
import { formatCode, formatCurrency } from "@/shared";
import { Accordion, ToolTip, ShortProgressBar } from "@/components";

interface Props {
  isChecked?: boolean;
  showProgress?: boolean;
  client: ClientProps;
  setClient: (value: ClientProps | undefined) => void;
}

const ClientCard = ({ showProgress, isChecked, client, setClient }: Props) => {
  const { getDiscountAmountByClient } = useDiscountAmountFlex();

  const hasDiscount = useMemo(
    () => !!getDiscountAmountByClient(client.COD_CLIENTE),
    [client.COD_CLIENTE, getDiscountAmountByClient]
  );
  // console.log(hasDiscount);

  const [accordion, setAccordion] = useState(false);

  const isDanger = client.daysWithoutPurchase >= 60;
  const isWarning = client.daysWithoutPurchase >= 30 && !isDanger;
  const hasAlert = isDanger || isWarning;
  const isDisabled = client.ST_COMPRA === "I";

  //console.log('Cliente',client);

  return (
    <S.Card $ischecked={isChecked}>
      <S.ContentInfos>
        <S.CheckContent
          onClick={() =>
            !isDisabled && setClient(isChecked ? undefined : client)
          }
          data-test={`card-company-check-select-${client.COD_CLIENTE}`}
        >
          <S.CheboxContent $isdisabled={isDisabled}>
            {isChecked ? (
              <S.CheckboxCircle size={24} />
            ) : (
              <S.CheckboxBlankCircle size={24} />
            )}
          </S.CheboxContent>
        </S.CheckContent>

        <S.InfosContent>
          <S.Row $between>
            <S.Column
              onClick={() =>
                !isDisabled && setClient(isChecked ? undefined : client)
              }
            >
              <S.CompanieName
                data-test={`card-company-title-${client.COD_CLIENTE}`}
              >
                <S.NumberCompanie $isActive={isChecked}>
                  {client.COD_CLIENTE}
                </S.NumberCompanie>

                <S.FantasyName $isdisabled={isDisabled}>
                  {client.NM_CLIENTE}
                </S.FantasyName>
              </S.CompanieName>

              <S.CNPJLabel $isdisabled={isDisabled}>
                {formatCode(client.NR_DOCUMENTO)}

                {hasDiscount && <S.DiscountFlex>F</S.DiscountFlex>}
              </S.CNPJLabel>
            </S.Column>

            <S.ColumnRight>
              <S.ArrowContent
                $isdisabled={isDisabled}
                onClick={() => !isDisabled && setAccordion(!accordion)}
              >
                <S.ArrowIcon
                  size={24}
                  $isOpened={accordion}
                  data-test={`card-company-handle-accordion-${client.COD_CLIENTE}`}
                />
              </S.ArrowContent>

              <S.Row>
                {!!client.notifications && (
                  <S.ContentBar>
                    <S.BarIcon size={24} />

                    <S.BarNotification>
                      {client.notifications}
                    </S.BarNotification>
                  </S.ContentBar>
                )}
                {isDisabled && (
                  <S.ContentProhibited>
                    <S.ProhibitedIcon size={16} />
                  </S.ContentProhibited>
                )}
              </S.Row>
            </S.ColumnRight>
          </S.Row>

          {showProgress && <S.Row $centered>
            <S.MetaLabel>Atingido</S.MetaLabel>

            <S.ProgressContent>
              <ShortProgressBar state={client.progress1 || "empty"} />
              <ShortProgressBar state={client.progress2 || "empty"} />
              <ShortProgressBar state={client.progress3 || "empty"} />
              <ShortProgressBar state={client.progress4 || "empty"} />
            </S.ProgressContent>
          </S.Row>}
        </S.InfosContent>
      </S.ContentInfos>

      <S.ContentAlert $showBorder={hasAlert || accordion}>
        {hasAlert && (
          <S.AlertMessage type={isDanger ? "danger" : "warning"}>
            {isWarning && <S.FlagIcon size={15} />}
            {isDanger && <S.CalendarIcon size={15} />}
            Não compra há mais de
            <S.AlertMessageBold>
              {client.daysWithoutPurchase} dias
            </S.AlertMessageBold>
          </S.AlertMessage>
        )}

        <Accordion
          noRadius
          noBodyPadding
          accordionTitle=""
          showHeader={false}
          bgColor={colors.transparent}
          accordionExpanded={accordion}
          setAccordionExpanded={setAccordion}
          id={`company-${client.COD_CLIENTE}`}
        >
          <S.AccordionContent>
            <S.AccordionInfos>
              <S.AccordionDataInfo>
                <S.AccordionLabel>Limite de crédito:</S.AccordionLabel>
                <S.AccordionLimit>
                  {formatCurrency({ value: client.LIMITE_CREDITO })}
                </S.AccordionLimit>
              </S.AccordionDataInfo>

              <S.AccordionDataInfo>
                <S.AccordionLabel>Saldo disponível:</S.AccordionLabel>
                <S.AccordionLimit>
                  {formatCurrency({ value: client?.SALDO_DISPONIVEL || 0 })}
                </S.AccordionLimit>
              </S.AccordionDataInfo>
            </S.AccordionInfos>

            <ToolTip text="Em breve" displayMode="click">
              <S.RequestLimit className="disabled-service">
                <S.IconPlus size={12} />
                Solicitar mais limite de crédito
              </S.RequestLimit>
            </ToolTip>
          </S.AccordionContent>
        </Accordion>
      </S.ContentAlert>
    </S.Card>
  );
};

export default ClientCard;
