import { colors } from '@/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $extraPadding?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  ${props => props.$extraPadding && css`
    padding: 3px 0 1px 0;
  `}
`;

export const Text = styled.div<{ $color?: string; $weight?: number; $shouldShow?: boolean; $size?: number; }>`
  font-family: 'Roboto', sans-serif;
  font-size: ${props => props.$size || 11}px;
  color: ${props => props.$color || colors.brand.grafite};
  font-weight: ${props => props.$weight || 500};
  line-height: 12px;
  &::after {
    ${props => props.$shouldShow && css`
      padding-left: 2px;
      content: '•';
    `}
  }
`;
