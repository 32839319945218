import { colors } from "@/constants";
import styled from "styled-components";

export const AlertContainer = styled.div`
  background-color: ${colors.alert.green50};
  border-radius: 6px;
  height: fit-content;
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const AlertLabel = styled.div`
  color: ${colors.alert.green110};
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  line-height: 16.41px;
  font-family: 'Roboto', sans-serif;
`;

export const LoadingBarContainer = styled.div`
  
`;



