import styled from "styled-components";
import { colors } from "@/constants";

export const UserDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  width: 100%;
  padding: 20px;

  background-color: ${colors.brand.white};
  position: relative;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.disabled{
   input{
    color: ${colors.gray.gray40};
   }
  }
`;

export const FormControl = styled.div`
  input {
    &:focus,
    :active {
      border: none;
    }
  }

  &.password-control {
    width: 261px;
  }

  &.small-control {
    width: 175px;
  }

  &.disabled{
   input{
    color: ${colors.gray.gray40};
   }
  }
`;

export const Label = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  color: ${colors.brand.grafite};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Wrapper = styled.div`
background-color: ${colors.brand.white};
`;

export const SaveModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  background-color: ${colors.brand.white};
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;

  min-height: 105px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 10px 40px 0px #00000040;
  display: flex;
  padding: 12px 20px 30px 20px;
`;

export const SaveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  background-color: ${colors.brand.red};
  border: none;
  border-radius: 6px;
  height: 55px;

  font-size: 16px;
  line-height: 18.75px;
  font-weight: 500;
  color: ${colors.brand.white};

  &:disabled{
    background-color: ${colors.gray.gray10};

    color: ${colors.gray.gray40};
  }
`;

export const Connectivity = styled.span`
  width: fit-content;
  padding: 8px;

  background-color: ${colors.brand.grafite};
  border-radius: 6px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${colors.brand.white};

  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 100%; 
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${colors.brand.grafite}; 
  }

`;