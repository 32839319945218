import { gql } from "@apollo/client";

export const GET_COMPANY_DATA = gql`
    query companyExternalData($cnpj: String, $uf: String) {
        companyExternalData(cnpj: $cnpj, uf: $uf){
            inscricao_estadual
            atividade_principal
            atividade_secundaria
            razao_social
            nome_fantasia
            uf
            situacao
            logradouro
            numero
            bairro
            complemento
            cep
            municipio
            porte
            abertura
            cnpj
            status
            email
            telefone
            capital_social
        }
    }
`;

export default {
    getCompanyDataQuery: GET_COMPANY_DATA
}