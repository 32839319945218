import * as S from "./styles";
import { colors } from "@/constants";
// import offer1 from "@/assets/images/offers/offer-1.png";
// import offer2 from "@/assets/images/offers/offer-2.png";

const offer1 = `${process.env.PUBLIC_URL}/offer-1.png`;
const offer2 = `${process.env.PUBLIC_URL}/offer-2.png`;

interface Props {
  text?: string;
  children?: any;
  isinvert?: boolean;
}

const OfferDivider = ({ text, children, isinvert }: Props) => {
  const img = !isinvert ? offer1 : offer2;
  const bgColors = !isinvert ? [colors.grey] : [colors.brand.red, colors.red];

  return (
    <S.Container>
      <S.Content bgcolor={bgColors}>
        <S.Img src={img} />

        {children ? children : <S.Text>{text}</S.Text>}
      </S.Content>
    </S.Container>
  );
};

export default OfferDivider;
