import { colors } from "@/constants";
import styled from "styled-components";

export const OrderNumberBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  padding: 24px 16px;
  background-color: ${colors.brand.white};

  border: solid 1px ${colors.background1};
  border-radius: 6px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const OrderInput = styled.input`
  width: 100%;
  height: 55px;
  padding: 0 16px;

  box-sizing: border-box;

  border: solid 1px ${colors.background1};
  border-radius: 6px;

  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;

  outline: none;

  &:focus {
    outline: none;
    border: solid 1px ${colors.background1};
  }
`;

export const OrderMessage = styled.div`
  display: flex;
  gap: 8px;

  background-color: ${colors.grey4};
  color: ${colors.grey3};

  padding: 16px 8px;

  border-radius: 3px;
  box-sizing: border-box;

  svg {
    min-width: 20px;
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 11px;
    font-weight: 400;
  }
`;
