import { useMemo } from "react";
import { RiMapPin2Fill, RiShoppingCartLine, RiBarcodeBoxLine } from "react-icons/ri";

import * as S from './styles';

import { Price } from '../../../components/price/Price';

interface ShippingProps {
    shippingData: any,
    label?: boolean
}

const shippingMethod = 'Entrega BATE FORTE';

const Shipping: React.FC<ShippingProps> = ({ shippingData, label = true }) => {

    const transformContent = (component: any) => {
        let infoContent;

        if (component.dataType === 'address') {
            const regionData = component.city + ' - ' + component.state + ' - ' + component.cep;
            const customerData = component.name + ' ' + ' - ' + component.telephone;

            infoContent = [regionData, customerData];

        }
        else if (component.dataType === 'shipping') {
            const deliveryDate = component.deliveryDate;

            infoContent = [deliveryDate];
        }

        return infoContent;
    };

    console.log('ShippingData', shippingData);

    const content = useMemo(() => {
        if (shippingData) {
            return shippingData.map((component: any, index: number) => {
                const dataType = component.dataType;

                const tagLabel = dataType === 'address' ? 'meu endereço' : 'forma de entrega';
                const title = dataType === 'address' ? `${component.street + ', ' + component.address_number + ', ' + component.region}` : dataType === 'shipping' ? `${shippingMethod}` : `Boleto`;

                const infoContent = transformContent(component);

                return (
                    <S.InfoBlock key={index}>
                        <S.ShippingIcon>
                            {dataType === 'address' ? (
                                <RiMapPin2Fill />
                            ) : dataType === 'shipping' ? (
                                <RiShoppingCartLine />
                            ) : (
                                <RiBarcodeBoxLine />
                            )}
                        </S.ShippingIcon>
                        <S.ShippingInformation>
                            {label && (<S.Tag>{tagLabel}</S.Tag>)}
                            <S.Title> {title} </S.Title>
                            <S.Description>
                                {infoContent?.map(item =>
                                    <span>{item}</span>
                                )}
                                {dataType === 'payment' && (
                                    <div>
                                        <span>Boleto faturado - {component.method} dias - </span>
                                        <Price value={component.total} />
                                    </div>
                                )}
                            </S.Description>
                            {dataType === 'shipping' && (
                                <S.FreeTag> Grátis </S.FreeTag>
                            )}
                        </S.ShippingInformation>
                    </S.InfoBlock>
                )
            })
        }
    }, [shippingData, label]);

    return (
        <S.ShippingInfoWrapper>
            {content}
        </S.ShippingInfoWrapper>
    )
}

export default Shipping;