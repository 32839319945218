interface OrderItem {
  __typename: string;
  entered_options: [
    {
      __typename: "OrderItemOption";
      label: string;
      value: string;
    }
  ];
  gift_wrapping?: string;
  id: string;
  product_image: string;
  product_name: string;
  product_package_quantity: number;
  product_price_st: number;
  product_sale_price: {
    __typename: "Money";
    currency: "BRL";
    value: number;
  };
  product_sku: string;
  product_type: string;
  product_url_key: string;
  quantity_invoiced: number;
  quantity_ordered: number;
  selected_options: any[];
  status: string;
}

interface ShippingAddress {
  __typename: "OrderAddress";
  city: string;
  firstname: string;
  lastname: string;
  postcode: string;
  region: string;
  street: string[];
  telephone: string;
}

interface Totals {
  __typename: "Money";
  currency: "BRL";
  value: number;
}
interface OrderTotal {
  __typename: "OrderTotal";
  grand_total: Totals;
  subtotal: Totals;
  total_tax: Totals;
}

interface Order {
  __typename: "CustomerOrder";
  gp_order_ext: {
    __typename: "GPOrderExtInterface";
    payment_condition_id: string;
    delivery_date: string;
  };
  id: string;
  items: OrderItem[];
  nro_ped_venda: string;
  number: string;
  order_date: string;
  payment_methods: [
    {
      __typename: "OrderPaymentMethod";
      additional_data: any[];
      name: string;
      type: string;
    }
  ];
  shipments: any[];
  shipping_address: ShippingAddress;
  shipping_method: string;
  state: string;
  status: string;
  total: OrderTotal;
}

export const orderMock: Order = {
  __typename: "CustomerOrder",
  gp_order_ext: {
    __typename: "GPOrderExtInterface",
    payment_condition_id: "Pagamento para 7 dias",
    delivery_date: "27 de janeiro de 2024",
  },
  id: "MTUzNzA=",
  items: [
    {
      __typename: "OrderItem",
      entered_options: [
        {
          __typename: "OrderItemOption",
          label: "Embalagens",
          value: "CX_20",
        },
      ],
      id: "MTM3NzA0",
      product_image:
        "https://mcstaging.bateforte.com.br/media/catalog/product/1/1/118877_13.jpg",
      product_name: "Café Melitta Tradicional Caixa 500g",
      product_package_quantity: 20,
      product_price_st: 0,
      product_sale_price: {
        __typename: "Money",
        currency: "BRL",
        value: 336.82,
      },
      product_sku: "118877-118877",
      product_type: "simple",
      product_url_key: "cafe-melitta-tradicional-vacuo-500g",
      quantity_invoiced: 0,
      quantity_ordered: 1,
      selected_options: [],
      status: "Comprado",
    },
  ],
  nro_ped_venda: "",
  number: "000016846",
  order_date: "2024-04-17 17:08:35",
  payment_methods: [
    {
      __typename: "OrderPaymentMethod",
      additional_data: [],
      name: "Boleto Faturado",
      type: "boletopayment",
    },
  ],
  shipments: [],
  shipping_address: {
    __typename: "OrderAddress",
    city: "Marilia",
    firstname: "Emp Tst Ecomm CD Vgp",
    lastname: "Emp Tst Ecomm CD Vgp",
    postcode: "1750001",
    region: "São Paulo",
    street: ["Rua 4 de Abril", "1322", "Altos do Palmital"],
    telephone: "6740092875",
  },
  shipping_method: "Free Shipping - Free",
  state: "New",
  status: "Pedido Realizado",
  total: {
    __typename: "OrderTotal",
    grand_total: {
      __typename: "Money",
      currency: "BRL",
      value: 713.51,
    },
    subtotal: {
      __typename: "Money",
      currency: "BRL",
      value: 713.51,
    },
    total_tax: {
      __typename: "Money",
      currency: "BRL",
      value: 0,
    },
  },
};

export default orderMock;
