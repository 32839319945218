import * as S from './styles';
import { colors } from '@/constants';
import { formatCurrency } from '@/shared';
import { Modal, ToolTip } from '@/components';
import { FaChevronDown } from 'react-icons/fa6';
import { CHECKOUT } from '@/constants/systemRoutes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGlobals, useCart, useCartTotals, useDiscountAmountFlex, useUserContext } from '@/hooks';

export const CartCheckoutBlock = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [tooltipOpen, setTooltip] = useState(false);
  const { paymentCode, setPaymentCode, listPaymentCode, setCanAccessCheckout } = useGlobals();
  const { totalQuantity } = useCart();
  const { rcaSaldoFlex, valorMinimo } = useUserContext();
  const { discountAmountClient } = useDiscountAmountFlex();
  const [selectedPaymentCode, setSelectedPaymentCode] = useState(paymentCode);

  const currentPayment = useMemo(() => {
    return listPaymentCode.find(({ cod_condicao }) => cod_condicao === paymentCode)?.desc_condicao;
  }, [listPaymentCode, paymentCode]);

  const setGlobalPaymentCode = useCallback(() => {
    setPaymentCode(selectedPaymentCode);
    setModalOpen(false);
  }, [selectedPaymentCode, setPaymentCode]);

  const { cartTotals, fetchTotalPriceByPaymentCode } = useCartTotals();

  const cartPrices = useMemo(() => {
    return {
      total: formatCurrency({
        value: cartTotals?.total,
      }),
      subtotal: formatCurrency({
        value: cartTotals?.subtotal,
      }),
      st_price: formatCurrency({
        value: cartTotals?.st_price,
      }),
    };
  }, [cartTotals]);

  useEffect(() => {
    if (modalOpen) setSelectedPaymentCode(paymentCode);
  }, [modalOpen, paymentCode]);

  const handleTooltip = useCallback(() => {
    if ((rcaSaldoFlex || 0) + discountAmountClient >= 0 && Number(cartTotals?.total || 0) >= valorMinimo) return;

    setTooltip(true);
    setTimeout(() => setTooltip(false), 3000);
  }, [rcaSaldoFlex, discountAmountClient, cartTotals?.total, valorMinimo, setTooltip]);

  return (
    <S.Container>
      <S.Button onClick={() => setModalOpen(true)} data-test="component-search-button-open-payment">
        <S.PaymentCondition>
          <S.Icon size={20} color={colors.brand.grafite} />

          <S.PaymentSelected>{currentPayment}</S.PaymentSelected>
        </S.PaymentCondition>

        <FaChevronDown color={colors.brand.grafite} />
      </S.Button>
      <S.TotalsContainer>
        <S.TotalLabel>
          Total
          <S.CartQuantityLabel>{totalQuantity > 1 ? `(${totalQuantity} itens)` : `(${totalQuantity} item)`}</S.CartQuantityLabel>
        </S.TotalLabel>
        <S.Total>{cartPrices?.total}</S.Total>
      </S.TotalsContainer>
      
      <ToolTip
        show={tooltipOpen}
        callback={() => handleTooltip()}
        displayMode="click"
        text={
          Number(cartTotals?.total || 0) < valorMinimo
            ? `Pedido mínimo de ${formatCurrency({ value: valorMinimo })}`
            : `Limite Flex indisponível, por favor verifique os itens do carrinho que aplicou desconto. R$ ${
                (rcaSaldoFlex || 0) + discountAmountClient
              }`
        }
      >
        <S.LinkButton
          $disabled={!cartTotals?.total || (rcaSaldoFlex || 0) + discountAmountClient < 0 || cartTotals?.total < valorMinimo}
          onClick={() => setCanAccessCheckout(true)}
          data-test={'checkout-button'}
          to={CHECKOUT.link}
        >
          <S.ButtonLabel>Fechar pedido</S.ButtonLabel>
        </S.LinkButton>
      </ToolTip>

      <Modal id="carrinho-mudar-condicoes-pagamento" closeModal={setModalOpen} isOpen={modalOpen} bottomMargin={60}>
        <S.ModalContent>
          <S.ModalTitle>Prazos e Formas de pagamento</S.ModalTitle>

          <S.ListBg>
            {listPaymentCode.map((item, ind: number) => {
              const isActive = item.cod_condicao === selectedPaymentCode;
              const priceCond = fetchTotalPriceByPaymentCode(item.cod_condicao);
              const formattedPrice = formatCurrency({ value: priceCond });

              if (!priceCond && totalQuantity) return '';

              return (
                <S.PaymentCard
                  $isactive={isActive}
                  key={`paymend-card-${ind}`}
                  onClick={() => setSelectedPaymentCode(item.cod_condicao)}
                  data-test={`search-component-payment-button-${item.cod_condicao}`}
                >
                  <S.CheckContent>
                    {isActive ? <S.CheckFill size={24} color={colors.green} /> : <S.CheckBlank size={24} color={colors.grey4} />}
                  </S.CheckContent>

                  <S.Icon size={18} color={isActive ? colors.brand.grafite : colors.gray.gray40} />

                  <S.TitlePaymentForm>
                    {item.desc_condicao}
                    {priceCond > 0 && totalQuantity > 0 && <S.FinalPrice>• {formattedPrice}</S.FinalPrice>}
                  </S.TitlePaymentForm>
                </S.PaymentCard>
              );
            })}
          </S.ListBg>

          <S.BtnContainer onClick={setGlobalPaymentCode} data-test="component-cart-checkout-block-set-payment-confirm">
            <S.ButtonModal>Continuar</S.ButtonModal>
          </S.BtnContainer>
        </S.ModalContent>
      </Modal>
    </S.Container>
  );
};

export default CartCheckoutBlock;
