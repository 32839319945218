import React from 'react';
import { RiCalendarScheduleLine, RiCalendarCloseLine } from "react-icons/ri";
import * as S from './styles';
import { colors } from '@/constants';

interface CalendarProps {
  date: string;
  pendingStatus?: boolean;
}

const Calendar = ({ date, pendingStatus }: CalendarProps) => {

  // console.log('pending status: ', pendingStatus);
  return (
    <S.CalendarContainer>
      <S.IconContainer>
        {pendingStatus ? <RiCalendarCloseLine size={21} color={colors.alert.red200} /> : <RiCalendarScheduleLine size={21} color={colors.brand.grafite} />}
      </S.IconContainer>
      <S.DateContainer>
        <S.Title>
          Pedido(s) feito(s) em:
        </S.Title>
        <S.Date color={pendingStatus ? colors.alert.red200 : ''}>
          {date}
        </S.Date>
      </S.DateContainer>
    </S.CalendarContainer>
  )
}

export default Calendar;