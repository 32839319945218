import * as S from './styles';

import { useCartTotals } from "@/hooks";
import { Price } from '../../../../components/price/Price';

const OrderTotals = ({ successTotals }: any) => {

    const { cartTotals } = useCartTotals();

    const totals = successTotals ? successTotals : cartTotals;

    return (
        <S.TotalsWrapper>
            <S.TotalsRow>
                <S.PriceLabel>Subtotal em produtos:</S.PriceLabel>
                <S.PriceValue>
                    <Price value={totals?.subtotal} />
                </S.PriceValue>
            </S.TotalsRow>
            <S.TotalsRow>
                <S.PriceLabel>Substituicao Tributária:</S.PriceLabel>
                <S.PriceValue>
                    <Price value={totals?.st_price} />
                </S.PriceValue>
            </S.TotalsRow>
            <S.TotalsRow>
                <S.PriceLabel>Frete</S.PriceLabel>
                <S.ShippingValue>Grátis</S.ShippingValue>
            </S.TotalsRow>
            <S.CartTotalRow>
                <S.PriceLabel>Total</S.PriceLabel>
                <S.CartTotalValue>
                    <Price value={totals?.total} />
                </S.CartTotalValue>
            </S.CartTotalRow>
        </S.TotalsWrapper>
    )
}

export default OrderTotals;