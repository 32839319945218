


export const StatusMock = [
  {
    date: 'Terça-feira, 14/05/2024',
    hasPendingStatus: false,
    orderList: [
      {
        clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
        clientRefNumber: '78717612',
        orders: [
        {
          orderNumber: '12345678-1234',
          erpNumber: '12345678-1234',
          quantity: 6,
          status: 'indisponivel',
        },
        ],
      },
      {
        clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
        clientRefNumber: '78717612',
        orders: [
        {
          orderNumber: '12345678-1234',
          erpNumber: '12345678-1234',
          quantity: 6,
          status: 'processando',
        },
        ],
      },
      {
        clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
        clientRefNumber: '78717612',
        orders: [
        {
          orderNumber: '12345678-1234',
          erpNumber: '12345678-1234',
          quantity: 36,
          status: 'reprocessando',
        },
        ],
      },
    {
      clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
      clientRefNumber: '78717612',
      orders: [
      {
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'indisponivel',
      },
      {
        orderNumber: '12345678-1234',
        erpNumber: '----',
        quantity: 10,
        status: 'rejeitado',
      },
      {
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 2,
        status: 'aprovado',
      }],
    },
    ]
  },
  {
    date: 'Segunda-feira, 13/05/2024',
    hasPendingStatus: true,
    orderList: [{
      clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
      clientRefNumber: '78717612',
      orders: [
      {
        orderRefNumber: '78717612',
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'indisponivel',
      }],
    },
    {
      clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
      clientRefNumber: '78717612',
      orders: [
      {
        orderRefNumber: '78717612',
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      },
      {
        orderRefNumber: '78717612',
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      }
    ],
    },
    {
      clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
      clientRefNumber: '78717612',
      orders: [
      {
        orderRefNumber: '78717612',
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      },
      {
        orderRefNumber: '78717612',
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      }
    ],
    },
    {
      clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
      clientRefNumber: '78717612',
      orders: [
      {
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      },
      {
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      }
    ],
    },
    {
      clientName: 'Comercial de Alimentos e Lanchonete São José LTDA.',
      clientRefNumber: '78717612',
      orders: [
      {
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      },
      {
        orderNumber: '12345678-1234',
        erpNumber: '12345678-1234',
        quantity: 6,
        status: 'rejeitado',
      }
    ],
    },
  ],
    
  },
];
