import { colors } from '@/constants';
import styled, { css } from 'styled-components';

export const Lables = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

interface TextProps {
  bg?: string;
  top?: string;
  bold?: number;
  size?: number;
  radius?: number;
  padding?: string;
  shadown?: string;
  bottom?: string;
  $inline?: boolean;
  color?: 'grafite' | 'white';
}

export const Text = styled.p<TextProps>`
  text-align: center;
  box-shadow: ${i => i.shadown};
  padding: ${i => i.padding || '0px'};
  font-size: ${(i) => i.size || 12}px;
  background-color: ${i => i.bg || ''};
  border-radius: ${i => i.radius || 0}px;
  margin-bottom: ${(i) => i.bottom || '0px'};
  margin-top: ${(i) => i.top || '0px'};
  font-weight: ${(i) => i.bold || 'inherit'};
  color: ${(i) => (i.color === 'grafite' ? colors.gray.gray40 : colors.brand.white)};
  ${(i) =>
    i.$inline &&
    css`
      display: inline-flex;
      align-items: baseline;
    `};
`;

export const Span = styled.span<TextProps>`
  text-align: center;
  box-shadow: ${i => i.shadown};
  padding: ${i => i.padding || '0px'};
  font-size: ${(i) => i.size || 12}px;
  background-color: ${i => i.bg || ''};
  border-radius: ${i => i.radius || 0}px;
  margin-bottom: ${(i) => i.bottom || '0px'};
  margin-top: ${(i) => i.top || '0px'};
  font-weight: ${(i) => i.bold || 'inherit'};
  color: ${(i) => (i.color === 'grafite' ? colors.gray.gray40 : colors.brand.white)};
  ${(i) =>
    i.$inline &&
    css`
      display: inline-flex;
      align-items: baseline;
    `};
`;

export const Left = styled.div`
  position: absolute;
  right: 20%;
  top: 10%;
  z-index: 1;
`;

export const Content = styled.div`
  padding: 0px 0px 0px 0px;
`;

export const Box = styled.div`
  position: relative;
`;

export const Bar = styled.div<{ $norotate?: boolean }>`
  top: 0px;
  left: 50%;
  width: 100%;
  position: absolute;
  ${i => !i.$norotate && css`
    transform: rotateY(180deg) translateX(50%);
  `}
  ${i => i.$norotate && css`
    transform: translateX(-50%);
  `}
`;
