import Item from "../item";
import * as S from "./styles";
import { FilterItemProps } from "@/types";
import { useCallback, useMemo, useState } from "react";
import { Accordion } from "@/components/accordion/Accordion";

interface Props {
  title: string;
  showDivisor?: boolean;
  filters: FilterItemProps[];
  handleList: (item: FilterItemProps) => void;
}

const minShow = 5;

const ContentItem = ({ filters, title, handleList, showDivisor }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const term = useMemo(() => (showAll ? "menos" : "tudo"), [showAll]);

  const handleShowMore = useCallback(() => {
    setShowAll(!showAll);
  }, [setShowAll, showAll]);

  return (
    <>
      <S.ContainerList>
        <S.TitleContainerList>{title}</S.TitleContainerList>

        {filters.slice(0, minShow).map((item, ind) => (
          <Item
            item={item}
            handleList={handleList}
            key={`category-item-${ind}`}
          />
        ))}

        <Accordion
          id=""
          noRadius
          noBodyPadding
          accordionTitle=""
          showHeader={false}
          accordionExpanded={showAll}
          setAccordionExpanded={() => {}}
        >
          {filters.slice(minShow, filters.length).map((item, ind) => (
            <Item
              item={item}
              handleList={handleList}
              key={`category-item-${ind}`}
            />
          ))}
        </Accordion>

        {filters.length > minShow && (
          <S.ShowMore
            onClick={handleShowMore}
            data-test={`filterbar-expand-button-${title}`}
          >
            {showAll ? <S.IconMinus size={20} /> : <S.IconMore size={20} />}
            Mostrar {term}
          </S.ShowMore>
        )}

        {!filters.length && <S.Empty>Nenhum item encontrado</S.Empty>}
      </S.ContainerList>

      {showDivisor && <S.Divisor />}
    </>
  );
};

export default ContentItem;
