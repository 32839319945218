import { colors } from "@/constants";
import styled, { css } from "styled-components";

export const Counter = styled.span<{
  $isactive: boolean;
  $lightmode?: boolean;
}>`
  font-size: 9px;
  font-weight: 700;
  padding: 4px 6px;
  height: fit-content;
  border-radius: 100px;
  color: ${colors.brand.white};
  background-color: ${(i) =>
    i.$isactive
      ? colors.alert.red200
      : i.$lightmode
      ? colors.gray.gray40
      : colors.grey7};
`;

export const HeaderContent = styled.div<{ $lightmode?: boolean }>`
  top: 0px;
  width: 100%;
  bottom: 0px;
  display: inline-flex;
  padding: 20px 5px 20px 5px;
  overflow-x: scroll !important;
  border-bottom: 1px solid ${colors.grey4}1A;
  background-color: ${(i) =>
    i.$lightmode ? colors.brand.white : colors.brand.grafite};
  &::-webkit-scrollbar {
    height: 0px !important;
  }
  ${(i) =>
    i.$lightmode &&
    css`
      z-index: -1;
      border-bottom: 1px solid #eeeeee1a;
      box-shadow: 0px 4px 20px 0px #00000040;
    `}
`;

export const TabHeader = styled.div<{ $active: boolean; $lightmode?: boolean }>`
  gap: 8px;
  padding: 0px 16px;
  position: relative;
  display: inline-flex;
  border-right: 1px solid
    ${(i) => (i.$lightmode ? colors.gray.gray10 : colors.grey7)};
  &:last-child {
    border-right: 0px solid ${colors.grey7};
  }
  ${(i) =>
    i.$active &&
    css`
      &:after {
        left: 50%;
        width: 40px;
        content: "";
        height: 4px;
        bottom: -16px;
        position: absolute;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
        background-color: ${colors.brand.red};
        transform: translateX(-50%) translateY(100%);
      }
    `}
`;

export const Title = styled.p<{ $isactive: boolean }>`
  font-size: 14px;
  font-weight: 400;
  flex-wrap: nowrap;
  text-align: center;
  width: max-content;
  color: ${(i) => (i.$isactive ? colors.brand.red : colors.gray.gray40)};
`;
