import * as S from "./styles";
import { Modal } from "@/components";
import ContentList from "./content-item";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Directions,
  BrandProps,
  ProductProps,
  CategoryProps,
  FilterItemProps,
} from "@/types";
import { useDbFunctions } from "@/talons";

interface FilterProps {
  categoryId?: number;
  direction: Directions;
  products?: ProductProps[];
  filters: FilterItemProps[];
  setDirection: (val: Directions) => void;
  setFilters: (val: FilterItemProps[]) => void;
  setCategories?: (val: CategoryProps[]) => void;
}

type FilterTypes = "category" | "brand";

const FilterBar = ({
  products,
  direction,
  categoryId,
  setFilters,
  setDirection,
  setCategories,
  filters = [],
}: FilterProps) => {
  const { getOnDB } = useDbFunctions();
  const isLoadedFilters = useRef(false);
  const [isOpenModal, setModalState] = useState(false);
  const [allBrands, setAllBrands] = useState([] as BrandProps[]);
  const [localFilters, setLocalFilters] = useState([] as FilterItemProps[]);
  const [allCategories, setAllCategories] = useState([] as CategoryProps[]);

  // Carregar as categorias e os filtros
  const loadFilters = useCallback(async () => {
    const listBrands = await getOnDB("marcas");
    const listCategories = await getOnDB("categorias");

    const categories =
      listCategories
        ?.map((c) => {
          const brands = products?.reduce((acc, p) => {
            if (
              p.COD_CATEGORIA === c.COD_CATEGORIA &&
              !acc.includes(p.COD_MARCA)
            ) {
              return [...acc, p.COD_MARCA];
            }

            return acc;
          }, [] as number[]);

          return { ...c, brands };
        })
        ?.sort((a, b) => {
          if (a?.DE_CATEGORIA > b?.DE_CATEGORIA) return 1;
          if (a?.DE_CATEGORIA < b?.DE_CATEGORIA) return -1;
          return 0;
        }) || [];

    const brands =
      listBrands
        ?.map((c) => {
          const categories = products?.reduce((acc, p) => {
            if (p.COD_MARCA === c.ID_MARCA && !acc.includes(p.COD_MARCA)) {
              return [...acc, p.COD_MARCA];
            }

            return acc;
          }, [] as number[]);

          return { ...c, categories };
        })
        ?.sort((a, b) => {
          if (a?.DESC_MARCA > b?.DESC_MARCA) return 1;
          if (a?.DESC_MARCA < b?.DESC_MARCA) return -1;
          return 0;
        }) || [];

    if (setCategories) setCategories(categories);

    setAllBrands(brands);
    setAllCategories(categories);
  }, [products, setCategories, getOnDB]);

  // Chamar a função de carregamento dos filtros assim que abre a tela
  useEffect(() => {
    if (!products?.length) return;
    if (allBrands?.length) return;
    if (allCategories?.length) return;
    if (isLoadedFilters.current) return;

    isLoadedFilters.current = true;

    loadFilters();
  }, [isLoadedFilters, products, allCategories, allBrands, loadFilters]);

  // Salva e remove items dos filtros selecionados
  const handleList = useCallback(
    (filtro: FilterItemProps) => {
      const { id, checked, type } = filtro;

      if (!checked) {
        let list = [...localFilters];

        if (type === "category") {
          list = localFilters.filter((f) => {
            return (
              f.type === "category" ||
              filtro.brands?.includes(f.id) ||
              (f.type === "brand" &&
                localFilters.some((l) => l.brands?.includes(f.id)))
            );
          });
        }
        setLocalFilters([...list, filtro]);
        return;
      }

      const filteredList = localFilters.filter((f) => {
        if (f.type === type && f.id === id) return false; // Marcas que devem ser removidas da lista por estar removendo uma categoria

        if (type === "category") {
          // Deve retornar todas as categorias porém filtrar as marcas
          return f.type === "category" || !filtro.brands?.includes(f.id);
        }

        return true;
      });

      setLocalFilters(filteredList);
    },
    [localFilters, setLocalFilters]
  );

  // limpar filtros
  const clearFilters = useCallback(
    () => setLocalFilters([]),
    [setLocalFilters]
  );

  // Responsável por salvar os filtros quando clicar no botão de ver resultado
  const saveFilters = useCallback(() => {
    setFilters(localFilters);
    setModalState(false);
  }, [setFilters, localFilters]);

  // Responsável realizar a contagem das listas categoria | marcas
  const mountCounter = useCallback(
    (list: any[], type: FilterTypes) => {
      if (!products?.length || !list?.length) return {};

      const idList = type === "brand" ? "ID_MARCA" : "COD_CATEGORIA";
      const idProduct = type === "brand" ? "COD_MARCA" : "COD_CATEGORIA";

      return list?.reduce((acc, c) => {
        const id = c[idList];
        const key = `${type}-${id}`;
        const count = products.filter((p) => p[idProduct] === id);
        return { ...acc, [key]: count?.length || 0 };
      }, {});
    },
    [products]
  );

  // Retorna uma estrutura de contadores produtos por categoria e produtos por marca
  const mapedCounter = useMemo(() => {
    if (!isOpenModal) return {};

    const brand = mountCounter(allBrands || [], "brand");
    const category = mountCounter(allCategories || [], "category");
    return { ...category, ...brand };
  }, [allBrands, allCategories, isOpenModal, mountCounter]);

  // Resopnsável por montar as listas mapeadas de categorias e bandeiras
  const mountList = useCallback(
    (list: any[], type: FilterTypes): FilterItemProps[] => {
      if (!list?.length) return [];

      return list.reduce((acc, c) => {
        const keyId = type === "brand" ? "ID_MARCA" : "COD_CATEGORIA";
        const id = c?.[keyId];
        const key = `${type}-${id}`;
        const count = mapedCounter?.[key] || 0;

        if (!count) return acc;

        const maped = {
          id,
          type,
          count,
          brands: c.brands,
          checked: localFilters?.some((f) => f.id === id),
          title: c?.[type === "brand" ? "DESC_MARCA" : "DE_CATEGORIA"],
        } as FilterItemProps;

        return [...acc, maped];
      }, []);
    },
    [localFilters, mapedCounter]
  );

  // Retorna as categorias mapeadas com seus contadores, status de selecionado...
  const mapedCategories: FilterItemProps[] = useMemo(() => {
    if (!isOpenModal) return [];

    const findDescendants = (items: CategoryProps[], fatherID: number) => {
      let descendants = [] as CategoryProps[];

      const findChildren = (id: number) => {
        const children = items.filter((item) => item.COD_CATEGORIA_PAI === id);
        children.forEach((child) => {
          descendants.push(child);
          findChildren(child.COD_CATEGORIA);
        });
      };

      findChildren(fatherID);
      return descendants;
    };

    const listCategories = categoryId
      ? findDescendants(allCategories || [], categoryId)
      : allCategories;

    return mountList(listCategories, "category");
  }, [allCategories, isOpenModal, categoryId, mountList]);

  // Retorna as bandeiras mapeadas com seus contadores, status de selecionado...
  const mapedBrands = useMemo(() => {
    if (!isOpenModal || !allBrands?.length || !mapedCategories?.length)
      return [];

    let list = allBrands.filter((b) =>
      mapedCategories.some((c) => c.brands?.includes(b.ID_MARCA))
    );

    if (localFilters?.length) {
      const listBrands = localFilters.reduce((acc, b) => {
        return Array.from(new Set([...acc, ...(b?.brands || [])]));
      }, [] as number[]);

      if (listBrands?.length) {
        list = list.reduce((acc, brand) => {
          const isIncluded = listBrands.includes(brand.ID_MARCA);
          if (!isIncluded) return acc;
          return [...acc, brand];
        }, [] as BrandProps[]);
      }
    }

    return mountList(list, "brand");
  }, [allBrands, isOpenModal, localFilters, mapedCategories, mountList]);

  // responsável por inserir no modal os filtros que estão no componente pai todas as vezes que o modal é aberto
  useEffect(() => {
    if (isOpenModal) return;

    setLocalFilters(filters);
  }, [isOpenModal, setLocalFilters, filters]);

  const countList = useMemo(() => {
    if (!isOpenModal || !products?.length) return 0;

    if (!localFilters?.length) return products?.length;

    const ids = localFilters.map((i) => i.id);

    return products?.filter((i) => {
      const isBrandFilter = localFilters?.some((f) => f.id === i.COD_MARCA);
      if (isBrandFilter) return true;

      const isCatFilter = localFilters?.find((f) => f.id === i.COD_CATEGORIA);

      return (
        isCatFilter && !isCatFilter?.brands?.some((item) => ids.includes(item))
      );
    })?.length;
  }, [products, localFilters, isOpenModal]);

  const isPlural = useMemo(() => (countList !== 1 ? "s" : ""), [countList]);

  return (
    <>
      <S.RowFilter>
        <S.ColLeft>
          <S.FilterButton
            onClick={() => setModalState(true)}
            data-test="filterbar-button-open-modal"
          >
            <S.ContentFilterIcon>
              <S.FilterIcon size={20} />

              {!!filters.length && (
                <S.Notification right={40}>{filters.length}</S.Notification>
              )}
            </S.ContentFilterIcon>
            Filtrar
          </S.FilterButton>
        </S.ColLeft>

        <S.ColRight>
          <S.DirectionControl>
            <S.ButtonDirection
              $active={direction === "grid"}
              data-test="filter-bar-grid-button"
              onClick={() => setDirection("grid")}
            >
              <S.GridIcon size={18} />
            </S.ButtonDirection>

            <S.ButtonDirection
              $active={direction === "list"}
              data-test="filter-bar-list-button"
              onClick={() => setDirection("list")}
            >
              <S.ListIcon size={18} />
            </S.ButtonDirection>
          </S.DirectionControl>
        </S.ColRight>
      </S.RowFilter>

      <Modal
        isLoading
        fullHeight
        id="filter-bar"
        direction="right"
        isOpen={isOpenModal}
        closeModal={setModalState}
      >
        <S.BgBody>
          <S.ModalHeader>
            <S.ArrowBackContent>
              <S.ArrowBack
                size={24}
                onClick={() => setModalState(false)}
                data-test="filterbar-button-close-modal"
              />
            </S.ArrowBackContent>

            <S.ModalTitleContent>
              <S.ModalTitle>
                Filtrar
                {!!localFilters.length && (
                  <S.Notification>{localFilters.length}</S.Notification>
                )}
              </S.ModalTitle>
            </S.ModalTitleContent>

            <S.ClearFilter
              onClick={clearFilters}
              data-test="filterbar-button-clean-filters"
            >
              Limpar
            </S.ClearFilter>

            <S.BorderedHeader />
          </S.ModalHeader>

          <S.ContentModal>
            <ContentList
              showDivisor
              title="Categorias"
              handleList={handleList}
              filters={mapedCategories}
            />

            <ContentList
              title="Marcas"
              filters={mapedBrands}
              handleList={handleList}
            />
          </S.ContentModal>

          <S.ContentFooter>
            <S.ContentCount>
              <S.Count>{countList && countList}</S.Count>
              <S.CountLabel>
                Produto{isPlural} encontrado{isPlural}
              </S.CountLabel>
            </S.ContentCount>

            <S.Button
              onClick={saveFilters}
              data-test="filterbar-button-save-filters"
            >
              Ver resultado
            </S.Button>
          </S.ContentFooter>
        </S.BgBody>
      </Modal>
    </>
  );
};

export default FilterBar;
