import styled from "styled-components";
import { colors } from "@/constants";
import { RiSearchLine, RiEqualizerLine } from "react-icons/ri";

export const SearchIcon = styled(RiSearchLine) <{ $isgray?: boolean }>`
  color: ${(i) => (i.$isgray ? colors.gray.gray40 : colors.brand.white)};
`;

export const SearchContent = styled.div`
  display: inline-flex;
  justify-content: end;
  align-items: center;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  width: 100%;
  padding: 20px;
  background-color: ${colors.brand.grafite};
  
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  flex-grow: 1;

  height: 40px;
  border-radius: 6px;
  background-color: ${colors.brand.white};

  svg{
    color: ${colors.brand.grafite} !important;
  }
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;

  width: 100%;
  
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: ${colors.gray.gray40};
`;

export const CloseSearch = styled.span`
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 500;
  line-height: 16.41px;
  color: ${colors.brand.white};
`;

export const Container = styled.div`
    width: 100%;
    padding: 12px 20px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Label = styled.span`
    font-size: 12px;
    line-height: 14.08px;
    font-weight: 400;
    color: ${colors.brand.grafite};
`;

export const FilterBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: 16px;
    background-color: ${colors.background1};
    border-radius: 6px;
    border: solid 1px ${colors.gray.gray10};
`;

export const CollapsibleFilterBox = styled.div<{ isVisible: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: ${({ isVisible }) => (isVisible ? '16px' : '0 16px')};
    background-color: ${colors.background1};
    border-radius: 6px;
    border: solid 1px ${colors.gray.gray10};

    max-height: ${({ isVisible }) => (isVisible ? '300px' : '0')}; // Controla a altura máxima
    overflow: hidden; // Esconde o conteúdo que ultrapassa o max-height
    transition: max-height 0.3s ease, padding 0.3s ease; // Animação suave
`;

export const Filter = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;

export const FilterOption = styled.div<{ isActive: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 16px;
    background-color: ${({ isActive }) => (isActive ? colors.brand.white : 'transparent')};
    box-shadow: ${({ isActive }) =>
    isActive
      ? '0px 4px 10px rgba(0, 0, 0, 0.1), 0px 6px 6px rgba(0, 0, 0, 0.05)'
      : 'none'
  };
    border-radius: 6px;

    cursor: pointer;

    svg{
        width: 16px;
        height: 16px;
        color: ${colors.brand.grafite};
    }
`;

export const FilterLabel = styled.span`
    font-size: 12px;
    font-weight: 500;
    line-height: 14.06px;
    color: ${colors.brand.grafite};
`;

export const OrdersContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
`;

export const LoaderContainer = styled.div`
    margin-top: 100px;
`;

export const PageControl = styled.div`
  padding: 20px;
`;

export const FilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 400;
`;

export const FilterButton = styled.div`
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  position: relative;
  align-items: center;
  display: inline-flex;
  color: ${colors.brand.grafite};
`;

export const FilterIcon = styled(RiEqualizerLine)`
  width: 20px;
  height: 20px;
`;

export const ClearFilter = styled.div`
  color: ${colors.red};
`;