import styled from "styled-components";
import { colors } from "../../constants";

interface Props {
  bgcolor: string[];
}

const getColor = ({ bgcolor }: Props, ind: number) =>
  bgcolor?.[ind] || bgcolor?.[0] || colors.brand.grafite;

export const Content = styled.div<Props>`
  width: 100%;
  gap: 10px;
  padding: 3px 5px;
  border-radius: 6px;
  align-items: center;
  display: inline-flex;
  background: linear-gradient(
    269.24deg,
    ${(props) => getColor(props, 0)} -0.36%,
    ${(props) => getColor(props, 1)} 99.74%
  );
`;

export const Container = styled.div`
  padding: 0px 15px;
  margin-bottom: 20px;
`;

export const Img = styled.img`
  width: 80px;
  object-fit: cover;
  margin-top: -35px;
  margin-bottom: -35px;
  filter: (0px 4px 20px 0px ${colors.shadown2});
`;

export const Text = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: ${colors.brand.white};
`;
