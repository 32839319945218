import React from 'react';
import * as S from './styles';
import { RiInboxArchiveLine } from "react-icons/ri";
import { colors } from '@/constants';

interface StockProps {
  stockLeft: number;
  height?: number;
  extraMargin?: boolean;
}

const Stock = ({ stockLeft, height, extraMargin }: StockProps) => {

  // if (!stockLeft) {
  //   return (
  //     <S.StockContainer>

  //     </S.StockContainer>
  //   )
  // }

  return (
    <S.Container $extraMargin={extraMargin} $height={height}>
      <RiInboxArchiveLine size={16} color={colors.gray.gray50} />
      <S.StockContainer>
        <S.Text $shouldShow={true}>
          Estoque
        </S.Text>
        <S.Text $weight={800} $size={11} $color={colors.brand.grafite}>
          {stockLeft}
        </S.Text>
      </S.StockContainer>
      
    </S.Container>
  )
}

export default Stock;