import { colors } from "@/constants";
import styled, { css } from "styled-components";
import {
  RiAddFill,
  RiFlag2Line,
  RiBarcodeBoxLine,
  RiArrowDownSLine,
  RiProhibitedLine,
  RiCheckboxCircleFill,
  RiCalendarScheduleLine,
  RiCheckboxBlankCircleLine,
} from "react-icons/ri";

export const Card = styled.div<{ $ischecked?: boolean }>`
  padding: 16px;
  position: relative;
  transition: all 0.5s;
  border-radius: 6px;
  margin: 0px 20px 15px 20px;
  border: 1px solid
    ${(i) => (i.$ischecked ? colors.alert.green300 : colors.gray.gray10)};
  background-color: ${(i) =>
    i.$ischecked ? colors.alert.green50 : colors.brand.white};
  ${(i) =>
    i.$ischecked &&
    css`
      box-shadow: 0px 4px 20px 0px #23a04740;
    `}
`;

export const ContentInfos = styled.div`
  gap: 16px;
  width: 100%;
  display: inline-flex;
`;

export const CheckContent = styled.div``;
export const CheboxContent = styled.div<{ $isdisabled: boolean }>`
  opacity: ${(i) => (i.$isdisabled ? 0.2 : 1)};
`;

export const CheckboxCircle = styled(RiCheckboxCircleFill)`
  color: ${colors.alert.green300};
`;

export const ProhibitedIcon = styled(RiProhibitedLine)`
  color: ${colors.brand.white};
`;

export const CheckboxBlankCircle = styled(RiCheckboxBlankCircleLine)`
  color: ${colors.gray.gray40};
`;

export const InfosContent = styled.div`
  width: 100%;
`;

export const Row = styled.div<{ $centered?: boolean; $between?: boolean }>`
  gap: 20px;
  width: 100%;
  align-items: ${(i) => (i.$centered ? "center" : "start")};
  justify-content: ${(i) => (i.$between ? "space-between" : "end")};
  display: inline-flex;
`;

export const Column = styled.div``;

export const ColumnRight = styled.div`
  flex: auto;
  text-align: right;
`;

export const CompanieName = styled.p`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  margin-bottom: 8px;
  line-height: 22.4px;
  color: ${colors.gray.gray50};
`;

export const DiscountFlex = styled.span`
  width: fit-content;
  padding: 4px;
  border-radius: 6px;
  background-color: #f2f2f2;
  font-size: 11px;
  font-weight: 700;
  margin-left: 10px;
  color: #666666;
`;

export const CNPJLabel = styled.p<{ $isdisabled?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
  color: ${colors.gray.gray40};
  opacity: ${(i) => (i.$isdisabled ? 0.5 : 1)};
`;

export const ContentAlert = styled.div<{ $showBorder?: boolean }>`
  transition: all 0.25s;
  ${(i) =>
    i.$showBorder &&
    css`
      margin-top: 10px;
      border-top: 1px dashed ${colors.gray.gray10};
    `}
`;

export const AlertMessage = styled.p<{ type: "warning" | "danger" }>`
  gap: 7px;
  width: 100%;
  font-size: 11px;
  margin-top: 10px;
  font-weight: 400;
  text-align: center;
  padding: 4px 12px;
  border-radius: 6px;
  display: inline-flex;
  justify-content: center;
  color: ${(i) =>
    i.type === "danger" ? colors.alert.red200 : colors.alert.orange300};
  border: 1px solid
    ${(i) =>
      i.type === "danger" ? colors.alert.red200 : colors.alert.orange300};
  background-color: ${(i) =>
    i.type === "danger" ? colors.alert.red50 : colors.alert.orange50};
`;

export const CalendarIcon = styled(RiCalendarScheduleLine)`
  color: ${colors.alert.red200};
`;

export const FlagIcon = styled(RiFlag2Line)`
  color: ${colors.alert.orange300};
`;

export const AlertMessageBold = styled.span`
  font-weight: 700 !important;
`;

export const FantasyName = styled.span<{ $isdisabled?: boolean }>`
  opacity: ${(i) => (i.$isdisabled ? 0.3 : 1)};
`;

export const NumberCompanie = styled.span<{ $isActive?: boolean }>`
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  margin-right: 5px;
  border-radius: 4px;
  padding: 4px 8px 4px 8px;
  color: ${colors.gray.gray60};
  background-color: ${(i) =>
    i.$isActive ? colors.alert.green200 : colors.gray.gray10};
`;

export const ArrowIcon = styled(RiArrowDownSLine)<{ $isOpened?: boolean }>`
  transition: all 0.4s;
  transform-origin: center;
  color: ${colors.gray.gray50};
  transform: rotate(${(i) => (i.$isOpened ? 180 : 0)}deg);
`;

export const BarIcon = styled(RiBarcodeBoxLine)`
  color: ${colors.gray.gray50};
`;

export const BarNotification = styled.span`
  top: -3px;
  right: -5px;
  font-size: 9px;
  min-width: 15px;
  padding: 2px 4px;
  position: absolute;
  border-radius: 50px;
  color: ${colors.brand.white};
  background: ${colors.alert.red200};
`;

export const ContentBar = styled.div`
  margin-top: 10px;
  position: relative;
`;

export const ContentProhibited = styled.div`
  padding: 4px 4px 1px 4px;
  width: fit-content;
  border-radius: 4px;
  background-color: ${colors.alert.red200};
`;

export const ArrowContent = styled.div<{ $isdisabled?: boolean }>`
  opacity: ${(i) => (i.$isdisabled ? 0.2 : 1)};
`;

export const ProgressContent = styled.div`
  gap: 5px;
  display: inline-flex;
  width: -webkit-fill-available;
`;

export const MetaLabel = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray.gray40};
`;

export const Progress = styled.div`
  width: 50px;
  height: 10px;
  position: relative;
  border-radius: 6px;
  border: 1px solid #ccc;
  background-color: #f0f0ee;
`;

export const BarProgress = styled.div`
  width: 50%;
  height: 100%;
  border-radius: 6px;
  background-color: ${colors.alert.green300};
`;

export const ProgressIcon = styled.div``;

export const AccordionInfos = styled.div`
  width: 100%;
  padding: 8px 0px;
  display: inline-flex;
  justify-content: space-evenly;
  background-color: ${colors.alert.green200};
`;

export const AccordionContent = styled.div`
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  border-radius: 6px;
`;

export const AccordionDataInfo = styled.div`
  padding: 0px 16px;
  border-right: 1px solid ${colors.grey11};
  &:last-child {
    border-right: none;
  }
`;

export const AccordionLabel = styled.p`
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  color: ${colors.gray.gray60};
`;

export const AccordionLimit = styled.p`
  font-size: 11px;
  text-align: left;
  font-weight: 700;
  color: ${colors.gray.gray60};
`;

export const RequestLimit = styled.button`
  gap: 8px;
  width: 100%;
  border: none;
  padding: 8px 0px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  color: ${colors.alert.green50};
  background-color: ${colors.alert.green300};
`;

export const IconPlus = styled(RiAddFill)`
  color: ${colors.alert.green50};
`;
