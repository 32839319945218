import { useMemo } from "react";
import { formatCurrency } from "@/shared/formatCurrency";

import * as S from "./styles";
interface PriceProps {
  value: number | string;
}

export const Price: React.FC<PriceProps> = ({ value }) => {
  const formattedPrice = useMemo(() => {
    return formatCurrency({ value });
  }, [value]);

  return (
    <S.Price>
      <span>{formattedPrice}</span>
    </S.Price>
  );
};
