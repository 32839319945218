import styled from "styled-components";
import { colors } from "@/constants";
import e from "express";

export const ContentPage = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: ${colors.brand.white};
    padding: 24px 20px;
    padding-bottom: 200px;
`;

export const InvoiceContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    padding: 20px 0;
    margin: 24px 0;
    border-top: solid 1px ${colors.background1};
    border-bottom: solid 1px ${colors.background1};
`;

export const Button = styled.div<{disabled?: boolean}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;

    height: 60px;

    background-color: ${colors.grey4};
    border-radius: 6px;

    font-size: 12px;
    font-weight: 700;
    line-height: 14.06px;
    color: ${colors.brand.grafite};

    opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};

    svg{
        width: 20px;
        height: 20px;
    }
`;

export const Timeline = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px ${colors.background1};
    padding-bottom: 28px;
`;

export const TimelineItem = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: -6px;
`;

export const TimeLineIcon = styled.div<{ isActive?: boolean }>`
    margin-top: -4px;

    svg{
        width: 24px;
        height: 24px;
        color: ${({ isActive }) => (isActive ? `${colors.green}` : `${colors.gray.gray40}`)};
    }
`;

export const DotSpace = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const TimeLineDot = styled.div<{ isActive?: boolean }>`
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .dot{
        width: ${({ isActive }) => (isActive ? '15px' : '10px')};
        height: ${({ isActive }) => (isActive ? '15px' : '10px')};
        background-color: ${({ isActive }) => (isActive ? colors.green : colors.brand.white)};
        border-radius: 50%;
        border: ${({ isActive }) => (isActive ? `solid 2px ${colors.green10}` : `solid 2px ${colors.gray.gray40}`)};
    }
`;

export const TimeLine = styled.div<{ isActive?: boolean }>`
    position: relative;
    width: 3px;
    height: 45px;
    background-color: ${({ isActive }) => (isActive ? colors.green : colors.gray.gray40)};
    top: -3px;
`


export const Detail = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`;

export const TimeLineLabel = styled.span<{ isActive?: boolean }>`
    font-size: 12px;
    font-weight: 700;
    line-height: 14.06px;
    color: ${({ isActive }) => (isActive ? colors.green : colors.gray.gray60)};
`;

export const TimeLineDate = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    color: ${colors.grey3};
`;

export const Actions = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 24px 0;

    div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        width: 100%;
        height: 35px;
        border-radius: 6px;

        font-size: 12px;
        font-weight: 700;

        svg{
            width: 16px;
            height: 16px;
        }
    }
`;

export const WishlistButton = styled.div`
    background-color: ${colors.grey4};
    color: ${colors.brand.grafite};
    border: none !important;
`;

export const RepeatOrderButton = styled.div`
    background-color: ${colors.brand.red};
    border: solid 1px;
    border-color: ${colors.brand.red} !important;
    color: ${colors.brand.white};
`;

export const ItemsQuantity = styled.div`
    padding: 10px 0;
    font-size: 14px;
    font-weight: 700;
    color: ${colors.brand.grafite};
`;

export const Products = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    border-top: solid 3px ${colors.background1};
    padding-top: 18px;

    height: 260px;
    overflow-y: scroll;
    overflow-x: hidden;
`;

export const OrderTotals = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-top: 20px;
    padding-bottom: 33px;
    border-bottom: solid 1px ${colors.background1};

    font-size: 12px;
`;

export const TotalRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const TotalInvoicedPrice = styled.span`
    font-weight: 700;
    color:${colors.brand.grafite};
`;

export const FinalPrice = styled.span`
    font-weight: 700;
    font-size: 18px;
    line-height: 21.09px;
    color:${colors.green};
`;

export const NormalLabel = styled.span`
    font-weight: 400;
    color:${colors.grey3};
`;

export const BoldLabel = styled.span`
    font-weight: 700;
    font-size: 18px;
    line-height: 21.09px;
    color:${colors.brand.grafite};
`;