import styled, { css } from "styled-components";
import { colors } from "@/constants";

export const CounterWrapper = styled.div<{ background: "light" | "white" }>`
  gap: 10px;
  display: flex;
  padding: 4px 10px;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: ${(i) =>
    i.background === "light" ? colors.gray.gray10 : colors.brand.white};
`;

export const Input = styled.input<{ showborder?: boolean }>`
  display: flex;
  color: black;
  align-items: center;
  width: 90px;
  justify-content: center;

  /* flex-grow: 1; */
  /* max-width: 200px; */
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.brand.white};
  border: solid 1px ${colors.brand.white};
  border-radius: 10px;

  font-size: 14px;
  line-height: 18.75px;
  font-weight: 500;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Remove spinners in Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &:focus {
    border: none;
    outline: none;
  }

  ${(props) =>
    props.showborder &&
    css`
      width: 40px;
      border: 1px solid ${colors.grey5};
    `}
`;

export const CounterButton = styled.button<{ $islimit?: boolean }>`
  width: 25px;
  height: 25px;
  border: none;
  display: flex;
  cursor: pointer;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  color: ${colors.brand.white};
  background-color: ${(i) => (i.$islimit ? colors.grey8 : colors.brand.red)};

  svg {
    font-size: 20px;
    color: white;
    fill: white;
    stroke: white;
  }
`;

export const Icon = styled.img``;