import * as S from './Styled';
import { colors } from '@/constants';
import { RiLock2Line, RiCheckLine } from 'react-icons/ri';

interface Props {
  ganho: number;
  checked: boolean;
  percentual: number;
  hasProgreess?: boolean;
}

const ProductivityExtractPenality = ({ checked, ganho, percentual, hasProgreess }: Props) => {
  return (
    <S.Column width={hasProgreess ? 33 : 0}>
      <S.Text left={-2} color={checked ? 'green' : 'grafite'} bold={700} size={14}>
        {percentual}%
      </S.Text>

      <S.ContentIcon>
        <S.Icon $checked={checked}>
          {checked ? <RiCheckLine size={12} color={colors.brand.white} /> : <RiLock2Line size={12} color={colors.brand.white} />}
        </S.Icon>

        {hasProgreess && <S.BarProgress $checked={checked} />}
      </S.ContentIcon>

      <S.Text color={checked ? 'green' : 'grafite'} size={12} bold={500}>
        {ganho}
      </S.Text>
    </S.Column>
  );
};

export default ProductivityExtractPenality;
