import { colors } from "@/constants";
import styled from "styled-components";

interface Props {
  isActive?: boolean;
  isComplete?: boolean;
  children?: React.ReactNode;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -4px;
  padding: 0 20px;
  background-color: ${colors.gray.gray60};
  border-radius: 0 0 6px 6px;
  box-shadow: 0 4px 10px 0 #00000026;
  position: relative;
`;

// Step layout

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex-grow: 1; */

  gap: 4px;
  padding-top: 12px;
  padding-bottom: 12px;

  position: relative;
`;

export const StepLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 12.89px;
  text-transform: capitalize;
  color: ${colors.brand.white};
`;

export const Mark: React.FC<Props> = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) =>
    props.isActive || props.isComplete
      ? `${colors.brand.red}`
      : `${colors.brand.grafite}`};
  width: ${(props) => (props.isActive || props.isComplete ? `15px` : `9px`)};
  height: ${(props) => (props.isActive || props.isComplete ? `15px` : `9px`)};

  border-radius: 50%;

  position: absolute;
  bottom: ${(props) => (props.isActive || props.isComplete ? `-6px` : `-4px`)};

  z-index: 1;

  svg {
    color: ${colors.brand.white};
    width: 11px;
    height: 11px;
  }
`;

export const Conector: React.FC<Props> = styled.div`
  min-width: 155px;
  width: 200px;
  max-width: 220px;
  flex-grow: 1;
  height: 2px;
  background-color: ${(props) =>
    props.isActive ? `${colors.brand.red}` : `${colors.brand.grafite}`};
  position: absolute;
  bottom: 0;
  left: 60%;
`;
