import { colors } from "@/constants";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const MessageWrapper = styled.div`

`;

export const MessageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export const MessageText = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.brand.grafite};
  line-height: 19.6px;
  text-align: center;
  max-width: 340px;
`;

export const MessageTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleText = styled.span`
  font-size: 18px;
  font-weight: 700;
  color: ${colors.brand.grafite};
`;

export const Icon = styled.div`
  svg {
    width: 80px;
    height: 80px;
  }
`;

export const NavigateButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Button = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 48px;

  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  color: ${colors.brand.white};
  text-decoration: none;

  &.green{
    background-color: ${colors.green};
  }

  &.dark{
    background-color: ${colors.brand.grafite};
  }

`;

export const OrderLink = styled.button`
  width: 100%;
  height: 45px;
  border: none;

  background-color: ${colors.green};
  color: ${colors.brand.white};

  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;

  cursor: pointer;
`;
