import styled from "styled-components";
import { colors } from "@/constants";

export const ProductCard = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 12px 0;

    border-bottom: solid 1px ${colors.background1};
`;

export const ProductImage = styled.div`
    width: 58px;
    height: 58px;
  

    img{
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
    }
`;

export const ProductInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
`;

export const ProductName = styled.div`
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    color: ${colors.brand.grafite};
    margin-bottom: 4px;
`;

export const PackageInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    width: fit-content;
    height: 25px;
    padding: 0 8px;
    background-color: ${colors.grey4};
    border-radius: 3px;
`;

export const PackingName = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: ${colors.gray.gray60};
`;

export const PackingIcon = styled.img`
    width: 15px;
    height: 15px;
`;

export const PackingQuantity = styled.span`
    font-size: 11px;
    font-weight: 400;
    color: ${colors.grey3};
`;

export const ProductDetails = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    row-gap: 12px;
`;

export const ProductDetailsItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const Label = styled.span`
    font-size: 11px;
    font-weight: 500;
    color: ${colors.brand.grafite};
`;

export const Value = styled.span`
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    font-size: 11px;
    line-height: 12.89px;
    color: ${colors.grey3};
    font-weight: 400;
`;

export const ProductQuantity = styled.div`
    width: 25px;
    height: 25px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 12px;
    font-weight: 700;
    line-height: 14.06px;
    color: ${colors.brand.grafite};

    background-color: ${colors.grey4};
    border-radius: 3px;
`;

export const ProductPrice = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    font-size: 14px;
    font-weight: 800;
    line-height: 16.41px;
    color: ${colors.brand.grafite};
`;
