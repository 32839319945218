import React from 'react';
import * as S from './styles';

interface HeaderProps {
  clientRefNumber: string;
  clientName: string;
}

const Header = ({ clientRefNumber, clientName }: HeaderProps) => {
  return (
    <S.HeaderContainer>
      <S.HeadingContainer>
        <S.Tag>{clientRefNumber}</S.Tag>
        <S.Title>{clientName}</S.Title>
      </S.HeadingContainer>
    </S.HeaderContainer>
  )
}

export default Header;
