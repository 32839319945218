import React, { Dispatch, SetStateAction } from 'react';
import * as S from './styles';
// import TruckSVG from '@/assets/svgs/truck-white.svg';
import { RiListCheck2, RiSearch2Line } from "react-icons/ri";
import { BiTrash } from "react-icons/bi";
import { colors } from '@/constants';
import { useCart } from '@/hooks';

const TruckSVG = `${process.env.PUBLIC_URL}/truck-white.svg`;

interface CartMenuProps {
  cartItemsCount: number;
  setIsSearching: Dispatch<SetStateAction<boolean>>;
  handleRemoveAll: () => void;
  handleCreateListFromCart: () => void;
}

const CartMenu = ({
  cartItemsCount,
  handleRemoveAll,
  setIsSearching,
  handleCreateListFromCart,
}: CartMenuProps) => {

  const { isSearching } = useCart();

  return (
    isSearching ? (
      <S.HeaderContainer>
        <S.SearchHeading>
          Pesquisar produto no carrinho
        </S.SearchHeading>
      </S.HeaderContainer>
    ) : (
    <S.HeaderContainer>
      <S.Subcontainer>
      <S.IconContainer>
          <S.Icon src={TruckSVG} />
        </S.IconContainer>
        <S.TitleContainer>
          <S.Title>Carrinho</S.Title>
          {cartItemsCount > 0 ? (
            <S.CartItemsCount>
              {`(${cartItemsCount} ${cartItemsCount > 1 ? 'itens' : 'item'})`}
            </S.CartItemsCount>
          ) : null}
        </S.TitleContainer>
      </S.Subcontainer>
      <S.ButtonContainer>
        <S.IconButton data-test="cart-menu-open-cart-search" $isfirst={true} onClick={() => setIsSearching(prev => !prev)}>
          <RiSearch2Line color={colors.gray.gray40} size={20} />
        </S.IconButton>
        <S.Divider />
        {/* <S.IconButton data-test="cart-menu-handle-create-list" onClick={handleCreateListFromCart}>
          <RiListCheck2 color={colors.gray.gray40} size={20} />
        </S.IconButton> */}
        {/* <S.Divider /> */}
        <S.IconButton data-test="cart-menu-clear-cart" onClick={handleRemoveAll}>
          <BiTrash color={colors.gray.gray40} size={20} />
        </S.IconButton>
      </S.ButtonContainer>
    </S.HeaderContainer>
    )
  )
}

export default CartMenu;