import styled from "styled-components";
import { colors } from "../../constants";

export const Div = styled.div`
  width: 100%;
`;

export const Br = styled.br``;

export const Emphasis = styled.span`
  font-weight: 600;
  text-decoration: underline;
  margin: 0px 5px;
`;

export const Paragraph = styled.p`
  text-align: center;
  width: -webkit-fill-available;
`;

export const Shadown = styled.div`
  top: 105px;
  z-index: 9;
  position: sticky;
  margin-left: -10px;
  margin-right: -10px;
  margin-bottom: -10px;
  box-shadow: 0px 0px 3px 4px ${colors.shadown3};
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const Timer = styled.div`
  padding: 5px 10px;
  border-radius: 6px;
  align-items: center;
  display: inline-flex;
  background-color: red;
  justify-content: space-between;
`;

export const OfferText = styled.div<{ margin?: string }>`
  flex: 1;
  font-size: 12px;
  margin-block-end: ${({ margin }) => (!margin ? "1" : margin)}em;
  margin-block-start: ${({ margin }) => (!margin ? "1" : margin)}em;
  color: ${colors.brand.white};
`;

export const OfferSpan = styled.span`
  font-weight: 700;
`;

export const SpaceBetween = styled.div`
  width: 100%;
  align-items: center;
  margin-bottom: 5px;
  display: inline-flex;
  justify-content: space-between;
`;

export const LoadMore = styled.button`
  border-radius: 4px;
  padding: 5px 10px;
  color: ${colors.brand.white};
  background-color: ${colors.brand.grafite};
`;

export const ImgLoading = styled.img`
  width: 40px;
`;

export const ImgIcon = styled.img``;

export const Loading = styled.div`
  text-align: center;
`;

export const LoadingText = styled.p`
  color: ${colors.grey2};
`;

export const Filters = styled.div`
  top: 50px;
  z-index: 9;
  position: sticky;
  background: ${colors.background2};
`;

export const ContentList = styled.div`
  gap: 10px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 5px));
`;

export const ProductsList = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;
  position: relative;
  padding-bottom: 50px;
`;

export const BtnAdd = styled.div`
  width: 100%;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  line-height: normal;
  cursor: pointer;
  transition: all 0.15s ease 0s;
  padding: 10px 25px;
  background: ${colors.brand.red};
  color: ${colors.brand.white};
  border: none;
`;

export const ConditionSelect = styled.div`
  max-width: 200px;
`;

export const VerticalBannerContent = styled.div`
  height: 60px;
  padding: 0px 20px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-color: ${colors.brand.white};
`;

export const ContentBanner = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 5px;
  margin-top: 25px;
`;

export const ContentCategory = styled.div`
  display: inline-flex;
  gap: 14px;
  padding-right: 15px;
  padding-left: 15px;
`;

export const ContainerCategory = styled.div`
  &::-webkit-scrollbar {
    height: 0px !important;
  }
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 15px;
`;

export const BoxCategoryImg = styled.div`
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
`;

export const CategoryImg = styled.img`
  width: 100%;
  object-fit: contain;
`;

export const CategoryDescription = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

export const BgBanner = styled.div`
  border-radius: 6px;
  overflow: hidden;
  background-color: ${colors.brand.white};
`;

export const Container = styled.div`
  &::-webkit-scrollbar {
    width: 0px !important;
  }
  height: 100vh;
  overflow-x: hidden;
  padding-bottom: 25px;
  background: ${colors.background2};
`;

export const ProductContainer = styled.div`
  height: 100%;
  margin-left: 15px;
`;
