import { useState, useEffect, useCallback } from "react";
import { useUserContext, useGlobals } from "@/hooks"
import { useMutation } from "@apollo/client";
import DEFAULT_OPERATIONS from "./resetPassword.gql";

interface ErrorControl {
    error: boolean;
    message?: string;
}

export const useResetPassword = () => {
    const { email } = useUserContext();
    const { setIsOpenResetPasswordModal, isOpenedResetPasswordModal } = useGlobals();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(email || "");
    const [emailValid, setEmailValid] = useState<boolean>(true);

    const [successReset, setSuccessReset] = useState<boolean>(false);

    const [isError, setIsError] = useState<boolean>(false);
    const [resetError, setResetError] = useState<ErrorControl | null>(null);

    const { requestPasswordResetMutation } = DEFAULT_OPERATIONS;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const [requestNewPassword, { loading, error, data, called }] = useMutation(
        requestPasswordResetMutation,
        {
            fetchPolicy: 'no-cache',
        },
    );

    useEffect(() => {
        if (!inputValue && email) {
            setInputValue(email);
        }
    }, [email]);

    const handleEmailChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (resetError) {
                setResetError(null);
                setIsError(false);
            }
            const value = event.target.value.trim();
            setInputValue(value);
            if (emailRegex.test(value)) {
                setEmailValid(true);
            } else {
                setEmailValid(false);
            }
        },
        [emailRegex]
    );

    const handleRequestResetPassword = useCallback(async (email: string) => {
        try {
            setIsLoading(true);
            await requestNewPassword({ variables: { email } });
            setSuccessReset(true);
        } catch (e) {
            setIsError(true);
            setSuccessReset(false);
            setResetError({
                error: true,
                message: "Verifique seus dados e tente novamente!",
            });
        } finally {
            setIsLoading(false);
        }
    }, []);

    const redirectLogin = () => {
        setIsOpenResetPasswordModal(false);
        setSuccessReset(false);
        setIsError(false);
        setResetError(null);
    }

    return {
        isLoading,
        email,
        handleEmailChange,
        inputValue,
        emailValid,
        isError,
        resetError,
        successReset,
        setSuccessReset,
        handleRequestResetPassword,
        setIsOpenResetPasswordModal,
        isOpenedResetPasswordModal,
        redirectLogin
    }
}