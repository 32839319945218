import { useState, useEffect, useCallback } from "react";
import { useClient, useUserContext } from "@/hooks";
import { useQuery, useLazyQuery, from } from "@apollo/client";

import DEFAULT_OPERATIONS from "./myOrdersPage.gql";
import { useMemo } from "react";

export const useMyOrdersPage = () => {
    const { selectedClient } = useClient();
    const { getCustomer } = useUserContext();

    const [customer, setCustomer] = useState(null);
    const { NR_DOCUMENTO: taxvat } = selectedClient;

    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [dateFilter, setDateFilter] = useState<Date | null>(null);

    const { getRcaOrdersQuery, getOrdersDateQuery } = DEFAULT_OPERATIONS;

    useEffect(() => {
        if (taxvat) {
            getCustomer({
                variables: {
                    taxvat
                }
            }).then((response: any) => {
                if (response?.data) {
                    setCustomer(response.data.customer);
                }
            }).catch((error: any) => {
                console.error("Erro ao buscar o cliente", error);
            });
        }
    }, [taxvat, getCustomer]);

    const formattedDateFilter = useMemo(() => {
        if (!dateFilter) return null;
        return dateFilter.toISOString().split('T')[0];
    }, [dateFilter])

    const [loadRcaOrders, { called, loading, data }] = useLazyQuery(getRcaOrdersQuery, {
        variables: {
            taxvat,
            pageSize: pageSize,
            currentPage: currentPage,
            ...(formattedDateFilter && {
                createdAt: {
                    from: `${formattedDateFilter} 00:00:00`,
                    to: `${formattedDateFilter} 23:59:59`
                }
            })
        },
        fetchPolicy: "network-only"
    });

    const [loadOrderDates, { data: dataOrdersDate, called: calledOrderDate }] = useLazyQuery(getOrdersDateQuery, {
        fetchPolicy: "network-only"
    });

    useEffect(() => {
        if (taxvat && customer) {
            loadRcaOrders();
        }
    }, [taxvat, customer, loadRcaOrders, currentPage]);

    const totalPages = useMemo(() => {
        if (!data) return 0;
        return data?.customer?.rcaOrders?.page_info.total_pages;
    }, [data]);

    useEffect(() => {
        if (data && !calledOrderDate && totalPages != 0) {
            loadOrderDates({
                variables: {
                    taxvat,
                    pageSize: pageSize * totalPages
                }
            });
        }
    }, [data, totalPages]);

    const { rcaOrders, ecommerceOrders } = useMemo(() => {
        if (!data) return { rcaOrders: [], ecommerceOrders: [] };

        const allOrders = data.customer?.rcaOrders?.items || [];

        const rcaOrders = allOrders.filter((order: any) => order.gp_order_ext.origin === "RCA" || order.gp_order_ext.origin === "BateForte_web_mobile_rca");
        const ecommerceOrders = allOrders.filter((order: any) => order.gp_order_ext.origin === "ECOMMERCE");

        return { rcaOrders, ecommerceOrders };
    }, [data]);

    const loadingPage = loading || !data;

    const handlePagination = useCallback(
        (page: number) => {
            if (page && currentPage) {
                setCurrentPage(page);
            }
        },
        [currentPage]
    );

    const orderDates = useMemo(() => {
        if (!dataOrdersDate) return [];
        return dataOrdersDate?.customer?.rcaOrders?.items.map((order: any) => {
            const orderDate = new Date(order.order_date);
            if (!isNaN(orderDate.getTime())) {
                return orderDate;
            }
            return null; // ou lidar com datas inválidas de alguma forma
        }).filter((date: any) => date !== null); // remove null dates
    }, [dataOrdersDate]);

    return {
        selectedClient,
        rcaOrders,
        ecommerceOrders,
        loading: loadingPage,
        totalPages,
        currentPage,
        handlePagination,
        orderDates,
        setDateFilter,
        dateFilter
    }
}