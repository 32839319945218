import * as S from "./styles";
import { useMemo } from "react";
import { ToolTip } from "@/components";
import { formatCurrency } from '@/shared';
import { useCartTotals, useUserContext } from "@/hooks";
import { RiCheckFill } from "react-icons/ri";
import OrderNumber from "./orderNumber/orderNumber";
import OrderResume from "./orderResume/orderResume";
import Shipping from "./shippingInformation/Shipping";
import PaymentMethod from "./paymentMethod/PaymentMethod";
import CheckoutHeader from "@/components/checkoutHeader/CheckoutHeader";
import { useCheckoutPage } from "@/talons/checkoutPage/useCheckoutPage";

interface CheckoutPageProps {}

const CheckoutPage: React.FC<CheckoutPageProps> = () => {
  const { valorMinimo } = useUserContext();
  const { fetchTotalPriceByPaymentCode } = useCartTotals();

  const {
    total,
    disabled,
    cartItems,
    erpNumber,
    conditions,
    productList,
    paymentCode,
    shippingData,
    creditLimitExceeded,
    setErpNumber,
    checkoutOrder,
    setPaymentCode,
  } = useCheckoutPage();
  const hasMin = useMemo(() => {
    return total >= valorMinimo
  }, [total, valorMinimo])

  const filteredConditions = useMemo(() => {
    return conditions?.filter((c) => fetchTotalPriceByPaymentCode(c.cod_condicao))
  }, [conditions, fetchTotalPriceByPaymentCode])

  return (
    <S.CheckoutPage>
      <CheckoutHeader pageName="Pagamento" key="pagamento" />
      <S.CheckoutPageWrapper>
        <S.PaymentWrapper>
          <S.DefaultLabel>Pagamento</S.DefaultLabel>
          <PaymentMethod
            paymentCode={paymentCode}
            conditions={filteredConditions}
            setPaymentCode={setPaymentCode}
          />
        </S.PaymentWrapper>
        <S.ShippingWrapper>
          <S.DefaultLabel>Entrega</S.DefaultLabel>
          <Shipping shippingData={shippingData} />
        </S.ShippingWrapper>
        <S.OrderNumberWrapper>
          <S.DefaultLabel>Número do pedido</S.DefaultLabel>
          <OrderNumber
            erpNumber={erpNumber}
            setErpNumber={setErpNumber}
          />
        </S.OrderNumberWrapper>
        <S.OrderResumeWrapper>
          <OrderResume cartItems={cartItems} productList={productList} />
        </S.OrderResumeWrapper>
      </S.CheckoutPageWrapper>
      <S.PlaceOrder>
        <ToolTip 
          direction="top"
          displayMode="default"
          text={hasMin ? "Valor total da compra excedendo o limite de crédito." : `Pedido mínimo de ${formatCurrency({ value: valorMinimo })}`}
          show={creditLimitExceeded || !hasMin}
        >
          <S.PlaceOrderButton data-test="checkout-button" onClick={() => checkoutOrder()} disabled={disabled || !hasMin}>
            <S.ButtonIcon>
              <RiCheckFill />
            </S.ButtonIcon>
            <span>Finalizar pedido</span>
          </S.PlaceOrderButton>
        </ToolTip>

      </S.PlaceOrder>
    </S.CheckoutPage>
  );
};

export default CheckoutPage;
