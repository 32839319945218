import * as S from './styles';
import { UseEffect } from '@sentry/react/build/types/types';

import { TextInput } from '@/components';
import { useEffect } from 'react';

interface PersonalDataProps {
    userPersonalData: {
        userFullName: string;
        fullNameIsValid: boolean;
        userCPF: string;
        userPhone: string;
        handleUserFullName: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleUserCPF: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleUserPhone: (event: React.ChangeEvent<HTMLInputElement>) => void;
    },
    setNextStepDisabled: (disabled: boolean) => void;
}

export const PersonalData = ({ userPersonalData, setNextStepDisabled }: PersonalDataProps) => {
    const { userFullName, userCPF, userPhone, handleUserFullName, handleUserCPF, handleUserPhone, fullNameIsValid } = userPersonalData;

    useEffect(() => {
        if (userFullName && fullNameIsValid && userCPF && userPhone) {
            setNextStepDisabled(false);
        } else {
            setNextStepDisabled(true);
        }
    }, [userFullName, fullNameIsValid, userCPF, userPhone]);

    return (
        <S.Container>
            <S.Label>Preencha com seu nome completo, CPF e telefone conforme registrado no seu documento.</S.Label>
            <S.Form>
                <TextInput
                    value={userFullName}
                    onChange={handleUserFullName}
                    type="text"
                    fillwidth
                    id={'inputFullName'}
                    name={'inputFullName'}
                    label={userFullName ? 'Nome completo' : 'Digite seu nome completo'}
                    data-test="cadastro-input-fullname"
                    required={true}
                    maxLenght={100}
                    success={fullNameIsValid}
                />
                <S.Field>
                    <TextInput
                        value={userCPF}
                        onChange={handleUserCPF}
                        type="text"
                        fillwidth
                        id={'inputCPF'}
                        name={'inputCPF'}
                        label={userCPF ? 'CPF' : 'Digite seu CPF'}
                        data-test="cadastro-input-cpf"
                        required={true}
                        maxLenght={14}
                        success={userCPF ? true : false}
                    />
                </S.Field>
                <S.Field>
                    <TextInput
                        value={userPhone}
                        onChange={handleUserPhone}
                        type="text"
                        fillwidth
                        id={'inputPhone'}
                        name={'inputPhone'}
                        label={userPhone ? 'Telefone' : 'Digite seu Whatsapp'}
                        data-test="cadastro-input-phone"
                        required={true}
                        maxLenght={15}
                        success={userPhone ? true : false} />
                </S.Field>

            </S.Form>
        </S.Container>
    )
}