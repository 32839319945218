import * as S from "./styles";
import { colors } from "@/constants";
import Chart, { Props as ChartProps } from "react-apexcharts";

interface Props {
  data: number[];
  height?: number;
  width?: number | string;
  theme?: "red" | "green" | "orange";
}

const themes = {
  red: colors.brand.red,
  green: colors.alert.green300,
  orange: colors.alert.orange300,
};

function BarChart() {
  const series = [{
    data: [1800, 800, 1900, 1000, 2500, 500, 1600]
  }]

  const options = {
    chart: {
      offsetX: 0,
      offsetY: 0,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    // colors: ["#dff8e6"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        columnWidth: '55%',
        // endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['#dff8e6']
    },
    xaxis: {
      categories: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL'],
    },
    yaxis: {
      categories: ['0', '1K', '2K', '3K']
      // title: {
      //   text: '$ (thousands)'
      // }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function (val : number) {
          return "R$ " + val
        }
      },
      x: {
        show: false
      }
    }
  } as ChartProps;

  return (
      <Chart
        type="bar"
        height={167}
        options={options}
        series={series}
        width={321 || "100%"}
      />
  );
}

export default BarChart;
