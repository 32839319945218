import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";

import * as S from "./styles";
import { useMemo } from "react";
import { BannerProps } from "@/types";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectCards } from "swiper/modules";
import type { PaginationOptions } from "swiper/types/modules/pagination";

interface Props {
  slides?: BannerProps[] | any[];

  id: string;
  className?: "promotional" | "fastWay" | "";

  dots?: boolean;
  isBanner?: boolean;
  isGallery?: boolean;
  autoplay?: boolean;
  vertical?: boolean;
  canSwipe?: boolean;
  infinite?: boolean;

  marginBottom?: number;
  transitionDuration?: number;
  slidesToShow?: number | "auto";

  openFullScreen?: any;
  closeFullScreen?: any;

  disableVerticalScroll?: () => {};
}

const Search = ({
  id,
  slides,

  dots = false,
  autoplay = false,
  isBanner = false,
  isGallery = false,
  infinite = false,
  canSwipe = false,
  vertical = false,

  className = "",
  marginBottom = 20,
  slidesToShow = 1,
  transitionDuration = 500,

  openFullScreen,
}: Props) => {
  const pagination = {
    enabled: dots && true,
    clickable: dots && true,
    renderBullet: (_, name) => `<span class="${name}"></span>`,
  } as PaginationOptions;

  const isValidHttpUrl = (str: string) => {
    let url;

    try {
      url = new URL(str);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  };

  const prepareComponents = useMemo(
    () =>
      slides?.map((slide, ind) => {
        if (isBanner) {
          const isExternal = isValidHttpUrl(slide?.LINK);
          return (
            <SwiperSlide key={`slider-${id}-${ind}`}>
              <S.ContentBanner>
                {isExternal ? (
                  <S.LinkHref
                    href={slide?.LINK}
                    data-test={`component-slider-link-${slide.LABEL}`}
                  >
                    <S.Banner src={slide.IMAGE} alt={slide.LABEL} />
                  </S.LinkHref>
                ) : (
                  <S.RouteLink
                    to={slide?.LINK}
                    data-test={`component-slider-link-${slide.LABEL}`}
                  >
                    <S.Banner src={slide.IMAGE} alt={slide.LABEL} />
                  </S.RouteLink>
                )}
              </S.ContentBanner>
            </SwiperSlide>
          );
        } else if (isGallery) {
          return (
            <SwiperSlide key={`slider-${id}-${ind}`}>
              <S.GalleryItem onClick={() => openFullScreen(slide.IMAGE)}>
                <img src={slide.IMAGE} alt={slide.LABEL} />
              </S.GalleryItem>
            </SwiperSlide>
          );
        } else {
          return <SwiperSlide key={`slider-${id}-${ind}`}>{slide}</SwiperSlide>;
        }
      }) || [],
    [isBanner, isGallery, slides, id, openFullScreen]
  );

  if (!prepareComponents.length) return null;

  return (
    <div style={{ marginBottom }}>
      <Swiper
        loop={infinite}
        autoplay={autoplay}
        className={className}
        pagination={pagination}
        allowTouchMove={canSwipe}
        speed={transitionDuration}
        slidesPerView={slidesToShow}
        modules={[Pagination, Autoplay, EffectCards]}
        direction={vertical ? "vertical" : "horizontal"}
      >
        {prepareComponents}
      </Swiper>
    </div>
  );
};

export default Search;
