import * as S from './styles';
import { RiSignalWifiOffLine } from "react-icons/ri";

const OfflinePage = () => {

    return (
        <S.OfflinePageContainer>
            <S.OfflinePageContent>
                <S.OfflinePageIcon>
                    <RiSignalWifiOffLine size={100} color="#000" />
                </S.OfflinePageIcon>
                <S.Title>
                    Ops! Parece que você está offline
                </S.Title>
                <S.Description>
                    Para acessar esse conteúdo, você precisa estar conectado à internet.
                </S.Description>
            </S.OfflinePageContent>
        </S.OfflinePageContainer>
    )
}

export default OfflinePage;