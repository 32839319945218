import { colors } from '@/constants';
import { css, styled } from 'styled-components';

export const Container = styled.div<{ $height?: number; $extraMargin?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid ${colors.gray.gray10};
  padding: 4px 6px;
  width: 100%;
  ${props => props.$extraMargin && css`
    margin-bottom: 6px;
  `}
  ${props => props.$height && css`
    height: ${props.$height}px;
  `}
  background-color: ${colors.brand.white};
`;

export const StockContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

export const Text = styled.div<{ $size?: number; $weight?: number; $color?: string; $shouldShow?: boolean }>`
  font-size: ${props => props.$size || 10}px;
  font-weight: ${props => props.$weight || 600};
  color: ${props => props.$color || colors.gray.gray50};
  line-height: 12px;
  &::after {
    ${props => props.$shouldShow && css`
      content: ': ';
    `}
  }
`;
