import * as S from './styles';

import StepperContainer from './stepper/StepperContainer';

import { RiArrowLeftLine } from "react-icons/ri";

interface ReducedHeaderProps {
    pageName: string,
    icon?: React.ReactNode,
    key: string
}

const CheckoutHeader: React.FC<ReducedHeaderProps> = () => {
    return (
        <StepperContainer />
    )
}

export default CheckoutHeader;
