import * as S from './Styled';
import GraphicUp from '@/assets/svgs/graphic-up.svg';

import Card from './card';
import Graphics from './graphics';
import CardPenalty from './card-penalty';

const ProductivityExtract = () => {
  return (
    <S.Container className="productivity-extract">
      <S.BgDark>
        <S.Row>
          <S.ColBgDark>ZNV 607 01 MS 06</S.ColBgDark>

          <S.ColBgDark $hasbg>4650</S.ColBgDark>
        </S.Row>

        <S.Row>
          <S.ColBgDark>Fátima do Sul / Centro</S.ColBgDark>
          <S.ColBgDark>PJ. Carlos BB</S.ColBgDark>
        </S.Row>

        <Graphics progress={33} />
      </S.BgDark>

      <S.Content>
        <S.Message>
          <S.UpContentMessage>
            <S.GraphicIconContent>
              <S.GraphicUpImg src={GraphicUp} alt="Icone de Gráfico" />
            </S.GraphicIconContent>

            <S.GraphicIconMessage>
              Se for faturado por dia <S.Enphasis>R$ 48.899</S.Enphasis>, nos próximos 7 dias, você atingirá sua meta!
            </S.GraphicIconMessage>
          </S.UpContentMessage>

          <S.DownContentMessage>
            <S.LeftDays>15 dias</S.LeftDays>
            <S.RightDays>7 dias</S.RightDays>
          </S.DownContentMessage>
        </S.Message>

        <S.Row>
          <S.Text textalign="left">Início</S.Text>
          <S.Text textalign="right">22 dias</S.Text>
        </S.Row>

        <Card
          title="Meta de Faturamento"
          meta={-3}
          ganho={99}
          premio={450}
          atingido={99}
          apuracao={723.68}
          metaPrice={723.68}
          meta1={{ checked: true, ganho: 260 }}
          meta2={{ checked: false, ganho: 340 }}
          meta3={{ checked: false, ganho: 400 }}
        />

        <Card
          title="Positivação de clientes"
          meta={-3}
          ganho={99}
          premio={450}
          atingido={99}
          apuracao={723.68}
          metaPrice={723.68}
          meta1={{ checked: true, ganho: 260 }}
          meta2={{ checked: false, ganho: 340 }}
          meta3={{ checked: false, ganho: 400 }}
        />

        <Card
          title="Média de positivação por dia"
          meta={-3}
          ganho={99}
          premio={450}
          atingido={99}
          apuracao={723.68}
          metaPrice={723.68}
          meta1={{ checked: true, ganho: 260 }}
          meta2={{ checked: false, ganho: 340 }}
          meta3={{ checked: false, ganho: 400 }}
        />

        <Card
          title="Participação do bazar"
          meta={-3}
          ganho={99}
          premio={450}
          atingido={99}
          apuracao={723.68}
          metaPrice={723.68}
          meta1={{ checked: true, ganho: 260 }}
          meta2={{ checked: false, ganho: 340 }}
          meta3={{ checked: false, ganho: 400 }}
        />

        <S.SubTotal>
          Subtotal <S.Span>R$ 3.256,57</S.Span>
        </S.SubTotal>
      </S.Content>

      <S.Content $isDanger>
        <S.Title>Penalidades</S.Title>

        <CardPenalty apuracao={12492} atingido={99} desconto={-10} title="Participação no Bazar" valorDesconto={325.66} />
        <CardPenalty apuracao={12492} atingido={99} desconto={-10} title="Items por cliente" valorDesconto={325.66} />

        <S.Row>
          <S.Text color="grafite" bold={500} size={14} textalign="left">
            Total de produtividade
          </S.Text>
          <S.Text color="grafite" bold={700} size={14} textalign="right">
            R$ 2.605,25
          </S.Text>
        </S.Row>

        <S.Separator />

        <S.Row>
          <S.Text color="grafite" bold={500} size={14} textalign="left">
            Desconto do Frete
          </S.Text>
          <S.Text color="red" bold={700} size={14} textalign="right">
            -R$ 75,00
          </S.Text>
        </S.Row>
      </S.Content>

      <S.FloatMessage>
        <S.Text marginbottom={15} color="grafite" bold={500} size={14} textalign="right">
          Total a Receber <S.Total>R$ 2.530,25</S.Total>
        </S.Text>

        <S.Text paddingleft="30%" color="grafite" bold={400} size={12} textalign="right">
          *Os valores são meramente informativos, podendo variar até o fim do período de apuração.
        </S.Text>
      </S.FloatMessage>
    </S.Container>
  );
};

export default ProductivityExtract;
