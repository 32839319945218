import { colors } from "@/constants";
import styled from "styled-components";

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 12px;
  gap: 12px;
`;

export const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-family: Roboto, sans-serif;
  color: ${colors.grey3};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const Date = styled.span<{ color?: string }>`
  color: ${(props) =>  props.color || colors.brand.grafite};
  font-weight: 700;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 16px;
`;
