import { colors } from "@/constants";
import styled from "styled-components";
import { LuSearch } from "react-icons/lu";
import { HiOutlineMicrophone } from "react-icons/hi";
import { MdOutlinePhotoCamera } from "react-icons/md";

interface PropsIcon {
  margintop?: number;
  marginleft?: number;
}

export const Search = styled(LuSearch)``;

export const OutlineMicrophone = styled(HiOutlineMicrophone)``;

export const OutlinePhotoCamera = styled(MdOutlinePhotoCamera)``;

export const Input = styled.input`
  &::placeholder {
    font-size: 14px;
  }
  width: 100%;
  border: none;
  outline: none;
`;

export const Icon = styled.img<PropsIcon>`
  margin-top: ${(props) => props.margintop || 0}px;
  margin-left: ${(props) => props.marginleft || 0}px;
`;

export const Button = styled.button`
  padding: 0px;
  border: none;
  display: grid;
  place-items: center;
  grid-auto-flow: column;
  background-color: ${colors.transparent};
`;

export const InputContent = styled.div`
  gap: 10px;
  width: 100%;
  transition: all 0.4s;
  height: 44px;
  display: flex;
  background: ${colors.brand.white};
  border-radius: 4px;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
