const PaymentTypes = {
  "7": {
    code: 7,
    label: 'Pagamento para 7 dias'
  },
  "14": {
    code: 14,
    label: 'Pagamento para 14 dias'
  },
  "21": {
    code: 21,
    label: 'Pagamento para 21 dias'
  },
  "28": {
    code: 28,
    label: 'Pagamento para 28 dias',
  },
  "35": {
    code: 35,
    label: 'Pagamento para 35 dias'
  },
  "114": {
    code: 114,
    label: 'Pagamento em 3x - 7, 14 e 21 dias (preço 14 dias)'
  },
  "121":  {
    code: 121,
    label: 'Pagamento em 3x - 14, 21 e 28 dias (preço 21 dias)'
  },
  "128":  {
    code: 128,
    label: 'Pagamento em 3x - 21, 28 e 35 dias (preço 28 dias)'
  },
  "135":  {
    code: 135,
    label: 'Pagamento em 3x - 28, 35 e 42 dias (preço 35 dias)'
  },
}
export default PaymentTypes;
