import { colors } from "@/constants";
import styled from "styled-components";

export const HeaderContainer = styled.span`
  display: flex;
  flex-direction: row;
  padding: 12px;
  gap: 4px;
  background-color: ${colors.grey4};
`;

export const Title = styled.span`
  font-weight: 500;
  color: ${colors.brand.grafite};
  font-size: 14px;
  margin-left: 2px;
  line-height: 19.6px;
  font-family: Roboto, sans-serif;
`;

export const HeadingContainer = styled.span`
`;

export const Tag = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 10px;
  max-height: 15px;
  background-color: ${colors.brand.white};
  border-radius: 4px;
  padding: 2px 8px;
  font-weight: 600;
  color: ${colors.brand.grafite};
  text-align: center;
`;
