import * as S from "./styles";
import { colors } from "@/constants";
import { useGlobals, useCartTotals, useCart } from "@/hooks";
import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa6";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Modal, SearchField, ModalListCompanies } from "@/components";
import { formatCurrency } from "@/shared";

interface Props {
  id: string;
  value: string;
  showUser?: boolean;
  showMicrophone?: boolean;
  showBackButton?: boolean;
  showPaymentMode?: boolean;
  showCancelButton?: boolean;
  onFocus?: (s: any) => void;
  setFilter: (s: any) => void;
  showCamera?: boolean;
  placeholder?: string;
  boxShadow?: string;
  padding?: string;
}

const SearchView = ({
  id,
  value,
  showUser,
  showBackButton,
  showPaymentMode,
  showCancelButton,
  onFocus,
  setFilter,
  showMicrophone,
  showCamera,
  placeholder,
}: Props) => {
  const navigation = useNavigate();
  const { totalQuantity } = useCart();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { fetchTotalPriceByPaymentCode } = useCartTotals();
  const { paymentCode, setPaymentCode, listPaymentCode } = useGlobals();
  const [selectedPaymentCode, setSelectedPaymentCode] = useState(paymentCode);

  const currentPayment = useMemo(() => {
    return listPaymentCode?.find(
      ({ cod_condicao }) => cod_condicao === paymentCode
    )?.desc_condicao;
  }, [listPaymentCode, paymentCode]);

  const setGlobalPaymentCode = useCallback(() => {
    setPaymentCode(selectedPaymentCode);
    setIsOpenModal(false);
  }, [selectedPaymentCode, setPaymentCode]);

  const handleFilter = useCallback((filter: string) => {
    // console.log(filter);
    
    setFilter(filter);
  }, [setFilter]);

  useEffect(() => {
    if (isOpenModal) setSelectedPaymentCode(paymentCode);
  }, [isOpenModal, paymentCode]);

  const goBack = () => navigation(-1);

  return (
    <S.SearchContent>
      <S.Row>
        {showBackButton && (
          <S.Button onClick={goBack}>
            <S.ArrowLeftLine size={20} />
          </S.Button>
        )}

        <SearchField
          id={id}
          onFocus={onFocus}
          value={value}
          showMicrophone={showMicrophone}
          showCamera={showCamera}
          setInput={handleFilter}
          placeholder={placeholder}
        />

        {showCancelButton && (
          <S.CancelButton
            $show={!!value}
            onClick={() => setFilter("")}
            data-test="search-page-clear-field"
          >
            Cancelar
          </S.CancelButton>
        )}

        {showUser && (
          <ModalListCompanies>
            <S.Button>
              <S.AccountBoxLine size={24} />
            </S.Button>
          </ModalListCompanies>
        )}
      </S.Row>

      {showPaymentMode && (
        <S.RowButton
          onClick={() => setIsOpenModal(true)}
          data-test="component-search-button-open-payment"
        >
          <S.Row>
            <S.PaymentIcon size={20} color={colors.brand.white} />

            <S.PaymentMode>{currentPayment}</S.PaymentMode>
          </S.Row>

          <FaChevronDown color={colors.brand.white} />
        </S.RowButton>
      )}

      <Modal id="search" closeModal={setIsOpenModal} isOpen={isOpenModal}>
        <S.ModalContet>
          <S.ModalTitle>Prazos e Formas de pagamento</S.ModalTitle>

          <S.ListBg>
            {listPaymentCode?.map((item, ind: number) => {
              const isActive = item.cod_condicao === selectedPaymentCode;
              const priceCond = fetchTotalPriceByPaymentCode(item.cod_condicao);
              const formattedPrice = formatCurrency({ value: priceCond });

              if (!priceCond && totalQuantity) return "";

              return (
                <S.PaymendCard
                  $isactive={isActive}
                  key={`paymend-card-${ind}`}
                  onClick={() => setSelectedPaymentCode(item.cod_condicao)}
                  data-test={`search-component-payment-button-${item.cod_condicao}`}
                >
                  <S.CheckContent>
                    {isActive ? (
                      <S.CheckFill size={24} color={colors.green} />
                    ) : (
                      <S.CheckBlank size={24} color={colors.grey4} />
                    )}
                  </S.CheckContent>

                  <S.PaymentIcon
                    size={18}
                    color={isActive ? colors.brand.grafite : colors.gray.gray40}
                  />

                  <S.TitlePaymentForm>
                    {item.desc_condicao}
                    {priceCond > 0 && totalQuantity > 0 && (
                      <S.FinalPrice>• {formattedPrice}</S.FinalPrice>
                    )}
                  </S.TitlePaymentForm>
                </S.PaymendCard>
              );
            })}
          </S.ListBg>

          <S.ButtonContent
            onClick={setGlobalPaymentCode}
            data-test="component-search-set-payment"
          >
            <S.ButtonModal>Continuar</S.ButtonModal>
          </S.ButtonContent>
        </S.ModalContet>
      </Modal>
    </S.SearchContent>
  );
};

export default SearchView;
