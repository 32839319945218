import styled, { css } from "styled-components";
import { colors } from "../../constants";
import { NavLink as Link } from "react-router-dom";

const stylesLink = css`
  display: flex;
  font-size: 12px;
  text-align: center;
  position: relative;
  align-items: center;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
`;

export const ItemLink = styled.div<{ color?: string }>`
  ${stylesLink}
  /* position: absolute; */
  color: ${(props) => props?.color} !important;
`;

export const NavLink = styled(Link)<{ color?: string }>`
  ${stylesLink};
  color: ${(props) => props?.color} !important;
`;

export const MenuContainer = styled.nav`
  width: 100%;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  position: fixed;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-top: 12px;
  padding-bottom: 10px;
  max-width: var(--mobile);
  background-color: ${colors.brand.grafite};
  box-shadow: 0px -10px 10px 0px ${colors.shadown1};
`;

export const Content = styled.div`
  gap: 18px;
  width: 100%;
  position: relative;
  align-items: center;
  display: inline-flex;
  justify-content: center;
`;

export const Icon = styled.div`
  margin-bottom: 4px;
`;

export const SelectedBar = styled.div`
  top: -12px;
  width: 100%;
  height: 4px;
  position: absolute;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${colors.brand.yellow};
`;
