import { gql } from "@apollo/client";

export const GET_ORDERS_DATE = gql`
  query getRcaOrdersDate($taxvat: String!, $pageSize: Int) {
    customer{
      rcaOrders(taxvat: $taxvat, pageSize: $pageSize){
        items{
          order_date
        }
      }
    }
  }
`;

export const GET_RCA_ORDERS = gql`
    query getRcaOrders($taxvat: String!, $currentPage: Int, $pageSize: Int, $createdAt: FilterRangeTypeInput) {
    customer{
      rcaOrders(taxvat: $taxvat, pageSize: $pageSize, currentPage: $currentPage, createdAt: $createdAt, sort: { sort_field: CREATED_AT, sort_direction: DESC }) {
      page_info {
        total_pages
        current_page
        page_size
      }
      items {
        carrier
        gp_order_ext{
            delivery_date
            external_erp_order_id
            items{
              order_item_id
              st_price_packaging
              quantity_multiplo
            }
            origin
            payment_condition_id
            payment_condition_title
            rcaCustomer{
                name
                user_type
            }
            seqpessoa
        }
        id
        number
        order_date
        nro_ped_venda
        payment_methods {
          name
          type
          additional_data {
            name
            value
          }
        }
        state
        total{
            base_grand_total{
                currency
                value
            }
            discounts{
                amount{
                    currency
                    value
                }
                label
                type
                value
            }
            grand_total{
                currency
                value
            }
            shipping_handling{
                amount_excluding_tax{
                    currency
                    value
                }
                amount_including_tax{
                    currency
                    value
                }
                total_amount{
                    currency
                    value
                }
                taxes{
                    amount{
                        currency
                        value
                    }
                    rate
                    title
                }
                discounts{
                    amount{
                        currency
                        value
                    }
                }
            }


        }
        shipping_address {
          city
          company
          country_code
          fax
          firstname
          lastname
          middlename
          postcode
          region
          region_id
          street
          suffix
          telephone
          vat_id
        }
        status
        items {
          discounts{
            amount{
                currency
                value
            }
          }
          eligible_for_return
          entered_options {
            label
            value
          }
          id
          product_image
          product_name
          product_package_quantity
          product_price_st
          product_sale_price{
            currency
            value
          }
          product_sku
          product_type
          product_url_key
          quantity_canceled
          quantity_invoiced
          quantity_ordered
          quantity_refunded
          quantity_shipped
          quantity_returned
          status
          selected_options {
            label
            value
          }
        }
      }
      }
    }
    }
`;

export default {
  getRcaOrdersQuery: GET_RCA_ORDERS,
  getOrdersDateQuery: GET_ORDERS_DATE
}
