import { useGlobals } from "@/hooks";
import { useEffect, useMemo } from "react";
import { PriceProductProps, ProductProps } from "@/types";
import { useCalcPrice } from "../calcPrices/useCalcPrice";

interface ProductPageProps {
  productId: string | undefined;
}

export const useProductPage = ({ productId }: ProductPageProps) => {
  const { paymentCode } = useGlobals();
  const { getCatalog, getProduct, isLoaded, isLoadingCatalog, substTrib } = useCalcPrice();
  // const listSubstTrib = await getOnDB("substituicaotributaria").then(list => list);
  // const parametroPrecoBase = await getOnDB("parametroPrecoBase").then(
  //   (list) => {
  //     // return list;
  //     return list?.filter(
  //       (p) =>
  //         `${p.NROTABVENDA}` === `${tabVenda}` &&
  //         condicoes.includes(p.NROCONDICAOPAGTO) &&
  //         codLoja === p.COD_LOJA
  //     );
  //   }
  // );

  // const percentualFornecedor = await getOnDB("percentualFornecedor").then(
  //   (list) => {
  //     return list?.filter((p) => `${p.NROTABVENDA}` === `${tabVenda}`);
  //   }
  // );

  const product = useMemo(
    () =>
      isLoaded.current ? getProduct(Number(productId)) : ({} as ProductProps),
    [getProduct, productId, isLoaded]
  );

  const price = useMemo(() => {
    return (
      product?.PRECO?.find(
        (price) => price.NROCONDICAOPAGTO === Number(paymentCode)
      ) || ({} as PriceProductProps)
    );
  }, [paymentCode, product]);

  useEffect(() => {
    if (isLoaded.current) return;

    getCatalog(false, 1000);
  }, [getCatalog, isLoaded]);

  const image = useMemo(() => product?.IMAGEM, [product]);

  return {
    image,
    price,
    product,
    isLoaded,
    substTrib,
    isLoading: isLoadingCatalog,
  };
};
