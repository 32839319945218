import styled from "styled-components";
import { colors } from "@/constants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    width: 100%;
    padding: 20px 20px;
`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

`;

export const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px;
    background-color: ${colors.gray.gray10};
`;

export const Label = styled.label` 
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: ${colors.gray.gray50};
`;

export const Row = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;

    span {
        font-size: 14px;
        font-weight: 500;
        line-height: 18.75px;
        color: ${colors.gray.gray60};
    }
`;

export const Button = styled.button`
    border: none;
    border-radius: 6px;
    background-color: ${({ disabled }) => (disabled ? colors.gray.gray10 : colors.alert.green110)};
    color: ${({ disabled }) => (disabled ? colors.gray.gray40 : colors.brand.white)};
    font-size: 16px;
    height: 55px;
`;