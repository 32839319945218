import { useState, useCallback, useEffect } from "react";
import { validateCNPJ, formatCNPJ, formatTelephone, formatCPF, isFullNameValid, isEmailValid } from "@/shared";
import { useLazyQuery } from "@apollo/client";
import { setOnStorage } from "@/shared";

import DEFAULT_OPERATIONS from './createAccount.gql';

export const useCreateAccount = () => {
    const { getCompanyDataQuery } = DEFAULT_OPERATIONS;

    const [cnpjInput, setCnpjInput] = useState<string>("");
    const [isCnpjValid, setIsCnpjValid] = useState<boolean>(false);

    const [userFullName, setUserFullName] = useState<string>("");
    const [fullNameIsValid, setFullNameIsValid] = useState(false);
    const [userCPF, setUserCPF] = useState<string>("");
    const [userPhone, setUserPhone] = useState<string>("");

    const [userEmail, setUserEmail] = useState<string>("");
    const [userEmailConfirmation, setUserEmailConfirmation] = useState<string>("");
    const [validEmail, setValidEmail] = useState<boolean>(false);
    const [isEmailsMatch, setIsEmailsMatch] = useState(false);

    const [companyData, setCompanyData] = useState<any>({});

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const [activeStep, setActiveStep] = useState(7);

    const [activeUf, setActiveUf] = useState(false);
    const [ufValue, setUfValue] = useState('');
    const [ufList, setUfList] = useState([
        {
            id: 35,
            nome: "São Paulo",
            regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
            sigla: "SP"
        },
        {
            id: 50,
            nome: "Mato Grosso do Sul",
            regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
            sigla: "MS"
        },
        {
            id: 51,
            nome: "Mato Grosso",
            regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
            sigla: "MT"
        }
    ]);

    const pagesSteps = [
        {
            index: 1,
            title: 'Criar cadastro',

        },
        {
            index: 2,
            title: 'Dados da empresa',
            detail: 'Dados da empresa'
        },
        {
            index: 3,
            title: 'Dados da empresa',
            detail: 'Fotos da Loja'
        },
        {
            index: 4,
            title: 'Dados da empresa',
            detail: 'Contrato social'
        },
        {
            index: 5,
            title: 'Dados da empresa',
            detail: 'Notas de compras'
        },
        {
            index: 6,
            title: 'Dados pessoais',
            detail: 'Dados pessoais'
        },
        {
            index: 7,
            title: 'E-mail',
            detail: 'E-mail'
        }
    ];

    const [getCompanyData, { loading, data, error }] = useLazyQuery(getCompanyDataQuery);

    const handleCnpjInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value.trim();
        setCnpjInput(formatCNPJ(value));

        if (validateCNPJ(value)) {
            setIsCnpjValid(true);
        } else {
            setIsCnpjValid(false);
        }

    }, []);

    const handleUserFullName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setUserFullName(inputValue);
        setFullNameIsValid(isFullNameValid(inputValue));
    }, []);

    const handleUserCPF = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        const formattedCPF = formatCPF(inputValue);

        setUserCPF(formattedCPF);
    }, []);

    const handleUserPhone = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const formattedPhone = formatTelephone(event.target.value);

        setUserPhone(formattedPhone);
    }, []);

    const handleChangeUfInput = useCallback((value: string) => {
        setUfValue(value);
    }, []);

    const handleClickSelect = useCallback(() => {
        setActiveUf(true);
        if (!ufValue) {
            setUfValue('SP');
        }
    }, [activeUf, setActiveUf]);

    const handleChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setUserEmail(inputValue);
        setValidEmail(isEmailValid(inputValue));
    }, []);

    const handleChangeEmailConfirmation = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        setUserEmailConfirmation(inputValue);
        if (inputValue && (userEmail === inputValue)) {
            setIsEmailsMatch(true);
        }
    }, [userEmail]);

    const getCompanyExternalData = useCallback(async () => {
        if (ufValue && (cnpjInput && isCnpjValid)) {
            setActiveStep(2);
            try {
                const response = await getCompanyData({
                    variables: {
                        cnpj: cnpjInput.replace(/\D/g, ''),
                        uf: ufValue
                    }
                })
                setCompanyData(response);
                if (response) {
                    console.log('Company data:', response);
                }
            } catch (error) {

            }
        }
    }, [ufValue, cnpjInput, isCnpjValid]);

    const handleNextStep = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep]);

    const handlePreviousStep = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const saveFiles = useCallback((field: string, file: any) => {
        // if (field && file) {
        //     setOnStorage(field, file);
        // }
    }, []);

    const handleCreateClient = useCallback(() => {
        setShowSuccessModal(true);
    }, []);

    return {
        getCompanyExternalData,
        activeStep,
        pagesSteps,
        setActiveStep,
        handleNextStep,
        handlePreviousStep,
        saveFiles,
        setShowSuccessModal,
        showSuccessModal,
        cnpjDataController: {
            cnpjInput,
            isCnpjValid,
            handleCnpjInput
        },
        ufData: {
            ufValue,
            ufList,
            handleChangeUfInput,
            handleClickSelect
        },
        companyData: {
            data,
            loading
        },
        userPersonalData: {
            userFullName,
            fullNameIsValid,
            handleUserFullName,
            userCPF,
            handleUserCPF,
            userPhone,
            handleUserPhone
        },
        userLoginData: {
            userEmail,
            handleChangeEmail,
            userEmailConfirmation,
            handleChangeEmailConfirmation,
            isEmailValid: validEmail,
            isEmailsMatch
        }
    }
}

export default useCreateAccount;