import React, { useState, useEffect } from 'react';
import * as S from './styles';

const addIcon = `${process.env.PUBLIC_URL}/addIcon.svg`;
const trashIcon = `${process.env.PUBLIC_URL}/trashIcon.svg`;
const removeIcon = `${process.env.PUBLIC_URL}/removeIcon.svg`;

interface OrderStatusCounterProps {
  background?: "white" | "light";
  id: string;
  quantity: number;
  isActive?: boolean;
  showBorder?: boolean;
}

const OrderStatusCounter = ({
    background = "white",
    id,
    quantity,
    isActive = false,
    showBorder
  }: OrderStatusCounterProps) => {

  const [input, setInput] = useState(1);

  useEffect(() => {
    if (quantity) {
      setInput(quantity);
    }
  }, [quantity]);

  return (
    <S.CounterWrapper background={background}>
      <S.CounterButton
        $islimit={!isActive}
        data-test={`cart-order-status-decrement-counter-${id}`}
        disabled={!isActive}
      >
        <S.Icon src={input === 1 ? trashIcon : removeIcon} />
      </S.CounterButton>
      <S.Input
        type="text"
        disabled={!isActive}
        data-test={`cart-order-status-text-input-${id}`}
        value={input}
        showborder={showBorder}
        onChange={(e) => setInput(Number(e?.target?.value?.replace(/[^0-9.]/g, '')))}
      />
      <S.CounterButton
        data-test={`cart-order-status-increment-counter-${id}`}
        disabled={!isActive}
        $islimit={!isActive}
      >
        <S.Icon src={addIcon} />
      </S.CounterButton>
    </S.CounterWrapper>
  )
}

export default OrderStatusCounter;
