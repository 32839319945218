import * as S from "./styles";
import { useState } from "react";

import { NewClientHeader } from "./new-client-header/newClientHeader";
import { CreateAccount } from "./registration-flow/create-account/createAccount";
import { CompanyData } from "./registration-flow/company-data/companyData";
import { PersonalData } from "./registration-flow/personal-data/personalData";
import { CreateLoginData } from "./registration-flow/create-login-data/createLoginData";
import { UploadInvoices, UploadContratoSocial, UploadStoreImages } from "./registration-flow/files-upload";
import { Footer } from "./registration-flow/footer/Footer";

import useCreateAccount from "@/talons/new-clients/useCreateAccount";

const NewClients = ({ }) => {

    const {
        handleNextStep,
        getCompanyExternalData,
        companyData,
        cnpjDataController,
        ufData,
        activeStep,
        pagesSteps,
        setActiveStep,
        userPersonalData,
        userLoginData
    } = useCreateAccount();

    const [nextStepDisabled, setNextStepDisabled] = useState(true);
    const goBack = () => setActiveStep(activeStep - 1);
    const showFooter = activeStep === 3 || activeStep === 4 || activeStep === 5 || activeStep === 6 || activeStep === 7;

    return (
        <S.Container>
            <NewClientHeader pagesSteps={pagesSteps} activeStep={activeStep} />

            {activeStep === 1 || (activeStep === 2 && !companyData.data) ? (
                <CreateAccount cnpjDataController={cnpjDataController} ufData={ufData} handleSubmit={getCompanyExternalData} loading={companyData.loading} />
            ) : activeStep === 2 && companyData.data ? (
                <CompanyData companyData={companyData} handleNextStep={handleNextStep} />
            ) : activeStep === 3 ? (
                <UploadStoreImages setNextStepDisabled={setNextStepDisabled} />
            ) : activeStep === 4 ? (
                <UploadContratoSocial setNextStepDisabled={setNextStepDisabled} />
            ) : activeStep === 5 ? (
                <UploadInvoices setNextStepDisabled={setNextStepDisabled} />
            ) : activeStep === 6 ? (
                <PersonalData userPersonalData={userPersonalData} setNextStepDisabled={setNextStepDisabled} />
            ) : activeStep === 7 ? (
                <CreateLoginData userLoginData={userLoginData} setNextStepDisabled={setNextStepDisabled} />
            ) : null}

            {showFooter && (
                <Footer handleBackStep={goBack} handleNextStep={handleNextStep} disabled={nextStepDisabled} activeStep={activeStep}/>
            )}

        </S.Container>
    );
}

export default NewClients;