import { useState } from "react";

export const useAccountPage = () => {
  //Animação de drag elemento
  const [collapsed, setCollapsed] = useState(false);
  const [startY, setStartY] = useState(0);
  const [dragging, setDragging] = useState(false);

  const handleTouchStart = (e: any) => {
    setStartY(e.touches[0].clientY);
    setDragging(true);
  };

  const handleTouchMove = (e: any) => {
    if (!dragging) return;

    const currentY = e.touches[0].clientY;
    // Define a sensibilidade do arraste
    if (startY - currentY > 20) {
      setCollapsed(true);
    } else if (currentY - startY > 20) {
      setCollapsed(false);
    }
  };

  const handleTouchEnd = () => {
    setDragging(false);
  };

  return {
    collapsed,

    handleTouchEnd,
    handleTouchMove,
    handleTouchStart,
  };
};
