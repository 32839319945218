import { colors } from "@/constants";
import styled from "styled-components";

export const Title = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  padding: 24px;
  color: ${colors.gray.gray60};
`;

export const Content = styled.div`
  padding: 20px;
`;

export const Card = styled.div`
  border: 1px solid ${colors.gray.gray10};
  border-radius: 5px;
`;
export const InnerContainer = styled.div`
  border: 1px solid ${colors.brand.white};
  border-radius: 5px;
`;

export const Subtitle = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
  text-align: left;
  padding: 0 20px 20px 20px;
  color: ${colors.brand.grafite};
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.background1};
`;
