import { colors } from "@/constants";
import styled from "styled-components";
import { RiLoopRightFill, RiCalendarScheduleLine } from "react-icons/ri";

export const Container = styled.div``;

export const InfosContent = styled.div`
  padding: 0px 20px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  justify-content: space-between;
  align-items: center;
`;

export const MomentContent = styled.div`
  gap: 12px;
  align-items: center;
  display: inline-flex;
`;

export const CalendarScheduleLine = styled(RiCalendarScheduleLine)`
  color: ${colors.gray.gray50};
`;

export const MomentData = styled.div``;

export const LastSync = styled.p`
  margin: 0px;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.brand.grafite};
`;

export const SyncLabel = styled.p`
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
  color: ${colors.gray.gray40};
`;

export const SyncButton = styled.div`
  gap: 10px;
  border-radius: 8px;
  padding: 12px 14px;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
  background-color: ${colors.brand.white};
  width: 139px;
`;

export const SyncButtonLabel = styled.p`
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
  color: ${colors.brand.grafite};
`;

export const SyncButtonIcon = styled(RiLoopRightFill)`
  color: ${colors.gray.gray50};
`;

export const LineVersion = styled.div`
  position: relative;
  &:after {
    top: 50%;
    left: 0px;
    height: 1px;
    width: 100%;
    content: "";
    position: absolute;
    background-color: ${colors.grey11};
  }
`;

export const Version = styled.p<{$redDot: boolean}>`
  z-index: 1;
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
  padding: 4px 10px;
  border-radius: 4px;
  width: fit-content;
  position: relative;
  margin-right: auto;
  color: ${colors.gray.gray40};
  background-color: ${colors.background3};
  cursor: pointer;
  ${({$redDot}) => $redDot && `
    &:after {
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100px;
    background-color: ${colors.alert.red200};
    border: 1px solid ${colors.gray.gray10};
    top: -7px;
    right: -7px;
  }
  `}
`;

export const VersionContent = styled.div`
  padding: 0px 20px;
`;


