import { gql } from "@apollo/client";

export const GET_CUSTOMER_DATA = gql`
    query getCustomerData {
        customer {
            firstname
            lastname
            email
            personal_phone
            taxvat
        }
    }
`;

export const UPDATE_CUSTOMER_DATA = gql`
    mutation updateCustomer($input: CustomerInput!) {
        updateCustomer(input: $input) {
            customer {
                firstname
                lastname
                personal_phone
            }
        }
    }
`;

export const UPDATE_CUSTOMER_PASSWORD = gql`
    mutation changeCustomerPassword($currentPassword: String!, $newPassword: String!){
        changeCustomerPassword(currentPassword: $currentPassword, newPassword: $newPassword){
            firstname
            email
        }
    }
`;

export default {
    updateCustomerMutation: UPDATE_CUSTOMER_DATA,
    updatePasswordMutation: UPDATE_CUSTOMER_PASSWORD
}