import styled, { css } from "styled-components";
import { colors } from "@/constants";

export const Controler = styled.div<{
  $isactive: boolean;
  $success?: boolean;
  $error?: boolean;
  $noFocusBorder?: boolean;
  disabled: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 0 20px;
  width: 100%;
  height: 55px;

  position: relative;
  transition: all 0.1s;

  box-sizing: border-box;
  border-radius: 10px;
  border: ${({ $isactive }) =>
    $isactive
      ? `solid 2px ${colors.gray.gray60}`
      : `solid 1px ${colors.grey8}`};
  ${({ $noFocusBorder }) =>
    $noFocusBorder && `border: solid 1px ${colors.grey8} !important`};
  ${({ disabled }) =>
    disabled && `border: solid 1px ${colors.gray.gray10} !important`};

  background-color: ${({ disabled }) =>
    disabled ? `${colors.gray.gray10}` : `${colors.brand.white}`};

  ${({ $success }) =>
    $success
      ? `
        border: solid 2px ${colors.green} !important;
    `
      : null}

  ${({ $error }) =>
    $error
      ? `
        border: solid 2px ${colors.red3} !important;
    `
      : null}
`;

export const Label = styled.label<{ $isactive: boolean; disabled: boolean, $fillwidth?: boolean }>`
  position: absolute;
  left: 20px;
  top: ${({ $isactive }) => ($isactive ? "-7px" : "18px")};
  transition: top 0.3s ease-in-out;
  background: ${({ disabled }) =>
    disabled ? `transparent` : `${colors.brand.white}`};

  cursor: text;
  color: ${({ $isactive }) => $isactive ? colors.gray.gray50 : colors.gray.gray40};
  font-size: ${({ $isactive }) => ($isactive ? "12px" : "16px")};
  ${(props) => props.$fillwidth && css`width: -webkit-fill-available;`};
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  appearance: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  -moz-appearance: none;
  color: ${colors.gray.gray60};
  -webkit-appearance: none;
  outline: none !important;
  background-color: transparent !important;
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${colors.brand.white} inset;
  }
`;

export const TogglePasswordVisibility = styled.div`
  color: ${colors.gray.gray40};
  font-size: 24px;

  display: flex;
  align-items: center;

  cursor: pointer;
`;

export const CheckIcon = styled.div`
  color: ${colors.green};

  display: flex;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Field = styled.div<{ $error?: boolean, $success?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ $error }) =>
    $error
      ? `
        background-color: ${colors.red3};
        border-radius: 10px;
    `
      : null};

  ${({ $success }) => $success ? ` background-color: ${colors.green}; border-radius: 10px;` : null};
`;
