import { colors } from "@/constants";
import styled, { keyframes } from "styled-components";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";

export const ModalContent = styled.div``;

export const ButtonSpan = styled.span``;

export const MarginTop = styled.div`
  margin-top: 50px;
`;

const pulseFileName = keyframes`
  0% { opacity: 1; }
  50% { opacity: .35; }
  100% { opacity: 1; }
`;

export const FileLoading = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 15px;
  animation: ${pulseFileName} 1000ms infinite;
`;

export const ColRight = styled.div`
  width: 150px;
`;

export const Button = styled.button`
  border: 1px solid ${colors.green3};
  padding: 5px 10px;
  background-color: ${colors.brand.white};
  border-radius: 6px;
  margin-top: 15px;
`;

export const Loader = styled.div`
  margin-bottom: 50px;
`;

export const Container = styled.div`
  padding: 50px;
  text-align: center;
`;

export const ProgressStyle = styled.div`
  width: 0%;
  height: 22px;
  background-color: ${colors.green3};
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  overflow: hidden;
  object-fit: cover;
  position: relative;
  border-radius: 20px;
  background-color: ${colors.gray.gray10};
`;

export const ProgressContent = styled.div<{ $collapsed: boolean }>`
  gap: 20px;
  display: flex;
  margin-top: 15px;
  overflow: hidden;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  transition: all 0.25s;
  opacity: ${({ $collapsed }) => ($collapsed ? "0" : "1")};
  height: ${({ $collapsed }) => ($collapsed ? "0" : "auto")};
  margin-top: ${({ $collapsed }) => ($collapsed ? "0" : "15px")};
`;

export const ContentLoading = styled.div`
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const Success = styled(RiCheckboxCircleFill)`
  color: ${colors.alert.green300};
`;

export const Error = styled(RiCloseCircleFill)`
  color: ${colors.brand.red};
`;

export const Message = styled.p`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
  color: ${colors.gray.gray40};
`;

export const LoadingMsg = styled.p`
  font-size: 22px;
  font-weight: 600;
  margin: 22px 0px;
  color: ${colors.brand.grafite};
`;

export const ColLeft = styled.div`
  width: 100px;
`;

export const ProgressName = styled.p`
  margin: 0px;
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-transform: capitalize;
`;

export const ProgressText = styled.p`
  top: 0px;
  width: 100%;
  margin: auto;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  align-self: center;
  text-align: center;
  position: absolute;
  color: ${colors.grey};
  font-size: 10px !important;
`;

export const SuccessText = styled.p`
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin: 15px 0px 30px 0px;
  color: ${colors.brand.grafite};
`;

export const ButtonClose = styled.button`
  width: 100%;
  border: none;
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 14px;
  color: ${colors.brand.grafite};
  background-color: ${colors.gray.gray10};
`;

export const DangerText = styled.p`
  width: 100%;
  font-size: 22px;
  text-align: center;
  color: ${colors.brand.red};
`;

const pulseBigLoad = keyframes`
  0% { border: 2px solid ${colors.grey11}00; }
  50% { border: 8px solid ${colors.grey11}00; }
  80% { border: 8px solid ${colors.grey11}99; }
  100% { border: 2px solid ${colors.grey11}22; }
`;

const pulseLittleLoad = keyframes`
  0% { height: 10px; width: 10px; }
  60% { height: 18px; width: 18px; }
  100% { height: 8px; width: 8px; }
`;

export const BigLoadContent = styled.div`
  height: 55px;
`;

export const BigLoad = styled.div`
  border: 6px;
  width: 40px;
  height: 40px;
  margin: auto;
  display: grid;
  font-size: 22px;
  border-radius: 50%;
  text-align: center;
  place-items: center;
  animation-delay: 1000ms;
  border: 6px solid ${colors.grey11};
  animation: ${pulseBigLoad} 1200ms infinite;
`;

export const CentralLoad = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${colors.brand.grafite};
  animation: ${pulseLittleLoad} 1200ms infinite;
`;

export const LittleLoading = styled.div`
  width: 16px;
  height: 16px;
  margin: auto;
  transform: scale(0.7);
  margin-top: 30px;
  border-radius: 50%;
  position: relative;
  background-color: ${colors.brand.grafite};
`;

const littleLoadingPulse = keyframes`
  0% { left: -35px; width: 14px; opacity: .2; height: 6px; }
  40% { left: 0px; width: 16px; opacity: .8; height: 12px; }
  60% { left: 0px; width: 16px; opacity: .8; height: 12px; }
  90% { left: 35px; width: 14px; opacity: 0; height: 6px; }
  95% { left: 35px; width: 14px; opacity: 0; height: 12px; }
  100% { left: 0px; width: 14px; opacity: 0; height: 12px; }
`;
export const LittleLoadingPulse = styled.div`
  width: 16px;
  height: 12px;
  top: 3px;
  border-radius: 50%;
  position: absolute;
  background-color: ${colors.brand.grafite};
  animation: ${littleLoadingPulse} 1200ms infinite;
`;
