import styled from "styled-components";
import colors from "@/constants/systemColors";

export const HeaderContainer = styled.div`
    top: 0px;
    bottom: 0px;
    z-index: 10;
    position: sticky;
    width: -webkit-fill-available;
`;

export const HeaderWrapper = styled.div<{
  $hasextension?: boolean;
  $noradius?: boolean;
  $maxHeight?: boolean;
}>`
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    z-index: 9;
    position: relative;

    max-height: 56px;
    padding: 16px;
    padding-bottom: ${(i) => (i.$hasextension ? "21px" : "16px")};
    width: -webkit-fill-available;
    border-radius: 0px 0px
        ${(i) => (i.$hasextension || i.$noradius ? "0px 0px" : "6px 6px")};
    background-color: ${colors.brand.grafite};
`;

export const HeaderTitle = styled.h1`
    flex-grow: 1;

    font-size: 18px;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 10px;
 
    color: ${colors.brand.white};
`;

export const BackButton = styled.button`
  padding: 0px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  background: transparent;

  svg {
    color: ${colors.brand.white};
  }
`;

export const Steps = styled.div`
  width: 100%; 
  padding: 14px 12px;
  padding-bottom: 0;
  margin-top: -6px;
  z-index: 1;
  background-color: ${colors.gray.gray60};
  border-radius: 0px 0px 6px 6px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const Step = styled.div<{ $active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;

  position: relative;
`;

export const StepLabel = styled.span<{ $active?: boolean }>`
  font-size: 11px;
  font-weight: 13px;
  line-height: 12.89px;
  color: ${({ $active }) => ($active ? colors.brand.white : colors.gray.gray20)};
`;

export const Mark = styled.div`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4.25px;

  display: grid;
  grid-template-columns: 1fr 9px 1fr;
  justify-content: center;
  align-items: center;
`;

export const MarkLine = styled.div<{ $active?: boolean }>`
  height: 4px;
  width: 100%;
  background-color: ${({ $active }) => ($active ? colors.alert.green300 : colors.brand.grafite)};

  display: flex;
`;

export const HalfLine = styled.div<{ $active?: boolean }>`
  width: 50%;
  height: 100%;
  background-color: ${({ $active }) => ($active ? colors.alert.green300 : colors.brand.grafite)};
`;

export const Space = styled.div`
  background-color: transparent;
  height: 4px;
  width: 100%;
`;

export const Circle = styled.div<{ $active?: boolean }>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${({ $active }) => ($active ? colors.alert.green300 : colors.brand.grafite)};
`;

export const Extension = styled.div`
  height: 7px;
  margin-top: -6px;
  overflow: hidden;
  border-radius: 6px 6px 0px 0px;
  background-color: ${colors.brand.white} ;
`;
