import React from "react";
import * as S from "./styles";

import { RiCheckFill } from "react-icons/ri";

interface RadioCheckboxProps {
  code: string;
  children: React.ReactNode;
  checked: boolean;
  onChange?: (code: string) => void;
}

const RadioCheckout: React.FC<RadioCheckboxProps> = ({
  code,
  checked,
  children,
  onChange,
}) => {
  return (
    <S.RadioWrapper
      checked={checked}
    
      data-test={`radio-checkout-${code}`}
    >
      <S.CustomCheckbox
        checked={checked}
        id={code}
        role="radio"
        aria-checked={checked}
        tabIndex={0}
      >
        {checked && <RiCheckFill aria-label="Selected" />}
      </S.CustomCheckbox>
      {children}
    </S.RadioWrapper>
  );
};

export default RadioCheckout;
