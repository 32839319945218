import * as S from './styles';

import Modal from "../modal/Modal";
import TextInput from '../textInput/TextInput';

import { RiErrorWarningLine, RiMailSendLine, RiMailCheckLine } from "react-icons/ri";

import { useResetPassword } from "@/talons/resetPassword/useResetPassword";

export const ResetPassword = () => {
    const { 
        isLoading,  
        handleEmailChange, 
        inputValue, 
        isError, 
        resetError, 
        successReset, 
        emailValid, 
        handleRequestResetPassword, 
        setIsOpenResetPasswordModal, 
        isOpenedResetPasswordModal,
        redirectLogin
     } = useResetPassword();

    const disabled = isLoading || !emailValid || !inputValue || isError;

    return (
        <Modal
            id="reset-password-modal"
            isOpen={isOpenedResetPasswordModal}
            isLoading={false}
            closeModal={setIsOpenResetPasswordModal}
            fullHeight={true}
        >
            <S.ResetPasswordModal>
                <S.RoundIcon $error={isError}>
                    {successReset ? (
                        <RiMailCheckLine size={56} />
                    ) : (
                        <RiMailSendLine size={56} />
                    )}

                </S.RoundIcon>

                <S.Content>
                    <S.ActionText>
                        {successReset ? (
                            <>
                                <S.TitleText>Confira seu e-mail...</S.TitleText>
                                <S.SubtitleText>
                                    Um link foi enviado para {inputValue} com as instruções para finalizar o processo de reset de senha.
                                </S.SubtitleText>
                                <S.SubtitleText>
                                    Não deixe de conferir a pasta “SPAM” caso não receba o e-mail na caixa de entrada.
                                </S.SubtitleText>
                            </>

                        ) : (
                            <>
                                <S.TitleText>Não lembra sua senha?</S.TitleText>
                                <S.SubtitleText>
                                    Insira o e-mail cadastrado no campo abaixo para resetar sua senha!
                                </S.SubtitleText>
                            </>
                        )}
                    </S.ActionText>
                    <S.Form>
                        {!successReset && (
                            <TextInput
                                value={inputValue}
                                onChange={handleEmailChange}
                                type="email"
                                id={"resetInputEmail"}
                                name={"resetInputEmail"}
                                label="E-mail"
                                success={inputValue != null && emailValid && !resetError?.error}
                                data-test="reset-password-input-email"
                                required={true}
                                error={resetError?.error}
                                errorMessage={resetError?.message}
                            />
                        )}

                        {isError && (
                            <S.ErrorContainer>
                                <RiErrorWarningLine />
                                <S.ErrorText>Em caso de dúvidas, entre em contato com nosso SAC <b>(11) 2138-2602</b></S.ErrorText>
                            </S.ErrorContainer>
                        )}
                        {successReset ? (
                            <S.SendButton $success={successReset} onClick={() => { redirectLogin() }}>Voltar para o login</S.SendButton>
                        ) : (
                            <S.SendButton disabled={disabled} onClick={() => { handleRequestResetPassword(inputValue) }}>Resetar senha</S.SendButton>
                        )}
                    </S.Form>
                </S.Content>
            </S.ResetPasswordModal>
        </Modal>
    )
}