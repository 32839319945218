import { useEffect, useState } from 'react';
import * as S from './styles';
import { TextInput } from '@/components';
import { ModalTermsAndConditions } from './modalTerms';

interface CreateLoginDataProps {
    userLoginData: {
        userEmail: string;
        userEmailConfirmation: string;
        handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
        handleChangeEmailConfirmation: (event: React.ChangeEvent<HTMLInputElement>) => void;
        isEmailValid: boolean;
        isEmailsMatch: boolean;
    },
    setNextStepDisabled: (disabled: boolean) => void;
}

export const CreateLoginData = ({ userLoginData, setNextStepDisabled }: CreateLoginDataProps) => {
    const [isOpenModal, setModalState] = useState(false);
    const { userEmail, userEmailConfirmation, handleChangeEmail, handleChangeEmailConfirmation, isEmailValid, isEmailsMatch } = userLoginData;

    useEffect(() => {
        if (userEmail && userEmailConfirmation && isEmailValid && isEmailsMatch) {
            setNextStepDisabled(false);
        } else {
            setNextStepDisabled(true);
        }
    }, [userEmail, userEmailConfirmation, isEmailValid, isEmailsMatch]);

    return (
        <S.Container>
            <S.Label>Informe um e-mail válido e crie uma senha de acesso.</S.Label>
            <S.Form>
                <TextInput
                    value={userEmail}
                    type="email"
                    fillwidth
                    id={'inputEmail'}
                    name={'inputEmail'}
                    label={userEmail ? 'E-mail' : 'Digite um e-mail válido'}
                    data-test="cadastro-input-email"
                    required={true}
                    onChange={handleChangeEmail}
                    success={isEmailValid}
                />
                <TextInput
                    value={userEmailConfirmation}
                    type="email"
                    fillwidth
                    id={'inputEmailConfirmation'}
                    name={'inputEmailConfirmation'}
                    label={'Confirme seu e-mail'}
                    data-test="cadastro-input-email-confirmation"
                    required={true}
                    onChange={handleChangeEmailConfirmation}
                    success={isEmailsMatch}
                    error={!isEmailsMatch && !!userEmailConfirmation}
                />
            </S.Form>
            <S.CreateAccountMessage>
                <span>Ao clicar em  “Criar Conta”, você concorda com os <b onClick={() => setModalState(true)}>Termos e condições de uso</b> do Bate Forte.</span>
            </S.CreateAccountMessage>
            <ModalTermsAndConditions isOpenModal={isOpenModal} setModalState={setModalState} />
        </S.Container>
    )
}