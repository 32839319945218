import * as S from './styles';

import { usePasswordStrength } from '@/talons/passwordValidator/usePasswordValidator';

interface Props {
    password: string | null
}

const PasswordValidator: React.FC<Props> = ({ password }) => {

    const {
        validateLowerCaseChar,
        validateUpperCaseChar,
        validateLength,
        validateNumericChar,
        validPassword
    } = usePasswordStrength(password);

    return (
        <S.Content>
            <S.Label>Sua senha deve conter:</S.Label>
            <S.BoxWrapper>
                <S.Box valid={validateLength}>
                    <S.StrongText>+8</S.StrongText>
                    <S.DetailText>Caracteres</S.DetailText>
                </S.Box>
                <S.Box valid={validateUpperCaseChar}>
                    <S.StrongText className='underlined'>AA</S.StrongText>
                    <S.DetailText>Maiúsculas</S.DetailText>
                </S.Box>
                <S.Box valid={validateLowerCaseChar}>
                    <S.StrongText>aa</S.StrongText>
                    <S.DetailText>Minúsculas</S.DetailText>
                </S.Box>
                <S.Box valid={validateNumericChar}>
                    <S.StrongText>123</S.StrongText>
                    <S.DetailText>Números</S.DetailText>
                </S.Box>
            </S.BoxWrapper>
        </S.Content>
    )
}

export default PasswordValidator;