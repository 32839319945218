import styled from "styled-components";
import { colors } from "@/constants";

// import splash from "@/assets/svgs/icons-repeat.svg";

const splash = `${process.env.PUBLIC_URL}/icons-repeat.svg`;
const bgRepeat = `${process.env.PUBLIC_URL}/bg-splash-repeat.png`;

export const SplashScreen = styled.div`
  top: 0;
  left: 50%;
  z-index: 2;
  width: 100%;
  height: 100vh;
  position: fixed;
  max-width: var(--mobile);
  transform: translateX(-50%);
  background-color: ${colors.brand.grafite};
`;

export const SplashBackground = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${bgRepeat});
  background-repeat: repeat;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoLine = styled.div`
  display: flex;
  flex-direction: row;
`;
