import styled, { keyframes } from "styled-components";
import { colors } from "@/constants";

const pulseLittleLoad = keyframes`
  0% { height: 10px; width: 10px; }
  60% { height: 18px; width: 18px; }
  100% { height: 8px; width: 8px; }
  `;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 0 35px;
    padding-top: 84px;
    width: 100%;
    min-height: 300px;

    color: ${colors.brand.grafite};
`;


export const ModalTitle = styled.span`
    text-align: center;
    font-size: 24px;
    line-height: 33.8px;
    font-weight: 900;
`;

export const ModalInfo = styled.span`
    text-align: center;
    font-size: 16px;
    line-height: 22.6px;
    margin-top: 20px;
    font-weight: 400;
`;

export const CentralLoad = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${colors.brand.grafite};
  animation: ${pulseLittleLoad} 1200ms infinite;
`;

export const LittleLoading = styled.div`
  width: 16px;
  height: 16px;
  margin: auto;
  transform: scale(0.7);
  margin-top: 30px;
  border-radius: 50%;
  position: relative;
  background-color: ${colors.brand.grafite};
`;

const littleLoadingPulse = keyframes`
  0% { left: -35px; width: 14px; opacity: .2; height: 6px; }
  40% { left: 0px; width: 16px; opacity: .8; height: 12px; }
  60% { left: 0px; width: 16px; opacity: .8; height: 12px; }
  90% { left: 35px; width: 14px; opacity: 0; height: 6px; }
  95% { left: 35px; width: 14px; opacity: 0; height: 12px; }
  100% { left: 0px; width: 14px; opacity: 0; height: 12px; }
`;
export const LittleLoadingPulse = styled.div`
  width: 16px;
  height: 12px;
  top: 3px;
  border-radius: 50%;
  position: absolute;
  background-color: ${colors.brand.grafite};
  animation: ${littleLoadingPulse} 1200ms infinite;
`;

export const ButtonClose = styled.button`
  width: 100%;
  height: 56px;
  background-color: ${colors.red4};
  border: solid 1px ${colors.red4};
  border-radius: 8px;

  margin: 40px 0;

  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  color: ${colors.brand.white};
`;