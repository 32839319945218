import { colors } from "@/constants";
import styled from "styled-components";

export const Title = styled.h5`
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  padding-top: 20px;
  padding-left: 20px;
  color: ${colors.gray.gray60};
  margin: 0px 0px 12px 0px;
  text-transform: uppercase;
`;

export const Container = styled.div`
  min-height: 100vh;
  overflow-y: scroll;
  padding-bottom: 90px;
  background: ${colors.gray.gray10};
`;
