import { colors } from '@/constants';
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
`;
export const Text = styled.span``;

export const Subtotal = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  padding: 15px 30px;
  color: ${colors.brand.grafite};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.background1};
`;

export const SubtotalContainer = styled.div`
  font-family: Roboto, sans-serif;
  width: 100%;
  background: ${colors.brand.white};
`;

export const SubtotalContainerRow = styled.div`
  width: 100%;
  padding: 12px 0;
`;

export const Separator = styled.div`
  height: 1px;
  background-color: ${colors.background1};
  width: 100%;
`;

export const RegularText = styled.span<{ color?: string; bold?: boolean }>`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: ${props => (props.bold ? 700 : 400)};
  line-height: 16px;
  text-align: left;
  color: ${props => props.color || colors.brand.grafite};
`;

export const TextRow = styled.div<{ $islast?: boolean; $isfirst?: boolean }>`
  display: flex;
  font-family: Roboto, sans-serif;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 30px;
  padding-top: ${props => (props.$isfirst ? 20 : 10)}px;
  padding-bottom: ${props => (props.$islast ? 20 : 10)}px;
`;

export const CardContainer = styled.div`
  background: ${colors.brand.white};
  padding-top: 12px;
`;