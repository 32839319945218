import * as S from './styles';
import { useState } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';

import { useNavigate } from 'react-router-dom';

interface NewClientHeaderProps {
    activeStep: any;
    pagesSteps: any;
}

export const NewClientHeader: React.FC<NewClientHeaderProps> = ({ activeStep, pagesSteps }) => {
    const showBackButton = activeStep === 1;

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    console.log('Step:', pagesSteps)

    return (
        <S.HeaderContainer>
            <S.HeaderWrapper>
                {showBackButton && (
                    <S.BackButton onClick={goBack}>
                        <RiArrowLeftLine size={20} />
                    </S.BackButton>
                )}

                {pagesSteps.map((page: any) => {
                    if (page?.index === activeStep) {
                        return (
                            <S.HeaderTitle key={page?.index}>{page.title}</S.HeaderTitle>
                        )
                    }
                })}
            </S.HeaderWrapper>
            {activeStep >= 2 && (
                <S.Steps>
                    <HeaderSteps activeStep={activeStep} />
                </S.Steps>
            )}
            {showBackButton && <S.Extension />}
        </S.HeaderContainer>
    )
}

export const HeaderSteps = ({ activeStep }: any) => {
    return (
        <>
            <S.Step>
                <S.StepLabel $active={(activeStep > 1)}>Dados da empresa</S.StepLabel>
                <S.Mark>
                    <S.Space />
                    <S.Circle $active={(activeStep > 1)}></S.Circle>
                    <S.MarkLine>
                        <S.HalfLine $active={(activeStep >= 2)} />
                        <S.HalfLine $active={(activeStep >= 3)} />
                    </S.MarkLine>
                </S.Mark>
            </S.Step>
            <S.Step>
                <S.StepLabel $active={(activeStep >= 5)}>
                    Dados do Comprador
                </S.StepLabel>
                <S.Mark>
                    <S.MarkLine>
                        <S.HalfLine $active={(activeStep >= 4)} />
                        <S.HalfLine $active={(activeStep >= 5)} />
                    </S.MarkLine>
                    <S.Circle $active={(activeStep >= 5)}></S.Circle>
                    <S.MarkLine $active={(activeStep > 6)} />
                </S.Mark>
            </S.Step>
            <S.Step>
                <S.StepLabel $active={(activeStep > 6)}>
                    Dados de Login
                </S.StepLabel>
                <S.Mark>
                    <S.MarkLine $active={(activeStep > 6)} />
                    <S.Circle $active={(activeStep > 6)}></S.Circle>
                    <S.Space />
                </S.Mark>
            </S.Step>
        </>
    )
}