import * as S from "./styles";
import { Menu } from "@/components";
import { colors } from "@/constants";
import { FaTools } from "react-icons/fa";

const RenderBuilding = () => {
  return (
    <S.Container>
      <S.Content>
        <FaTools size={75} color={colors.gray.gray40} />
        <S.Label>Em Construção</S.Label>
      </S.Content>
      <Menu />
    </S.Container>
  );
};

export default RenderBuilding;
