import styled from "styled-components";
import colors from "@/constants/systemColors";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100vh - 160px);
    padding: 20px 20px;
    position: relative; /* Para que o elemento filho possa ser posicionado de forma absoluta */
`;

export const Form = styled.div`
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    gap: 20px;
`;

export const Label = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: ${colors.gray.gray50};
`;

export const CreateAccountMessage = styled.div`
    display: flex;
    position: absolute;
    bottom: 40px;
    left: 20px;
    right: 20px;
    padding: 20px 0;
    text-align: left;
    justify-content: center;

    border-top: solid 1px ${colors.gray.gray10};

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.gray.gray60};

    b{
        font-weight: 700;
        text-decoration: underline;
    }
`;

export const TermsContainer = styled.div`
    padding: 32px 30px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const TermsTitle = styled.span`
    max-width: 220px;
    font-size: 22px;
    font-weight: 700;
    line-height: 25.78px;
    text-align: left;
    color: ${colors.gray.gray60};
`;

export const TermsText = styled.div`
    p{
        margin-bottom: 12px;
    }

    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    text-align: left;
    color: ${colors.gray.gray60};
`;

