import styled from "styled-components";
import colors from "../../constants/systemColors";

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  background-color: ${colors.brand.white};
  z-index: 2;
`;

export const PageDescription = styled.div`
  padding: 30px 0 20px 0;
`;

export const Description = styled.span`
  padding: 0;
  margin: 0;

  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  color: ${colors.gray.gray60};

  vertical-align: middle;

  b {
    font-size: 16px;
    font-weight: 700;
    color: ${colors.brand.red};

    cursor: pointer;
  }

  svg {
    width: 19px;
    height: 19px;
    vertical-align: middle;
    color: ${colors.brand.red};
  }
`;

export const Content = styled.div``;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 20px 0;
  border-top: solid 3px ${colors.background1};
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;

  color: ${colors.brand.grafite};
`;

export const Field = styled.div`
  position: relative;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: ${colors.gray.gray50};

  position: absolute;
  left: 20px;
  top: -6px;
`;

export const CustomInput = styled.div<{ $isdanger?: boolean }>`
  display: flex;
  align-items: center;
  min-width: 50%;
  width: max-content;
  height: 55px;
  padding: 0 20px;
  background-color: ${colors.background2};
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: ${(i) => (i.$isdanger ? colors.brand.red : colors.gray.gray60)};
`;
