import styled from "styled-components";
import { colors } from "@/constants";

export const Pagination = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;

export const Pages = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    font-size: 14px;
`;

export const CurrentPage = styled.input`
    width: 50px;
    height: 42px;
    text-align: center;
    color: ${colors.brand.grafite};
    font-weight: 700;
    
    font-size: 14px;
    border: solid 1px ${colors.brand.white};
    border-radius: 6px;
    background-color: ${colors.brand.white};
`;

export const TotalPages = styled.span`
    font-weight: 400;
    color: ${colors.grey3};

    b{
        font-weight: 700;
        color: ${colors.brand.grafite};
    }
`;

export const Skip = styled.div`
    display: flex;
`;

export const PageControl = styled.div`
   display: flex;
   gap: 10px;
`;

export const PageControlItem = styled.div<{ disabled?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 42px;
    height: 42px;

    svg{
        width: 24px;
        height: 24px;
        color: ${props => props.disabled ? colors.gray.gray20 : colors.gray.gray40};
        
    }
`;