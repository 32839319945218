import { colors } from "@/constants";
import styled, { keyframes } from "styled-components";
import { RiCloseLine } from "react-icons/ri";


export const BetinhoContainer = styled.div`
height: 100%;
`
const bounce = keyframes`
  0%, 100% { transform: translateY(0px); }
  10%, 90% { transform: translateY(1px); }
  15%, 85% { transform: translateY(2px); }
  20%, 80% { transform: translateY(3px); }
  25%, 75% { transform: translateY(4px); }
  30%, 70% { transform: translateY(5px); }
  35%, 65% { transform: translateY(6px); }
  40%, 60% { transform: translateY(7px); }
  45%, 55% { transform: translateY(8px); }
  50% { transform: translateY(9px); }
`;

export const FloatingMessage = styled.div`
  position: absolute;
  z-index: 2;
  top: -25px;
  right: 33px;
  padding: 12px;
  border-radius: 8px;
  width: max-content;
  display: flex;
  align-items: center; 
  justify-content: center;
  gap: 8px;
  background-color: ${colors.brand.white};
  box-shadow: 0px 4px 10px ${colors.shadown7};
  font-size: 12px;
  color: ${colors.brand.grafite};
  font-weight: 500;
  animation: ${bounce} 2000ms infinite;
  
  &:before {
    content: "";
    bottom: 0px;
    right: 10%;
    border-width: 5px;
    position: absolute;
    border-style: solid;
    transform: translateX(50%) rotate(180deg) translateY(-100%);
    border-color: transparent transparent ${colors.brand.white + "f2"};
  }
`



export const CloseIcon = styled(RiCloseLine)`
  color: ${colors.shadown7};
  width: 19px;
  height: 19px;
`
