import { gql } from "@apollo/client";

export const RCA_SIGN_IN = gql`
    mutation RCALogin ($taxvat: String, $email: String, $password: String!) {
        RCALogin(taxvat: $taxvat, email: $email, password: $password){
            magento_token
            aws_data
            aws_token
            seqpessoa
        }
    }
`;

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordResetEmail(email: $email)
  }
`;


export default {
    signInMutation: RCA_SIGN_IN,
    requestPasswordResetMutation: REQUEST_PASSWORD_RESET
};
