import { colors } from '@/constants';
import styled, { css } from 'styled-components';
import { RiArrowDownSFill } from 'react-icons/ri';

const { gray40 } = colors.gray;
const { red50 } = colors.alert;
const { grey11, green } = colors;
const { grafite, red, white } = colors.brand;

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
`;

export const DownIcon = styled(RiArrowDownSFill)`
  color: ${red};
`;

export const ChevronIcon = styled.div`
  left: 18px;
  position: relative;
`;
export const ButtonAccordion = styled.button`
  width: 100%;
  border: none;
  padding: 10px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border-radius: 0px 0px 8px 8px;
  border: 1px solid ${colors.grey11};
  background-color: ${colors.gray.gray10};
`;

export const AccordionContent = styled.div`
  margin-top: -8px;
  padding: 24px 25px 16px 25px;
  border-radius: 0px;
  background-color: ${colors.gray.gray10};
  border-left: 1px solid ${colors.grey11};
  border-right: 1px solid ${colors.grey11};
`;

export const Card = styled.div<{ $isOpen?: boolean }>`
  padding: 15px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
  transition: all 0.25s;
  background-color: ${white};
  border: 1px solid ${grey11};
  border-bottom: ${(i) => (i.$isOpen ? 1 : 0)}px solid ${grey11};
  border-radius: 8px 8px ${(i) => (i.$isOpen ? '8px 8px' : '0px 0px')};
`;

export const Row = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
`;

export const ProgressContent = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: end;
`;

export const Column = styled.div<{ width?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  ${(i) =>
    i.width &&
    css`
      width: ${i.width}%;
    `}
`;

export const ContentIcon = styled.div`
  width: 100%;
  display: grid;
  margin-left: 1px;
  place-items: center;
  justify-content: start;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
`;

export const BarProgress = styled.div<{ $checked?: boolean }>`
  width: 100%;
  height: 5px;
  overflow: hidden;
  position: relative;
  background-color: ${colors.gray.gray30};
  &:before {
    top: 0px;
    left: 0px;
    width: 60%;
    height: 5px;
    content: '';
    position: absolute;
    background-color: ${(i) => (i.$checked ? colors.green : colors.gray.gray30)};
  }
`;

export const Icon = styled.div<{ $checked: boolean }>`
  width: 24px;
  height: 24px;
  display: grid;
  margin: 4px 0px;
  border-radius: 50%;
  place-items: center;
  background-color: ${(i) => (i.$checked ? green : colors.gray.gray30)};
  border: 2px solid ${(i) => (i.$checked ? colors.green4 : colors.gray.gray10)};
`;

export const Separete = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0px 15px 0px;
  background-color: ${grey11};
`;

export const Text = styled.p<{
  bold?: number;
  padding?: string;
  size?: number;
  left?: number;
  marginbottom?: number;
  textalign?: 'left' | 'right' | 'center';
  color?: 'grafite' | 'red' | 'gray' | 'green';
}>`
  ${(i) =>
    i.color === 'red' &&
    css`
      width: fit-content;
      align-items: center;
      display: inline-flex;
      background-color: ${red50};
    `}
  border-radius: 10px;
  position: relative;
  left: ${(i) => i.left || 0}px;
  padding: ${(i) => i.padding || '0px'};
  font-size: ${(i) => (i.size || 12)}px;
  text-align: ${(i) => i.textalign || 'left'};
  margin-bottom: ${(i) => i.marginbottom || 0}px;
  font-weight: ${(i) => (!i.bold ? 400 : i.bold)};
  color: ${(i) => (!i.color || i.color === 'gray' ? gray40 : i.color === 'red' ? red : i.color === 'green' ? green : grafite)};
`;
