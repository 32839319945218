import React from 'react';
import * as S from "./styles";

const CartCounter = ({ cartTotalQuantity }: { cartTotalQuantity: number }) => {
  if (!cartTotalQuantity) return null;

  return (
    <S.CartCounterContainer>
      <S.Counter>{cartTotalQuantity > 999 ? `999+` : cartTotalQuantity}</S.Counter>
    </S.CartCounterContainer>
  )
}

export default CartCounter;
