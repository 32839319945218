import { useCallback, useMemo, useState } from "react";
import * as S from "./styles";
import { colors } from "@/constants";

interface Props {
  id: string;
  value: string;
  placeholder?: string;
  onFocus?: (s?: any) => void;
  setInput: (s: any) => void;
  showCamera?: boolean;
  showMicrophone?: boolean;
}

const Search = ({
  id,
  value,
  onFocus,
  setInput,
  showCamera = true,
  showMicrophone = true,
  placeholder = "Busque por leite, arroz...",
}: Props) => {
  const win: any = window;
  const [isSpeaking, setIsSpeaking] = useState(false)

  const recognition = useMemo(() => 
    {
      const Speech = win?.SpeechRecognition || win?.webkitSpeechRecognition || win?.mozSpeechRecognition || win?.msSpeechRecognition;
      const recognit = Speech ? new Speech() : null;
      if (recognit) {
        recognit.lang = 'pt-BR';
      
        recognit.onstart = () => {
          onFocus ? onFocus() :setIsSpeaking(true)
        };
        
        recognit.onresult = (event: any) => {
          const transcript = event.results?.[0]?.[0]?.transcript;
          setInput(transcript)
        };
        
        recognit.onend = () => {
          setIsSpeaking(false)
        };
      }

      return recognit;
    }, [win, onFocus, setInput]);
    
  
  const speech = useCallback(() => {
    recognition?.start();
  }, [recognition])


  return (
    <S.InputContent>
      <S.Search size={26} color={colors.gray.gray60} />

      <S.Input
        type="text"
        value={value}
        id="field-search"
        onFocus={onFocus}
        className="form-control"
        placeholder={placeholder}
        data-test={`component-search-input-${id}`}
        onChange={(e) => setInput(e.target.value)}
      />

      {showCamera &&
        <S.Button>
          <S.OutlinePhotoCamera size={20} color={colors.gray.gray60} />
        </S.Button>
      }

      {showMicrophone && 
        <S.Button>
          <S.OutlineMicrophone
            size={20}
            style={{ marginLeft: 5 }}
            color={isSpeaking ? colors.brand.red : colors.gray.gray60}
            onClick={() => !isSpeaking && speech()}
          />
        </S.Button>
      }
    </S.InputContent>
  );
};

export default Search;
