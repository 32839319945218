import * as S from "./styles";

const ShortProgressBar = ({ state }: S.Props) => {
  const types: S.Types = {
    danger: <S.ProhibitedLine size={16} />,
    checked: <S.CheckboxCircle size={16} />,
    empty: <S.CheckboxCircleLine size={16} />,
    "semi-checked": <S.CheckboxCircleLine size={16} />,
    warning: <S.IndeterminateCircleLine size={16} />,
  };

  return (
    <S.Progress state={state}>
      {state.includes("checked") && (
        <S.BarProgress $isFill={state === "checked"} />
      )}

      {types[state]}
    </S.Progress>
  );
};

export default ShortProgressBar;
