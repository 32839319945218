import * as S from "./styles";
import { useMemo } from "react";
import { colors } from "@/constants";
import { packingTypes } from "@/shared";
import {
  ProductProps,
  PackagesTypesProps,
  PackagingProductProps,
} from "@/types";

interface Product {
  size?: number;
  showQtd?: boolean;
  product: ProductProps;
  pack: PackagesTypesProps;
  packQty: number;
  setPackage: (val: PackagesTypesProps) => void;
  setPackQuantity: (val: number) => void;
}

const RenderPackaging = ({
  pack,
  size,
  product,
  showQtd,
  packQty,
  setPackage,
  setPackQuantity,
}: Product) => {
  const qtd = useMemo(() => {
    const emb = product?.EMBALAGEM.find((e: PackagingProductProps) => {
      if (pack === "PK") {
        return e?.SG_EMBALAGEM === pack && e?.QTD_MULTIPLO_VENDA === packQty;
      }
      return e?.SG_EMBALAGEM === pack && e?.QTD_EMBALAGEM === packQty;
    });
    if (emb?.QTD_MULTIPLO_VENDA && emb?.QTD_MULTIPLO_VENDA > 1) {
      return emb?.QTD_MULTIPLO_VENDA;
    }
    return emb?.QTD_EMBALAGEM;
  }, [pack, packQty, product?.EMBALAGEM]);

  const list = useMemo(() => {
    return product?.EMBALAGEM?.length
      ? product.EMBALAGEM
      : [{ SG_EMBALAGEM: "UN" }];
  }, [product.EMBALAGEM]) as PackagingProductProps[];

  return (
    <>
      <S.Row>
        {/* Rever esse map */}
        {list.map((embalagem) => {
          const packing = packingTypes?.[embalagem?.SG_EMBALAGEM];

          const qty = embalagem?.QTD_MULTIPLO_VENDA > 1 ? embalagem?.QTD_MULTIPLO_VENDA : embalagem?.QTD_EMBALAGEM;

          const isActive = pack === "PK" ?
          embalagem?.QTD_MULTIPLO_VENDA > 1 &&
          embalagem?.QTD_MULTIPLO_VENDA === packQty &&
          pack === embalagem?.SG_EMBALAGEM :
          embalagem?.SG_EMBALAGEM === pack &&
          embalagem?.QTD_EMBALAGEM === packQty;

          return (
            <S.BoxEmbalagem
              size={size}
              $isactive={isActive}
              onClick={() => {
                setPackage(packing.shortName)
                setPackQuantity(qty);
              }}
              key={`pack-${product.COD_PRODUTO}-${packing.shortName}`}
              data-test={`pack-${product.COD_PRODUTO}-${packing.shortName}`}
            >
              <S.PackImage src={packing.icon} alt="" />
              {isActive && <S.CheckFill size={24} color={colors.green} />}

              <S.BoxLabel>{packing?.displayName}</S.BoxLabel>
            </S.BoxEmbalagem>
          );
        })}
      </S.Row>

      {showQtd && (
        <S.BoxQtdEmbalagem>
          <S.QtdEmbalagem>Com {qtd} {(qtd && qtd > 1) ? 'unidades' : 'unidade'}</S.QtdEmbalagem>
        </S.BoxQtdEmbalagem>
      )}
    </>
  );
};

export default RenderPackaging;
