import styled from "styled-components";

export const PageContainer = styled.div`
  margin-bottom: 80px;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoadingContainer = styled.div`
  /* text-align: center; */
  display: flex;
  align-items: center;
  height: 50vh;
  justify-content: center;
  /* padding-bottom: 20vh; */
`;