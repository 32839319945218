import * as S from "./styles";
import { useState, useRef, useCallback } from "react";
import { MessageField } from "./messageField/MessageField";

import {
  RiEyeLine,
  RiEyeCloseLine,
  RiCheckboxCircleLine,
} from "react-icons/ri";

interface TextInputProps {
  id: string;
  name: string;
  type: string;
  value?: string;
  label: string;
  error?: boolean;
  required: boolean;
  success?: boolean;
  maxLenght?: number;
  disabled?: boolean;
  fillwidth?: boolean;
  errorMessage?: string;
  noFocusBorder?: boolean;
  successMessage?: string;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  value,
  onChange,
  label,
  type,
  required,
  fillwidth,
  success,
  successMessage,
  error,
  errorMessage,
  maxLenght,
  noFocusBorder,
  disabled = false,
  placeholder
}) => {
  const [isFocused, setFocused] = useState(false);
  // Estado de visibilidade do input de senha
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [inputType, setInputType] = useState(type);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFocus = () => setFocused(true);

  const handleBlur = () => {
    if (!value) {
      setFocused(false);
    }
  };

  const handleLabelClick = () => {
    inputRef.current?.focus();
  };

  const togglePasswordVisibility = useCallback(() => {
    setPasswordVisible(!passwordVisible);
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  }, [inputType, passwordVisible]);

  return (
    <S.Field $error={error} $success={success}>
      <S.Controler
        $isactive={isFocused || value !== ""}
        $success={success}
        $error={error}
        $noFocusBorder={noFocusBorder}
        disabled={disabled}
      >
        <S.Label
          htmlFor={id}
          disabled={disabled}
          onClick={handleLabelClick}
          $fillwidth={fillwidth && !(isFocused || value !== "")}
          data-test={`text-input-component-label-${id}`}
          $isactive={isFocused || value !== ""}
        >
          {label}
        </S.Label>
        <S.Input
          id={id}
          name={name}
          value={value}
          ref={inputRef}
          type={inputType}
          required={required}
          disabled={disabled}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          maxLength={maxLenght ? maxLenght : 60}
          data-test={`text-input-component-input-${id}`}
          placeholder={placeholder ? placeholder : ""}
        />
        {type === "password" && (
          <S.TogglePasswordVisibility
            onClick={togglePasswordVisibility}
            data-test={`toggle-password-visibility-${id}`}
          >
            {passwordVisible ? <RiEyeLine /> : <RiEyeCloseLine />}
          </S.TogglePasswordVisibility>
        )}
        {type !== "password" && success && (
          <S.CheckIcon>
            <RiCheckboxCircleLine />
          </S.CheckIcon>
        )}
      </S.Controler>
      {error && errorMessage && <MessageField message={errorMessage} />}
      {successMessage && success && <MessageField type={'success'} message={successMessage} />}
    </S.Field>
  );
};

export default TextInput;
