import * as S from './styles';
import { Modal } from '@/components';

interface ModalTermsAndConditionsProps {
    isOpenModal: boolean;
    setModalState: (state: boolean) => void;
}

export const ModalTermsAndConditions = ({ isOpenModal, setModalState }: ModalTermsAndConditionsProps) => {

    return (
        <Modal
            id="terms-and-conditions"
            direction="bottom"
            isOpen={isOpenModal}
            closeModal={setModalState}
            fullHeight
        >
            <S.TermsContainer>
                <S.TermsTitle>Termos e Condições de uso</S.TermsTitle>
                <S.TermsText>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptate reiciendis at quod ipsum, iure similique deserunt aperiam pariatur eos sequi adipisci praesentium quaerat, amet inventore id, laudantium in consequuntur fugiat?
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam aliquam velit hic! Iste quasi perferendis temporibus qui dolorum natus dolore enim. Consectetur quisquam quod atque possimus odit delectus, similique illum.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam aliquam velit hic! Iste quasi perferendis temporibus qui dolorum natus dolore enim. Consectetur quisquam quod atque possimus odit delectus, similique illum.
                    </p>
                </S.TermsText>
            </S.TermsContainer>
        </Modal>
    )
}