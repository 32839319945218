import styled from "styled-components";
import { colors } from "@/constants";

export const ResetPasswordModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 0 30px;
    padding-top: 108px;
    gap: 46px;

    height: 100vh;
`;

export const RoundIcon = styled.div<{ $error?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 112px;
    height: 112px;
    background-color: ${props => props.$error ? colors.alert.red200 : colors.green}; ;
    border-radius: 50%;

    color: ${colors.brand.white};

    ${props => props.$error && ''}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 24px;
    
`;

export const ActionText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const TitleText = styled.span`
    font-size: 22px;
    font-weight: 700;
    line-height: 25.78px;
    color: ${colors.gray.gray60};
`;

export const SubtitleText = styled.span`
    margin: 0 10px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.gray.gray50};

    text-align: center;
`;

export const Form = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const SendButton = styled.button<{$success?: boolean}>`
  width: 100%;
  height: 55px;
  padding: 18px;

  background-color: ${props => props.$success ? colors.brand.grafite : colors.green};
  border: solid 1px ${colors.green};
  border-radius: 10px;

  font-size: 16px;
  font-weight: 500;
  color: ${colors.brand.white};
  line-height: 18.75px;

  &:disabled {
    background-color: ${colors.gray.gray10};
    border: none;

    color: ${colors.gray.gray40};
  }
`;


export const ErrorText = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
`;

export const ErrorContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 10px;
    padding: 16px;

    border-radius: 6px ;
    background-color: rgba(253, 238, 236, 1);
    justify-content: flex-start;
    align-items: center;
    color: ${colors.red3};

    svg{ 
        width: 24px;
        height: 24px;
        font-weight: 700;
    }
`;