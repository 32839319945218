import * as S from './styled';
import { colors } from '@/constants';
import { HiMiniMinusSmall } from 'react-icons/hi2';
import React, { useCallback, useMemo, useState } from 'react';
import { RiAddFill, RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

type AccordionDefaultProps = {
  id: string;
  bgColor?: string;
  maxheight?: string;
  noRadius?: boolean;
  marginTop?: number;
  showHeader?: boolean;
  accordionTitle: string;
  noBodyPadding?: boolean;
  noHeaderPadding?: boolean;
  children?: React.ReactNode; //Conteudo a ser exibido no body do accordion
  accordionIcon?: React.ReactNode;
  headerDetails?: React.ReactNode;
  expandedIcon?: 'plus' | 'arrow';
  showOnlyRightIcon?: boolean;
  showBorder?: boolean;
};

interface LocalProps extends AccordionDefaultProps {
  handleLocalState: boolean;
  accordionExpanded?: never;
  setAccordionExpanded?: never;
}

interface ChildProps extends AccordionDefaultProps {
  handleLocalState?: never;
  accordionExpanded: boolean;
  setAccordionExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

type AccordionProps = LocalProps | ChildProps;

export const Accordion: React.FC<AccordionProps> = ({
  id,
  bgColor,
  children,
  noRadius,
  marginTop,
  accordionIcon,
  headerDetails,
  noBodyPadding,
  noHeaderPadding,
  accordionTitle,
  handleLocalState,
  accordionExpanded,
  showHeader = true,
  maxheight = '100vh',
  expandedIcon,
  setAccordionExpanded,
  showOnlyRightIcon,
  showBorder,
}) => {
  const [isOpened, setIsponed] = useState(!!accordionExpanded);

  const handleAccordion = useCallback(
    (val: boolean) => {
      setIsponed(val);
      if (setAccordionExpanded) setAccordionExpanded(val);
    },
    [setAccordionExpanded]
  );

  const isExpanded = useMemo(
    () => (handleLocalState ? isOpened : accordionExpanded || false),
    [isOpened, handleLocalState, accordionExpanded]
  );

  return (
    <S.AccordionWrapper $bgColor={bgColor} $noRadius={noRadius} className="accordion-wrapper">
      {showHeader && (
        <React.Fragment>
          <S.AccordionHeader
            $showborder={showBorder}
            $noheaderpadding={noHeaderPadding}
            data-test="accordion-handle-expand"
            onClick={() => handleAccordion(handleLocalState ? !isOpened : !accordionExpanded)}
          >
            <S.HeaderController>
              <S.Label>
                <>
                  {showOnlyRightIcon && (
                    <S.AccordionController $isExpanded={false}>
                      {isExpanded ? <RiArrowUpSLine size={24} /> : <RiArrowDownSLine size={24} />}
                    </S.AccordionController>
                  )}
                  {accordionIcon && accordionIcon}
                  {accordionTitle}
                </>
              </S.Label>

              {!showOnlyRightIcon && (
                <S.AccordionController $isExpanded={isExpanded}>
                  {expandedIcon === 'arrow' ? (
                    <RiArrowDownSLine size={24} />
                  ) : isExpanded ? (
                    <HiMiniMinusSmall color={colors.gray.gray40} size={18} />
                  ) : (
                    <RiAddFill color={colors.gray.gray40} size={18} />
                  )}
                </S.AccordionController>
              )}
            </S.HeaderController>

            {headerDetails && <S.HeaderDetails>{headerDetails}</S.HeaderDetails>}
          </S.AccordionHeader>
          {showBorder && <S.Divider />}
        </React.Fragment>
      )}

      <S.AccordionBody
        $bgColor={bgColor}
        $noRadius={noRadius}
        maxheight={maxheight}
        margintop={marginTop}
        $isExpanded={isExpanded}
        $nobodypadding={noBodyPadding}
      >
        {children}
      </S.AccordionBody>
    </S.AccordionWrapper>
  );
};
