import React from 'react';
import * as S from './styles';
// import TruckSVG from "@/assets/svgs/truck.svg";

const TruckSVG = `${process.env.PUBLIC_URL}/truck.svg`;

const EmptyCart: React.FC = () => {
  return (
    <S.PageContainer>
      <S.IconContainer>
        <S.Icon src={TruckSVG} />
      </S.IconContainer>
      <S.TextContainer>
        <S.BoldText>Seu carrinho está vazio</S.BoldText>
        <S.Text>
        Comece comprando pelo botão abaixo e aproveite todas as nossas ofertas!
        </S.Text>
      </S.TextContainer>
      <S.ButtonOuterContainer>
        <S.ButtonContainer to="/produtos">
          <S.ButtonLabel>Comece a comprar</S.ButtonLabel>
        </S.ButtonContainer>
      </S.ButtonOuterContainer>
    </S.PageContainer>
  )
}

export default EmptyCart;