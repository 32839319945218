import { colors } from '@/constants';
import styled, { css } from 'styled-components';
import { RiCalendarEventLine, RiCalendarScheduleLine, RiLineChartFill, RiSpeedUpLine, RiTrophyLine } from 'react-icons/ri';

export const TrophyLine = styled(RiTrophyLine)`
  color: ${colors.brand.white};
`;

export const ContentTrophy = styled.div`
  display: grid;
  padding: 12px;
  border-radius: 6px;
  place-items: center;
  margin-bottom: 27px;
  background-color: ${colors.green5};
`;

export const SpeedUpLine = styled(RiSpeedUpLine)`
  color: ${colors.brand.white};
`;

export const LineChartFill = styled(RiLineChartFill)`
  color: ${colors.brand.white};
`;

export const CalendarTimer = styled(RiCalendarScheduleLine)`
  color: ${colors.brand.white};
`;

export const CalendarIcon = styled(RiCalendarEventLine)`
  color: ${colors.brand.grafite};
`;

export const Container = styled.div`
  min-height: 100vh;
  position: relative;
  background-color: ${colors.brand.white};
`;

export const ButtonAccess = styled.button<{ disabled: boolean }>`
  width: 100%;
  border: none;
  padding: 18px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  color: ${colors.brand.white};
  background-color: ${colors.green};
  opacity: ${(i) => (i.disabled ? 0.75 : 1)};
`;

export const BgDark = styled.div`
  padding: 5px 20px 14px 20px;
  background-color: ${colors.brand.grafite};
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0px;
  background-color: ${colors.grey11};
`;

export const Row = styled.div<{
  justify?: 'center' | 'space-between' | 'space-around' | 'start';
  mb?: number;
  align?: 'center';
  gap?: number;
}>`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  gap: ${(i) => i.gap || 0}px;
  align-items: ${(i) => i.align};
  margin-bottom: ${(i) => i.mb}px;
  justify-content: ${(i) => i.justify || 'space-between'};
`;

export const Col = styled.div<{ width?: string, align?: 'center' }>`
  display: flex;
  flex-direction: column;
  align-items: ${i => i.align};
  ${(i) =>
    i.width &&
    css`
      width: ${i.width};
    `};
`;

export const Content = styled.div<{ $isDanger?: boolean }>`
  margin-top: -${(i) => (i.$isDanger ? 6 : 0)}px;
  padding: ${(i) => (i.$isDanger ? '25px 20px 40% 20px' : '0px 20px')};
  border-radius: ${(i) => (i.$isDanger ? '6px 6px' : '0px 0px')} 0px 0px;
  background-color: ${(i) => (i.$isDanger ? colors.alert.red50 : colors.brand.white)};
`;

export const Message = styled.div`
  display: grid;
  margin-bottom: -35px;
  transform: translateY(-40px);
`;

export const GraphicUpImg = styled.img`
  width: 20px;
`;

export const GraphicIconContent = styled.div`
  padding: 6px;
  width: 32px;
  display: grid;
  place-items: center;
  height: 32px;
  border-radius: 6px;
  background-color: ${colors.brand.red};
`;

export const GraphicIconMessage = styled.p`
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: ${colors.brand.white};
`;

export const UpContentMessage = styled.div`
  gap: 10px;
  padding: 15px;
  display: inline-flex;
  border-radius: 6px 6px 0px 0px;
  background-color: ${colors.grey7};
`;

export const Enphasis = styled.span`
  border-radius: 3px;
  padding: 1px 3px;
  font-size: 12px;
  font-weight: 700;
  color: ${colors.alert.red200};
  background-color: ${colors.alert.red100};
`;

export const DownContentMessage = styled.div`
  display: grid;
  margin-top: 8px;
  margin-bottom: 4px;
  grid-template-columns: 2fr 1fr;
`;

export const LeftDays = styled.div`
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${colors.brand.white};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: ${colors.alert.red200};
`;

export const RightDays = styled.div`
  padding: 5px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${colors.alert.red200};
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${colors.alert.red100};
`;

export const SubTotal = styled.p`
  font-size: 14px;
  font-weight: 500;
  padding: 20px 0px 26px 0px;
  text-align: right;
  color: ${colors.brand.grafite};
  span {
    font-size: 20px;
    margin-left: 3px;
    font-weight: 800;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  text-align: left;
  font-weight: 700;
  margin: 0px 0px 20px 0px;
  color: ${colors.brand.red};
`;

export const ContentTabSlider = styled.div`
  top: 50px;
  z-index: 9;
  position: sticky;
  margin-bottom: 15px;
`;

export const ContentListCompany = styled.div`
  padding-bottom: 200px;
`;

export const FloatMessage = styled.div`
  z-index: 9;
  bottom: 0px;
  width: 100%;
  position: fixed;
  text-align: right;
  max-width: var(--mobile);
  padding: 20px 20px 40px 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: ${colors.brand.white};
  box-shadow: 0px 0px 20px 0px ${colors.shadown1};
`;

export const CycleCard = styled.div`
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid ${colors.gray.gray50};
`;

export const Divisor = styled.div<{ direction?: 'line' | 'column' }>`
  background-color: ${colors.gray.gray50};
  width: ${(i) => (i.direction === 'line' ? '100%' : '1px')};
  height: ${(i) => (i.direction !== 'line' ? 'auto' : '1px')};
  margin: ${(i) => (i.direction === 'line' ? '16px 0px' : '0px 16px')};
`;

export const Icon = styled.div``;

export const Text = styled.p<{
  bg?: string;
  bold?: number;
  size?: number;
  color?: string;
  radius?: number;
  padding?: string;
  display?: string;
  margintop?: number;
  marginbottom?: number;
  textalign?: 'left' | 'right' | 'center';
}>`
  align-items: center;
  display: ${(i) => i.display};
  background-color: ${(i) => i.bg};
  text-align: ${(i) => i.textalign};
  padding: ${(i) => i.padding || '0px'};
  margin-top: ${(i) => i.margintop || 0}px;
  border-radius: ${(i) => i.radius || 0}px;
  font-size: ${(i) => (!i.size ? 12 : i.size)}px;
  margin-bottom: ${(i) => i.marginbottom || 0}px;
  font-weight: ${(i) => (!i.bold ? 400 : i.bold)};
  color: ${(i) =>
    i.color === 'gray' ? colors.gray.gray40 : i.color === 'red' ? colors.brand.red : i.color || colors.brand.grafite};
`;

export const Span = styled.span<{ bg?: string; radius?: number; padding?: string; size?: number, margin?: string }>`
  margin: ${(i) => i.margin};
  padding: ${(i) => i.padding};
  font-size: ${(i) => i.size}px;
  background-color: ${(i) => i.bg};
  border-radius: ${(i) => i.radius}px;
`;

export const Total = styled.span`
  font-size: 18px;
  font-weight: 800;
`;
