import * as S from './styles';
import { Loading } from "@/components"

interface CompanyDataProps {
    companyData: any,
    handleNextStep: () => void
}

export const CompanyData: React.FC<CompanyDataProps> = ({ companyData, handleNextStep }) => {
    const { data } = companyData;

    const companyInfo = [
        { label: 'CNPJ', value: data?.companyExternalData?.cnpj },
        { label: 'Razão Social', value: data?.companyExternalData?.razao_social },
        { label: 'Nome Fantasia', value: data?.companyExternalData?.nome_fantasia || 'Não informado' },
        { label: 'Segmento', value: data?.companyExternalData?.atividade_principal },
        { label: 'Inscrição Estadual', value: data?.companyExternalData?.inscricao_estadual || 'Não informada' },
        { label: 'Telefone', value: data?.companyExternalData?.telefone },
        { label: 'Endereço', value: `${data?.companyExternalData?.logradouro}, ${data?.companyExternalData?.numero} - ${data?.companyExternalData?.bairro}, ${data?.companyExternalData?.municipio}, ${data?.companyExternalData?.uf}, CEP: ${data?.companyExternalData?.cep}` },
    ];

    const companyAddress = [
        { label: 'CEP', value: data?.companyExternalData?.cep },
        { label: 'Endereço', value: `${data?.companyExternalData?.logradouro}, ${data?.companyExternalData?.numero}` },
        { label: 'Complemento', value: data?.companyExternalData?.complemento.includes('**') ? 'Não possui' : data?.companyExternalData?.complemento },
        { label: 'Bairro', value: data?.companyExternalData?.bairro },
        { label: 'Estado', value: data?.companyExternalData?.uf },
        { label: 'Cidade', value: data?.companyExternalData?.municipio },
    ]

    return (
        <S.Container>
            <S.Box>
                <S.Label>
                    Confirme os dados da empresa
                </S.Label>
                <S.InfoBox>
                    {companyInfo.map((item, index) => {
                        return (
                            <S.Row key={index}>
                                <S.Label>{item.label}</S.Label>
                                <span>{item.value}</span>
                            </S.Row>
                        )
                    })}
                </S.InfoBox>
            </S.Box>
            <S.Box>
                <S.Label>
                    Endereço para entrega
                </S.Label>
                <S.InfoBox>
                    {companyAddress.map((item, index) => {
                        return (
                            <S.Row key={index}>
                                <S.Label>{item.label}</S.Label>
                                <span>{item.value}</span>
                            </S.Row>
                        )
                    })}
                </S.InfoBox>
            </S.Box>
            <S.Button onClick={handleNextStep}>Avançar</S.Button>
        </S.Container>
    )
}
