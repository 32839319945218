import { colors } from "@/constants";
import styled from "styled-components";

export const SuccessPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  padding-bottom: 100px;
`;

export const OrderHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OrderContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const OrderNumber = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  color: ${colors.brand.grafite};
`;

export const OrderDate = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  color: ${colors.grey3};
`;
