import { colors } from "@/constants";
import styled, { keyframes } from "styled-components";

const breatheAnimation = keyframes`
 0% { opacity: 1 }
 50% { opacity: 0.3 }
 100% { opacity: 1 }
`;

export const Content = styled.div`
  text-align: center;
  animation-duration: 2s;
  animation-name: ${breatheAnimation};
  animation-iteration-count: infinite;
`;

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  overflow: hidden;
  place-items: center;
  background: ${colors.background2};
`;

export const Label = styled.p`
  color: ${colors.grey2};
  font-size: 19px;
`;
