// import * as Sentry from "@sentry/react";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
  window.location.hostname === "[::1]" ||
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

type Config = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const registerServiceWorker = () => {
  console.log('Aqui');

  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

  if ("serviceWorker" in navigator) {

    console.log('Service Workers are supported.');

    window.addEventListener("load", () => {
      console.log('Loadzou');

      navigator.serviceWorker.register(`${process.env.PUBLIC_URL}/service-worker.js`, {scope: '/'})
        .then((registration) => {
          console.log('Service worker registered with scope: ', registration.scope);
          checkValidServiceWorker(swUrl);

          showConnectivityStatus();
          navigator.serviceWorker.ready.then(() => {
            console.log(
              "READY SW: This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA"
            );
            // Sentry.captureMessage("This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA");
            registerValidSW(swUrl);
          });
        })
        .catch((error) => {
          console.error('Service worker registration failed: ', error);
        });
    });
  } else {
    console.warn('Service Workers are not supported in this browser.');
  }
}

export function register(config?: Config) {
  // Sentry.captureMessage("Service Worker Registration");
  console.log('Registerrrr', process.env.NODE_ENV)
  if ((process.env.NODE_ENV === "production" || process.env.NODE_ENV === 'development') && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }
    window.addEventListener("load", () => {

      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        console.log('is local host: ', isLocalhost, config);
        checkValidServiceWorker(swUrl, config);
        // Sentry.captureMessage("serviceworker >>> is local host");
        navigator.serviceWorker.ready.then(() => {
          console.log(
            "This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA"
          );
          // Sentry.captureMessage("This web app is being served cache-first by a service worker. To learn more, visit https://cra.link/PWA");
          registerValidSW(swUrl, config);
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              console.log("New content is available and will be used when all tabs for this page are closed.");

              // Exibe a mensagem que o conteúdo foi atualizado e define um timer para recarregar a página após 5 segundos
              console.log('App has been updated. This page will refresh in 5 seconds.');
              setTimeout(() => {
                window.location.reload();
              }, 5000); // Aguarda 5 segundos antes de recarregar a página

              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              console.log("Content is cached for offline use.");
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}


function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Sentry.captureMessage("checkValidServiceWorker");
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then((response) => {
      const contentType = response.headers.get("content-type");

      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf("javascript") === -1)
      ) {
        // Sentry.captureMessage("No service worker found. Falling back to the offline page.");
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            // window.location.reload();
          }).catch((error) => {
            // Sentry.captureException("Erro ao desregistrar o service worker:", error);
            console.log('Erro ao desregistrar o service worker:', error);
          });
        }).catch((error) => {
          // Sentry.captureException("Erro ao acessar navigator.serviceWorker.ready:", error);
          console.log('Erro ao acessar navigator.serviceWorker.ready:', error);
        });

      } else {
        // Sentry.captureMessage("Service worker found. Registering the service worker.");
        registerValidSW(swUrl, config);
      }
    })
    .catch((error) => {
      // Sentry.captureException("Erro ao buscar o service worker:", error);
      console.error('Erro ao buscar o service worker:', error);
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}


export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

export function showConnectivityStatus() {
  let isOnline = navigator.onLine;
  // const statusSec = document.createElement('h1')

  if (!isOnline) {
    // statusSec.textContent = "You're currently offline.";
  }

  window.addEventListener("online", () => {
    console.log('>>>>>>>>>>>>>>tá online<<<<<<<<<<<<<<');
    // statusSec.textContent = "Your internet conection was restored.";
    localStorage.setItem('@PWA_RCA:network_status', 'online');
    isOnline = true;
  });

  window.addEventListener("offline", () => {
    // statusSec.textContent = "You're currently offline.";
    console.log('>>>>>>>>>>>>>tá offlineeee<<<<<<<<<<<<<');
    localStorage.setItem('@PWA_RCA:network_status', 'offline');
    isOnline = false;
    window.location.reload();
  });
}