import * as S from './styles';
import * as R from '@/constants/systemRoutes';

const RiEmptyCartLine = `${process.env.PUBLIC_URL}/RiLuggageCartItem.svg`;

export const EmptyOrderPage = () => {
    return (
        <S.Container>
            <S.Icon>
                <img src={RiEmptyCartLine} alt="" />
            </S.Icon>
            <S.Title>
                Não há pedidos realizados
            </S.Title>
            <S.Description>
                Comece comprando pelo botão abaixo e aproveite todas as nossas ofertas!
            </S.Description>
            <S.ActionButton to={R.ECOMMERCE.link}>
                Comece a comprar
            </S.ActionButton>
        </S.Container>
    )
}

export default EmptyOrderPage;