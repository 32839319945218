import { colors } from "@/constants";
import styled from "styled-components";

export const TooltipContent = styled.div`
  margin: 0px 12px;
  position: relative;
  width: calc(100% - 24px);
`;

export const TooltipValue = styled.span<{ percentage: number }>`
  bottom: 0px;
  z-index: 9;
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  border-radius: 6px;
  text-align: center;
  padding: 4px 8px 4px 8px;
  left: ${(i) => i.percentage}%;
  color: ${colors.brand.grafite};
  background-color: ${colors.brand.white};
  transform: translateX(-50%) translateY(-15%);
  box-shadow: 0px 4px 10px 0px ${colors.shadown7};
  &::before {
    content: "";
    width: 10px;
    left: 50%;
    bottom: -50%;
    height: 10px;
    border-bottom-right-radius: 2px;
    position: absolute;
    transform: rotate(45deg) translateX(-80%);
    background-color: ${colors.brand.white};
  }
`;

export const ValueContent = styled.span`
  cursor: pointer;
`;

export const ContentInput = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 10%;
`;

export const Mark = styled.span`
  width: 1px;
  height: 11px;
  position: relative;
  display: flex;
  z-index: 9;
  flex-direction: column;
  background-color: ${colors.grey11};
  span {
    top: 0px;
    left: 50%;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    position: absolute;
    color: ${colors.gray.gray50};
    transform: translateY(100%) translateX(-50%);
  }
`;

export const ContentMark = styled.div`
  width: 100%;
  /* z-index: -1; */
  left: 0px;
  bottom: 0px;
  position: absolute;
  display: inline-flex;
  transform: translateY(50%);
  justify-content: space-around;
`;

export const Input = styled.input<{
  theme?: "danger" | "success";
  percentage: number;
}>`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 6px;
  outline: none;
  height: 12px;
  cursor: pointer;
  appearance: none;
  position: relative;
  z-index: 9;
  border-radius: 50em;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: ${(i) =>
    `linear-gradient(90deg, ${
      i.theme === "danger" ? colors.brand.red : colors.green
    } ${i.percentage}%, ${colors.gray.gray30} ${i.percentage + 0.1}%)`};
  &::-webkit-slider-thumb {
    z-index: 9;
    width: 24px;
    height: 24px;
    cursor: pointer;
    appearance: none;
    border-radius: 50%;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: ${colors.brand.white};
    transition: background 0.15s ease-in-out;
    box-shadow: 0px 4px 18px 0px ${colors.shadown5};
    &:hover {
      transform: scale(0.97);
    }
    &:active {
      transform: scale(0.92);
    }
  }
`;
