import * as S from './styles';

interface MessageProps {
    message?: string,
    type?: string
}

export const MessageField: React.FC<MessageProps> = ({ message, type }) => {

    return (
        <S.ErrorWrapper type={type}>
            <S.Message>{message}</S.Message>
        </S.ErrorWrapper>
    )
}