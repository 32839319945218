export const arrToObj = (v: any[]): any =>
  v.reduce((acc, item) => {
    const [[key, value]] = Object.entries(item);
    return { ...acc, [key]: value };
  }, {});

export const cleanStr = (v: string): string =>
  v
    ?.trim()
    ?.toLowerCase()
    ?.normalize("NFD")
    .replace(/\p{Mn}/gu, "") || v;


export const cleanInput = (v: string): string =>  v?.trim()?.replace(/[^0-9.]/g, '');

export const copy = (v: any): any => {
  try {
    return JSON.parse(JSON.stringify(v))
  } catch (e) {
    return v
  }
}

