import { colors } from "@/constants";
import styled, { css } from "styled-components";

interface RadioProps {
  checked: boolean;
}

const checkedStylesWrapper = (checked: boolean) =>
  checked &&
  css`
    background-color: ${colors.green2};
    color: ${colors.alert.green300} svg {
      color: ${colors.brand.white};
    }
  `;

const checkedStylesCheckbox = (checked: boolean) => css`
  border: solid 2px ${checked ? colors.alert.green300 : colors.grey5};
  ${checked &&
  `
        background-color: ${colors.alert.green300};
        svg {
            color: ${colors.brand.white};
        }
    `}
`;

export const RadioWrapper = styled.div<RadioProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 100%;
  padding: 0px 12px;

  ${(props) => checkedStylesWrapper(props.checked)}
`;

export const CustomCheckbox = styled.div<RadioProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  svg {
    width: 20px;
    height: 20px;
  }
  ${(props) => checkedStylesCheckbox(props.checked)}
`;
