import { useState, useEffect, useCallback } from 'react';

type PasswordStrengthResult = {
    validateLowerCaseChar: boolean;
    validateUpperCaseChar: boolean;
    validateLength: boolean;
    validateNumericChar: boolean;
    validPassword: boolean;
    validatePassword: any;
};

export const usePasswordStrength = (password?: string | null): PasswordStrengthResult => {
    const [validateLowerCaseChar, setValidateLowerCaseChar] = useState<boolean>(false);
    const [validateUpperCaseChar, setValidateUpperCaseChar] = useState<boolean>(false);
    const [validateLength, setValidateLength] = useState<boolean>(false);
    const [validateNumericChar, setValidateNumericChar] = useState<boolean>(false);
    const [validPassword, setValidPassword] = useState<boolean>(false);

    const validatePassword = useCallback((value: string) => {
        const strongPass = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');
        const lowerCaseAlphaChar = new RegExp('^(?=.*[a-z])');
        const upperCaseAlphaChar = new RegExp('^(?=.*[A-Z])');
        const numericChar = new RegExp('^(?=.*[0-9])');
        const minLength = new RegExp('^(?=.{8,})');

        setValidateLowerCaseChar(lowerCaseAlphaChar.test(value));
        setValidateUpperCaseChar(upperCaseAlphaChar.test(value));
        setValidateNumericChar(numericChar.test(value));
        setValidateLength(minLength.test(value));

        setValidPassword(strongPass.test(value));
    }, []);

    useEffect(() => {
        if (password) {
            validatePassword(password);
        }
    }, [password, validatePassword]);

    return {
        validateLowerCaseChar,
        validateUpperCaseChar,
        validateLength,
        validateNumericChar,
        validPassword,
        validatePassword
    };
};
