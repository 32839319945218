import * as S from "./styles";
import { TabCodes, TabParams } from "@/types";

type Props = {
  lightMode?: boolean;
  listTabs: TabParams[];
  selectedTab: TabCodes;
  setSelectedTab: (value: TabCodes) => void;
};

const TabSlider = ({
  listTabs,
  lightMode,
  selectedTab,
  setSelectedTab,
}: Props) => {
  return (
    <S.HeaderContent $lightmode={lightMode}>
      {listTabs.map((tab) => {
        const isActive = tab.code === selectedTab;

        return (
          <S.TabHeader
            key={tab.code}
            $active={isActive}
            $lightmode={lightMode}
            onClick={() => !tab.disabled && setSelectedTab(tab.code)}
            data-test={`component-select-tab-${tab.code}`}
          >
            <S.Title
              className={tab.disabled ? "disabled-service" : ""}
              $isactive={isActive}
            >
              {tab.title}
            </S.Title>

            {!!tab.notification && (
              <S.Counter $lightmode={lightMode} $isactive={isActive}>
                {tab.notification}
              </S.Counter>
            )}
          </S.TabHeader>
        );
      })}
    </S.HeaderContent>
  );
};

export default TabSlider;
