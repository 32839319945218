import { colors } from "@/constants";
import styled from "styled-components";
import { MdGridView } from "react-icons/md";
import { RiSubtractLine } from "react-icons/ri";

import {
  RiAddLine,
  RiCloseLine,
  RiListCheck2,
  RiCheckboxFill,
  RiEqualizerLine,
  RiArrowUpDownLine,
  RiArrowLeftLine,
  RiCheckboxBlankLine,
} from "react-icons/ri";

export const RowFilter = styled.div`
  width: 100%;
  align-items: center;
  display: inline-flex;
  justify-content: space-between;
`;

export const ContainerFilter = styled.div`
  padding: 0px 15px 0px 15px;
`;

export const CheckBlank = styled(RiCheckboxBlankLine)`
  color: ${colors.grey4};
`;

export const CheckFill = styled(RiCheckboxFill)`
  color: ${colors.green};
`;

export const Label = styled.p``;

export const LabelSpan = styled.span`
  font-weight: 400;
  color: ${colors.gray.gray40};
`;

export const Count = styled.p`
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: ${colors.brand.grafite};
`;

export const CountLabel = styled.p`
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  text-wrap: nowrap;
  color: ${colors.gray.gray40};
`;

export const ContentCount = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

export const ContentFooter = styled.div`
  bottom: 0px;
  width: 100%;
  height: 90px;
  display: inline-flex;
  gap: 48px;
  position: absolute;
  padding: 0px 2rem;
  place-items: center;
  border-radius: 10px 10px 0px 0px;
  background-color: ${colors.brand.white};
  border-top: 1px solid rgb(233, 232, 231);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px;
`;

export const Button = styled.button`
  width: 100%;
  border: none;
  height: 45px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  color: ${colors.brand.white};
  background-color: ${colors.brand.red};
`;

export const Item = styled.button<{ $isactive: boolean }>`
  gap: 10px;
  padding: 10px;
  font-size: 14px;
  margin-right: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  align-items: center;
  display: inline-flex;
  transition: all 0.25s;
  font-weight: ${(i) => (i.$isactive ? 700 : 400)};
  color: ${(i) => (i.$isactive ? colors.brand.white : colors.brand.grafite)};
  border: 1px solid
    ${(i) => (i.$isactive ? colors.brand.red : colors.gray.gray10)};
  background-color: ${(i) =>
    i.$isactive ? colors.brand.red : colors.transparent};
  span {
    color: ${(i) => (i.$isactive ? colors.brand.white : colors.gray.gray40)};
  }
`;

export const ArrowBackContent = styled.div`
  width: 30%;
  display: grid;
`;

export const ArrowBack = styled(RiArrowLeftLine)`
  color: ${colors.brand.white};
`;

export const Notification = styled.span<{ right?: number }>`
  right: 0px;
  font-size: 11px;
  font-weight: 700;
  position: absolute;
  border-radius: 60px;
  padding: 3px 3px 4px 3px;
  color: ${colors.brand.white};
  background-color: ${colors.brand.red};
  transform: translateX(${(i) => i.right || 100}%) translateY(-30%);
`;

export const ModalTitleContent = styled.div`
  width: 40%;
`;

export const ModalTitle = styled.div`
  font-size: 18px;
  width: fit-content;
  margin: auto;
  font-weight: 700;
  text-align: center;
  position: relative;
  color: ${colors.brand.white};
`;

export const BorderedHeader = styled.div`
  width: 100%;
  height: 8px;
  background-color: ${colors.brand.white};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  position: absolute;
  bottom: 0px;
  left: 0px;
`;

export const ModalHeader = styled.div`
  top: 0px;
  z-index: 1;
  bottom: 0px;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  position: sticky;
  display: inline-flex;
  padding: 10px 15px 18px 15px;
  justify-content: space-between;
  background-color: ${colors.brand.grafite};
`;

export const ClearFilter = styled.button`
  border: none;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  width: 30%;
  text-align: right;
  color: ${colors.brand.white};
  background-color: ${colors.transparent};
`;

export const CheckedList = styled.div`
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  flex-direction: row;
`;

export const RemoveIcon = styled(RiCloseLine)`
  color: ${colors.grey14};
`;

export const CheckedItem = styled.p`
  gap: 0.5rem;
  display: flex;
  font-size: 14px;
  padding: 0.8rem;
  font-weight: 500;
  border-radius: 5px;
  align-items: center;
  color: ${colors.brand.grafite};
  background-color: ${colors.background1};
`;

export const ContentModal = styled.div`
  top: 100px;
  height: 100%;
  display: grid;
  position: sticky;
  align-content: baseline;
  padding: 15px 20px 120px 20px;
  background-color: ${colors.brand.white};
`;

export const ColLeft = styled.div`
  display: inline-flex;
`;

export const BgBody = styled.div`
  background-color: ${colors.brand.grafite};
`;

export const TitleContainerList = styled.h6`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  margin-bottom: 20px;
  color: ${colors.brand.grafite};
`;

export const IconMore = styled(RiAddLine)`
  margin-right: 10px;
`;

export const IconMinus = styled(RiSubtractLine)`
  margin-right: 10px;
`;

export const ShowMore = styled.button`
  width: 100%;
  border: none;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
  display: inline-flex;
  color: ${colors.brand.red};
  background-color: ${colors.transparent};
`;

export const ContainerList = styled.div`
  flex-wrap: wrap;
  display: inline-flex;
`;

export const Divisor = styled.div`
  height: 1px;
  width: 100%;
  margin: 10px 0px 20px 0px;
  background-color: ${colors.grey13};
`;

export const OrderButton = styled.p`
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  display: inline-flex;
  color: ${colors.brand.grafite};
`;

export const OrderIcon = styled(RiArrowUpDownLine)``;

export const ContentFilterIcon = styled.div`
  position: relative;
`;

export const FilterButton = styled.div`
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  position: relative;
  align-items: center;
  display: inline-flex;
  color: ${colors.brand.grafite};
`;

export const FilterIcon = styled(RiEqualizerLine)``;

export const ColRight = styled.div``;

export const DirectionControl = styled.div`
  padding: 5px;
  border-radius: 6px;
  display: inline-flex;
  background-color: ${colors.background1};
`;

export const ButtonDirection = styled.button<{ $active?: boolean }>`
  display: grid;
  padding: 8px;
  border: none;
  border-radius: 6px;
  transition: all 0.45s;
  place-content: space-around;
  background-color: ${(i) =>
    i.$active ? colors.brand.white : colors.transparent};
`;

export const GridIcon = styled(MdGridView)`
  color: ${colors.brand.grafite};
`;

export const ListIcon = styled(RiListCheck2)`
  color: ${colors.brand.grafite};
`;
