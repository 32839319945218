import { colors } from "@/constants";
import styled from "styled-components";
import { RiCheckboxCircleFill } from "react-icons/ri";

export const BoxQtdEmbalagem = styled.div`
  padding: 8px 10px;
  margin-bottom: 10px;
  border-radius: 0px 6px 6px;
  background-color: ${colors.gray.gray10};
`;

export const QtdEmbalagem = styled.div`
  white-space: nowrap;
  font-weight: 400;
  font-size: 11px;
  text-align: left;
  line-height: 120%;
  margin-bottom: 0px;
  color: ${colors.gray.gray60};
`;

export const Row = styled.div`
  gap: 5px;
  margin-top: 5px;
  flex-direction: row;
  display: inline-flex;
`;

export const CheckFill = styled(RiCheckboxCircleFill)`
  top: -5px;
  right: -5px;
  position: absolute;
  border-radius: 50%;
  background-color: ${colors.brand.white};
`;

export const PackImage = styled.img``;

export const BoxEmbalagem = styled.div<{ $isactive: boolean; size?: number }>`
  gap: 5px;
  width: ${(i) => i.size || 50}px;
  height: ${(i) => i.size || 50}px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-top: 7px;
  position: relative;
  align-items: center;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  transition: all 0.35s;
  border-radius: 6px 6px 0px 0px;
  background-color: ${(i) =>
    i.$isactive ? colors.gray.gray10 : colors.transparent};
  border-top: 2px solid
    ${(i) => (i.$isactive ? colors.gray.gray50 : colors.transparent)};
`;

export const BoxLabel = styled.p`
  color: ${colors.gray.gray50};
  text-align: center;
  font-size: 9px;
  font-weight: 500;
  margin: 0px;
  line-height: normal;
`;
