import { useMemo, useState, useEffect, useCallback } from "react";
import { orderMock } from "../../mocks/orderMocks";
import { ProductProps } from "@/types";
import { CartItems } from "@/hooks/CartContext";

import { useGlobals } from "@/hooks";
import { useNetworkStatusContext } from "@/hooks";

import { useClient } from "@/hooks";
interface CartProductItem {
    item: CartItems;
    product: ProductProps | undefined;
}

export const useSuccessPage = () => {
    const [orderItems, setOrderItems] = useState<any>(null);
    const [orderData, setOrderData] = useState<any>(null);
    const [cartTotals, setCartTotals] = useState<any>(null);

    const { selectedClientAddress } = useClient();
    const { connectivityStatus } = useNetworkStatusContext();
    const { setCanAccessSuccess } = useGlobals();

    useEffect(() => {
        const storedOrderData = sessionStorage.getItem('orderItems');
        const storedOrder = sessionStorage.getItem('orderData');
        const storedCartTotals = sessionStorage.getItem('cartTotals');

        if (storedOrderData && storedOrder && storedCartTotals) {
            try {
                const parsedData = JSON.parse(storedOrderData);
                setOrderItems(parsedData);
                const parsedOrder = JSON.parse(storedOrder);
                setOrderData(parsedOrder)
                const parsedCartTotals = JSON.parse(storedCartTotals);
                setCartTotals(parsedCartTotals);

                //Remover os dados do storage para nao persistirem entre sessoes
                sessionStorage.removeItem('orderItems');
                sessionStorage.removeItem('orderData');
                sessionStorage.removeItem('cartTotals');
                
            } catch (error) {
                console.error("Error parsing stored order data", error);
            }
        }
    }, []);

    //orderData

    const shippingData = useMemo(() => {
        if (selectedClientAddress && orderData) {
            return [
                { 
                    dataType: 'address',
                    ...selectedClientAddress
                },
                {
                    dataType: 'shipping',
                    carrier_code: 'freeshipping',
                    carrier_title: orderMock.shipping_method,
                    amount: { currency: "BRL", value: 0, __typename: "Money" },
                    // delivery_date: orderMock.gp_order_ext.delivery_date,
                    method_code: 'freeshipping',
                    method_title: 'Free'
                },
                {
                    dataType: 'payment',
                    total: orderData?.orders?.total,
                    method: orderData?.orders?.payment_terms
                }
            ];
        }
        return null;
    }, [selectedClientAddress, orderData]);

    const orderNumber = useMemo(() => {
        if (orderData) return orderData.orders?.order_number;
        return null;
    }, [orderData]);

    const orderDate = useMemo(() => {
        if (orderData) return orderData.orders?.orderDate;
    }, [orderData]);

    return {
        shippingData,
        orderNumber,
        orderDate,
        productList: orderItems,
        connectivityStatus,
        setCanAccessSuccess,
        cartTotals
    };
}
