import { colors } from "@/constants";
import styled from "styled-components";

export const HeaderContainer = styled.div<{ }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  background-color: ${colors.background1};
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  text-align: left;
  color: ${colors.brand.grafite};
`;

export const CartItemsCount = styled.span`
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: ${colors.brand.grafite};
`;

export const IconContainer = styled.div`
  background-color: ${colors.brand.grafite};
  width: 33px;
  height: 33px;
  padding: 8px 9px;
  align-items: center;
  border-radius: 100%;
  margin-right: 14px;
  justify-content: center;
  align-items: center;
  /* align-content: center; */
  /* justify-items: center; */
`;

export const Icon = styled.img`
  width: 17px;
  height: 17px;
  /* margin-left: 20%; */
`;

export const IconButton = styled.button<{ $isfirst?: boolean }>`
  width: 53px;
  height: 40px;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  border: none;
  border-left-width: ${props => props.$isfirst ? 0 : 1}px;
  border-left-color: ${colors.gray.gray40};
  background-color: ${colors.background1};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const SearchInputContainer = styled.div`
  height: 50px;
  background: ${colors.background1};
  border-radius: 6px;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  width: '100%';
  padding: 0 15px;
`;

export const SearchInput = styled.input`
  height: 50px;
  width: '100%';
  padding: 0 10px;
`;

export const SearchContainer = styled.div`
  flex-direction: row;
  padding: 0 10px;
  justify-content: center;
  margin: 20px 0;
  align-items: center;
`;

export const ButtonLabel = styled.span``;

export const Button = styled.button`
  height: 50px;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${colors.borders.border1};
`;

export const SearchHeading = styled.div`
  padding-left: 20px;
  color: ${colors.brand.grafite};
  font-weight: 500;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
`;