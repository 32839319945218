import styled from "styled-components";
import { colors } from "@/constants";
import { Link } from "react-router-dom";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding-top: 82px;
`;

export const Icon = styled.div`
    img{
        width: 53px;
        height: 53px;
        opacity: 0.5;
    }
`;

export const Title = styled.span`
    font-size: 16px;
    font-weight: 700;
    line-height: 18.75px;
    color: ${colors.brand.grafite};

    text-align: center;
`;

export const Description = styled.span`
    max-width: 290px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    color: ${colors.gray.gray40};
    text-align: center;
`;

export const ActionButton = styled(Link)`
    background-color: ${colors.brand.red};
    border-radius: 6px;
    margin-top: 20px;
    width: 220px;
    padding: 12px 0;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.75px;
    text-decoration: none;
    color: ${colors.brand.white};
`;