import * as S from './styles';
import { RiUploadCloud2Line, RiAlertLine, RiCheckLine, RiDeleteBin2Line, RiRefreshLine } from "react-icons/ri";
import Loading from '@/components/spinner-loader/Loading';
import { useFileUpload } from '@/shared/uploadFIles';// Hook de upload
import { useEffect } from 'react';

const UploadStoreImages = ({ setNextStepDisabled }: any) => {
    const {
        files,
        uploadProgress,
        uploadError,
        uploading,
        alreadyMinFiles,
        getRootProps,
        getInputProps,
        retryUpload,
        retryAllFailedUploads,
        removeFile,
    } = useFileUpload({
        accept: { 'image/jpg': [], 'image/png': [] },
        maxSize: 5000000,
        minFiles: 3,
        maxFiles: 10
    });

    useEffect(() => {
        if (alreadyMinFiles && !(Object.keys(uploadError).length > 0)) {
            setNextStepDisabled(false);
        } else {
            setNextStepDisabled(true);
        }

    }, [alreadyMinFiles, uploadError]);

    return (
        <S.Container>
            <S.PageHeader>
                <S.PageTitle>Fotos da sua loja</S.PageTitle>
                <S.Description>As fotos devem ser da fachada e interior da loja</S.Description>
            </S.PageHeader>
            <S.PageContent>
                <S.UploadBox {...getRootProps()} success={alreadyMinFiles} error={Object.keys(uploadError).length > 0}>
                    <input {...getInputProps()} />
                    <S.UploadIcon success={alreadyMinFiles} error={Object.keys(uploadError).length > 0}>
                        {uploading ? (
                            <Loading size={32} borderSize={4} circularTopColor='#37322D' circularBorderColor='transparent' />
                        ) : !alreadyMinFiles && !(Object.keys(uploadError).length) ? (
                            <RiUploadCloud2Line size={48} />
                        ) : alreadyMinFiles && !(Object.keys(uploadError).length) ? (
                            <RiCheckLine size={48} />
                        ) : (
                            <RiAlertLine size={48} />
                        )}
                    </S.UploadIcon>

                    {!(Object.keys(uploadError).length > 0) ? (
                        <>
                            <S.UploadMessage>
                                {uploading ? 'Enviando arquivos ...' : !alreadyMinFiles ? (
                                    <>Solte seus arquivos aqui ou <b>Clique aqui</b></>
                                ) : (
                                    <>Obrigado! Você anexou o mínimo de imagens.</>
                                )}
                            </S.UploadMessage>
                            <S.UploadSmallMessage>
                                {uploading ? `Aguarde...` : !alreadyMinFiles ? `Max. 5MB | JPG, PNG` : null}
                            </S.UploadSmallMessage>
                        </>
                    ) : (
                        <>
                            <S.UploadMessage error={true}>
                                Falha ao carregar
                            </S.UploadMessage>
                            <S.UploadSmallMessage>
                                Um ou mais arquivos deram erro ao carregar
                            </S.UploadSmallMessage>
                            <S.ErrorRefetchAll onClick={retryAllFailedUploads}>
                                <RiRefreshLine />
                                Tente novamente
                            </S.ErrorRefetchAll>
                        </>
                    )}
                </S.UploadBox>

                {files.length > 0 && (
                    <S.FileList>
                        {files.map((file, index) => {
                            const progress = uploadProgress[file.id] || 0;
                            const hasError = uploadError[file.id];

                            return (
                                <S.FileItem key={index}>
                                    <S.FileIcon error={hasError}>
                                        {progress === 100 && !hasError ? (
                                            <RiCheckLine size={24} />
                                        ) : progress !== 100 && !hasError ? (
                                            <Loading size={20} borderSize={2} circularTopColor='#23A047' circularBorderColor='transparent' fullBorder={true} />
                                        ) : (
                                            <RiAlertLine size={24} />
                                        )}
                                    </S.FileIcon>
                                    <S.FileDetails>
                                        <S.FileName error={hasError}>
                                            {progress < 100 && !hasError
                                                ? `Fazendo upload do arquivo`
                                                : progress === 100 && !hasError
                                                    ? file.file.name
                                                    : hasError && `Erro de upload`}
                                        </S.FileName>
                                        <S.ProgressText>
                                            {progress < 100 && !hasError ? (
                                                <span>Aguarde...</span>
                                            ) : progress === 100 && !hasError ? (
                                                <span>{file.size}</span>
                                            ) : hasError && (
                                                <span>Tente Novamente</span>
                                            )}
                                            {!hasError && (
                                                <span>{progress}%</span>
                                            )}
                                        </S.ProgressText>

                                        {!hasError && (
                                            <S.ProgressBar>
                                                <S.ProgressFill style={{ width: `${progress}%` }} />
                                            </S.ProgressBar>
                                        )}
                                    </S.FileDetails>

                                    {!hasError ? (
                                        <S.RemoveButton onClick={() => removeFile(file.id)}>
                                            <RiDeleteBin2Line size={20} />
                                        </S.RemoveButton>
                                    ) : (
                                        <S.ErrorRefetch onClick={() => retryUpload(file)}>Enviar novamente</S.ErrorRefetch>
                                    )}
                                </S.FileItem>
                            );
                        })}
                    </S.FileList>
                )}
            </S.PageContent>
        </S.Container>
    );
};

export default UploadStoreImages;