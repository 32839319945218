import { colors } from "@/constants";
import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 6px;
  flex-direction: row;
  align-items: center;
  padding: 18px 0;
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: none;
  margin-top: 10px;
  padding-left: 10px;
  background-color: ${colors.brand.white};
  border-radius: 6px;
`;

export const CancelButton = styled.button<{ $show: boolean }>`
  flex: none;
  border: none;
  padding: 0px;
  display: flex;
  overflow: hidden;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.25s;
  color: ${colors.brand.white};
  opacity: ${(i) => (i.$show ? 1 : 0)};
  background-color: ${colors.transparent};
  margin-left: ${(i) => (i.$show ? 10 : 0)}px;
  max-width: ${(i) => (i.$show ? "100%" : "0px")};
`;