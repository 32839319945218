import * as S from '../styles';

import { RiCheckFill } from 'react-icons/ri';

interface StepProps {
    key: string,
    step: any,
    isComplete: boolean,
    isActive: boolean,
    index: number,
    totalLength: number
}

export const Step: React.FC<StepProps> = ({ key, step, isComplete, isActive, index, totalLength }) => {
    return (
        <S.StepWrapper key={key}>
            <S.StepLabel>{step.name}</S.StepLabel>
            <S.Mark isActive={isActive} isComplete={isComplete}>
                {isComplete && (
                    <RiCheckFill />
                )}
            </S.Mark>
            {
                index < totalLength - 1 && (
                    <S.Conector isActive={isComplete} />
                )
            }
        </S.StepWrapper>
    )
}