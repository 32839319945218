import * as S from './styles';
import { Packaging, InputRange, ToolTip } from '@/components';
import { calcPrice, formatCurrency } from '@/shared';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ProductProps, PackagesTypesProps, TaxSubstitutionProps } from '@/types';

import { useCart, useClient, useGlobals, useUserContext, useDiscountFlex, useDiscountAmountFlex } from '@/hooks';
import { PackagingProductProps } from '../../types';

interface Props {
  product: ProductProps;
  isOpenedModal: boolean;
  pack: PackagesTypesProps;
  substTrib: TaxSubstitutionProps[];
  openModal: (val: boolean) => void;
  setPackage: (val: PackagesTypesProps) => void;
  packQty: number;
  setPackQuantity: (val: number) => void;
}

const ModalFlexDiscount = ({ pack, product, substTrib, openModal, setPackage, isOpenedModal, setPackQuantity, packQty }: Props) => {
  const { paymentCode } = useGlobals();
  const { selectedClient } = useClient();
  const { rcaSaldoFlex } = useUserContext();
  const { cartDetails, addProductToCart } = useCart();
  const [isOpenTooltip, setOpenTooltip] = useState(false);
  const { setDiscount, getDiscountProduct } = useDiscountFlex();
  const { discountAmountClient: discountAmount } = useDiscountAmountFlex();

  const prices = useMemo(
    () => product.PRECO.filter((p) => {

      if (pack === "PK") {
        return p.SG_EMBALAGEM === pack && p.QTD_MULTIPLO_VENDA === packQty;
      }
      return p.SG_EMBALAGEM === pack && p.QTD_EMBALAGEM === packQty;
    }),
    [product.PRECO, pack, packQty]
  );

  const initialPrice = useMemo(
    () => prices.find((p) => p.NROCONDICAOPAGTO === paymentCode)?.VLR_FINAL || 0,
    [prices, paymentCode]
  );

  const qtdEmb = useMemo(() => {

    const emb = product?.EMBALAGEM.find((e: PackagingProductProps) => {

      if (pack === "PK") {
        return e.SG_EMBALAGEM === pack && e.QTD_MULTIPLO_VENDA === packQty;
      }
      return e.SG_EMBALAGEM === pack && e?.QTD_EMBALAGEM === packQty;

    }) as PackagingProductProps;

    const isMultiple = emb?.QTD_MULTIPLO_VENDA > 1 && pack === "PK" ? true : false;

    return isMultiple ? emb?.QTD_MULTIPLO_VENDA : emb?.QTD_EMBALAGEM || 0;
  }, [product, pack, packQty]) as number;

  const ctxDiscountProduct = useMemo(() => {

    return (
      getDiscountProduct({
        package: pack,
        quantity: qtdEmb,
        productId: product.COD_PRODUTO,
      })?.discountPrice?.[paymentCode] || 0
    );
  }, [getDiscountProduct, pack, qtdEmb, product, paymentCode]);

  const startPrice = useMemo(() => {
    return `${ctxDiscountProduct || initialPrice || 0}`;
  }, [ctxDiscountProduct, initialPrice]);
  console.log({ startPrice });
  // console.log({ initialPrice });

  const [flex, setFlex] = useState(startPrice);

  const [currentPrice, setCurrentPrice] = useState(startPrice);
  // console.log(currentPrice);

  const calcPrices = useCallback(
    (curFlex: number) => {
      const newPrices = prices.map((p) => ({
        ...p,
        VLR_PRECO: p.VLR_PRECO + (p.VLR_PRECO / 100) * curFlex,
      }));

      
      const calculated = calcPrice(selectedClient, newPrices, substTrib);
      // console.log('------------------------------------');
      console.log({ selectedClient, newPrices, substTrib, calculated });

      return calculated.reduce(
        (acc, p) => ({
          ...acc,
          [p.NROCONDICAOPAGTO]: Number(p?.VLR_FINAL?.toFixed(2)),
        }),
        {} as { [key: string]: number }
      );
    },
    [selectedClient, prices, substTrib]
  );

  const quantityCart = useMemo(() => {
    const [pck,] = pack?.split("_");

    return (
      cartDetails[selectedClient.COD_CLIENTE]?.items?.find((i) => i.uid === `${product.COD_PRODUTO}-${pck}-${qtdEmb}`)
        ?.quantity || 0
    );
  }, [selectedClient, cartDetails, product.COD_PRODUTO, qtdEmb, pack]);

  const newSaldoFlex = useMemo(() => {
    const qtd = quantityCart;
    const curDiscount = (Number(currentPrice) - initialPrice) * (qtd || 1);
    const productDiscount = ctxDiscountProduct ? (ctxDiscountProduct - initialPrice) * qtd : 0;
    return (rcaSaldoFlex || 0) + discountAmount - productDiscount + curDiscount;
  }, [initialPrice, currentPrice, quantityCart, rcaSaldoFlex, discountAmount, ctxDiscountProduct]);

  const setDiscountPrice = useCallback(() => {
    const [pck,] = pack?.split("_");

    if (newSaldoFlex < 0) {
      setOpenTooltip(true);
      setTimeout(() => setOpenTooltip(false), 5000);
      return;
    }

    setDiscount({
      package: pck,
      quantity: qtdEmb,
      percentual: Number(flex),
      price: calcPrices(Number(flex)),
      productId: product.COD_PRODUTO,
    });

    openModal(false);
  }, [pack, flex, qtdEmb, newSaldoFlex, product.COD_PRODUTO, openModal, calcPrices, setDiscount]);

  const findQtdPack = useCallback(() => {
    const packing = product?.EMBALAGEM.find((p) => {
      let packOption = pack;
      let packQtd = packQty;
      if (p.QTD_MULTIPLO_VENDA > 1) {
        return p.SG_EMBALAGEM === packOption && p.QTD_MULTIPLO_VENDA === packQtd;
      }
      return p.SG_EMBALAGEM === packOption && p.QTD_EMBALAGEM === packQtd;
    });

    if (packing?.QTD_MULTIPLO_VENDA && packing?.QTD_MULTIPLO_VENDA > 1) {
      return packing?.QTD_MULTIPLO_VENDA;
    }
    return packing?.QTD_EMBALAGEM;
  }, [product, pack, packQty]);

  const handleChangeFlex = useCallback(
    (val: number) => {
      const price = calcPrices(val);
      if (!price[paymentCode]) return;

      setCurrentPrice(`${price[paymentCode].toFixed(2)}`);
      setFlex(`${val}`);
    },
    [calcPrices, paymentCode]
  );

  const handleChangePrice = useCallback((val: string) => {
    const newValue = val.replace(',', '.');
    setCurrentPrice(newValue);
  }, []);

  const handleBlurPrice = useCallback(
    (val: string) => {
      const newValue = Number(val);
      const initialValue = initialPrice;
      if (isNaN(newValue) || !initialValue) return;

      setCurrentPrice(`${newValue.toFixed(2)}`);
      const porcentagem = ((newValue - initialValue) / initialValue) * 100;
      const isMax = porcentagem > 9;
      const isMin = porcentagem < -5;
      handleChangeFlex(isMax ? 9 : isMin ? -5 : porcentagem);
    },
    [initialPrice, handleChangeFlex]
  );

  const setPriceAndAddToCart = useCallback(() => {
    if (newSaldoFlex < 0) {
      setOpenTooltip(true);
      setTimeout(() => setOpenTooltip(false), 5000);
      return;
    }

    addProductToCart({
      quantity: 1,
      sku: product?.COD_PRODUTO,
      category_id: product?.COD_CATEGORIA,
      option_uid: `${pack}_${findQtdPack()}`,
      uid: `${product?.COD_PRODUTO}-${pack}-${findQtdPack()}`,
    });
    setDiscountPrice();
  }, [setDiscountPrice, addProductToCart, findQtdPack, newSaldoFlex, product, pack]);

  useEffect(() => {
    if (!isOpenedModal) return;
    console.log({startPrice});
    
    handleBlurPrice(`${startPrice}`);
  }, [isOpenedModal, startPrice, handleBlurPrice]);

  return (
    <S.ModalContent>
      <S.ModalTitle>Aplicar desconto no produto</S.ModalTitle>

      <S.ContentProduct>
        <S.ImageProductContent>
          <S.ImageProduct src={`data:image/jpg;base64,${product.IMAGEM}`} />
        </S.ImageProductContent>

        <S.ProductInfos>
          <S.ProductDesc>{product.DESC_PRODUTO}</S.ProductDesc>

          <Packaging
            setPackQuantity={setPackQuantity}
            packQty={packQty}
            showQtd
            pack={pack}
            setPackage={setPackage}
            product={product}
          />
        </S.ProductInfos>
      </S.ContentProduct>

      <S.InstructionLAbel>Escolha abaixo a porcentagem que deseja alterar no valor do produto</S.InstructionLAbel>

      <S.ProductPriceLabel>Valor do produto</S.ProductPriceLabel>

      <S.ProductPrice>
        <S.Cifrao>R$</S.Cifrao>

        <S.RelativeValue>
          <S.Value>{`${currentPrice}`.replace('.', ',')}</S.Value>

          <S.InputProductPrice
            type="number"
            step={'0.02'}
            value={currentPrice}
            onBlur={(event: any) => handleBlurPrice(event.target.value)}
            onChange={(event: any) => handleChangePrice(event.target.value)}
            id={`modal-flex-discount-input-product-price-${product.COD_PRODUTO}`}
          />
        </S.RelativeValue>
      </S.ProductPrice>

      <S.ContentFlex>
        <InputRange
          value={Number(flex)}
          setValue={handleChangeFlex}
          id={`modal-flex-discount-input-range-${product.COD_PRODUTO}`}
        />
      </S.ContentFlex>

      <S.BalanceContent>
        <S.Balance>
          Saldo Estimado:
          <S.BalanceValue>
            <S.BalanceValueEmphasis $negative={newSaldoFlex < 0}>
              {formatCurrency({ value: newSaldoFlex })}
            </S.BalanceValueEmphasis>
          </S.BalanceValue>
        </S.Balance>

        <ToolTip direction="top" show={isOpenTooltip} displayMode="default" text="Saldo Flex Indisponível">
          {!!quantityCart ? (
            <S.ApplyButton theme="dark" $inactive={false} onClick={setDiscountPrice} data-test="modal-flex-discount-apply-button">
              Aplicar
            </S.ApplyButton>
          ) : (
            <S.ApplyButton
              theme="green"
              $inactive={false}
              onClick={setPriceAndAddToCart}
              data-test="modal-flex-discount-apply-and-add-to-cart-button"
            >
              Aplicar e adicionar ao carrinho
            </S.ApplyButton>
          )}
        </ToolTip>
      </S.BalanceContent>
    </S.ModalContent>
  );
};

export default React.memo(ModalFlexDiscount);
