import { colors } from "@/constants";
import styled from "styled-components";

export const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  padding-bottom: 50px;
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  background: ${colors.background1};
`;

export const LoaderContainer = styled.div`
  height: 50vh;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  padding: 50px;
`