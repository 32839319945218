import * as S from './styles';
import { colors } from '@/constants';
import { Graphics } from '@/components';
import { formatCurrency } from '@/shared';
import { RiLineChartLine } from 'react-icons/ri';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const formatarValor = (value: any) => {
  const valor = formatCurrency({ value }) as string;
  return valor.replace('-', '');
};

interface Props {
  value: number;
}

const MetricasComp = ({ value }: Props) => {
  const isUp = value > 0;
  const isDown = value < 0;
  return (
    <S.Line $iswrap $bgwhite $horizontalstart $gap={8}>
      <S.IconGraphic bgcolor={colors.alert.red50}>
        <RiLineChartLine color={colors.alert.red200} size={20} />
      </S.IconGraphic>

      <S.Column $flex>
        <S.Line $horizontalstart>
          <S.LabelCategory>Meta</S.LabelCategory>

          <S.MetricPercentual $bgcolor={colors.alert.red50}>
            {isUp ? <FaCaretUp size={16} color={colors.alert.red200} /> : <FaCaretDown size={16} color={colors.alert.red200} />}

            <S.MetricPercentualLabel color={colors.alert.red200}>
              {isUp ? '+' : isDown ? '' : ''}
              {value}%
            </S.MetricPercentualLabel>
          </S.MetricPercentual>
        </S.Line>

        <S.Value>{formatarValor(150)}</S.Value>
      </S.Column>

      <Graphics width={65} theme="red" data={[25, 52, 54, 64, 46, 62, 58, 71, 37, 39, 10]} />

      <S.Column margin="0px 0px 0px 10px">
        <S.Label>Apuração</S.Label>
        <S.Value>{formatarValor(150)}</S.Value>
      </S.Column>
    </S.Line>
  );
};

export default MetricasComp;
