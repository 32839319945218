import { gql } from "@apollo/client";

export const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($email: String!) {
    requestPasswordResetEmail(email: $email)
  }
`;

export default {
    requestPasswordResetMutation: REQUEST_PASSWORD_RESET
}