import styled from 'styled-components';

import { colors } from '@/constants';

export const Loading = styled.div<{ size: number, border: number, borderColor: string, topColor: string, fullBorder?: boolean }>`
  display: flex;
  border: ${({ border }) => border}px solid ${({ borderColor }) => borderColor};
  border-top: ${({ border }) => border}px solid ${({ topColor }) => topColor};
  ${({ fullBorder, border, topColor }) => fullBorder && `
    border-right: ${border}px solid ${topColor};
    border-bottom: ${border}px solid ${topColor};
  `}
  border-radius: 50%;
  width: ${props => props.size}px !important;
  height: ${props => props.size}px;
  animation: loading 2s linear infinite;

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SkeletonWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
`;

export const Skeleton = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  animation: shimmer 2s linear infinite forwards;

  @keyframes shimmer {
    0% {
      background-color: ${colors.loading.primary};
    }
    50% {
      background-color: ${colors.loading.secondary};
    }
    100% {
      background-color: ${colors.loading.primary};
    }
  }
`;
