import { useState, useEffect } from 'react';
import * as S from './styles';

import { removeFromStorage } from '@/shared';

import Modal from "../modal/Modal";
import Lottie from 'react-lottie-player';

import AnimationTimer from './animation/timer.json';
import AnimationSol from './animation/sol.json';

import { useUserContext } from '@/hooks';
import { useTimeMonitorContext } from '@/hooks';
import { useDbFunctions } from '@/talons';

const ModalTimeChange = ({ }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const { showManualTimeChangeModal, showSameDayModal, setShowManualTimeChangeModal, setShowSameDayModal } = useTimeMonitorContext();
    const { signOut, isSignedIn } = useUserContext();
    const { deleteDB } = useDbFunctions();

    useEffect(() => {
        if ((showManualTimeChangeModal || showSameDayModal) && isSignedIn) {
            setIsOpenModal(true);
        }
    }, [showManualTimeChangeModal, showSameDayModal, isSignedIn]);

    useEffect(() => {
        if (showManualTimeChangeModal && isOpenModal) {
            setTimeout(() => {
                setIsOpenModal(false);
                setShowManualTimeChangeModal(false);
                signOut();
            }, 4000);
        }
    }, [showManualTimeChangeModal, isOpenModal, signOut]);

    const handleSignOut = () => {
        setShowSameDayModal(false);
        setIsOpenModal(false);
        removeFromStorage('datasets');
        deleteDB();
        signOut();
    }

    if (showSameDayModal && isOpenModal) {
        return <DayChangeModal handleSignOut={handleSignOut} />
    }

    return (
        <Modal
            isLoading
            isOpen={isOpenModal}
            closeModal={setIsOpenModal}
            id="timeChange"
            direction="bottom"
            headerIcon={<Lottie animationData={AnimationTimer} play loop style={{ width: 220, height: 220, boxShadow: 'none' }} />}
        >
            <S.Container>
                <S.ModalTitle>Identificamos que o horário foi alterado manualmente!</S.ModalTitle>
                <S.ModalInfo>
                    Estamos forçando o seu logout.
                </S.ModalInfo>

                <S.LittleLoading>
                    <S.LittleLoadingPulse />
                </S.LittleLoading>
            </S.Container>
        </Modal>
    )
}

const DayChangeModal = ({ handleSignOut }: { handleSignOut: () => void }) => {

    return (
        <Modal
            isLoading
            isOpen={true}
            closeModal={() => { }}
            id="dayChange"
            direction="bottom"
            headerIcon={<Lottie animationData={AnimationSol} play loop style={{ width: 220, height: 220, boxShadow: 'none' }} />}
        >
            <S.Container>
                <S.ModalTitle>Um novo dia, novos preços, novas oportunidades!</S.ModalTitle>
                <S.ModalInfo>
                    Vamos atualizar os dados para que esse novo dia seja melhor que ontem.
                </S.ModalInfo>
                <S.ButtonClose onClick={() => handleSignOut()}> Fazer um novo login </S.ButtonClose>
            </S.Container>

        </Modal>
    )
}

export default ModalTimeChange;