import * as S from "./styles";
import { useCartTotals } from "@/hooks";
import { useState, useMemo } from "react";
import RadioCheckout from "./radioCheckout";
import { Price } from "@/components/price/Price";
import { RiBarcodeBoxLine } from "react-icons/ri";
import { Accordion } from "@/components/accordion/Accordion";

const title = `Prazos de pagamento para boleto faturado`;
interface PaymentProps {
  conditions: any;
  paymentCode: number;
  setPaymentCode: any;
}

const PaymentMethod: React.FC<PaymentProps> = ({ conditions, setPaymentCode, paymentCode }) => {
  const { fetchTotalPriceByPaymentCode } = useCartTotals();
  const [accordionExpanded, setAccordionExpanded] = useState(true);
  
  const options = useMemo(
    () => {
      const handleRadioChange = (code: string) => setPaymentCode(code);

      return conditions.map((condition: any) => {
        const isChecked = condition.cod_condicao === paymentCode;
        const total = fetchTotalPriceByPaymentCode(condition?.cod_condicao);

        let newTitle = condition?.desc_condicao.includes("3x")
          ? "3 parcelas"
          : condition?.desc_condicao.split("para")[1];

        let newDescription = condition?.desc_condicao.includes("3x")
          ? condition?.desc_condicao
            .split("(preço")[0]
            .split("-")[1]
            .replace(" e", ",")
            .trim()
          : "";

        return (
          <S.OptionCondition key={condition.code}
            onClick={() => handleRadioChange(condition.cod_condicao)}>
            <RadioCheckout
              code={condition.code}
              checked={isChecked}
            >
              <S.OptionLabel data-test={`checkout-payment-code-${condition.code}`}>
                <S.OptionTitle>{newTitle}</S.OptionTitle>
                {newDescription && (
                  <>
                    <span>•</span>
                    <S.OptionDescription>{newDescription}</S.OptionDescription>
                  </>
                )}
                <span>•</span>
                <S.OptionPrice>
                  <Price value={total} />
                </S.OptionPrice>
              </S.OptionLabel>
            </RadioCheckout>
          </S.OptionCondition>
        );
      })},
    [conditions, paymentCode, fetchTotalPriceByPaymentCode, setPaymentCode]
  );

  return (
    <Accordion
      id="Boleto"
      accordionTitle="Boleto"
      accordionIcon={<RiBarcodeBoxLine />}
      accordionExpanded={accordionExpanded}
      setAccordionExpanded={setAccordionExpanded}
    >
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.OptionsWrapper data-test={'checkout-payment-options'}>{options}</S.OptionsWrapper>
      </S.Content>
    </Accordion>
  );
};

export default PaymentMethod;
