import { useMemo } from "react";
import * as S from "./styles";

import { Price } from "@/components/price/Price";
import { StPrice } from "@/components";
import { ClientProps, PackagesTypesProps } from "@/types";
import { PackingTypes } from "@/shared";
import { useDiscountFlex } from "@/hooks";
// import PackingImg from "@/assets/svgs/icon-caixa.png";

interface ProductCardProps {
  name: string;
  img?: string;
  uid: string;
  packing?: string;
  productPrice: number;
  productTotalPrice: number;
  unityPrice: number;
  quantity?: string;
  stPrice?: number;
  client: ClientProps;
  productCalculatedPrice?: number;
  productTaxCode?: number;
}

const PackingImg = `${process.env.PUBLIC_URL}/icon-caixa.png`;

const findPacking = (packing: string) => {
  switch (packing) {
    case "CX":
      return "Caixa com";
    case "PT":
      return "Pacote com";
    case "PK":
      return "Pack com";
    case "UN":
      return "Pack com";
    case "CE":
      return "Caixa de embq. com";
    case "CT":
      return "Cartela com";
    case "FD":
      return "Fardo com";
    case "LT":
      return "Lata com";
    default:
      return "Pack com";
  }
};

const ProductCard: React.FC<ProductCardProps> = ({
  name,
  img,
  uid,
  productPrice,
  productTotalPrice,
  unityPrice,
  packing,
  quantity,
  stPrice,
  client,
  productCalculatedPrice,
  productTaxCode,
}) => {

  const pack = useMemo(() => {
    const [packType, _qtd] = packing?.split("_") as [PackagesTypesProps, string];
    return packType;
  }, [packing]) as PackagesTypesProps;

  const selectedPackage = useMemo(() => {
    if (packing) {
      const [packType, qtd] = packing.split("_");
      return { packingType: findPacking(packType), quantity: qtd };
    }
  }, [packing]);

  const { discounts } = useDiscountFlex();

  return (
    <S.ProductCardWrapper>
      <S.ProductImageWrapper>
        <S.QuantityLabel>
          {quantity}
        </S.QuantityLabel>
        <S.ProductImage
          alt={name}
          src={`data:image/jpg;base64,${img}`}>
        </S.ProductImage>
      </S.ProductImageWrapper>
      <S.ProductName>
        <S.Name>{name}</S.Name>
        <S.PackingBlock>
          <S.Packing src={PackingTypes?.[pack].icon} />
          <S.PackingLabel>{selectedPackage?.packingType}</S.PackingLabel>
          <S.PackingUnities>{selectedPackage?.quantity} unidades </S.PackingUnities>
        </S.PackingBlock>
      </S.ProductName>
      <S.Prices>
        <S.TotalPrice>
          <Price value={productTotalPrice} />
        </S.TotalPrice>
        <StPrice
          precoSt={stPrice}
          quantidade={Number(quantity)}
          cliente={client}
          codTributacaoProduto={productTaxCode}
          precoCalculado={productCalculatedPrice}
          descontoFlex={discounts?.[client?.COD_CLIENTE]?.[uid]?.percentual ? discounts?.[client?.COD_CLIENTE]?.[uid]?.percentual : 0}
        />
        <S.Price>
          <Price value={productPrice} />
        </S.Price>
        <S.UnityPrice>
          <Price value={unityPrice} /> /un
        </S.UnityPrice>
      </S.Prices>
    </S.ProductCardWrapper>
  );
};

export default ProductCard;
