import React from 'react';
import { RiSearchLine } from "react-icons/ri";
import * as S from './styles';
import { colors } from '@/constants';

const SearchButton = () => {
  return (
    <S.SearchButtonContainer>
      <RiSearchLine size={21} color={colors.brand.white} />
    </S.SearchButtonContainer>
  )
}

export default SearchButton;
