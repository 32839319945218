import styled, { css } from "styled-components";
import colors from "@/constants/systemColors";
import { Link } from "react-router-dom";

export const HeaderSpace = styled.div`
  width: 100%;
  height: 60px;
  margin-top: -5px;
  background-color: ${colors.brand.grafite};
`;

export const AccountPageWrapper = styled.div`
  background-color: ${colors.brand.white};
  flex-grow: 1;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;

  font-size: 14px;
  color: ${colors.gray.gray60};
`;

export const MenuTitle = styled.span`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;

  padding: 12px 20px;
  border-bottom: solid 1px ${colors.gray.gray10};
`;

const styleItem = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 12px 20px;
  border-bottom: solid 1px ${colors.gray.gray10};

  text-decoration: none;
  color: ${colors.gray.gray60};

  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    color: ${colors.gray.gray50};
  }

  &:hover {
    background-color: ${colors.background1};

    :not(:has(.logout)) {
      svg {
        color: ${colors.gray.gray50};
      }
    }
  }
`;

export const MenuItem = styled(Link)`
  ${styleItem}
`;

export const MenuButton = styled.div`
  ${styleItem}
`;

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  &.logout {
    color: ${colors.red3};
    svg {
      color: ${colors.red3} !important;
    }
  }
`;

export const RiUsersLine = styled.img`
  width: 24px;
  height: 24px;
`;

export const Text = styled.div``;

export const Message = styled.span``;

export const Name = styled.span``;

export const Balance = styled.div``;

export const UserMenu = styled.div``;
export const Label = styled.span`
  font-weight: 400;
  line-height: 16.41px;
`;

export const NavActionIcon = styled.div`
  svg {
    color: ${colors.grey8};
  }
`;
