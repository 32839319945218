import { colors } from "@/constants";
import styled from "styled-components";
import { RiCloseLine, RiTimeLine, RiSettings3Line, RiSettingsLine, RiBugLine } from "react-icons/ri";

export const VersionModal = styled.div`
  width: 100%;
`;

export const CloseIcon = styled(RiCloseLine)`
  color: ${colors.gray.gray40};
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const ModalHeader = styled.div`
  padding: 51px 53px 0px 53px;
  position: relative;
`

export const ModalTitle = styled.p`
  font-size: 22px;
  font-weight: 700;
  color: ${colors.gray.gray60};
  text-align: center;
  max-width: 284px;
  margin: 0 auto;
`;

export const Date = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  color: ${colors.gray.gray50};
  font-weight: 400;
  margin-top: 12px;
`

export const ClockIcon = styled(RiTimeLine)`
  color: ${colors.gray.gray50};
`

export const VersionLine = styled.div`
  position: relative;
  margin-top: 24px;
  &:after {
    top: 50%;
    left: 0px;
    height: 2px;
    width: 100%;
    content: "";
    position: absolute;
    background-color: ${colors.grey10};
  }
`;

export const TransparentBlock = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  background-color: ${colors.brand.white};
  width: fit-content;
  padding: 0 10px;
`

export const VersionLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  padding: 4px 10px;
  border-radius: 100px;
  width: fit-content;
  color: ${colors.brand.white};
  background-color: ${colors.brand.grafite};
`

export const VersionList = styled.div`
  margin-top: 24px;
  padding: 0 24px;
  height: 100%;
`

export const Cards = styled.div`
`

export const VersionCard = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 48px;
  height: 100%;
  box-sizing: border-box;
  &:after {
    height: 100%;
    width: 1px;
    left: 48px;
    content: "";
    position: absolute;
    background-color: ${colors.grey10};
    transform: translateY(5px);
  }
`

export const Timeline = styled.div`
  min-height: 16px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  height: fit-content;
  border-radius: 100px;
  position: relative;
  height: 100%;
  z-index: 3;
  top: 5px;
  `

export const Circle = styled.div`
  width: 16px;
  position: relative;
  height: 16px;
  border-radius: 100px;
  background-color: ${colors.grey11};
  &:after {
    content: '';
    position: absolute;
    transform: translateY(6px);
    left: 24px;
    width: 20px;
    height: 2px;
    background-color: ${colors.grey10};
  }
`
export const VersionTag = styled.div`
  border-radius: 100px;
  padding: 6px 8px;
  color: ${colors.brand.white};
  background-color: ${colors.brand.grafite};
  width: fit-content;
  height: fit-content;
  font-size: 12px;
  font-weight: 700;
`

export const VersionInfo = styled.div``

export const VersionDescription = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.brand.grafite};
`

export const VersionCategory = styled.div<{$category: string}>`
  border-radius: 4px;
  padding: 4px 8px;
  width: fit-content;
  font-size: 12px;
  font-weight: 400;
  color: ${colors.gray.gray50};
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  ${({ $category }) => {
    if($category === 'Novidade') {
      return `background-color: ${colors.alert.green50};`
    }
    if($category === 'Melhoria') {
      return `background-color: ${colors.alert.blue50};`
    }
    if($category === 'Correção') {
      return `background-color: ${colors.alert.orange200};`
    }
  }}
`

export const NewFeatureIcon = styled(RiSettingsLine)``

export const ImprovementIcon = styled(RiSettings3Line)``

export const BugIcon = styled(RiBugLine)``

