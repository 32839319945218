import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import { OrderStatusCalendar as Calendar, OrderStatusFullCard as Card, Loading } from '@/components';
import { useDbFunctions, useOrderStatusPage } from '@/talons';
import { OrderProps } from '@/talons/orderStatusPage/useOrderStatusPage';
import { ProductProps } from '@/types';

interface ParsingDateProps {
  dia: number;
  mes: number;
  ano: number;
}

const OrderStatusPage = () => {
  const { ordersList, offlineOrdersList, synchedOrdersList, loading } = useOrderStatusPage();

  const [productsDict, setProductsDict] = useState({} as Record<number, ProductProps>);

  const { getOnDB } = useDbFunctions();

  const loadProductDetails = useCallback(async () => {
    const dict = await getOnDB("dicionario_produtos");
    // console.log('here >>>>', dict );
    setProductsDict(dict);
  }, [getOnDB]);

  useEffect(() => {
    if (!productsDict) {
      loadProductDetails();
    }
  }, [loadProductDetails, productsDict]);

  const parsingDates = (dateStr: string): ParsingDateProps => {
    if (!dateStr) {
      console.error('Invalid date string:', dateStr);
      return {
        dia: 0,
        mes: 0,
        ano: 0,
      }
    }

    const [day, date, month, year] = dateStr.split(/[ ,/]+/);

    return {
      dia: Number(date),
      mes: Number(month),
      ano: Number(year),
    }
    
  };


  const parseDate = (dateStr: any) => {
    if (!dateStr) {
      console.error('Invalid date string:', dateStr);
      return new Date(0); // Retorna uma data inválida
    }

    const [day, date, month, year] = dateStr.split(/[ ,/]+/);
    // console.log('{ ---->>>> }', { dateStr, month, year, date });  
    
    const monthNames = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];
    const monthIndex = monthNames.indexOf(month?.toLowerCase());
    return new Date(year, monthIndex, date);
  }

  const compareIndexes = useCallback((synchedList: any[], ordersList: any[]) => {

  }, []);

  // Unir e ordenar os pedidos
  const groupByDate = (orders: OrderProps[]) => {
    return orders?.reduce((groups, order) => {
      const date = order.orders.orderDate;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(order);

      return groups;
    }, {} as Record<string, OrderProps[]>);
  };

  // Agrupa os pedidos por data, ordena da data mais recente para mais antiga, e segrega os pedidos por clientes p/ exibição dos cards de identificação no front


  const combinedOrdersList: any[] = [...(ordersList || []), ...(offlineOrdersList || []), ...(synchedOrdersList || [])];


  const groupedOrders = groupByDate(combinedOrdersList);

  const sortedGroupedOrders = Object.keys(groupedOrders)
  .sort((a, b) => { 

    const dateA = parsingDates(a);
    const dateB = parsingDates(b);

    const dateStrA = `${dateA?.ano}-${dateA?.mes}-${dateA?.dia}`;
    const dateStrB = `${dateB?.ano}-${dateB?.mes}-${dateB?.dia}`;

    const parseB = new Date(dateStrB)?.getTime();
    const parseA = new Date(dateStrA)?.getTime();

    return parseB - parseA;
  })
  .map(date => {
      const ordersMap = new Map() as Record<string, any>;

      const clientList = [] as string[];

      const list = groupedOrders[date];
      list.forEach(i => {
        if (!ordersMap?.has(i.clientRefNumber)) {
          ordersMap.set(i.clientRefNumber, {
            clientName: i.clientName,
            clientRefNumber: i.clientRefNumber,
            clientCnpj: i?.clientCnpj,
            orders: [{ ...i.orders }],
          });
          clientList.push(i.clientRefNumber);
        } else if (ordersMap?.has(i.clientRefNumber)) {
          const val = ordersMap.get(i.clientRefNumber);
          const updatedVal = [
            ...val?.orders,
            {
              ...i.orders,
            }
          ];
          ordersMap.set(i.clientRefNumber, {
            ...val,
            orders: updatedVal,
          });
        } 
      })

      return {
        date,
        ordersMap,
        clientList,
      }
    })

    if (loading) {

      return (
        <S.LoadingContainer>
          <Loading scale={1.5} />
        </S.LoadingContainer>
    )
    }

  return (
    <S.PageContainer>
      {sortedGroupedOrders.map(({ date, ordersMap, clientList }, groupIndex) => (
        <S.PageContent key={`group-${groupIndex}`}>
          {date !== undefined && (
            <Calendar date={date} pendingStatus={groupIndex > 0} />
          )}
          {clientList?.map((clientNumber, idx) => 
            {
              const mapOrder = ordersMap.get(clientNumber);
              const clientName = mapOrder?.clientName;
              const clientCnpj = mapOrder?.clientCnpj;
              const ordersList = mapOrder?.orders;


              if (mapOrder) return (
                <Card
                  productsDict={productsDict}
                  clientName={clientName}
                  clientCnpj={clientCnpj}
                  date={date}
                  clientRefNumber={clientNumber}
                  orders={ordersList}
                  key={`card-${groupIndex}-${idx}`}
                />
              )
             return null;
            }
          )}
        </S.PageContent>
      ))}
    </S.PageContainer>
  )
}

export default OrderStatusPage;
