import { colors } from "@/constants";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const PageContainer = styled.div`
  max-width: var(--mobile);
  height: 100vh;
  width: 100%;
`;

export const Icon = styled.img`
  width: 50px;
  height: 53px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const Text = styled.span`
  font-family: Roboto, sans-serif;
  font-weight: 400;
  /* margin: 20px; */
  padding: 16px 30px;
  text-align: center;
  color: ${colors.gray.gray40};
`;

export const BoldText = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  text-align: left;
  color: ${colors.brand.grafite};
`;

export const ButtonOuterContainer = styled.div`
  padding: 30px;
  /* max-width: 220px; */
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContainer = styled(Link)`
  width: 100%;
  max-width: 220px;
  height: 45px;
  border-radius: 6px;
  background: ${colors.brand.red};
  display: flex;
  text-decoration: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: none;
`;

export const ButtonLabel = styled.span`
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  color: ${colors.brand.white};
`;


export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;