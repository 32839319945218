import styled, { css } from "styled-components";
import colors from "@/constants/systemColors";

export const HeaderWrapper = styled.div<{
  $hasextension?: boolean;
  $noradius?: boolean;
  $maxHeight?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  max-height: 56px;
  padding: 16px;
  padding-bottom: ${(i) => (i.$hasextension ? "21px" : "16px")};
  width: -webkit-fill-available;
  border-radius: 0px 0px
    ${(i) => (i.$hasextension || i.$noradius ? "0px 0px" : "6px 6px")};
  background-color: ${colors.brand.grafite};
`;

export const Container = styled.div`
  top: 0px;
  bottom: 0px;
  z-index: 10;
  position: sticky;
  width: -webkit-fill-available;
`;

export const Description = styled.h6`
  margin: 0px;
  font-size: 16px;
  font-weight: 700;
  background-color: ${colors.background1};
  text-transform: uppercase;
  padding: 8px 20px;
`;

export const BackButton = styled.button`
  padding: 0px;
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  background: transparent;

  svg {
    color: ${colors.brand.white};
  }
`;

export const RouteLabel = styled.span<{ $routelabelsize?: number }>`
  font-size: ${(props) => props?.$routelabelsize || 18}px;
  font-weight: 700;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
  ${props => props.$routelabelsize && css`
    text-align: left;
    padding: 0;
  `};
  color: ${colors.brand.white};
`;

export const RouteSublabel = styled.span`
  margin-left: 4px;
  font-size: 12px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.brand.white};
`;

export const ActionButton = styled.div`

`;

export const Extension = styled.div<{ $bgcolor: boolean; $bgcolor2: boolean }>`
  height: 7px;
  margin-top: -6px;
  overflow: hidden;
  border-radius: 6px 6px 0px 0px;
  background-color: ${(p) =>
    p.$bgcolor2
      ? colors.gray.gray10
      : p.$bgcolor
      ? colors.background1
      : colors.brand.white};
`;
