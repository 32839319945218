import * as S from "./styles";
import { colors } from "@/constants";
import Chart, { Props as ChartProps } from "react-apexcharts";

interface Props {
  data: number[];
  height?: number;
  width?: number | string;
  theme?: "red" | "green" | "orange";
}

const themes = {
  red: colors.brand.red,
  green: colors.alert.green300,
  orange: colors.alert.orange300,
};

function AreaChart({ theme = "orange", data, height = 35, width }: Props) {
  const options = {
    chart: {
      offsetX: 0,
      offsetY: 0,
      type: "area",
      toolbar: { show: false },
      zoom: { enabled: false },
      sparkline: { enabled: true },
    },
    dataLabels: { enabled: false },
    stroke: {
      width: 2,
      show: true,
      curve: "smooth",
    },
    colors: [themes[theme]],
    fill: {
      type: "gradient",
      colors: [themes[theme]],
      gradient: {
        opacityTo: 0.2,
        stops: [0, 100],
        opacityFrom: 0.5,
        shadeIntensity: 1,
      },
    },
    grid: {
      show: false,
      padding: {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      },
    },
    xaxis: {
      labels: { show: false },
      axisTicks: { show: false },
      axisBorder: { show: false },
    },
    tooltip: { enabled: false },
    yaxis: { labels: { show: false } },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false,
      },
    },
  } as ChartProps;

  return (
    <S.Content>
      <Chart
        type="area"
        height={height}
        options={options}
        series={[{ data }]}
        width={width || "100%"}
      />
    </S.Content>
  );
}

export default AreaChart;
