import { colors } from "@/constants";
import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding-bottom: 23px;
`;

export const Title = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.41px;
`;

export const OptionsWrapper = styled.div`
  border: solid 1px ${colors.background1};
  border-radius: 6px;
`;

export const OptionCondition = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  height: 45px;

  font-size: 13px; //ver com o Kae se posso deixar 13px nesse component, pois 14px está quebrando o layout
  line-height: 16.41px;
  color: ${colors.brand.grafite};

  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: solid 1px ${colors.background1};
  }
`;

export const OptionLabel = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;

export const OptionTitle = styled.div`
  font-weight: 700;
`;

export const OptionDescription = styled.span`
  font-weight: 400;
`;

export const OptionPrice = styled.span`
  font-weight: 400;
`;
