import { DashboardsProps } from "@/types";

const dashboards: DashboardsProps = {
  metaFaturamento: {
    percentual: 70,
    dashboard: [31, 49, 45, 62, 56, 60, 53, 61, 42, 46, 40],
    percentualReference: -17,
  },
  positivacaoClientes: {
    percentual: 30,
    dashboard: [31, 49, 45, 62, 56, 60, 53, 61, 42, 46, 40],
    percentualReference: -17,
  },
  mediaPositivacaoDia: {
    percentual: 37,
    dashboard: [31, 49, 45, 62, 56, 60, 53, 61, 42, 46, 40],
  },
  participacaoBazar: {
    percentual: 20,
    dashboard: [31, 49, 45, 62, 56, 60, 53, 61, 42, 46, 40],
  },
  itensPorCliente: {
    percentual: 10,
    dashboard: [31, 49, 59, 71, 66, 76, 67, 65, 79, 76, 87],
    percentualReference: 8,
  },
  categoriaPilarMeta: {
    valor: 40,
    dashboard: [31, 49, 45, 62, 56, 60, 53, 61, 42, 46, 40],
    valorAtingido: -450,
    percentualReference: -17,
  },
  categoriaPilarPositivacao: {
    valor: 40,
    dashboard: [31, 49, 45, 62, 56, 60, 53, 61, 42, 46, 40],
    valorAtingido: 450,
    percentualReference: 8,
  },
};

export default dashboards;
