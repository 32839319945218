import * as S from "./styles";
import { RiCloseFill } from "react-icons/ri";

import { Slider } from "@/components";

interface GalleryProps {
  images: any;
  firstImage: string;
  handleCloseFulScreen: any;
}
//receber todas as imagens, o indice e a imagem aberta primeiro

export const FullScreenGallery = ({
  images,
  firstImage,
  handleCloseFulScreen,
}: GalleryProps) => {
  return (
    <S.Wrapper>
      <S.Header>
        <S.CloseButton
          onClick={() => handleCloseFulScreen()}
          data-test="button-handle-close-fulscreen"
        >
          <RiCloseFill />
        </S.CloseButton>
      </S.Header>
      <S.FullGalleryWrapper>
        <Slider
          dots
          canSwipe
          isGallery
          autoplay={false}
          slidesToShow={1}
          id="main-gallery"
          slides={images}
        />
      </S.FullGalleryWrapper>
    </S.Wrapper>
  );
};
