import { SetStateAction, Dispatch } from 'react';
import * as S from './styles';

import { RiInformationLine } from "react-icons/ri";

interface OrderNumberProps {
    erpNumber: string;
    setErpNumber: Dispatch<SetStateAction<string>>;
}

const OrderNumber = ({ erpNumber, setErpNumber } : OrderNumberProps) => {

    return (
        <S.OrderNumberBlock>
            <S.OrderInput
            value={erpNumber}
            onChange={(e) => setErpNumber(e?.target?.value)}
            type='text'
            placeholder='Meu número do pedido'
            />
            <S.OrderMessage>
                <RiInformationLine />
                <span>Caso tenha um número de pedido gerado pelo seu sistema de gestão (ERP) informe no campo acima.</span>
            </S.OrderMessage>
        </S.OrderNumberBlock>
    )
}

export default OrderNumber;