import * as S from './styled';
import { useMemo, useState, useCallback } from 'react';

interface Props {
  id: string;
  value: number;
  setValue: (val: number) => void;
}

const InputRange = ({ value, id, setValue }: Props) => {
  const [max] = useState(9);
  const [min] = useState(-5);

  const percentage = useMemo(() => (100 * (value - min)) / (max - min), [value, min, max]);

  const points = Array(max - min + 1).fill(0);

  // A função handleChange é chamada somente na interação direta do usuário
  const handleChange = useCallback((event: any) => {
    setValue(Number(event.target.value));
  }, [setValue]);

  return (
    <S.ContentInput>
      <S.TooltipContent>
        <S.TooltipValue percentage={percentage}>{value.toFixed(2)}%</S.TooltipValue>
      </S.TooltipContent>

      {/* Alterado de onInput para onChange para evitar execuções desnecessárias */}
      <S.Input
        min={min}
        max={max}
        step="0.02"
        type="range"
        value={value}
        percentage={percentage}
        className="range-slider-input"
        data-test={`input-range-${id}`}
        theme={value > 0 ? 'success' : 'danger'}
        onChange={handleChange} // Usando onChange ao invés de onInput
      />

      <S.ContentMark>
        {points.map((_, ind) => {
          const count = min + ind;
          return (
            <S.Mark key={`content-mark-${id}-${ind}`} onClick={() => handleChange({ target: { value: count }})}>
              {(count === min || count === max || !count) && <S.ValueContent>{count}%</S.ValueContent>}
            </S.Mark>
          );
        })}
      </S.ContentMark>
    </S.ContentInput>
  );
};

export default InputRange;
