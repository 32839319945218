interface CustomizableOptionsValue {
    customizable_option_value_uid: string,
    label: string,
    value: string
}

interface CustomizableOptions {
    __typename: 'SelectedCustomizableOptions',
    customizable_option_uid: string,
    label: string,
    values: CustomizableOptionsValue[]
}

interface Price {
    __typename: 'Money',
    currency: string,
    value: number
}

interface RowTotal {
    value: number,
    __typename: 'Money'
}

interface TotalItemDiscount {
    __typename: 'Money',
    value: number
}

interface Prices {
    price: Price,
    row_total: RowTotal,
    total_item_discount: TotalItemDiscount
}

// Products interface

interface CartInfo {
    __typename: 'CartInfo',
    item_id: string,
    quantity: number,
    uid: string
}

interface Categories {
    __typename: 'CategoryTree',
    breadcrumbs: string | null,
    children_count: string,
    default_sort_by: string | null,
    description: string | null,
    display_mode: string | null,
    image: string | null,
    name: string,
    uid: string,
    url_key: string,
    url_path: string
}

interface CustomAttributes {
    __typename: 'CustomAttributes',
    attribute_code: string,
    default_label: string | null,
    value: string
}

interface OptionsValue {
    __typename: 'CustomizableDropDownValue',
    option_type_id: number,
    price: number,
    price_base: number,
    price_st: number,
    quantity: number,
    sku: string,
    title: string,
    uid: string
}

interface Options {
    __typename: 'CustomizableDropDownOption',
    required: boolean,
    sort_order: number,
    title: string,
    uid: string,
    value: OptionsValue[],

}

interface SmallImage {
    __typename: 'ProductImage',
    url: string
}

interface Thumbnail {
    __typename: 'ProductImage',
    url: string
}

interface Product {
    __typename: 'SimpleProduct',
    cart_info: CartInfo,
    categories: Categories[],
    custom_attributes: CustomAttributes[],
    id: number,
    name: string,
    options: Options[],
    sku: string,
    small_image: SmallImage,
    stock_left: number,
    stock_status: string,
    thumbnail: Thumbnail,
    uid: string,
    url_key: string,
    url_suffix: string

}

interface CartItems {
    __typename: 'SimpleCartItem',
    customizable_options: CustomizableOptions[],
    errors: string,
    id: string,
    prices: Prices,
    product: Product,
    quantity: number,
    uid: string
}

const cartItemsMock: CartItems[] = [
    {
        __typename: 'SimpleCartItem',
        customizable_options: [
            {
                __typename: 'SelectedCustomizableOptions',
                customizable_option_uid: 'Y3VzdG9tLW9wdGlvbi80NzA5NTQ=',
                label: 'Embalagens',
                values: [
                    {
                        customizable_option_value_uid: 'Y3VzdG9tLW9wdGlvbi80NzA5NTQvODE5MzE3',
                        label: 'CX_24',
                        value: '819317'
                    }
                ],
            }
        ],
        errors: '',
        id: '269794',
        prices: {
            price: {
                __typename: 'Money',
                currency: 'BRL',
                value: 164
            },
            row_total: {
                __typename: 'Money',
                value: 164
            },
            total_item_discount: {
                __typename: 'Money',
                value: 0
            }
        },
        product: {
            __typename: 'SimpleProduct',
            cart_info: {
                __typename: 'CartInfo',
                item_id: '269794',
                quantity: 1,
                uid: 'MjY5Nzk0'
            },
            categories: [
                {
                    __typename: 'CategoryTree',
                    breadcrumbs: null,
                    children_count: '22',
                    default_sort_by: null,
                    description: null,
                    display_mode: 'PRODUCTS',
                    image: null,
                    name: 'infantil',
                    uid: 'NDMwNw',
                    url_key: 'infantil',
                    url_path: 'infantil'
                }
            ],
            custom_attributes: [
                {
                    __typename: "CustomAttributes",
                    attribute_code: "grp_marca",
                    default_label: "Marca",
                    value: 'Nestl\\u00e9'
                },
                {
                    __typename: "CustomAttributes",
                    attribute_code: "peso_grp",
                    default_label: null,
                    value: '"5.68 KG - CX | 5.78 KG - CX"'
                },
                {
                    __typename: "CustomAttributes",
                    attribute_code: "ean_grp",
                    default_label: null,
                    value: '"7891000099032"'
                }
            ],
            id: 76379,
            name: 'Farinha Láctea Nestlé Original Sachê 210g',
            options: [
                {
                    __typename: "CustomizableDropDownOption",
                    required: true,
                    sort_order: 1,
                    title: "Embalagens",
                    uid: "Y3VzdG9tLW9wdGlvbi80NzA5NTQ=",
                    value: [
                        {
                            __typename: "CustomizableDropDownValue",
                            option_type_id: 819317,
                            price: 164,
                            price_base: 164,
                            price_st: 0,
                            quantity: 24,
                            sku: "642550",
                            title: "CX",
                            uid: "Y3VzdG9tLW9wdGlvbi80NzA5NTQvODE5MzE3"
                        }
                    ]
                }
            ],
            sku: '642550',
            small_image: {
                __typename: 'ProductImage',
                url: 'https://mcstaging.bateforte.com.br/media/catalog/product/cache/cb46bf64594f649400be892ee44e967c/6/4/642550_3.jpg'
            },
            stock_left: 3792,
            stock_status: 'IN_STOCK',
            thumbnail: {
                __typename: 'ProductImage',
                url: 'https://mcstaging.bateforte.com.br/media/catalog/product/cache/cb46bf64594f649400be892ee44e967c/6/4/642550_3.jpg'
            },
            uid: 'NzYzNzk=',
            url_key: 'farinha-lactea-nestle-original-sache-210g',
            url_suffix: ''
        },
        quantity: 1,
        uid: 'MjY5Nzk0'
    },
]

export default cartItemsMock;