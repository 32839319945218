import React from 'react';
import * as S from './styles';
import { RiLoopRightLine, RiSignalWifiOffLine } from 'react-icons/ri';
import { colors } from '@/constants';

const SyncHeader = ({ syncOrder }: { syncOrder: () => void }) => {

  return (
    <S.Container>
      <S.Icon>
        <RiSignalWifiOffLine color={colors.brand.grafite} />

      </S.Icon>
        <S.Text>
          Pedido realizado sem acesso à internet
        </S.Text>
        <S.SubText>
        Se você está com acesso à internet, clique no botão abaixo para sincronizar seu pedido.
        </S.SubText>

        <S.SyncButton onClick={syncOrder}>
          <S.ButtonLabel>
            Sincronizar 
            <RiLoopRightLine size={18} color={colors.brand.white} />
          </S.ButtonLabel>
        </S.SyncButton>
    </S.Container>
  )
}

export default SyncHeader;