import * as S from "./styles";

interface Props {
  id: string;
  children: any; // Modal Body Content
  isOpen: boolean; // Default false
  shadown?: boolean; // Default true
  isLoading?: boolean; // Default false
  fullHeight?: boolean; // Default false
  direction?: "bottom" | "right"; // Default false
  bottomMargin?: number; // Defaults to zero
  headerIcon?: React.ReactNode; // Default false 
  closeModal: (
    val: boolean
  ) => void | React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal = ({
  id,
  isOpen,
  children,
  isLoading,
  fullHeight,
  closeModal,
  direction = "bottom",
  bottomMargin,
  headerIcon
}: Props) => {
  const close = () => !isLoading && closeModal(false);

  return (
    <S.ModalContainer
      className={`modal-content ${isOpen && "modal-content-open"}`}
    >
      <S.BgContainer
        onClick={close}
        $isopen={isOpen}
        direction={direction}
        data-test={`modal-component-film-close-${id}`}
      />
      <S.ModalContent
        $bottomMargin={bottomMargin}
        $fullHeight={fullHeight}
        $isopen={isOpen}
        direction={direction}
        showOverflow={headerIcon ? true : null}
      >
        {headerIcon &&
          <S.HeaderIcon>
              {headerIcon}
          </S.HeaderIcon>}
        <S.Header>
          {!isLoading && (
            <S.CloseIcon
              size={32}
              onClick={close}
              data-test={`modal-component-button-close-${id}`}
            />
          )}
        </S.Header>

        <S.ModalBody $fullHeight={fullHeight}>{children}</S.ModalBody>
      </S.ModalContent>
    </S.ModalContainer>
  );
};

export default Modal;
