import { colors } from "@/constants";
import styled from "styled-components";
import { RiSearchLine } from "react-icons/ri";

export const SearchIcon = styled(RiSearchLine) <{ $isgray?: boolean }>`
  color: ${(i) => (i.$isgray ? colors.gray.gray40 : colors.brand.white)};
`;

export const SearchContent = styled.div`
  display: inline-flex;
  justify-content: end;
  align-items: center;
`;

export const SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;

  width: 100%;
  padding: 20px;
  background-color: ${colors.brand.grafite};
  
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  flex-grow: 1;

  height: 40px;
  border-radius: 6px;
  background-color: ${colors.brand.white};

  svg{
    color: ${colors.brand.grafite} !important;
  }
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;

  width: 100%;
  
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  color: ${colors.gray.gray40};
`;

export const CloseSearch = styled.span`
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 500;
  line-height: 16.41px;
  color: ${colors.brand.white};
`;

export const ContetListCompany = styled.div`
  min-height: 100vh;
`;

export const Content = styled.div`
  padding-top: 15px;
  position: relative;
  height: 100vh;
  background-color: ${colors.gray.gray10};
`;

export const Background = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 136px;
  position: absolute;
  background-color: ${colors.brand.grafite};
`;

export const InputContent = styled.div`
  gap: 14px;
  width: 100%;
  padding: 20px;
  align-items: center;
  display: inline-flex;
  border-bottom: 1px solid ${colors.background1};
`;

export const Container = styled.div`
  text-align: center;
  min-height: 100vh;
  background: ${colors.background2};
`;

export const BottomContent = styled.div`
  bottom: 0px;
  width: 100%;
  position: sticky;
  background-color: #fff;
  padding: 20px 16px;
  border-top: 1px solid #e9e8e7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px -6px 20px 0px #00000026;
`;

export const Button = styled.button`
  width: 100%;
  color: #fff;
  border: none;
  padding: 18px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  background-color: #27ae61;
`;
